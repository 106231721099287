import React, { useState } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import ImageInput from "../util/ImageInput";

export default function SellerCompEditor(props) {
  const formStyle = props.formStyle;
  const { register, control, trigger, getValues, setValue } = useFormContext();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "Organizations",
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      <TitleInput
        register={register}
        defaultValue={props.sellerName + " Competition"}
      />
      {fields.map((news, newsIndex) => (
        <div key={news.id}>
          <div className={formStyle}>
            Company Name:
            <input {...register(`Organizations[${newsIndex}].CompanyName`)} />
            Logo:
            <ImageInput
              name={`Organizations[${newsIndex}].Logo`}
              control={control}
              setValue={setValue}
            ></ImageInput>
            Description:
            <TextareaAutosize
              {...register(`Organizations[${newsIndex}].Description`, {})}
              aria-label=""
              minRows={3}
            />
            Rating (Number no greater than 5):
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              step="0.01"
              {...register(`Organizations[${newsIndex}].Rating`, {
                valueAsNumber: true,
              })}
            />
            Review Count
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              {...register(`Organizations[${newsIndex}].ReviewCount`)}
            />
            <h4>BattleCard:</h4>
            <div /> {/*lazy div to balance out two column grid format*/}
            <br />
            Strengths:
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={[]}
                  onChange={(e, data) => field.onChange(data)}
                  freeSolo
                  autoSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Strengths"
                      placeholder="Press enter to add entries"
                    />
                  )}
                />
              )}
              name={`Organizations[${newsIndex}].BattleCard.Strengths`}
              type="select"
              control={control}
              // rules={rules}
            />
            Weaknesses:
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={[]}
                  onChange={(e, data) => field.onChange(data)}
                  freeSolo
                  autoSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Weaknesses"
                      placeholder="Press enter to add entries"
                    />
                  )}
                />
              )}
              name={`Organizations[${newsIndex}].BattleCard.Weaknesses`}
              type="select"
              control={control}
              // rules={rules}
            />
            Differentiators:
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={[]}
                  onChange={(e, data) => field.onChange(data)}
                  freeSolo
                  autoSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Differentiators"
                      placeholder="Press enter to add entries"
                    />
                  )}
                />
              )}
              name={`Organizations[${newsIndex}].BattleCard.Differentiators`}
              type="select"
              control={control}
              // rules={rules}
            />
            Landmine Questions:
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={[]}
                  onChange={(e, data) => field.onChange(data)}
                  freeSolo
                  autoSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Landmine Questions"
                      placeholder="Press enter to add entries"
                    />
                  )}
                />
              )}
              name={`Organizations[${newsIndex}].BattleCard.LandmineQuestions`}
              type="select"
              control={control}
              // rules={rules}
            />
            Source Name:
            <input
              {...register(
                `Organizations[${newsIndex}].BattleCard.Source.Name`
              )}
            />
            Source Logo:
            <input
              {...register(
                `Organizations[${newsIndex}].BattleCard.Source.Logo`
              )}
            />
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(newsIndex, moveToIndex);
                trigger();
              }}
              index={newsIndex}
              options={[...Array(getValues("Organizations").length).keys()]}
            ></MoveAutocomplete>
          </div>
          <DeleteButton
            onClick={() => {
              setLastDeleted(news);
              remove(newsIndex);
              trigger();
            }}
          >
            Delete Organizations
          </DeleteButton>
          <br />
          <Divider
            light
            style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
          />
        </div>
      ))}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Organizations
      </AddButton>
      <br />
      <br />
    </div>
  );
}

// let testData =
// {
//     "Title": "News",
//     "Organizations": [
//         {
//             "Title": "Moderna vs. Pfizer: Both Knockouts, but One Seems to Have the Edge",
//             "Link": "https://www.nytimes.com/2021/09/22/health/covid-moderna-pfizer-vaccines.html",
//             "ShortDescription": "A series of studies found that the Moderna vaccine seemed to be more protective as the months passed than the Pfizer-BioNTech vaccine.",
//             "Thumbnail": "https://drive.google.com/file/d/1YdpgLau-a4xB4sw-hckLD-cwKobl0LG_/view?usp=sharing",
//             "Source": [
//                 {
//                     "Name": "New York Times",
//                     "URL": "https://www.nytimes.com"
//                 }
//             ],
//             "PublishedDate": "9/22/2021",
//             "Authors": [
//                 "Apoorva Mandavilli"
//             ]
//         },
//         {
//             "Title": "I want a normal Thanksgiving’: Covid booster shot recipients say of third Pfizer dose",
//             "Link": "https://www.cnbc.com/2021/09/30/pfizer-covid-booster-recipients-want-a-normal-thanksgiving-after-getting-third-shots.html",
//             "ShortDescription": "Four people who received Pfizer’s booster shot said they felt relieved after getting the extra dose and experienced minimal side effects.They said they got the extra doses over the fear that they could expose themselves or their loved ones to the delta variant and become severely sick. “I didn’t want to bring it home to my wife or other members of my family,” California resident Wayne Adams said.",
//             "Thumbnail": "https://drive.google.com/file/d/1chlE8bwsT4bmasDgQXOKL1FFWr_Q2fjW/view?usp=sharing",
//             "Source": [
//                 {
//                     "Name": "CNBC",
//                     "URL": "https://www.cnbc.com"
//                 }
//             ],
//             "PublishedDate": "9/30/2021",
//             "Authors": [
//                 "Berkeley Lovelace Jr."
//             ]
//         }
//     ],
//     "NewUpdate": false
// };
