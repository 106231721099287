import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./global.css";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import JwtService from "./user/JwtService";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import PosthogPageTracker from "./context/PosthogPageTracker";

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: "https://app.posthog.com",
  disable_session_recording: true,
  capture_pageview: false,
});

axios.interceptors.request.use(
  (request) => {
    // Edit request config
    request.headers.Authorization = "Bearer " + JwtService.getToken();
    return request;
  },
  (error) => {
    // if (error.)
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Edit request config
    return response;
  },
  (error) => {
    // Don't catch errors for 400s (these are not genuine errors)
    if (error.response) {
      if (error.response.status >= 400 && error.response.status < 500) {
        return error.response;
      }
    }
    console.log(error);
    // if (error.)
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <BrowserRouter>
    <PosthogPageTracker />
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
