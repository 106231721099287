import React from "react";
import { Typography, Button, Collapse } from "@mui/material";
import { rule5BaseUrl, rule5properties } from "../properties";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useUser } from "../context/UserContext";
import { isSuccessStatus } from "../common/RequestUtils";
import useSnack from "../context/Snack";
import { useLocation } from "react-router-dom";

export default function ContactSalesConfirmation(props) {
  const user = useUser();
  const snackBar = useSnack();
  const location = useLocation();

  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const CALENDLY_LINK = rule5BaseUrl.includes("dev")
    ? "https://calendly.com/ramana-rule5/30min"
    : "https://calendly.com/eric-rule5ai/15min";

  return (
    <>
      <Typography variant="body2" sx={{ mb: "30px" }}>
        Explore our plans to find the perfect fit for your needs and discover
        how rule5 can help you achieve your goals. For further guidance, connect
        with one of our experienced and knowledgeable team members to discuss
        your specific requirements.
        {!location.pathname.includes("manageplan") && (
          <>
            {" "}
            <a href="/main/settings/manageplan" target="_blank">
              View plan details.
            </a>
          </>
        )}
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "15px",
          width: "fit-content",
          marginLeft: "auto",
        }}
      >
        <LoadingButton
          variant="outlined"
          sx={{ textTransform: "none", width: "100%" }}
          loading={loading}
          disabled={isEmailSent}
          onClick={() => {
            setLoading(true);
            const params = {
              to: "sales@rule5.io",
              body: `User requesting upgrade: ${user.email}`,
              subject: "A user is interested in upgrading",
            };
            axios
              .post(rule5properties.emailSend, params)
              .then((res) => {
                setLoading(false);
                if (isSuccessStatus(res.status)) {
                  setIsEmailSent(true);
                } else {
                  snackBar.createSnack("Error sending email.");
                }
              })
              .catch((err) => {
                console.log("error sending email: " + JSON.stringify(err));
                setLoading(false);
              });
          }}
        >
          Send Email
        </LoadingButton>
        <Button
          onClick={() => window.open(CALENDLY_LINK, "_blank", "noreferrer")}
          variant="contained"
          disableElevation
          sx={{ textTransform: "none", width: "100%" }}
        >
          Schedule Call
        </Button>
      </div>
      <Collapse in={isEmailSent}>
        <Typography textAlign="right" variant="body2" sx={{ mt: "15px" }}>
          Thanks for your upgrade request. We will be in touch with you asap!
        </Typography>
      </Collapse>
    </>
  );
}
