import { makeStyles } from "@mui/styles";
import { ContentBlock, ContentState, convertToRaw } from "draft-js";
import { convertFromHTML } from "draft-convert";

export const getUserlabel = (user) => {
  return `${user.firstName} ${user.lastName} (${user.email})`;
};

export const dataGridStyles = makeStyles(() => ({
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "15px",
      marginRight: "15px",
    },
    card: {},
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            marginLeft: "-6px",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          // color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-row": {
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
  },

  linkText: {
    // textDecoration: "none",
    // "&:hover": {
    //   textDecoration: "underline",
    // },
    "&:visited": {
      color: "rgb(0, 0, 238)",
    },
  },
  tableCellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const formatUrl = (url) => {
  let urlCopy = url.replace(
    "storage.cloud.google.com",
    "storage.googleapis.com"
  );
  return urlCopy;
};

// Generates an array from an HTML ordered list.
export const convertFromHtmlListToArray = (html) => {
  const liRegex = /<li>(.*?)<\/li>/g;
  const liMatches = html.match(liRegex);
  const resultArray = liMatches.map((li) => li.replace(/<\/?li>/g, ""));
  return resultArray;
};

// Generates a content state with an unordered list from an array of strings.
export const generateRawBulletContentStateFromArray = (array) => {
  let bullets = [];
  // Create the content blocks.
  array.forEach((summaryItem, summaryItemIndex) => {
    const contentBlock = new ContentBlock({
      key: "summaryItem-" + summaryItemIndex,
      type: "unordered-list-item",
      text: summaryItem,
      depth: 0,
    });
    console.log(contentBlock);
    bullets.push(contentBlock);
  });

  console.log(bullets);

  // Create the content state.
  let contentState = ContentState.createFromBlockArray(bullets);
  return convertToRaw(contentState);
};

export const convertHtmlToRawContentState = (html) => {
  const contentState = convertFromHTML(removeTrailingTags(html));
  return convertToRaw(contentState);
};

/** Removes any broken tags if found. */
export function removeTrailingTags(str) {
  if (str?.endsWith("</")) {
    return str.slice(0, -2);
  } else if (str?.endsWith("<")) {
    return str.slice(0, -1);
  } else {
    return str;
  }
}
