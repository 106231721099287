import React, { useEffect } from "react";
import Broken from "../res/broken.png";
import axios from "axios";
import { rule5properties } from "../properties";
import { Fade } from "@mui/material";
import { useUser } from "../context/UserContext";
import { detRoles } from "../det/DetMainPage";

export default function Image(props) {
  // Snackbar Start

  let { src, ...other } = props; // remove src
  const [firstTime, setFirstTime] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  // What's the purpose of the default srcVal set below?
  const [srcVal, setSrcVal] = React.useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  );
  const [srcCheck, setSrcCheck] = React.useState(null);
  const user = useUser();

  const processSource = (src) => {
    if (!src) {
      setSrcVal(Broken);
      setLoaded(true);
    } else if (
      src.startsWith("https://storage.googleapis.com") ||
      src.startsWith("https://storage.cloud.google.com")
    ) {
      // The backend image API's support links formatted like "storage.googleapis"
      // and "storage.cloud.google". Manually copy/pasting from GCP will result in using
      // the cloud.google variant.
      // Temporarily disabling the direct image access from buckets as this isn't enabled on staging / prod yet.
      // if (src.includes("rule5-dev-cards")) {
      //   setSrcVal(src);
      //   setLoaded(true);
      // } else {
      getSrcFromApi(src, user).then((res) => {
        setSrcVal(res);
        setLoaded(true);
      });
      // }
    } else if (src.substring(0, 4).toUpperCase() === "HTTP") {
      src = embedImageFromGoogleDrive(src);
      setSrcVal(src);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      setSrcCheck(src);
      processSource(src);
    }
  }, []);

  if (!firstTime && srcCheck !== src) {
    setSrcCheck(src);
    processSource(src);
  }

  return (
    // eslint-disable-next-line
    <Fade in={loaded}>
      <img alt="" src={srcVal} {...other} />
    </Fade>
  );
}

export function embedImageFromGoogleDrive(source) {
  const url = "https://drive.google.com/thumbnail?id=";
  const sharedPrefix = "https://drive.google.com/file/d/";
  const sharedSuffix = "/view?usp=sharing";
  let sourceCopy = source;
  sourceCopy = sourceCopy.replace(sharedPrefix, url);
  sourceCopy = sourceCopy.replace(sharedSuffix, "");
  return sourceCopy;
}

export async function getSrcFromApi(url, user) {
  // DET may add a queryString with timestamp so that the url value
  // has changed which will cause the desired re-render when underlying data has changed.
  // We don't actually want the querystring when parsing the image though.
  url = url?.split("?")[0];

  let parameter = "?url=" + encodeURIComponent(url);
  let options = {
    responseType: "arraybuffer",
    headers: { Accept: "image/*" },
  };

  // TODO remove this if DET gets moved to separate project
  const endpointUrl = detRoles.includes(user.role)
    ? rule5properties.detDownloads
    : rule5properties.getSecureFile;

  return axios
    .get(endpointUrl + parameter, options)
    .then((response) => {
      let rawdata = response.data;
      if (rawdata) {
        // Validate content
        const base64String = btoa(
          [].reduce.call(
            new Uint8Array(rawdata),
            function (p, c) {
              return p + String.fromCharCode(c);
            },
            ""
          )
        );
        return "data:image/png;base64," + base64String;
      } else {
        // API call failed
        console.log("Image retrieval fail: " + url);
        console.log(response);
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log("Image retrieval fail: " + url);
        console.log(error.response.status);
        console.log(error.response.data);
      }
      return;
    });
}
