import { useGridApiContext } from "@mui/x-data-grid-pro";
import { ErrorTooltip, StyledTextfield } from "./StyledComponents";
import React from "react";

function EditInputCell(props) {
  const { id, value, field, error, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const handleChange = (event, newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  React.useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus();
    }
  }, [hasFocus]);

  return (
    <ErrorTooltip title={error}>
      <StyledTextfield
        multiline
        inputRef={ref}
        name="texfield"
        value={value}
        onChange={handleChange}
        style={{ width: "100%", margin: "0px 10px" }}
        {...props}
      />
    </ErrorTooltip>
  );
}

export const renderEditInputCell = (params) => {
  return <EditInputCell {...params} />;
};
