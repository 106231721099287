import { Box, Collapse } from "@mui/material";
import React from "react";
import {
  CancelConfirmButtons,
  ErrLabel,
  StyledLabel,
  StyledTextfield,
} from "../../../common/StyledComponents";
import { Controller, useForm, useFormContext } from "react-hook-form";

export default function ConfigureAgent(props) {
  const { modAgent } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <StyledLabel>Name</StyledLabel>
      <StyledTextfield
        autoFocus
        {...register("name", { required: true })}
        error={!!errors?.name}
        fullWidth
        name={"name"}
        size="small"
      />
      {errors?.name && <ErrLabel>Name is required.</ErrLabel>}
      <StyledLabel sx={{ mt: 1.5 }}>Description</StyledLabel>
      <StyledTextfield
        multiline
        minRows={3}
        error={!!errors?.description}
        fullWidth
        {...register("description")}
        name="description"
      />
    </Box>
  );
}
