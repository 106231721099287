import React, { useEffect, useState } from "react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import makeStyles from "@mui/styles/makeStyles";
import { isString } from "lodash";

const styleMap = {
  HYPERLINK: {
    color: "rgb(25, 118, 210)",
    textDecoration: "underline",
  },
};

const useStyles = makeStyles(() => ({
  editorStyle: {
    padding: "1rem",
    border: "1px solid rgb(215, 215, 215)",
    "& div": {
      margin: 0,
      fontSize: (props) => (props.fontSize ? props.fontSize : 16),
      fontFamily: "Arial",
    },
  },
}));

const RichTextEditor = (props) => {
  const classes = useStyles(props);
  const getDefaultValue = (value) => {
    // Value shouldn't really be a string unless field is being updated from plain text to rte (e.g. during dev).
    if (!value || isString(value)) {
      return EditorState.createEmpty();
    }
    return EditorState.createWithContent(convertFromRaw(value));
  };
  const [editorState, setEditorState] = useState(getDefaultValue(props.value)); //props.value -> undefined if new, else blocks
  const [updated, setUpdated] = useState(false);
  const onEditorStateChange = (editorState) => {
    setUpdated(true);
    setEditorState(editorState);
    return props.onChange(convertToRaw(editorState.getCurrentContent()));
  };

  // allows for generated editor values to be updated from the parent.
  useEffect(() => {
    if (props.generatedValue && !isString(props.generatedValue)) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(props.generatedValue))
      );
      props.clearGeneratedValue();
    }
  }, [props.generatedValue]);

  useEffect(() => {
    if (!updated) {
      const state =
        props.value && !isString(props.value)
          ? EditorState.createWithContent(convertFromRaw(props.value))
          : EditorState.createEmpty();
      setEditorState(state);
    }
  }, [props.value]);

  return (
    <div className="editor">
      <Editor
        customStyleMap={styleMap}
        editorState={editorState}
        editorClassName={classes.editorStyle}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          link: { defaultTargetOption: "_blank", showOpenOptionOnHover: false },
          fontSize: props.fontSize ? props.fontSize : 16,
        }}
      />
    </div>
  );
};

export default RichTextEditor;
