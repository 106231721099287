import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Fade,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Image from "../../common/Image";
import { rule5AppUrl, rule5properties } from "../../properties";
import { StyledTextfield } from "../../common/StyledComponents";
import ClearIcon from "@mui/icons-material/Clear";
import { useDialog } from "../../context/DialogContext";
import ResearchCompany from "../../modal/ResearchCompany";
import { throttle } from "lodash";
import {
  createUrlSearchParams,
  useDisplayContext,
} from "../../context/DisplayContext";

/* Landing page that automatically searches and loads opportunities for provided company name. */
export default function SFLanding(props) {
  let params = new URLSearchParams(document.location.search);
  // If redirectType is unspecified, it will default to the company research. either research or gpt.
  const redirectType = params.get("redirect");

  const displayContext = useDisplayContext();

  const [searchValue, setSearchValue] = React.useState(
    params.get("companyName")
  );
  const [statusMessage, setStatusMessage] = React.useState(
    "Continue typing to search companies."
  );
  const [loading, setLoading] = React.useState(true);
  const [accountList, setAccountList] = React.useState([]);
  const [selectedAccount, setSelectedAccount] = React.useState(-1);
  const [selectedAccountOpps, setSelectedAccountOpps] = React.useState([]);

  const dialog = useDialog();

  // Throttling function for company search.
  const fetchCompanies = React.useMemo(
    () =>
      throttle((input, callback) => {
        axios.get(`${rule5properties.companies}?name=${input}`).then((res) => {
          if (res.data) {
            callback(res.data);
          } else {
          }
        });
      }, 200),
    []
  );

  // When the search value is changed, call the API to search for companies.
  useEffect(() => {
    setSelectedAccount(-1);
    setSelectedAccountOpps([]);
    if (searchValue?.trim().length > 2) {
      setLoading(true);
      // Search the endpoint for accounts that match the provided account name.
      // If there's multiple accounts that match, display list of results for user to select.
      fetchCompanies(encodeURIComponent(searchValue), (result) => {
        setLoading(false);
        if (Array.isArray(result)) {
          let accountList = result;
          setAccountList(accountList);
          if (accountList.length === 0) {
            // No account match, allow user to request research on this company.
            setStatusMessage(`No results found for "${searchValue}".`);
          }
          if (accountList.length === 1) {
            // Automatically select the one account in the list.
            setSelectedAccount(0);
          }
          if (accountList.length > 1) {
            // If more than one account is in the list, first search the list to see if there are any exact matches.
            let exactMatch = accountList.findIndex(
              (account) =>
                account.name.trim().toLowerCase() ===
                searchValue?.trim().toLowerCase()
            );
            if (exactMatch >= 0) {
              setSelectedAccount(exactMatch);
            } else {
              setStatusMessage(
                `There were multiple accounts found for "${searchValue}". Specify a company to research.`
              );
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      setStatusMessage("Continue typing to search companies.");
      setLoading(false);
      setAccountList([]);
    }
  }, [searchValue, fetchCompanies]);

  // Selected account was changed, call endpoint that returns list of researches for the selected account.
  useEffect(() => {
    if (selectedAccount >= 0) {
      setLoading(true);
      axios
        .get(
          rule5properties.getOpportunityList +
            `?companyId=${accountList[selectedAccount]?.id}`
        )
        .then((res) => {
          if (res.data && Array.isArray(res.data)) {
            setSelectedAccountOpps(res.data);
          } else {
            console.log(
              "Issue fetching opportunities for company: " +
                accountList[selectedAccount].name
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setSelectedAccountOpps([]);
    }
  }, [selectedAccount, accountList]);

  // After the account was selected, the opportunities were loaded for the account and displayed.
  useEffect(() => {
    // If we are in research mode, we can perform a redirect to the URL parameter specified opportunity.
    if (redirectType !== "gpt") {
      // If the selected account only has one opportunity, redirect to that opportunity.
      if (selectedAccountOpps.length === 1) {
        window.location.replace(
          `${rule5AppUrl}/main/opportunities/${
            selectedAccountOpps[0].id
          }/research?${createUrlSearchParams(
            selectedAccountOpps[0],
            accountList[selectedAccount].id
          )}`
        );
      }
      // See if there is an opportunity that matches the provided functional area.
      let matchedOpp = selectedAccountOpps.find(
        (el) =>
          el.functionalArea.toLowerCase() ===
          params.get("functionalArea")?.toLowerCase()
      );
      if (matchedOpp) {
        window.location.replace(
          `${rule5AppUrl}/main/opportunities/${
            matchedOpp.id
          }/research?${createUrlSearchParams(
            matchedOpp,
            accountList[selectedAccount].id
          )}`
        );
      }
    }
  }, [selectedAccountOpps, params]);

  return (
    <Stack alignItems="center">
      <Stack sx={{ p: 3, width: "600px" }}>
        <StyledTextfield
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          sx={{ mb: 2 }}
          endAdornment={
            <InputAdornment>
              <IconButton size="small">
                <ClearIcon
                  sx={{ width: "18px", height: "18px" }}
                  onClick={() => {
                    setSearchValue("");
                  }}
                />
              </IconButton>
            </InputAdornment>
          }
        />
        {selectedAccount < 0 ? (
          <Box>
            <Typography sx={{ mb: 2, mx: 2, opacity: 0.8 }}>
              {statusMessage}
            </Typography>
            <Box>
              <Collapse
                in={loading}
                unmountOnExit
                sx={{ alignItems: "center" }}
              >
                <Stack alignItems="center">
                  <CircularProgress sx={{ my: 3 }} />
                </Stack>
              </Collapse>
              {accountList?.map((account, index) => (
                <AccountListItem
                  account={account}
                  key={index}
                  clickFunction={() => {
                    setSelectedAccount(index);
                  }}
                />
              ))}
            </Box>
          </Box>
        ) : (
          <Box>
            <Stack direction="row" alignItems="center">
              <Tooltip title="Return to list">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSelectedAccount(-1);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography sx={{ ml: 2, mt: "2px", fontSize: "16px", mb: 1 }}>
                Opportunities for {accountList[selectedAccount]?.name}
              </Typography>
            </Stack>
            <Stack>
              <Collapse
                in={loading}
                unmountOnExit
                sx={{ alignItems: "center" }}
              >
                <Stack alignItems="center">
                  <CircularProgress sx={{ my: 3 }} />
                </Stack>
              </Collapse>
              {selectedAccountOpps.map((opp, index) => (
                <OpportunityListItem
                  opp={opp}
                  account={accountList[selectedAccount]}
                  key={index}
                  redirectType={redirectType}
                />
              ))}
            </Stack>
          </Box>
        )}
        {searchValue?.trim().length > 2 && !loading && (
          <Stack sx={{ mt: 6, mx: 6 }} alignItems="center">
            <Typography sx={{ opacity: 0.7, fontSize: "14px" }}>
              Couldn't find the exact company research you were looking for? You
              can request a company's research using the button below.
            </Typography>
            <Button
              disableElevation
              variant="contained"
              sx={{
                width: "200px",
                textTransform: "none",
                mt: 2,
              }}
              onClick={(event) =>
                dialog.openModal(
                  "Research Company",
                  ResearchCompany,
                  null,
                  "sm"
                )
              }
            >
              Request research
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

/* Component for list item displaying an account */
function AccountListItem(props) {
  return (
    <Card
      variant="outlined"
      sx={{ p: 2, borderRadius: "6px", mt: 1, cursor: "pointer" }}
      onClick={props.clickFunction}
    >
      <Stack direction="row" alignItems="center" sx={{ height: "36px" }}>
        {props.account.companyInfo.icon ? (
          <Image
            style={{ maxHeight: "30px", maxWidth: "30px" }}
            src={props.account.companyInfo.icon}
          />
        ) : (
          <img
            loading="lazy"
            width="30"
            src={`https://logo.clearbit.com/${props.account.domain}?s=128`}
            alt=""
          />
        )}
        <Box sx={{ ml: 3 }}>
          <Typography>{props.account.name}</Typography>
        </Box>
      </Stack>
    </Card>
  );
}

/* Component for list item displaying an opportunity */
function OpportunityListItem(props) {
  let { opp, redirectType, account } = props;
  return (
    <Card
      variant="outlined"
      sx={{ p: 2, borderRadius: "6px", mt: 1, cursor: "pointer" }}
      onClick={
        redirectType !== "gpt"
          ? () => {
              window.location.replace(
                `${rule5AppUrl}/main/opportunities/${
                  opp.id
                }/research?${createUrlSearchParams(opp, account.id)}`
              );
            }
          : () => {
              // Redirect type is GPT.
              window.location.replace(
                `${rule5AppUrl}/main/chat?${createUrlSearchParams(
                  opp,
                  account.id
                )}&filterToDefault=true`
              );
            }
      }
    >
      {opp?.functionalArea}
    </Card>
  );
}
