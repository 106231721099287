import React, { useRef } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ExpandMore from "../../../../common/ExpandMore";
import CloseItem from "../../../../common/CloseItem";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import IconButton from "@mui/material/IconButton";
import { useDialog } from "../../../../context/DialogContext";
import FlagItem from "../../../../modal/FlagItem";
import { Box, Collapse, useTheme } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

const useStyles = makeStyles(() => ({
  cardstyle: {
    textAlign: "center",
    backgroundColor: "white",
    userSelect: "text",
  },
  cardPadding: {
    padding: "8px 56px 40px 56px",
  },
  cardstyleHighlight: {
    textAlign: "center",
    backgroundColor: "white",
    boxShadow: "5px 5px 10px #8884d8",
  },
  cardTitle: {
    fontSize: "14pt",
    textAlign: "left",
    color: "#333333",
    fontWeight: 500,
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    userSelect: "none",
    cursor: "pointer",
    "& .MuiCardHeader-action": {
      margin: 0,
    },
  },
  dragIndicatorClass: {
    cursor: "grab",
    color: "rgba(0,0,0,0.2)",
    position: "absolute",
    left: "50%",
    WebkitTransform: "translate(-50%, -30%) rotate(90deg) scale(0.8)",
    transform: "translate(-50%, -30%) rotate(90deg) scale(0.8)",
    "&:active": {
      cursor: "-webkit-grabbing!important",
      cursor: "-moz-grabbing!important",
      cursor: "-o-grabbing!important",
      cursor: "-ms-grabbing!important",
      cursor: "grabbing!important",
    },
  },
}));

export default function Researchcard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dialog = useDialog();
  const cardContainerRef = useRef();

  return (
    <Card
      className={props.viewed ? classes.cardstyle : classes.cardstyleHighlight}
      variant="outlined"
      ref={cardContainerRef}
    >
      <CardHeader
        {...props.dragHandleProps}
        sx={{ ".MuiCardHeader-avatar": { marginRight: "8px" } }}
        className={classes.cardHeader}
        onClick={(e) => {
          props.toggleExpanded();
        }}
        avatar={
          props.expandHandler && ( // Only show expandmore arrow if handler is provided
            <ExpandMore
              style={{
                color: theme.opportunity.research.cardHeaderIconsColor,
              }}
              expand={props.expanded}
              aria-expanded={props.expanded}
              aria-label="show more"
              sideways="true"
            />
          )
        }
        action={
          <Box>
            {props.hasSync ? (
              <Tooltip title="Sync to Salesforce">
                <IconButton
                  sx={{
                    color: theme.opportunity.research.cardHeaderIconsColor,
                    transform: "scale(" + theme.cards.header.iconScale + ")",
                  }}
                  onClick={props.handleSync}
                >
                  <SyncIcon style={props.style ? props.style : {}} />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Give feedback">
              <IconButton
                aria-label="settings"
                onClick={(e) => {
                  dialog.openModal(
                    props.title,
                    FlagItem,
                    { opsId: props.opsId, cardId: props.cardId },
                    "sm"
                  );
                  e.stopPropagation();
                }}
                sx={{
                  color: theme.opportunity.research.cardHeaderIconsColor,
                  transform: "scale(" + theme.cards.header.iconScale + ")",
                }}
              >
                <EmojiFlagsIcon style={props.style ? props.style : {}} />
              </IconButton>
            </Tooltip>
            <CloseItem
              onClick={props.closeCard}
              style={{
                color: theme.opportunity.research.cardHeaderIconsColor,
                transform: "scale(" + theme.cards.header.iconScale + ")",
              }}
            />
          </Box>
        }
        title={
          <div className={classes.cardTitle}>
            <span style={{ cursor: "pointer" }}>{props.title}</span>&nbsp;
            {!props.viewed && (
              <Chip sx={{ ml: 2 }} label="Updated" size="small" />
            )}
            {/* <DragIndicatorIcon className={classes.dragIndicatorClass} /> */}
          </div>
        }
      />
      <Collapse
        in={props.expanded}
        collapsedSize={0}
        style={{ position: "relative" }}
      >
        <div className={classes.cardPadding}>{props.children}</div>
      </Collapse>
    </Card>
  );
}

Researchcard.propTypes = {
  expanded: PropTypes.bool,
  expandHandler: PropTypes.bool,
  toggleExpanded: PropTypes.func.isRequired,
  opsId: PropTypes.number.isRequired,
  cardId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  closeCard: PropTypes.func.isRequired,
  viewed: PropTypes.bool.isRequired,
  dragHandleProps: PropTypes.any.isRequired,
};
