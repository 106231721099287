import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useEffect } from "react";
import { toMonthName } from "../common/Utils";

export default function PersonProfile({ data }) {
  const [expandSummary, setExpandSummary] = React.useState(true);
  const [expandQuotes, setExpandQuotes] = React.useState(false);

  const [summaryHeight, setSummaryHeight] = React.useState(0);
  const [quotesHeight, setQuotesHeight] = React.useState(0);

  const summaryRef = useRef();
  const quotesRef = useRef();

  useEffect(() => {
    setSummaryHeight(summaryRef?.current?.offsetHeight);
    setQuotesHeight(quotesRef?.current?.offsetHeight);
  }, []);

  const profileInfo = data.PersonProfile;
  return (
    <Box>
      {data.Title && (
        <Typography
          sx={{ fontSize: "16px", fontWeight: 400, opacity: 0.8, mb: 2 }}
        >
          {data.Title}
        </Typography>
      )}
      {(profileInfo.Summary?.trim().length > 0 ||
        profileInfo.Languages?.length > 0 ||
        data.managementQuotes?.length > 0) && (
        <Box sx={{ mt: 1, mb: 3 }}>
          {profileInfo.Summary?.trim().length > 0 && (
            <React.Fragment>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "24px",
                  fontWeight: 500,
                  mb: 1,
                  opacity: 0.8,
                }}
              >
                Summary
              </Typography>
              <Collapse
                in={expandSummary}
                collapsedSize={Math.min(summaryHeight, 160)}
                sx={{ overflow: "hidden" }}
              >
                <Box ref={summaryRef}>
                  <Typography>{`${profileInfo.Summary}`}</Typography>
                </Box>
              </Collapse>
              {summaryRef.current?.clientHeight > 180 && (
                <Divider
                  sx={{ mx: 8, mt: 2, cursor: "pointer", fontSize: "12px" }}
                  onClick={() => {
                    setExpandSummary(!expandSummary);
                  }}
                >
                  <Typography variant="caption" sx={{ opacity: 0.8 }}>
                    Show {expandSummary ? "less" : "more"}
                  </Typography>
                </Divider>
              )}
            </React.Fragment>
          )}
          {data.managementQuotes?.length > 0 && (
            <React.Fragment>
              <Typography
                variant="h1"
                sx={{
                  mt: 3,
                  fontSize: "24px",
                  fontWeight: 500,
                  mb: 1,
                  opacity: 0.8,
                }}
              >
                Quotes
              </Typography>
              <Collapse
                in={expandQuotes}
                collapsedSize={Math.min(quotesHeight, 160)}
              >
                <Box sx={{ fontStyle: "italic", opacity: 0.7 }} ref={quotesRef}>
                  {data.managementQuotes.map((quote) => (
                    <Typography sx={{ mb: 1 }}>{quote.quote}</Typography>
                  ))}
                </Box>
              </Collapse>
              {quotesHeight > 180 && (
                <Divider
                  sx={{ mx: 8, mt: 2, cursor: "pointer", fontSize: "12px" }}
                  onClick={() => {
                    setExpandQuotes(!expandQuotes);
                  }}
                >
                  <Typography variant="caption" sx={{ opacity: 0.8 }}>
                    Show {expandQuotes ? "less" : "more"}
                  </Typography>
                </Divider>
              )}
            </React.Fragment>
          )}
          {profileInfo.Languages?.length > 0 && (
            <React.Fragment>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "24px",
                  fontWeight: 500,
                  mt: 3,
                  mb: 1,
                  opacity: 0.8,
                }}
              >
                Languages
              </Typography>
              <Typography
                sx={{
                  opacity: profileInfo.Languages?.length > 0 ? 1 : 0.5,
                  fontStyle:
                    profileInfo.Languages?.length > 0 ? "normal" : "italic",
                }}
              >
                {profileInfo.Languages?.map((language, index) => {
                  return (
                    language.charAt(0).toUpperCase() +
                    language.slice(1) +
                    (index === profileInfo.Languages.length - 1 ? "" : ", ")
                  );
                })}
              </Typography>
            </React.Fragment>
          )}
        </Box>
      )}
      <Stack direction="row">
        <Box sx={{ flex: 1 }}>
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: "24px", fontWeight: 500, mb: 1, opacity: 0.8 }}
            >
              Work Experience
            </Typography>
            {profileInfo.WorkExperience?.length > 0 ? (
              <React.Fragment>
                {profileInfo.WorkExperience?.map((work, index) => (
                  <Position position={work} key={index} />
                ))}
              </React.Fragment>
            ) : (
              <Typography sx={{ opacity: 0.5, fontStyle: "italic", mb: 3 }}>
                Not available
              </Typography>
            )}
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mx: 3 }} />
        <Box sx={{ flex: 1 }}>
          {/* education */}
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: "24px", fontWeight: 500, mb: 1, opacity: 0.8 }}
            >
              Education
            </Typography>
            {profileInfo.Education?.length > 0 ? (
              <React.Fragment>
                {profileInfo.Education?.map((education, index) => (
                  <Education education={education} key={index} />
                ))}
              </React.Fragment>
            ) : (
              <Typography sx={{ opacity: 0.5, fontStyle: "italic", mb: 3 }}>
                Not available
              </Typography>
            )}
          </Box>
          {/* boards */}
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="h1"
              sx={{ fontSize: "24px", fontWeight: 500, mb: 1, opacity: 0.8 }}
            >
              Boards
            </Typography>
            {profileInfo.Boards?.length > 0 ? (
              <React.Fragment>
                {profileInfo.Boards?.map((board, index) => (
                  <Position position={board} key={index} />
                ))}
              </React.Fragment>
            ) : (
              <Typography sx={{ opacity: 0.5, fontStyle: "italic", mb: 3 }}>
                Not available
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

function Position(props) {
  const { position } = props;
  return (
    <Card variant="outlined" sx={{ p: 2, mb: 1 }}>
      <Typography sx={{ fontWeight: 500, textDecoration: "underline" }}>
        {position.company}
      </Typography>
      <Typography>{position.location}</Typography>
      <Typography>{position.title}</Typography>
      <Typography sx={{ opacity: 0.5, fontStyle: "italic" }}>{`${
        position.startMonth
          ? toMonthName(position.startMonth) + " " + position.startYear
          : position.startYear
      } - ${
        position.endYear
          ? position.endMonth
            ? toMonthName(position.endMonth) + " " + position.endYear
            : position.endYear
          : position.startMonth
          ? "present"
          : ""
      } `}</Typography>
    </Card>
  );
}

function Education(props) {
  const { education } = props;
  return (
    <Card variant="outlined" sx={{ p: 2, mb: 1 }}>
      <Typography sx={{ fontWeight: 500, textDecoration: "underline" }}>
        {education.school}
      </Typography>
      <Typography>{education.degree}</Typography>
      <Typography>{education.fieldOfStudy}</Typography>
      <Typography sx={{ opacity: 0.5, fontStyle: "italic" }}>{`${
        education.startMonth
          ? toMonthName(education.startMonth) + " " + education.startYear
          : education.startYear
          ? education.startYear + " -"
          : ""
      }${
        education.endYear
          ? education.endMonth
            ? toMonthName(education.endMonth) + " " + education.endYear
            : education.endYear
          : education.startMonth
          ? "present"
          : ""
      } `}</Typography>
    </Card>
  );
}
