import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { detRoles } from "../det/DetMainPage";
import { rule5properties } from "../properties";
import axios from "axios";
import { COREAPP_USER_ROLES, useUser } from "../context/UserContext";
import { map } from "lodash";

const Joi = require("joi");

const emailSchema = Joi.string().email({ tlds: { allow: false } });

const useStyles = makeStyles(() => ({
  actionButtons: {
    paddingRight: "0.5",
    display: "flex",
    justifyContent: "right",
    width: "100%",
  },
}));

const statusOptions = ["Active", "Inactive", "Suspended"];

const newUserStatusOptions = ["Inactive", "Suspended"];

const fieldsNeedValidate = new Set(["email", "status", "role"]);

const defaultUserInformation = {
  id: -1,
  email: "",
  firstName: "",
  lastName: "",
  status: "",
  role: "",
};

const defaultErrorText = {
  email: "",
  status: "",
  role: "",
};

export default function UserForm(props) {
  const classes = useStyles();
  const [values, setValues] = useState(defaultUserInformation);
  const [errors, setErrors] = useState({ ...defaultErrorText });
  const [originalValues, setOriginalValues] = useState(defaultUserInformation);
  const [errorMessage, setErrorMessage] = useState("");
  const user = useUser();
  const [token, setToken] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;

    if (fieldsNeedValidate.has(name)) {
      validate({ [name]: value });
    }
  };

  const resetForm = () => {
    setValues(defaultUserInformation);
    setErrors({ ...defaultErrorText });
  };

  const { addOrEdit, recordForEdit, isCoreApp } = props;

  const getStatusOptions = () => {
    if (originalValues.id !== -1) {
      if (originalValues.status === "Invited") {
        return newUserStatusOptions;
      }
      return statusOptions;
    }
    return ["Invited"];
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (values.email != 0) {
        temp.email = emailSchema.validate(fieldValues.email).error
          ? "Email is not valid."
          : "";
      } else {
        temp.email = "";
      }
    }
    if ("status" in fieldValues) {
      temp.status =
        fieldValues.status.length != 0 ? "" : "This field is required.";
    }
    if ("role" in fieldValues) {
      temp.role = fieldValues.role.length != 0 ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) {
      if (fieldValues.id === -1) {
        return temp.email === "" && temp.role === "";
      }
      return Object.values(temp).every((x) => x == "");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      let url = isCoreApp
        ? `${rule5properties.inviteEmailList}`
        : `${rule5properties.detCreateUser}`;

      let userData = [{ email: values.email, role: values.role }];

      let params = isCoreApp
        ? { inviteList: userData, emailOptions: ["app"] }
        : { email: values.email, role: values.role };

      axios
        .post(url, params, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            addOrEdit(values, resetForm, response.status);
            return;
          }

          console.log(response);

          if (response.data.code === "INVALID_ARGUMENT") {
            setErrorMessage(response.data.message);
          }

          if (response.data.code === "USER_EXISTS") {
            setErrorMessage(
              "One or more of the provided users is already registered with rule5."
            );
          }
          if (response.data.code === "VALIDATION_ERROR") {
            setErrorMessage(
              "There was an issue with one or more of the provided emails."
            );
          }
          if (response.data.code === "UNAUTHORIZED") {
            setErrorMessage("User is not authorized to create invitations.");
          }

          addOrEdit(values, resetForm, response.status);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error);
            addOrEdit(values, resetForm, error.response.status);
          }
        });
    }
  };

  useEffect(() => {
    setToken(user.accessToken);
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
      setOriginalValues({ ...recordForEdit });
    }
  }, [recordForEdit]);

  return (
    <div>
      <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "250px", height: "80px" },
          }}
        >
          <TextField
            name="email"
            label="Email"
            value={values.email}
            onChange={handleInputChange}
            onBlur={handleOnBlur}
            size="small"
            variant="standard"
            required
            error={errors.email !== ""}
            helperText={errors.email}
            disabled={values.id !== -1}
          />

          {values.id !== -1 && (
            <TextField
              name="firstName"
              label="First Name"
              value={values.firstName}
              onChange={handleInputChange}
              size="small"
              variant="standard"
              disabled
            />
          )}

          {values.id !== -1 && (
            <TextField
              name="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleInputChange}
              size="small"
              variant="standard"
              disabled
            />
          )}
          {values.id !== -1 && (
            <FormControl
              variant="standard"
              required={values.id !== -1}
              sx={{ m: 1, minWidth: 120 }}
              error={errors.status !== ""}
            >
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={values.status}
                onChange={handleInputChange}
                label="Status"
              >
                <MenuItem value="">None</MenuItem>
                {getStatusOptions(values.id).map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
              {errors.status !== "" && (
                <FormHelperText>{errors.status}</FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl
            variant="standard"
            required
            sx={{ m: 1, minWidth: 120 }}
            error={errors.role !== ""}
          >
            <InputLabel>Role</InputLabel>
            <Select
              value={values.role}
              onChange={handleInputChange}
              label="Role"
              name="role"
            >
              <MenuItem value="">None</MenuItem>
              {isCoreApp
                ? map(COREAPP_USER_ROLES, (role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))
                : detRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
            </Select>
            {errors.role !== "" && (
              <FormHelperText>{errors.role}</FormHelperText>
            )}
          </FormControl>
        </Box>
        {errorMessage && (
          <Typography
            sx={{ fontSize: "14px", flexGrow: 1, mt: 1, color: "#e64539" }}
          >
            {errorMessage}
          </Typography>
        )}
        <div className={classes.actionButtons}>
          {values.id === -1 && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ marginRight: "20px", textTransform: "none" }}
              onClick={resetForm}
            >
              Reset
            </Button>
          )}
          <Button
            type="submit"
            sx={{ textTransform: "none" }}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </Box>
    </div>
  );
}
