import { Typography } from "@mui/material";
import { ErrorTooltip } from "../../../common/StyledComponents";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import React from "react";

export default function AgentListStatus(props) {
  const { row } = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  let textColor;
  switch (row?.status) {
    case "Error":
      textColor = "error";
      break;
    case "Active":
      textColor = "green";
      break;
    default:
      textColor = "inherit";
      break;
  }
  return (
    <ErrorTooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={row?.errorMessage}
    >
      <Typography
        color={textColor}
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "inherit",
          display: "flex",
        }}
      >
        {row?.status}
        {row?.status === "Error" && (
          <ErrorOutlineOutlinedIcon
            color="error"
            sx={{ mt: -1, ml: 0.5, fontSize: 14 }}
          />
        )}
      </Typography>
    </ErrorTooltip>
  );
}
