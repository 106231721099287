import React from "react";
import {
  Stack,
  Tabs,
  Tab,
  Box,
  IconButton,
  LinearProgress,
  Collapse,
} from "@mui/material";
import { StyledTab } from "../../workspaces/opportunity/OpportunityWindow";
import CompanyNotifications from "./CompanyNotifications";
import TimelineNotifications from "./TimelineNotifications";
import { useSidepanel } from "../../context/SidepanelContext";
import { useHistory } from "react-router-dom";
import { rule5properties } from "../../properties";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import NotificationsService from "./NotificationsService";

export default function Notifications(props) {
  // Notification view mode.
  const [notificationView, setNotificationView] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [notifications, setNotifications] = React.useState(props.notifications);

  const sidepanel = useSidepanel();
  const history = useHistory();

  function notificationsViewed(notifications) {
    setLoading(true);
    // Takes a list of notification IDs and marks them as viewed.
    let newIds = notifications.map((notif) => notif.id);
    const params = {
      ids: newIds,
      notificationStatus: "Viewed",
    };
    axios.patch(rule5properties.notifications, params).then((res) => {
      // Refresh the notifications list.
      props.getNotifications((res) => {
        setNotifications(res);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
    });
  }

  function notificationClicked(notification) {
    // Params can be either a single notification ID or an array of notifications. If array is passed, mark all
    // notifications in the array as viewed.
    let oppId;
    let notifIds = [];
    if (Array.isArray(notification)) {
      notifIds = notification.map((tempNotif) => tempNotif.id);
      oppId = notification[0].opportunityId;
    } else {
      notifIds = [notification.id];
      oppId = notification.opportunityId;
    }
    // Notification item is clicked, nagivate to the notification, call API to open notification, and close the panel.
    const params = {
      ids: notifIds,
      notificationStatus: "Viewed",
    };
    if (notification.notificationStatus === "New") {
      axios.patch(rule5properties.notifications, params).then((res) => {
        // Refresh the notifications list.
        props.getNotifications();
      });
    }
    history.push(`/main/opportunities/${oppId}/research`);
    sidepanel?.closeSidepanel();
  }

  // Delete a notification.
  function deleteNotification(notification) {
    const params = {
      ids: [notification],
    };
    // Trigger loading state change to ensure latest notifications data is rendered.
    setLoading(true);
    axios
      .delete(rule5properties.notifications, { data: params })
      .then((res) => {
        props.getNotifications((res) => {
          setNotifications(res);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
      });
  }

  const handleChange = (event, newValue) => {
    setNotificationView(newValue);
  };
  return (
    <Stack sx={{ mt: -1 }}>
      <Stack direction="row" sx={{ mb: 2 }} alignItems="center">
        <Tabs
          value={notificationView}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        >
          <StyledTab label="Timeline" value={0} />
          <StyledTab label="Company Updates" value={1} />
        </Tabs>
        <IconButton
          sx={{ width: "36px", height: "36px" }}
          onClick={() => {
            setLoading(true);
            props.getNotifications((res) => {
              setNotifications(res);
              setTimeout(() => {
                setLoading(false);
              }, 500);
            });
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Stack>
      <Collapse in={loading}>
        <LinearProgress sx={{ mt: 1, borderRadius: 2 }} />
      </Collapse>
      <Box
        sx={{ flexGrow: 1, transition: "0.25s", opacity: loading ? 0.5 : 1 }}
      >
        {notificationView === 0 ? (
          <TimelineNotifications
            notifData={notifications.all}
            newNotifs={notifications.new}
            oldNotifs={notifications.old}
            clickFunction={notificationClicked}
            notificationsViewed={notificationsViewed}
            deleteNotification={deleteNotification}
          />
        ) : (
          <CompanyNotifications
            notifData={notifications.all}
            clickFunction={notificationClicked}
            notificationsViewed={notificationsViewed}
            deleteNotification={deleteNotification}
          />
        )}
      </Box>
    </Stack>
  );
}
