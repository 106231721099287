import React, { createContext, useContext, useState, useEffect } from "react";
import { getSrcFromApi } from "../common/Image";
import { useUser } from "./UserContext";

const ImageMapContext = createContext();

const ImageMapProvider = ({ children }) => {
  const [imageDataMap, setImageDataMap] = useState(new Map());
  const user = useUser();

  const getImageData = async (url, callback) => {
    if (imageDataMap.has(url)) {
      const value = imageDataMap.get(url);
      if (value instanceof Promise) {
        // If a request is already in progress, wait for it and return the result
        const resolvedValue = await value;
        callback(resolvedValue);
      } else {
        callback(value);
      }
      const cachedData = imageDataMap.get(url);
      callback(cachedData);
    } else {
      // Data is not in the map, fetch it from the API
      const srcPromise = getSrcFromApi(url, user);
      imageDataMap.set(url, srcPromise);
      try {
        const src = await srcPromise;
        imageDataMap.set(url, src);
        callback(src);
      } catch (error) {
        // Handle error, if any
        imageDataMap.delete(url);
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <ImageMapContext.Provider value={{ getImageData }}>
      {children}
    </ImageMapContext.Provider>
  );
};

const useImageMapContext = () => {
  return useContext(ImageMapContext);
};

export { ImageMapProvider, useImageMapContext };
