import React from "react";
import {
  Collapse,
  Typography,
  Button,
  Box,
  CircularProgress,
  Autocomplete,
  Chip,
  createFilterOptions,
} from "@mui/material";
import { StyledTextfield, ErrLabel } from "../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";
import { Controller, useForm } from "react-hook-form";
import { arrayContainsArray } from "../common/Utils";
import useSnack from "../context/Snack";
import { isSuccessStatus } from "../common/RequestUtils";
import { union } from "lodash";

const filter = createFilterOptions();
const EMPTY_DEFAULTS = { category: "", values: [] };

export default function CreateCategory(props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [categories, setCategories] = React.useState([]);

  const { loadContent, content, defaultValues } = props;

  const {
    resetField,
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues ? defaultValues : EMPTY_DEFAULTS,
  });

  const dialog = useDialog();
  const snackBar = useSnack();

  const loadCategories = () => {
    setLoading(true);
    // todo search value
    axios
      .get(rule5properties.contentCategories)
      .then((response) => {
        if (!isSuccessStatus(response.status)) {
          snackBar.createSnack("Error fetching categories.");
        }
        setLoading(false);
        setCategories(response.data);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          snackBar.createSnack("There was a problem with loading prompt books");
        }
      });
  };

  React.useEffect(() => {
    loadCategories();
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();
    addCategory(data);
  };

  const addCategory = (data) => {
    if (!data.category?.categoryId) {
      // New category.
      const createParams = {
        allowedValues: data.values,
        category: data.category?.category,
      };
      axios
        .post(rule5properties.contentCategories, createParams)
        .then((resp) => {
          if (isSuccessStatus(resp.status)) {
            addCategoryValues(data.values, resp.data.id);
          }
        })
        .catch((error) => console.log(error));
    } else if (!arrayContainsArray(data.values, data.category?.allowedValues)) {
      // New values in existing category
      const updateParams = {
        updateContentCategoryObj: {
          allowedValues: union(data.values, data.category?.allowedValues),
        },
      };
      axios
        .patch(
          `${rule5properties.contentCategories}/${data.category.categoryId}`,
          updateParams
        )
        .then((resp) => {
          if (!isSuccessStatus(resp.status)) {
            snackBar.createSnack("Error updating category values.");
          }
          addCategoryValues(data.values, data.category.categoryId);
        });
    } else {
      // Updating values in existing category while using existing allowedValues.
      addCategoryValues(data.values, data.category.categoryId);
    }
  };

  function addCategoryValues(values, categoryId) {
    const params = [
      {
        values: values,
        categoryId: categoryId,
      },
    ];
    axios
      .post(
        `${rule5properties.contents}/${content.contentId}/category-values`,
        params
      )
      .then((resp) => {
        if (resp.status === 200) {
          loadContent();
          dialog.closeModal();
        } else {
          setError(
            resp?.data?.message || "Unexpected error creating category."
          );
        }
      });
  }

  const selectedCategory = getValues("category");

  const allowedValues = selectedCategory?.category
    ? categories.find((category) => {
        return selectedCategory.category === category.category;
      })?.allowedValues
    : null;

  return (
    <Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          flexWrap: "wrap",
          gap: "15px",
        }}
      >
        <Typography sx={{ width: "100%" }}>{content?.name}</Typography>
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              sx={{ flexGrow: 1, width: "100%" }}
              loading={loading}
              options={categories}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.category;
              }}
              onChange={(e, data) => {
                let newValue = data;
                let categoryValues = [];
                if (typeof data === "string") {
                  newValue = { category: data };
                } else if (data && data.inputValue) {
                  // Create a new value from the user input
                  newValue = { category: data.inputValue };
                } else {
                  categoryValues = content.categories?.find(
                    (contentCategory) =>
                      contentCategory?.categoryId === data?.categoryId
                  )?.categoryValues;
                }
                resetField("values", {
                  defaultValue: categoryValues ? categoryValues : [],
                });
                field.onChange(newValue);
              }}
              freeSolo
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // TODO fix this and replace "Add" text with a plus icon
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.category
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    category: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => {
                const { InputLabelProps, ...rest } = params;
                return (
                  <StyledTextfield
                    placeholder="Type to add categories…"
                    {...rest}
                    {...params.InputProps}
                  />
                );
              }}
            />
          )}
          name={`category`}
          type="select"
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors?.category && (
          <Typography variant="formError">Category is required</Typography>
        )}
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              sx={{
                flexGrow: 1,
                width: "100%",
                visibility: selectedCategory ? "visible" : "hidden", // hmmmmmm
              }}
              multiple
              loading={loading}
              options={allowedValues ? allowedValues : []}
              onChange={(e, data) => field.onChange(data)}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => {
                const { InputLabelProps, ...rest } = params;
                return (
                  <StyledTextfield
                    placeholder="Type to add values…"
                    {...rest}
                    {...params.InputProps}
                  />
                );
              }}
            />
          )}
          name={`values`}
          type="select"
          control={control}
          rules={{
            required: true,
          }}
        />
        {errors?.values && !errors?.category && (
          <Typography variant="formError">
            At least one value is required
          </Typography>
        )}
        <Collapse in={error !== ""}>
          <ErrLabel>{error}</ErrLabel>
        </Collapse>
        <Button
          sx={{
            textTransform: "none",
          }}
          color="primary"
          variant="outlined"
          onClick={dialog.closeModal}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={loading}
          sx={{ width: "100px" }}
          variant="contained"
        >
          <Typography
            variant="button"
            sx={{ textTransform: "none", mt: "3px" }}
          >
            Save
          </Typography>
          {loading ? (
            <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
          ) : null}
        </Button>
      </form>
    </Box>
  );
}
