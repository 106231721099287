import React from "react";
import { Typography, Button, Box, CircularProgress } from "@mui/material";
import { StyledTextfield } from "../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";
import { Controller, useForm } from "react-hook-form";
import { onEnterPress } from "../common/Utils";
import Dropzone from "../workspaces/content/Dropzone";
import useSnack from "../context/Snack";
import { isSuccessStatus } from "../common/RequestUtils";
import { concat } from "lodash";

export default function UploadContent(props) {
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const { setShouldReload, selectedContents, setSelectedContents } = props;

  const dialog = useDialog();
  const snackBar = useSnack();

  const onSubmit = (data, event) => {
    event.preventDefault();
    event.stopPropagation();
    createContent(data);
  };

  const createContent = (newContent) => {
    setLoading(true);

    // Seems like appending at item level is required to convert to FormData.. looping through entries
    // might be better approach than below but w/e
    const formData = new FormData();
    formData.append("name", newContent.name);
    formData.append("pdfFile", newContent.pdfFile);
    if (newContent.description) {
      formData.append("description", newContent.description);
    }
    axios
      .post(rule5properties.contents, formData)
      .then((resp) => {
        setLoading(false);
        if (isSuccessStatus(resp.status)) {
          if (setShouldReload) {
            setShouldReload(resp.data?.id);
          }
          if (setSelectedContents) {
            resp.data.contentId = parseInt(resp.data?.id);
            setSelectedContents(concat(selectedContents, resp.data));
          }
          dialog.closeModal();
        } else {
          const message = resp.data?.message;
          snackBar.createSnack(
            message ? message : "Unexpected error uploading content."
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        snackBar.createSnack("Unexpected error uploading content.");
        console.log(error);
      });
  };

  return (
    <Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          flexWrap: "wrap",
          gap: "15px",
        }}
      >
        <Controller
          render={({ field }) => (
            <Dropzone
              setFileValue={(file) => {
                setValue("pdfFile", file);
                setValue("name", file?.name?.replace(/\.pdf$/, ""));
              }}
              value={watch("pdfFile")}
            />
          )}
          rules={{
            required: true,
          }}
          name={"pdfFile"}
          control={control}
          defaultValue=""
        />
        {errors?.pdfFile && (
          <Typography variant="formError">PDF file is required</Typography>
        )}
        <StyledTextfield
          style={{
            width: "100%",
            height: "36px",
            backgroundColor: "inherit",
            fontSize: "0.9rem",
          }}
          inputRef={(input) => input && input.focus()}
          {...register("name", { required: true })}
          autoFocus
          placeholder="Name"
        ></StyledTextfield>
        {errors?.name && (
          <Typography variant="formError">Content name is required</Typography>
        )}
        <StyledTextfield
          {...register("description")}
          style={{
            minHeight: "200px",
            backgroundColor: "inherit",
            fontSize: "0.9rem",
            flexBasis: "100%",
            display: "block",
          }}
          multiline
          onKeyDown={onEnterPress(() => createContent(watch()))}
          placeholder="Description"
        ></StyledTextfield>
        {loading ? (
          <CircularProgress sx={{ margin: "auto" }} size={36} />
        ) : (
          <>
            <Button
              sx={{
                textTransform: "none",
              }}
              color="primary"
              variant="outlined"
              onClick={(e) => {
                dialog.closeModal();
                e.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={loading}
              sx={{ width: "100px" }}
              variant="contained"
            >
              <Typography
                variant="button"
                sx={{ textTransform: "none", mt: "3px" }}
              >
                Create
              </Typography>
            </Button>
          </>
        )}
      </form>
    </Box>
  );
}
