import Button from "@mui/material/Button";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, TextField } from "@mui/material";
import _ from "lodash";

export function AddButton(props) {
  const onClick = props.onClick;
  const sx = props.sx;

  return (
    <Button
      variant="outlined"
      style={{ textTransform: "none" }}
      onClick={onClick}
      sx={sx}
      startIcon={<AddIcon />}
    >
      {props.children}
    </Button>
  );
}

export function DeleteButton(props) {
  const onClick = props.onClick;
  const sx = props.sx;

  return (
    <Button
      variant="outlined"
      style={{ textTransform: "none" }}
      onClick={onClick}
      sx={sx}
      startIcon={<DeleteIcon />}
    >
      {props.children}
    </Button>
  );
}

export function TitleInput(props) {
  const defaultValue = props.defaultValue;
  const register = props.register;

  return (
    <div>
      Title:
      <input
        style={{ marginLeft: "15px", width: "400px" }}
        {...register(`Title`)}
        defaultValue={defaultValue}
        hidden={false}
      ></input>
    </div>
  );
}

export function MoveAutocomplete(props) {
  const onChange = props.onChange;
  const index = props.index;
  const options = props.options;

  return (
    <>
      <Autocomplete
        disablePortal
        id={`Autocomplete for moving array at index ${index}`}
        options={options}
        getOptionDisabled={(option) => option === index}
        getOptionLabel={(option) => option.toString()}
        value={index}
        size="small"
        sx={{ width: 100, pb: "15px" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Index"
            InputLabelProps={{ shrink: true }}
          />
        )}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
      />
      <span style={{}}>
        *Can use this to change the ordering of the displayed info
      </span>
    </>
  );
}

/**
 * React-hook-form's useFieldArray.append() function doesn't work with empty objects. The problem
 * is that if there was an item deleted from the fieldArray, then append will add back those values. So
 * somewhat inconveniently needs to be sent a complete skeleton of the object structure with
 * empty values. Skeleton should match the last deleted object.
 */
export function appendFuncClickHandler(append, lastDeleted) {
  if (!lastDeleted) {
    return append({});
  }
  function skeleton(source) {
    if (!source) {
      return {};
    }
    let o = Array.isArray(source) ? [] : {};
    for (let key in source) {
      if (source.hasOwnProperty(key)) {
        if (source[key] === null) {
          o[key] = null;
          continue;
        }
        let t = typeof source[key];
        o[key] =
          t === "object"
            ? skeleton(source[key])
            : { string: "", number: 0, boolean: false }[t];
      }
    }
    return o;
  }
  const fieldArraySkeleton = skeleton(lastDeleted);
  return append(fieldArraySkeleton);
}
