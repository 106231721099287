import React from "react";
import {
  Stack,
  Box,
  Avatar,
  Divider,
  Typography,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Button,
  ClickAwayListener,
} from "@mui/material";
import { getColorFromString, getRelativeTimestamp } from "../Utils";
import { useHistory } from "react-router-dom";
import { useSidepanel } from "../../context/SidepanelContext";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useEffect } from "react";
import axios from "axios";
import { rule5properties } from "../../properties";

export default function TimelineNotifications(props) {
  const [baseMenuOpen, setBaseMenuOpen] = React.useState(false);
  const { newNotifs, oldNotifs, notifData } = props;

  return (
    <Stack sx={{ mt: 1 }}>
      {newNotifs.length > 0 && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: 500, opacity: 0.8 }}>
            New Items
          </Typography>
          <Button
            onClick={() => {
              props.notificationsViewed(newNotifs);
            }}
            color="inherit"
            sx={{
              borderRadius: "999px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "400",
              px: 2,
            }}
          >
            Dismiss All
          </Button>
        </Stack>
      )}
      <Box>
        {newNotifs.map((notification, index) => (
          <>
            <NotificationItem
              notification={notification}
              key={index}
              {...props}
            />
            {index < notifData?.length - 1 && (
              <Divider sx={{ my: 1.5, opacity: 0.5 }} />
            )}
          </>
        ))}
      </Box>
      {newNotifs.length > 0 && oldNotifs.length > 0 && (
        <Typography
          sx={{ fontSize: "20px", fontWeight: 500, opacity: 0.8, mb: 1 }}
        >
          Older
        </Typography>
      )}
      <Box>
        {oldNotifs.map((notification, index) => (
          <>
            <NotificationItem
              baseMenuOpen={baseMenuOpen}
              setBaseMenuOpen={setBaseMenuOpen}
              notification={notification}
              key={index}
              {...props}
            />
            {index < notifData?.length - 1 && (
              <Divider sx={{ my: 1.5, opacity: 0.5 }} />
            )}
          </>
        ))}
      </Box>
      {props.notifData?.length === 0 ? (
        <Stack sx={{ flexGrow: 1, mt: 2 }} alignItems="center">
          <Typography sx={{ opacity: 0.6 }}>Nothing to show here.</Typography>
        </Stack>
      ) : null}
    </Stack>
  );
}

function NotificationItem(props) {
  let { notification } = props;
  const [menuOpen, setMenuOpen] = React.useState(false);
  const handleClick = (e) => {
    if (menuOpen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  };
  const handleClose = () => {
    setMenuOpen(false);
  };

  return (
    <Stack direction="row">
      <Stack
        direction="row"
        sx={{
          px: 1,
          py: 1.75,
          mx: -1,
          flexGrow: 1,
          borderRadius: "6px",
          "&:hover": {
            backgroundColor: "rgba(0,0,0,.03)",
          },
          cursor: "pointer",
        }}
        onClick={() => {
          props.clickFunction(notification);
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            ml: 1,
          }}
        >
          <Avatar
            sx={{
              width: "40px",
              height: "40px",
              bgcolor: getColorFromString(notification.companyName),
            }}
          >
            {notification.companyName?.toUpperCase().charAt(0)}
          </Avatar>
          {notification.notificationStatus === "New" && (
            <Chip
              sx={{ mt: 1.5, fontSize: "12px", opacity: 0.6 }}
              color="primary"
              size="small"
              label="new"
            />
          )}
        </Stack>
        <Box sx={{ ml: 2.25, flexGrow: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ opacity: 0.7, fontWeight: 500 }}>
              {notification.companyName}
            </Typography>
            <IconButton
              size="small"
              sx={{ mt: -0.5 }}
              onClick={(event) => {
                event.stopPropagation();
                handleClick(event);
              }}
            >
              <MoreHorizIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Typography sx={{ my: 0.5 }}>
            {notification.notificationInfo?.Description}
          </Typography>
          <Typography sx={{ opacity: 0.5, fontSize: "14px" }}>
            {notification.functionalArea}
            {notification.functionalArea ? (
              <Typography display="inline" sx={{ mx: 0.75 }}>
                •
              </Typography>
            ) : null}
            {getRelativeTimestamp(notification.notificationDate)}
          </Typography>
        </Box>
      </Stack>
      <Collapse orientation="horizontal" in={menuOpen}>
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            sx={{
              height: "100%",
              ml: 2,
              borderRadius: "6px",
              backgroundColor: "rgba(0,0,0,.03)",
            }}
          >
            <Button
              disableRipple
              size="small"
              color="inherit"
              sx={{
                textTransform: "none",
                color: "#FFFFFF",
                backgroundColor: "#de8787",
                px: 2,
                flexGrow: 1,
                "&:hover": {
                  backgroundColor: "#c77777",
                },
              }}
              onClick={() => {
                props.deleteNotification(notification.id);
              }}
            >
              Delete
            </Button>
            {/* <Button
              disableRipple
              size="small"
              color="inherit"
              sx={{
                textTransform: "none",
                color: "rgba(0,0,0,.5)",
                px: 2,
                flexGrow: 1,
              }}
            >
              Settings
            </Button> */}
          </Stack>
        </ClickAwayListener>
      </Collapse>
    </Stack>
  );
}
