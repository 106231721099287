import React, { useState } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import RichTextEditor from "../util/RichTextEditor";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import { formatUrl as formatStorageUrl } from "../common/utils";
import ImageInput from "../util/ImageInput";

/** This component is reused for various card types other than industry trends.. e.g. SP SI BC */
export default function IndustryTrendsEditor(props) {
  const { formStyle, functionalArea, cardType } = props;

  const {
    register,
    control,
    trigger,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "Trend",
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  let title = cardType;
  // TODO industry trends should be like 'Industry Trends for <Industry>', but need to figure out where to get industry from
  if (cardType !== "Industry Trends" && functionalArea) {
    title += " in " + functionalArea;
  }

  return (
    <div>
      <TitleInput register={register} defaultValue={title} />
      {fields.map((news, trendIndex) => (
        <div key={news.id}>
          <div className={formStyle}>
            Image Content
            <Controller
              control={control}
              defaultValue=""
              name={`Trend[${trendIndex}].Image`}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    onChange={(e) =>
                      field.onChange(formatStorageUrl(e.target.value))
                    }
                  />
                );
              }}
            />
            {!getValues(`Trend[${trendIndex}].Image`) && (
              <>
                -OR-
                <hr></hr>
                Title
                <input {...register(`Trend[${trendIndex}].Title`)} />
                Link
                <input {...register(`Trend[${trendIndex}].Link`)} />
                Content:
                <Controller
                  name={`Trend[${trendIndex}].Content`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <RichTextEditor
                        value={field.value}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
                Thumbnail
                <ImageInput
                  name={`Trend[${trendIndex}].Thumbnail`}
                  control={control}
                  setValue={setValue}
                ></ImageInput>
                Sources
                <Sources
                  nestIndex={trendIndex}
                  {...{ control, register, formStyle }}
                />
                Published Date
                <TextField
                  {...register(`Trend[${trendIndex}].PublishedDate`)}
                  placeholder="mm/dd/yyyy"
                  // type="date" //TODO make this a datepicker
                />
                Authors
                <div>
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={[]}
                        onChange={(e, data) => field.onChange(data)}
                        freeSolo
                        autoSelect
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            label="Authors"
                            placeholder="Press enter to add entries"
                          />
                        )}
                      />
                    )}
                    name={`Trend[${trendIndex}].Authors`}
                    type="select"
                    control={control}
                  />
                  {errors?.Trend?.[trendIndex]?.Authors?.type ===
                    "required" && (
                    <p style={{ color: "red" }}>
                      {errors.Trend[trendIndex].Authors.message}
                    </p>
                  )}
                </div>
              </>
            )}
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(trendIndex, moveToIndex);
                trigger();
              }}
              index={trendIndex}
              options={[...Array(getValues("Trend").length).keys()]}
            ></MoveAutocomplete>
          </div>
          <DeleteButton
            onClick={() => {
              setLastDeleted(news);
              remove(trendIndex);
              trigger();
            }}
          >
            Delete Trend
          </DeleteButton>

          <Divider
            light
            style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
          />
        </div>
      ))}
      <AddButton
        onClick={() => {
          appendFuncClickHandler(append, lastDeleted);
        }}
      >
        Add Trend
      </AddButton>
    </div>
  );
}

/** Nested form array */
function Sources({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Trend.${nestIndex}.Source`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            Name:
            <input
              {...register(`Trend.${nestIndex}.Source.${k}.Name`, {
                // required: true
              })}
            />
            URL:
            <input {...register(`Trend.${nestIndex}.Source.${k}.URL`)} />
            <DeleteButton onClick={() => remove(k)}>Delete Source</DeleteButton>
          </div>
        );
      })}

      <AddButton
        onClick={() =>
          append({
            Name: "",
            URL: "",
          })
        }
      >
        Add Source
      </AddButton>
    </div>
  );
}

/*
const stubData = {
  "Title": "Industry Trends",
  "Trend": [
    {
      "Title": "Beyond demand levels, the fundamental structure of the aftermarket industry will likely change.",
      "Link": "https://www.mckinsey.com/industries/automotive-and-assembly/our-insights/beyond-coronavirus-the-road-ahead-for-the-automotive-aftermarket",
      "Content": {
        "blocks": [
          {
            "key": "b4b1l",
            "text": "Car parc. The car parc is the after market’s core performance driver each year, accounting for about 45 to 50 percent of its growth. New car sales expand the parc by 6 to 10 percent each year, depending on region, and COVID-19 will cause disruption to this inflow. The impact will differ by aftermarket channel: temporarily, the original-equipment aftermarket channel will drop as the number of vehicles under warranty will decrease. The independent aftermarket could potentially decrease in size around 2025 because COVID-19 has sharply reduced sales of new vehicles, thus reducing the number of cars requiring aftermarket services at five years—a point when more frequent repairs are often necessary.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 8,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "9iin1",
            "text": "E-commerce and digital channels. Many consumers and businesses have already migrated to e-commerce for parts purchases, and this trend may persist. In response, aftermarket players will likely offer omnichannel models similar to those of retailers—for instance, the ability to make purchases online and then make a quick pickup. The increased affinity for internet purchases may create an emerging opportunity for new business models or service options.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 31,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "9cikh",
            "text": "Supply chain. The aftermarket supply chain will change if distributors and retailers trade far-flung sources for near-shore ones, perceiving a lower risk of disruption. For the United States, this could mean more NAFTA sourcing at the expense of Asian manufacturers. Single-sourcing might also decline as the need for supply security outweighs the cost of qualifying second sources.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 12,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "csboj",
            "text": "Automation. Automation of manufacturing, distribution centers, and warehouses is likely to accelerate to hedge against additional disruptions of the labor force, enable physical distancing among employees, and reduce the risk of new infections.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 10,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "62d88",
            "text": "Consumer preferences. Changing consumer workshop preferences may necessitate new service offerings. A new category of do-it-yourself (DIY) may emerge, with video support provided. The potential uplift from DIY will be limited, because not all consumers are able to repair vehicles themselves. Upcoming vehicle generations and technologies will decrease the ranks of DIYers even further.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 20,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "86bfm",
            "text": "Shifting consumer travel preferences. Increased private-car use may enhance VMT as people avoid public transportation for their commutes. Many may also have an increased preference for traveling by car, rather than by plane or train (if travel distances allow the switch). In case of stalled new-car sales, this preference change would imply prolonged usage of current vehicles.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 36,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "em6nc",
            "text": "Competition. The competitive landscape may shift, since economic pressures could force smaller service locations and distributors to exit the market. In the United States, independent body shops constitute 35 percent of industry revenue but have increasingly consolidated into mid-size independents and multishop operations. This trend may accelerate because of crisis-related bankruptcies and business closures.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 11,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          }
        ],
        "entityMap": {}
      },
      "Thumbnail": "",
      "Source": [
        {
          "Name": "McKinsey",
          "URL": "https://www.mckinsey.com"
        }
      ],
      "PublishedDate": "05/01/2020",
      "Authors": [
        "Alex Brotschi",
        "Daniel Christof",
        "Joe Dertouzos",
        "Sebastian Kempf",
        "Prashant Vaze"
      ]
    },
    {
      "Title": "Impact of COVID on Auto Aftermarket",
      "Link": "https://www.mckinsey.com/industries/automotive-and-assembly/our-insights/beyond-coronavirus-the-road-ahead-for-the-automotive-aftermarket",
      "Content": {
        "blocks": [
          {
            "key": "b4b1l",
            "text": "Lower vehicle miles traveled. In many regions, roads are eerily empty and cities are free of crowds. Congestion indicators released by the navigation-system manufacturer TomTom show a dramatic decline in private and business travel, including commuting. Consider Italy and Spain, two countries that were hard hit in February and March. Car density was down to 17 percent of levels from a year earlier in both Bergamo and Milano, and to 18 percent in Madrid as of March 20. In Germany, where a curfew is in effect in some locations, the traffic load on urban roads had decreased approximately 40 percent by end of March.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 28,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "7udah",
            "text": "Fewer collisions. With regulations keeping people home and lowering car density, the collision rate has dropped in many cities. For instance, Seattle has seen about 50 percent fewer collisions since the government introduced measures to control the spread of coronavirus, while San Francisco has had a drop of about 60 percent. Very similar developments can be observed in Europe. This is clearly excellent news, as fewer accidents translate into fewer injuries and deaths. For the aftermarket, revenue planning should account for fewer crash-repair jobs.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 16,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "7n414",
            "text": "Decreased retail and workshop business. Many people are delaying inspections or discretionary repairs, and some government transportation agencies are relaxing deadlines for mandatory technical controls and inspections. Consumers with lower incomes are also delaying avoidable repairs. These changes are dramatically reducing visits to garages, service stations, and repair workshops, even though these businesses are typically allowed to remain open and can still easily receive parts from distributors. For instance, German garages report that business is down about 50 percent.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 38,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "g9t8",
            "text": "Increase in digital channels and e-commerce volumes. In the United States, more end-consumers are now shopping for parts online, which helps mitigate the adverse effect of curfews and closed workshops. This trend was already prevalent in the B2B market, especially those in Asia and Eastern Europe, before it hit B2C.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 51,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "d5nd7",
            "text": "Lower use of public transportation. Many people are taking fewer trips via public transport and shared-mobility services to reduce social contact. (In some cases, ride-sharing services have also temporarily ceased operations). The increased reliance on personal vehicles may spur demand for aftermarket services once people begin routinely traveling by car again, but it is difficult to predict when that might occur. With many people still remaining close to home, it is unlikely that lower use of public transport will have a substantial impact on aftermarket spending in 2020.",
            "type": "unordered-list-item",
            "depth": 0,
            "inlineStyleRanges": [
              {
                "offset": 0,
                "length": 34,
                "style": "BOLD"
              }
            ],
            "entityRanges": [],
            "data": {}
          }
        ],
        "entityMap": {}
      },
      "Thumbnail": "",
      "Source": [
        {
          "Name": "McKinsey",
          "URL": "https://www.mckinsey.com"
        }
      ],
      "PublishedDate": "05/01/2020",
      "Authors": [
        "Alex Brotschi",
        "Daniel Christof",
        "Joe Dertouzos",
        "Sebastian Kempf",
        "Prashant Vaze"
      ]
    }
  ],
  "NewUpdate": false
}
*/
