import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDialog } from "../context/DialogContext";
import { useUser } from "../context/UserContext";
import { detRoles } from "../det/DetMainPage";
import ContactSalesConfirmation from "../modal/ContactSalesConfirmation";
import { isMobile } from "react-device-detect";
import { BlackButton } from "./StyledComponents";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: "#FAFAFA", //theme.toolbar.color,
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
    height: 72,
    padding: 8,
    paddingLeft: 32,
    paddingRight: isMobile ? "16px" : "32px",
    verticalAlign: "middle",
    color: "#525252",
    userSelect: "none",
    fontSize: "x-large", //theme.toolbar.fontSize,
    top: 0,
    width: (props) => (props.historyOpen ? `calc(100% - 300px)` : "100%"),
    fontWeight: 500,
  },
  toolbarHeader: {
    height: 72,
  },
}));

export default function Toolbar(props) {
  const classes = useStyles(props);
  const { endChild } = props;

  return (
    <div className={classes.toolbar}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          justifySelf: "start",
          width: "100%",
        }}
      >
        {props.children}
      </div>
      <UpgradeButton />
      {endChild}
    </div>
  );
}

const UpgradeButton = React.memo(function UpgradeButton(props) {
  const user = useUser();
  const dialog = useDialog();
  const history = useHistory();

  if (detRoles.includes(user.role)) {
    return null;
  }

  const planType = user.userSubscription?.planType;
  if (!(planType?.includes("Basic") || planType === "Standard")) {
    return null;
  }

  return (
    <BlackButton
      style={{
        margin: "10px 0px",
        justifySelf: "end",
      }}
      variant="contained"
      disableRipple
      disableElevation
      onClick={() => {
        if (planType?.includes("Basic")) {
          history.push("/main/settings/manageplan");
        } else {
          dialog.openModal(
            "Connect with an Expert",
            ContactSalesConfirmation,
            {},
            "sm"
          );
        }
      }}
    >
      Upgrade
    </BlackButton>
  );
});
