import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { rule5properties, rule5BaseUrl } from "./properties";
import { useUserUpdate } from "./context/UserContext";
import { detRoles } from "./det/DetMainPage";
import JwtService from "./user/JwtService";
import { CircularProgress, Stack } from "@mui/material";
import { PRICING_PAGE } from "./user/Registration";

export default function RedirectPage(props) {
  const location = useLocation();
  const history = useHistory();
  const updateUserContext = useUserUpdate();
  const [status, setStatus] = React.useState("Redirecting...");

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);

    let system = searchParams.get("system");
    let code = searchParams.get("code");
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
        "Cache-Control": "no-cache",
      },
      withCredentials: true,
    };
    let redirectUrl;

    // Determine the redirect URL.
    if (system === "Salesforce") {
      // Redirected from Salesforce login.
      redirectUrl = "/auth/sfdc-callback";
    }
    if (system === "Google") {
      // Redirected from Google login.
      redirectUrl = "/auth/google-callback";
    }
    if (system === "Okta") {
      // Redirected from Google login.
      redirectUrl = "/auth/okta-callback";
    }

    if (searchParams.get("error")) {
      window.opener.postMessage({
        message: searchParams.get("error"),
      });
      window.close();
    } else if (redirectUrl) {
      let openerUrl = new URL(window.opener.location);
      let openerParams = openerUrl.searchParams;
      let invite = openerParams.get("invite");
      let planType = openerParams.get("planType");
      let state = searchParams.get("state");
      let timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
      // Create callback query params.
      let urlParams = new URLSearchParams();
      if (invite) urlParams.append("invite", invite);
      if (planType) urlParams.append("planType", planType);
      if (invite) urlParams.append("timeZone", timeZone);
      if (state) urlParams.append("state", state);
      if (code) urlParams.append("code", code);
      axios
        .get(rule5BaseUrl + `${redirectUrl}?${urlParams.toString()}`, options)
        .then((res) => {
          let data = res.data;
          if (data.accessToken) {
            // Successful login.
            JwtService.setToken(data.accessToken, data.expiresIn);
            updateUserContext(data);
            window.opener.location.reload();
            window.close();
          } else {
            setStatus(data.code);
            console.log("Error: No access token in response.");

            // Capture "No plan" status and redirect to website pricing page.
            // This means unregistered user clicked SF or google button on login page.
            if (
              data.code === "INVALID_ARGUMENT" &&
              data.message ===
                "Plan is required to register in rule5. Please select a plan."
            ) {
              window.opener.location.href = PRICING_PAGE;
            } else {
              window.opener.postMessage({
                message: data.message,
                fullData: data,
              });
            }
            window.close();
          }
        })
        .catch((error) => {
          console.log("Error:");
          console.log(error);
          window.opener.postMessage({
            message: "Internal server error.",
          });
          window.close();
        });
    } else {
      console.log("No system queue parameter provided.");
      window.opener.postMessage({
        message: "No system queue parameter provided.",
      });
      window.close();
    }
  }, []);

  return (
    <Stack
      sx={{ height: "100vh", opacity: 0.9 }}
      alignItems="center"
      justifyContent="center"
    >
      {status === "Redirecting..." ? (
        <CircularProgress
          size={80}
          color="inherit"
          sx={{ mb: 4, color: "black" }}
        />
      ) : (
        status
      )}
    </Stack>
  );
}
