import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { rule5properties } from "../../../../properties";
import CustomSnackbar from "../../../../common/CustomSnackbar";

const useStyles = makeStyles((theme) => ({
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    overflow: "hidden",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  flexContent: {
    flexGrow: 1,
    position: "relative",
    margin: "10px",
    width: 320,
    height: 200,
    overflowX: "hidden",
    overflowY: "hidden",
    "&::after": {
      position: "absolute",
      bottom: 0,
      height: "15%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 0.7), rgba(255,255,255, 1));",
      pointerEvents: "none" /* so the text is still selectable */,
    },
  },
  newsImage: {
    width: "120px",
    float: "left",
    marginRight: "10px",
  },
  authorContent: {
    color: "green",
  },
  contentType: {
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  boldStyle: {
    fontWeight: "800",
  },
}));

export default function CompanyUpdates(props) {
  const classes = useStyles();
  const previewData = props.previewData;

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [companyUpdatesData, setCompanyUpdatesData] = React.useState({
    News: [],
    Events: [],
    Source: [],
  });

  const [firstTime, setFirstTime] = React.useState(true);

  useEffect(() => {
    if (previewData) {
      // Call Stub
      setCompanyUpdatesData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId; // cardId hardcoded for now
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            let rawdata = response.data;
            if (!Object.keys(rawdata) !== 0) {
              // Validate content
              // Array reference will update the value
              setCompanyUpdatesData(rawdata);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
  }, [firstTime, previewData]);

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={
        companyUpdatesData.Title ? companyUpdatesData.Title : props.cardType
      }
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <div className={classes.flexContainer}>
        <Grid container spacing={6}>
          {companyUpdatesData.News &&
            companyUpdatesData.News.map((element, index) => {
              return (
                <Grid key={index} item xs={4}>
                  <Typography className={classes.contentType}>News</Typography>
                  <Typography variant="body1" gutterBottom>
                    <a href={element.ArticleLink}>{element.ArticleTitle}</a>
                    <br />
                  </Typography>
                  <Typography variant="body2">
                    {element.ShortDescription}
                  </Typography>
                </Grid>
              );
            })}
          {companyUpdatesData.Events &&
            companyUpdatesData.Events.map((element, index) => {
              return (
                <Grid key={index} item xs={4}>
                  <Typography className={classes.contentType}>
                    Events
                  </Typography>
                  <Typography variant="body1">
                    <a href={element.EventLink}>{element.EventTitle}</a>
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {element.EventDate}
                  </Typography>
                  <br />
                </Grid>
              );
            })}
        </Grid>
      </div>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}
