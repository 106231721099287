import React from "react";
import { StyledTableCell, StyledTableRow } from "../../common/StyledComponents";
import upgradeFeatureCheckBlue from "../../res/upgrade_feature_check_blue.svg";
import {
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { PLAN_LIMITS } from "./PricingTable";
import { PLAN_TYPES } from "../../context/UserContext";

export default function PlanFeaturesTable(props) {
  const [open, setOpen] = React.useState(false);

  const handleChange = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Button
        sx={{ alignSelf: "center", my: 2 }}
        variant="text"
        onClick={handleChange}
      >
        {open ? "Hide" : "View"} plan details
      </Button>
      <Collapse in={open}>
        <Typography sx={{ fontWeight: 500 }} variant="settingsMainHeader">
          Features
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            mb: 4,
            mt: 1,
            maxHeight: "calc(100vh - 200px)",
          }}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="kpi table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell sx={{ width: "300px", position: "sticky" }}>
                  Plan
                </StyledTableCell>
                <StyledTableCell sx={{ position: "sticky" }} align="center">
                  Monitor
                </StyledTableCell>
                <StyledTableCell sx={{ position: "sticky" }} align="center">
                  Research
                </StyledTableCell>
                <StyledTableCell sx={{ position: "sticky" }} align="center">
                  Act
                </StyledTableCell>
                <StyledTableCell sx={{ position: "sticky" }} align="center">
                  Scale
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow
                sx={{
                  backgroundColor: "#f2f3f2",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell colSpan={"100%"} sx={{ fontWeight: 500 }}>
                  Signal IQ
                </StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Companies monitored
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.standard
                  ].monitorAccountsLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.professional
                  ].monitorAccountsLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.professionalPlus
                  ].monitorAccountsLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.premium
                  ].monitorAccountsLimit.toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  People monitored
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.standard
                  ].monitorPeopleLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.professional
                  ].monitorPeopleLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.professionalPlus
                  ].monitorPeopleLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.premium
                  ].monitorPeopleLimit.toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Unlimited company signals
                </StyledTableCell>
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Unlimited people signals
                </StyledTableCell>
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Email & Slack signal delivery
                </StyledTableCell>
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Custom signals
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  backgroundColor: "#f2f3f2",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell colSpan={"100%"} sx={{ fontWeight: 500 }}>
                  Account IQ
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Number of public companies
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.professional
                  ].publicResearchLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.professionalPlus
                  ].publicResearchLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.premium
                  ].publicResearchLimit.toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Number of private companies
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.professional
                  ].privateResearchLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.professionalPlus
                  ].privateResearchLimit.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {PLAN_LIMITS[
                    PLAN_TYPES.premium
                  ].privateResearchLimit.toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Company research
                </StyledTableCell>
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Strategic insights
                </StyledTableCell>
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Key performance indicators
                </StyledTableCell>
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  People intelligence
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Technology landscape
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  backgroundColor: "#f2f3f2",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell colSpan={"100%"} sx={{ fontWeight: 500 }}>
                  rule5GPT AI Assistant
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Personalized outreach messages
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  AI content generation
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Prompt books
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Bring Your Own Content
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  backgroundColor: "#f2f3f2",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell colSpan={"100%"} sx={{ fontWeight: 500 }}>
                  Extensions, Integrations & API
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Chrome extension
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Slack integration
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  APIs
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Salesforce integration
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Outreach integration
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  backgroundColor: "#f2f3f2",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell colSpan={"100%"} sx={{ fontWeight: 500 }}>
                  Security & Privacy
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  SOC2 compliance
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  SSO & role-based access
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  backgroundColor: "#f2f3f2",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell colSpan={"100%"} sx={{ fontWeight: 500 }}>
                  Onboarding & Support
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Email support
                </StyledTableCell>
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Premium support
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Office hours
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Onboarding
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left" sx={{ pl: 4 }}>
                  Early access to betas
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <CheckMarkTableCell />
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
}

function CheckMarkTableCell(props) {
  return (
    <StyledTableCell align="center">
      <img
        src={upgradeFeatureCheckBlue}
        alt="checkmark"
        style={{ width: "18px" }}
      />
    </StyledTableCell>
  );
}
