import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Image from "../../../../common/Image";
import { Fab } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles(() => ({
  cardContent: {
    padding: "0px",
    textAlign: "left",
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  flexContent: {
    textAlign: "left",
    flexGrow: 1,
    position: "relative",
    margin: "10px",
    width: 320,
    "&:hover button": {
      display: "inherit",
    },
  },
  newsImage: {
    width: "120px",
    float: "left",
    marginRight: "10px",
  },
  authorContent: {
    color: "green",
  },
  deleteFab: {
    margin: 0,
    top: "auto",
    right: "5%",
    bottom: "10%",
    left: "auto",
    position: "absolute",
    display: "none",
  },
}));

export default function News(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  const [newsData, setNewsData] = React.useState(null);

  // eslint-disable-next-line
  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    if (previewData) {
      // Call Stub
      setNewsData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId; // cardId hardcoded for now
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            // let columns=JSON.parse(response.data);
            let rawdata = response.data;
            if (rawdata.Article) {
              // Validate content
              setNewsData(rawdata);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
  }, [firstTime, previewData]);

  if (!newsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }
  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={newsData.Title}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <NewsContent
        isNewton={!!previewData}
        articles={newsData.Article}
        {...props}
      ></NewsContent>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}

export function NewsContent(props) {
  const { articles, removeCallback, triggerSubmit, isNewton } = props;
  const classes = useStyles();

  return (
    <div className={classes.flexContainer}>
      {articles?.map((row, index) => {
        let alt = "News#" + index;
        const maxDescriptionLength = 400;
        const displayDescription =
          row.ShortDescription?.length < maxDescriptionLength
            ? row.ShortDescription
            : row.ShortDescription?.substring(
                0,
                maxDescriptionLength - 1
              ).concat("...");
        return (
          <div
            key={index}
            className={classes.flexContent}
            style={{
              border:
                isNewton && row.RelevancyStatus?.toLowerCase() === "not chosen"
                  ? "2px solid red"
                  : "none",
            }}
          >
            <Image
              src={row.Thumbnail}
              alt={alt}
              className={classes.newsImage}
            />
            <Typography variant="body1" gutterBottom component="div">
              <a href={row.Link} target="_blank" rel="noreferrer">
                {row.Title}
              </a>
            </Typography>
            <Typography
              variant="caption"
              className={classes.authorContent}
              gutterBottom
              component="div"
            >
              {row.Authors.map((authorRow, authorIndex) => {
                if (row.Authors.length > 1 && authorIndex > 0) {
                  if (authorIndex + 1 === row.Authors.length) {
                    return " & " + authorRow;
                  } else return ", " + authorRow;
                } else return authorRow;
              })}
              &nbsp;via&nbsp;
              {row.Source.map((sourceRow, sourceIndex) => {
                if (row.Source.length > 1 && sourceIndex > 0) {
                  if (sourceIndex + 1 === row.Source.length) {
                    return " & " + sourceRow.Name;
                  } else return ", " + sourceRow.Name;
                } else return sourceRow.Name;
              })}
              &nbsp;
              <div style={{ display: "inline", color: "gray" }}>
                {" "}
                - {row.PublishedDate}
              </div>
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
              {displayDescription}
            </Typography>
            {removeCallback && (
              <Fab
                size="small"
                color="primary"
                aria-label="remove article"
                className={classes.deleteFab}
                onClick={() => {
                  removeCallback(index);
                  triggerSubmit();
                }}
              >
                <DeleteIcon />
              </Fab>
            )}
          </div>
        );
      })}
    </div>
  );
}
