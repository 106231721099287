import { Button, Card, Stack, Typography } from "@mui/material";
import React from "react";

export default function Congratulations(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        sx={{
          margin: "auto",
          borderRadius: "10px",
          boxSizing: "border-box",
          padding: 10,
          boxShadow: "0 3px 3px -2px rgba(0, 0, 0, 0.1)",
        }}
        variant="outlined"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "30px",
          }}
        >
          <>
            <Typography variant="h5" textAlign="center">
              Congratulations! 🎉🎊🎈
            </Typography>

            <Typography variant="body2" textAlign="center">
              You have successfully upgraded your subscription.
            </Typography>
          </>

          <Stack direction="row" gap="10px">
            <Button variant="outlined" href="/main/monitor">
              Back to Signal IQ
            </Button>
            <Button
              variant="contained"
              href="/main/monitor/accounts?appcue=0107d8d8-bfb1-412d-9ce7-6372351f59f7"
            >
              Tour Signal IQ
            </Button>
          </Stack>
        </div>
      </Card>
    </div>
  );
}
