import axios from "axios";
import { rule5properties } from "../properties";

export function getPromptBookSearchRequests(searchValue) {
  let requests = [
    axios.get(
      `${rule5properties.promptBooks}${
        searchValue?.length > 1
          ? "?searchValue=" + encodeURIComponent(searchValue)
          : ""
      }`
    ),
  ];
  // Only request the prompts if there's a search string.
  if (searchValue?.length > 1) {
    requests.push(
      axios.get(
        `${rule5properties.prompts}?searchValue=${encodeURIComponent(
          searchValue
        )}`
      )
    );
  }
  return requests;
}

/**
 * Tests if the given status is a successful HTTP code i.e. 2xx
 * @param {} status
 */
export function isSuccessStatus(status) {
  return status >= 200 && status < 300;
}

export function downloadPdfFileUrl(encodedUrl, fileName) {
  if (!encodedUrl) {
    return;
  }
  let parameter = "?url=" + encodedUrl;
  let options = {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
  };
  axios
    .get(rule5properties.getSecureFile + parameter, options)
    .then((response) => {
      let rawdata = response.data;
      if (rawdata) {
        // Validate content
        const file = new Blob([response.data], { type: "application/pdf" });
        let link = document.createElement("a");
        // feature detection
        if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const fileUrl = URL.createObjectURL(file);
          link.setAttribute("href", fileUrl);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        // API call failed
        console.log("pdf retrieval fail: " + encodedUrl);
        console.log(response);
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log("pdf retrieval fail: " + encodedUrl);
        console.log(error.response.status);
        console.log(error.response.data);
      }
      return;
    });
}
