import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { rule5properties } from "../../../../properties";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Image from "../../../../common/Image";
import LinearProgress from "@mui/material/LinearProgress";
import Slide from "@mui/material/Slide";
import { addSuffix } from "../../../../common/Utils";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
  cardContent: {
    padding: "0px",
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  metricsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(60px, 190px))",
    gridGap: "5px 30px",
    paddingTop: "2%",
  },
  metric: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid lightgray",
    padding: "3% 0%",
  },
  measureParent: {
    paddingBottom: "20px",
    "&:last-child": {
      paddingBottom: "0px",
    },
  },
  measureGrid: {
    display: "grid",
    gridTemplateColumns: "min-content 1fr 4fr 2fr",
    gridGap: "10px",
    paddingTop: "2%",
    paddingLeft: "15px",
    alignItems: "center",
  },
  measureRowWrapper: {
    display: "contents",
  },
  companyLogo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  panel: {
    flex: "1",
  },
  gridItem: {
    cursor: "pointer",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
}));

// Sorts measures by descending score, then alphabetical.
const sortMeasures = (a, b) => {
  if (a.Score < b.Score) {
    return 1;
  } else if (a.Score === b.Score) {
    return a.CompanyName < b.CompanyName ? 1 : -1;
  } else {
    return -1;
  }
};

export default function PeerGroup(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [peerGroupData, setPeerGroupData] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [hasError, setError] = React.useState(false);
  const [displayedMeasure, setDisplayedMeasure] = React.useState({
    companies: [],
  });
  const [measureMap, setMeasureMap] = React.useState({});
  const previewData = props.previewData;

  const setData = (data) => {
    let measureMap = new Map();
    data.Measure?.forEach((item, index) => {
      measureMap.set(item.MeasureName, item.orgs);
    });
    setMeasureMap(measureMap);
    if (data.Measure.length > 0) {
      // Sets the default displayed measure to the first item in the list.
      setDisplayedMeasure({
        title: data.Measure[0].MeasureName,
        companies: data.Measure[0].orgs,
      });
    }
    setPeerGroupData(data);
  };

  useEffect(() => {
    setExpanded(!props.collapse);
    setIsLoading(true);
    if (previewData) {
      setData(previewData);
      // setPeerGroupData(previewData);
      setIsLoading(false);
      return;
    }
    axios
      .get(`${rule5properties.getCard}/${props.cardId}`)
      .then((response) => {
        let rawdata = response.data;
        // let rawdata = require('./test.json');;
        if (rawdata.Measure) {
          setData(rawdata);
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setError(true);
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      });
  }, [previewData]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (isLoading) {
    return (
      <Card className={classes.cardstyle} variant="outlined">
        <CardHeader
          sx={{ padding: theme.cards.header.padding }}
          avatar={<InfoIcon />}
          title={<div className={classes.cardTitle}>{props.cardType}</div>}
        />
        <CircularProgress />
      </Card>
    );
  }

  if (hasError) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }

  const companiesCopy = [...displayedMeasure.companies];

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={peerGroupData.Title}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <div
        style={{
          display: "flex",
          flexGrow: "1",
        }}
      >
        <div className={classes.panel} style={{ marginRight: "20px" }}>
          <Typography variant="body2" align="left" style={{ lineHeight: 1.5 }}>
            {peerGroupData.Overview.Description}
          </Typography>
          <Grid container sx={{ mt: 2 }} columns={4} spacing={1}>
            {peerGroupData.Metrics.map((metric, index) => {
              return (
                <Grid item key={index} xs={2}>
                  <div
                    className={classes.gridItem}
                    onClick={() => {
                      let measure = {
                        title: metric.Name,
                        companies: measureMap.get(metric.Name),
                      };
                      if (measure.companies) {
                        setDisplayedMeasure(measure);
                      } else {
                        setDisplayedMeasure({ companies: [] });
                      }
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#1976d2",
                        flexGrow: "1",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        mr: 3,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {metric.Name}
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                      {addSuffix(metric.Rank)}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <Divider orientation="vertical" flexItem />
        <div
          className={classes.panel}
          style={{
            marginLeft: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ width: "100%", textAlign: "left", mb: 2 }}
          >
            See {props.opportunity.Account} {displayedMeasure.title} vs
            Competitors{" "}
          </Typography>
          <Grid container columns={1} sx={{}}>
            {companiesCopy.sort(sortMeasures).map((org, index) => {
              return (
                <Grid
                  item
                  xs={1}
                  key={index}
                  sx={{ overflow: "hidden", height: "50px" }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        overflow: "hidden",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{ mr: 3, opacity: 0.7 }}
                      >
                        {addSuffix(index + 1)}
                      </Typography>
                      <Typography
                        sx={{
                          flexGrow: 1,
                          textAlign: "left",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        {org.CompanyName}
                      </Typography>
                      <Box sx={{ height: "18px", mr: 3, mb: "2px" }}>
                        <Image
                          src={org.Logo}
                          alt=""
                          style={{ maxHeight: "18px", maxWidth: "80px" }}
                        />
                      </Box>
                      <Typography sx={{ fontSize: "14px", color: "#1976d2" }}>
                        {org.Score} / {org.scale}
                      </Typography>
                    </div>
                    <Slide in={true} appear direction="right">
                      <LinearProgress
                        value={(org.Score / org.scale) * 100}
                        variant="determinate"
                        sx={{ width: "100%", borderRadius: "2px" }}
                      />
                    </Slide>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}
