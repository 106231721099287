import React from "react";
import {
  StyledTextfield,
  StyledLabel,
  ErrLabel,
} from "../common/StyledComponents";
import { Stack, Button, Typography, Collapse, Box } from "@mui/material";
import axios from "axios";
import { rule5properties } from "../properties";

export default function PasswordChange(props) {
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [errors, setErrors] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const submitForm = (event) => {
    event.preventDefault();
    const params = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };

    // Basic validations
    let submit = true;
    if (newPassword !== confirmPassword) {
      submit = false;
      setErrors({
        ...errors,
        confirmPassword: "Confirmation password must match.",
      });
    }
    const regex = /^(?=.*\d)(?=.*[!@?#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (regex.test(newPassword)) {
    } else {
      submit = false;
      setErrors({
        ...errors,
        newPassword:
          "Password must be at least 8 characters, contain a symbol, number, and uppercase / lowercase letter.",
      });
    }

    if (submit) {
      return axios
        .patch(
          rule5properties.usersApiUrl + "/change-password",
          params,
          options
        )
        .then((res) => {
          if (res.status === 200) {
            // Success
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setStatusMessage("Password updated successfully.");
          }
          if (res.status === 400) {
            if (
              res.data.message === '"newPassword" contains an invalid value'
            ) {
              setErrors({
                ...errors,
                newPassword:
                  "New password cannot match your previous password.",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          if (err.response.data.message === "Incorrect existing password.") {
            setErrors({
              ...errors,
              oldPassword: "Password is incorrect.",
            });
          }
          return err;
        });
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: "500px" }}>
      <form
        onSubmit={submitForm}
        style={{ width: "90%", display: "flex", flexDirection: "column" }}
      >
        <StyledLabel sx={{ width: "100%", mt: 1 }}>
          Current Password
        </StyledLabel>
        <StyledTextfield
          value={oldPassword}
          onChange={(event) => {
            setErrors({
              ...errors,
              oldPassword: "",
            });
            setOldPassword(event.target.value);
            setStatusMessage("");
          }}
          fullWidth
          type="password"
          autoComplete="off"
        />
        <Collapse
          sx={{ width: "96%", textAlign: "right" }}
          in={errors.oldPassword}
        >
          <ErrLabel>{errors.oldPassword}</ErrLabel>
        </Collapse>

        <StyledLabel sx={{ width: "100%", mt: 4 }}>New Password</StyledLabel>
        <StyledTextfield
          value={newPassword}
          onChange={(event) => {
            setErrors({
              ...errors,
              newPassword: "",
            });
            setNewPassword(event.target.value);
            setStatusMessage("");
          }}
          fullWidth
          type="password"
          autoComplete="off"
        />
        <Collapse
          sx={{ width: "96%", textAlign: "right" }}
          in={errors.newPassword}
        >
          <ErrLabel>{errors.newPassword}</ErrLabel>
        </Collapse>

        <StyledLabel sx={{ width: "100%", mt: 2 }}>
          Confirm New Password
        </StyledLabel>
        <StyledTextfield
          value={confirmPassword}
          onChange={(event) => {
            setErrors({
              ...errors,
              confirmPassword: "",
            });
            setConfirmPassword(event.target.value);
            setStatusMessage("");
          }}
          fullWidth
          type="password"
          autoComplete="off"
        />
        <Collapse
          sx={{ width: "96%", textAlign: "right" }}
          in={errors.confirmPassword}
        >
          <ErrLabel>{errors.confirmPassword}</ErrLabel>
        </Collapse>

        <Collapse in={statusMessage}>
          <Box
            sx={{
              mt: 2,
              padding: "8px 8px",
              borderRadius: "6px",
              backgroundColor: "#f1faeb",
              border: "1px solid #cddec1",
            }}
          >
            <Typography sx={{ color: "green", fontSize: "14px" }}>
              {statusMessage}
            </Typography>
          </Box>
        </Collapse>

        <Button
          type="submit"
          disabled={
            oldPassword === "" ||
            newPassword === "" ||
            confirmPassword === "" ||
            errors.oldPassword !== "" ||
            errors.newPassword !== "" ||
            errors.confirmPassword !== ""
          }
          sx={{
            textTransform: "none",
            mt: 4,
            width: "140px",
            alignSelf: "center",
          }}
          variant="outlined"
        >
          Submit
        </Button>
      </form>
    </Stack>
  );
}
