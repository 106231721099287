import React, { useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { rule5properties } from "../../../../properties";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Image from "../../../../common/Image";
import { hasRteContent } from "../../../../common/Utils";
import RichTextReader from "../../../../common/RichTextReader";
import { convertFromRaw, EditorState } from "draft-js";
import { Stack } from "@mui/material";
import { MemoPerson } from "./Person";
import _ from "lodash";
import { PeopleSearch, SEARCHABLE } from "./People";
import { Fab } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles(() => ({
  leftContent: {
    borderRight: "solid 1px rgba(0, 0, 0, 0.4)",
    width: "50%",
    minWidth: "200px",
    paddingRight: "24px",
  },
  flexContainer: {
    display: "flex",
    width: "100%",
  },
  rteReader: {
    "& .rdw-editor-main": {
      overflow: "hidden",
    },
    paddingBottom: "20px",
  },
  authorContent: {
    color: "green",
  },
  insightImage: {
    width: "120px",
    float: "left",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  insightContainer: {
    position: "relative",
    marginBottom: "20px",
    textAlign: "left",
    "&:hover button": {
      display: "inherit",
    },
  },
  peopleContainer: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-evenly",
    maxHeight: (props) =>
      props.leftHeight
        ? Math.floor(props.leftHeight / 140) * 140 + 20 // 140 is essentially theme.cards.peopleCard.person.height but 20 extra for the margin added in Person.personContainer style
        : "600px",
    overflow: "auto",
  },
  deleteFab: {
    margin: 0,
    top: "auto",
    right: "5%",
    bottom: "10%",
    left: "auto",
    position: "absolute",
    display: "none",
  },
}));

export default function FlashCard(props) {
  const [flashCardDetail, setFlashCardDetail] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [hasError, setError] = useState(false);
  const [leftHeight, setLeftHeight] = useState();
  const classes = useStyles();
  const previewData = props.previewData;

  useEffect(() => {
    if (previewData) {
      // Below two set states are kind of hacks to make sure the right content gets set based off of the
      // height of left content. This is just in Newton/DET where height needs to be dynamic based on edits.
      setLeftHeight(null);
      setFlashCardDetail(previewData);
      const queryString = new URLSearchParams({
        type: "People",
        scope: "Global",
        companyId: previewData.companyId,

        ...(previewData.functionalArea
          ? { functionalArea: previewData.functionalArea }
          : { functionalArea: "NA" }),
      });
      axios
        .get(rule5properties.detCards + `?` + queryString)
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
        })
        .then((response) => {
          const results = response.data.results;
          if (results?.length === 0) {
            // Card does not exist yet. No preview or anything to display.
            return;
          }
          // Get the most recent approved or published since this is in DET.
          let maxVersion = results?.reduce(function (prev, current) {
            return prev.version > current.version ? prev : current;
          });

          const approvedStatuses = ["Published", "Approved"];
          if (!approvedStatuses.includes(maxVersion.status)) {
            maxVersion = results?.find(
              (card) =>
                parseInt(card.version) === parseInt(maxVersion.version) - 1
            );
            // Assuming the next to most recent version should always be approved or published.
            if (!approvedStatuses.includes(maxVersion.status)) {
              window.alert(
                `Unexpected status ${maxVersion.status} for version ${maxVersion.version}.`
              );
            }
          }

          setFlashCardDetail({
            ...previewData,
            people: maxVersion.cardInfo.Person,
          });
        });
      return;
    }

    const requests = [
      axios.get(`${rule5properties.getCard}/${props.cardId}`),
      axios.get(`${rule5properties.getCard}/${props.peopleCardId}`),
    ];

    Promise.all(requests)
      .then((body) => {
        if (body?.[0]?.data.Title) {
          setFlashCardDetail({
            ...body[0].data,
            people: body?.[1]?.data.Person,
          });
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setError(true);
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      });
  }, [previewData]);

  const leftRef = useRef();

  const getLeftSize = () => {
    const newHeight = leftRef.current?.clientHeight;
    if (newHeight) {
      setLeftHeight(newHeight);
    }
  };

  useEffect(() => {
    getLeftSize();
  }, [flashCardDetail, leftHeight]);

  // Update heights when the window resizes
  useEffect(() => {
    window.addEventListener("resize", getLeftSize);
  }, []);

  if (!flashCardDetail) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity?.id}
        cardId={props.cardId}
        title={props.cardType || ""}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <CircularProgress />
      </Researchcard>
    );
  }

  if (hasError) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity?.id}
        cardId={props.cardId}
        title={props.cardType || ""}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity?.id}
      cardId={props.cardId}
      title={flashCardDetail.Title || ""}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <div className="splitFlag">
        <div className={classes.flexContainer}>
          <LeftContent
            flashCardDetail={flashCardDetail}
            leftRef={leftRef}
            {...props}
          ></LeftContent>
          <RightContent
            flashCardDetail={flashCardDetail}
            leftHeight={leftHeight}
            {...props}
          ></RightContent>
        </div>
      </div>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}

function LeftContent(props) {
  const { flashCardDetail, leftRef, removeCallback, triggerSubmit } = props;
  const classes = useStyles();

  return (
    <div className={classes.leftContent} ref={leftRef}>
      {flashCardDetail.insights && flashCardDetail.insights.length > 0 && (
        <>
          <h4
            style={{
              textAlign: "left",
              marginTop: "0px",
              marginBottom: "15px",
            }}
          >
            Insights
          </h4>
          <div>
            {flashCardDetail.insights.map((insight, index) => (
              <div className={classes.insightContainer} key={insight.title}>
                <Stack>
                  <div>
                    {insight.thumbnail && (
                      <Image
                        src={insight.thumbnail}
                        alt={"article thumbnail"}
                        className={classes.insightImage}
                      />
                    )}
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      {insight.title}
                    </Typography>

                    {/* <Typography variant="h6" gutterBottom component="div">
                    {insight.title}
                  </Typography> */}
                    {/* {insight.authors && (
                    <Typography
                      variant="caption"
                      className={classes.authorContent}
                      gutterBottom
                      component="div"
                    >
                      {insight.authors.map((authorRow, authorIndex) => {
                        if (insight.authors.length > 1 && authorIndex > 0) {
                          if (authorIndex + 1 === insight.authors.length) {
                            return " & " + authorRow;
                          } else return ", " + authorRow;
                        } else return authorRow;
                      })}
                    </Typography>
                  )} */}
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      {insight.source + " - " + insight.publishedDate}
                      &nbsp;(
                      <a href={insight.link} target="_blank" rel="noreferrer">
                        Source
                      </a>
                      )
                    </Typography>
                  </div>
                  {hasRteContent(insight.rteContent) && (
                    <RichTextReader
                      fontSize={14}
                      editorState={EditorState.createWithContent(
                        convertFromRaw(insight.rteContent)
                      )}
                    />
                  )}
                </Stack>
                {removeCallback && (
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="remove insight"
                    className={classes.deleteFab}
                    onClick={() => {
                      removeCallback(index);
                      triggerSubmit();
                    }}
                  >
                    <DeleteIcon />
                  </Fab>
                )}
              </div>
            ))}
          </div>
        </>
      )}
      {hasRteContent(flashCardDetail.talkingPoints) && (
        <>
          <h4 style={{ textAlign: "left", margin: "0px" }}>Talking Points</h4>
          <div className={classes.rteReader}>
            <RichTextReader
              fontSize={14}
              editorState={EditorState.createWithContent(
                convertFromRaw(flashCardDetail.talkingPoints)
              )}
            />
          </div>
        </>
      )}
    </div>
  );
}

function RightContent(props) {
  const { flashCardDetail, managementQuotesCardId, leftHeight } = props;
  const [peopleQuery, setPeopleQuery] = useState("");
  const [reversed, setReversed] = useState(true);
  const classes = useStyles(props);

  const anyFlashCardPeople = flashCardDetail.people?.some(
    (person) => person.isFlashCard
  );

  // Let the left size render first since it will dictate height of right side
  if (!leftHeight) {
    return null;
  }

  return (
    <div style={{ marginLeft: "0px", flexBasis: "55%" }}>
      <Stack>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4
            style={{
              textAlign: "left",
              marginTop: "0px",
              marginBottom: "15px",
              marginLeft: "50px",
            }}
          >
            Key People
          </h4>
          <PeopleSearch
            peopleQuery={peopleQuery}
            reversed={reversed}
            setPeopleQuery={setPeopleQuery}
            setReversed={setReversed}
          ></PeopleSearch>
        </div>
        <div className={classes.peopleContainer}>
          {_.chain(_.clone(flashCardDetail.people))
            .tap(reversed ? _.reverse : _.noop)
            .filter((person) => {
              const searchableFields = _.pick(person, SEARCHABLE);
              if (peopleQuery === "") {
                return true;
              } else if (
                Object.values(searchableFields).some((personField) =>
                  personField
                    ?.toLowerCase()
                    .includes(peopleQuery?.toLowerCase())
                )
              ) {
                return true;
              } else {
                return false;
              }
            })
            .map((row, index) => {
              if (anyFlashCardPeople && !row.isFlashCard) {
                return null;
              }
              return (
                <MemoPerson
                  key={row.personId}
                  rowIndex={index}
                  row={row}
                  managementQuotesCardId={managementQuotesCardId}
                  {...flashCardDetail.people}
                  isFlashCard={true}
                ></MemoPerson>
              );
            })
            .value()}
        </div>
      </Stack>
    </div>
  );
}
