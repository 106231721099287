import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { rule5properties } from "../../properties";
import { TextareaAutosize } from "@mui/material";
import {
  convertHtmlToRawContentState,
  removeTrailingTags,
} from "../common/utils";
import RichTextEditor from "../util/RichTextEditor";

export default function AnnualReportsEditor(props) {
  const formStyle = props.formStyle;

  const { register, control, trigger, getValues } = useFormContext();

  const [loading, setLoading] = useState(false);

  /** Removes any unwanted strings and converts answer to rte rawContentState */
  function convertQuestionAnswer(questionAnswerArray) {
    const promptToRemove =
      " Classify them and list the titles in bold. Return lists surrounded by HTML tags ol and li.";
    return questionAnswerArray
      ?.filter((qa) => qa.answer !== "Sorry, I don't know")
      ?.map((qa) => {
        qa.question = qa.question?.replace(promptToRemove, "");
        qa.answer = convertHtmlToRawContentState(removeTrailingTags(qa.answer));
        return qa;
      });
  }

  return (
    <div>
      <TitleInput register={register} />
      <LoadingButton
        loading={loading}
        style={{ textTransform: "none", marginTop: "2%" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          setLoading(true);
          const cardDataParams = new URLSearchParams({
            functionalArea: props.functionalArea,
            cardType: "Annual Reports",
            companyId: props.companyId,
            numQuestions: "10",
          });

          axios
            .get(rule5properties.detCardData + "?" + cardDataParams)
            .then((res) => {
              setLoading(false);
              if (res.status === 200) {
                const cardInfo = {
                  mda: convertQuestionAnswer(res.data["MD&A"]),
                  business: convertQuestionAnswer(res.data.Business),
                  Title: "Annual Reports",
                  date: res.data.date,
                  url: res.data.url,
                };
                props.setForm((draft) => {
                  draft.cardInfo = cardInfo;
                  draft.isDraftSaved = false;
                });
                props.setHasPreview(true);
              } else {
                window.alert(
                  `Card data response code: ${
                    res.status
                  }. Details: ${JSON.stringify(res.data)}`
                );
              }
            })
            .catch((err) => {
              window.alert("Error retrieving on card data: " + err);
              setLoading(false);
            });
        }}
      >
        Generate Data
      </LoadingButton>
      <div className={formStyle}>
        Date:
        <input {...register(`date`)} />
        Url:
        <input {...register(`url`)} />
        Business q&a's
        <QuestionAndAnswer
          {...{ trigger, control, register, getValues, formStyle }}
          name="business"
        />
        MD&A q&a's
        <QuestionAndAnswer
          {...{ trigger, control, register, getValues, formStyle }}
          name="mda"
        />
      </div>
    </div>
  );
}

/** Form array */
export function QuestionAndAnswer({
  trigger,
  control,
  register,
  getValues,
  formStyle,
  name,
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: name,
  });
  const [lastDeleted, setLastDeleted] = useState(null);
  const [generatedValue, setGeneratedValue] = useState(null);

  return (
    <div>
      {fields.map((item, itemIndex) => (
        <div key={itemIndex} className={formStyle}>
          Question:
          <input {...register(`${name}[${itemIndex}].question`)} />
          Answer:
          {/* <TextareaAutosize
            {...register(`${name}[${itemIndex}].answer`)}
            aria-label=""
            minRows={3}
            style={{ width: "100%" }}
          /> */}
          <Controller
            name={`${name}[${itemIndex}].answer`}
            control={control}
            render={({ field }) => {
              return (
                <RichTextEditor value={field.value} onChange={field.onChange} />
              );
            }}
          />
          <MoveAutocomplete
            onChange={(moveToIndex) => {
              move(itemIndex, moveToIndex);
              trigger();
            }}
            index={itemIndex}
            options={[...Array(getValues(name).length).keys()]}
          ></MoveAutocomplete>
          <DeleteButton
            onClick={() => {
              setLastDeleted(item);
              remove(itemIndex);
              trigger();
            }}
          >
            {`Delete ${name} question`}
          </DeleteButton>
        </div>
      ))}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        {`Add ${name} question`}
      </AddButton>
    </div>
  );
}
