import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  InputBase,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  GrayCircularProgress,
  StyledLabel,
  StyledTextfield,
} from "../../../common/StyledComponents";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useFormContext } from "react-hook-form";
import { validateEmailString } from "../../../common/Utils";
import axios from "axios";
import { rule5AppUrl, rule5properties } from "../../../properties";
import monitor_service from "./monitor_service";

export default function ConfigureActions(props) {
  const {
    tempEmail,
    setTempEmail,
    userMessageChannels,
    setUserMessageChannels,
  } = props;
  const { control, getValues, setValue } = useFormContext();

  const [loading, setLoading] = React.useState(false);

  // Reload the user message channels to see if slack channels were changed while this modal was open.
  async function reloadUserMessageChannels() {
    setLoading(true);
    const newUserMessageChannels =
      await monitor_service.getUserMessageChannels();
    if (newUserMessageChannels) {
      setUserMessageChannels(newUserMessageChannels);
    }
    // Filter out and remove any existing channels that are no longer available.
    const newChannelIds = newUserMessageChannels.map(
      (existingMsgChannel) => existingMsgChannel.channelId
    );
    const existingValues = getValues(
      "action.agentActionInfo.notifications.slackChannels"
    );
    const adjustedChannels = existingValues.filter((configedChannel) =>
      newChannelIds.includes(configedChannel.channelId)
    );
    setValue(
      "action.agentActionInfo.notifications.slackChannels",
      adjustedChannels
    );
    setLoading(false);
  }

  return (
    <Box>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ mb: 1 }}
        >
          <Typography variant="settingsHeader">Generate Alerts?</Typography>

          <Controller
            name="action.isEnabled"
            control={control}
            render={({ field, onChange }) => (
              <Switch
                {...field}
                name="action.isEnabled"
                checked={field.value}
              />
            )}
          />
        </Stack>
        <Typography variant="settingsCaption" sx={{ mb: 0.5 }}>
          Provide a list of email addresses to receive alerts for this agent.
        </Typography>
        <Controller
          name="action.agentActionInfo.notifications.emails"
          control={control}
          render={({ field, onChange }) => (
            <Autocomplete
              {...field}
              name="action.agentActionInfo.notifications.emails"
              onChange={(e, data) => field.onChange(data)}
              options={[]}
              multiple
              freeSolo
              multiline
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <ValidatedEmailChip
                    key={option}
                    email={option}
                    getTagProps={getTagProps}
                    index={index}
                  />
                ));
              }}
              inputValue={tempEmail}
              onInputChange={(event, value) => {
                setTempEmail(value.trim());
              }}
              renderInput={(params) => (
                <StyledTextfield
                  {...params}
                  {...params.InputProps}
                  label="Email addresses"
                  sx={{ px: "10px", py: "6px" }}
                />
              )}
            />
          )}
        />
        <Typography variant="settingsCaption" sx={{ mb: 0.5, mt: 2 }}>
          Select Slack channels to receive alerts for this agent.{" "}
          <Link
            target="_blank"
            href={rule5AppUrl + "/main/settings/integrations"}
          >
            Can't find your Slack channels? Click here to configure your Slack
            channels with rule5.
          </Link>
        </Typography>
        <Controller
          name="action.agentActionInfo.notifications.slackChannels"
          control={control}
          render={({ field, onChange }) => (
            <Autocomplete
              {...field}
              name="action.agentActionInfo.notifications.slackChannels"
              onChange={(e, data) => {
                field.onChange(data);
              }}
              options={userMessageChannels}
              multiple
              multiline
              renderOption={(props, option) => {
                return (
                  <Box {...props}>
                    <Typography variant="body1">
                      #{option.channelName}
                    </Typography>
                  </Box>
                );
              }}
              getOptionLabel={(x) => `#${x.channelName}`}
              isOptionEqualToValue={(option, val) => {
                const equal = option.id === val.id;
                return equal;
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    sx={{ pt: 0.25 }}
                    variant="outlined"
                    label={"#" + option.channelName}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <StyledTextfield
                  {...params}
                  {...params.InputProps}
                  label="Slack channels"
                  sx={{ px: "10px", py: "6px" }}
                  endAdornment={loading && <GrayCircularProgress size={16} />}
                />
              )}
              onOpen={() => {
                reloadUserMessageChannels();
              }}
            />
          )}
        />
      </Box>
      {/* <Box>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <Typography variant="settingsHeader">
            Generate Outreach Email?
          </Typography>
          <Controller
            name="actions.generateOutreach"
            control={control}
            render={({ field, onChange }) => (
              <Switch
                {...field}
                name="generateOutreach"
                checked={field.value}
              />
            )}
          />
        </Stack>
        <Typography variant="settingsCaption">Email Addresses</Typography>
        <Controller
          name="actions.outreachEmails"
          control={control}
          render={({ field, onChange }) => (
            <Autocomplete
              {...field}
              options={[]}
              multiple
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <StyledTextfield
                  {...params}
                  {...params.InputProps}
                  label="Email addresses"
                  sx={{ px: "10px", py: "6px" }}
                />
              )}
              defaultValue={[]}
              sx={{ mb: 2 }}
            />
          )}
        />
      </Box> */}
      {/* // Possibly returning?
      <Stack>
        <Typography variant="settingsMainHeader">
          Prompts to be used...
        </Typography>
        <Stack direction="row" sx={{ my: 1 }}>
          <Typography variant="settingsCaption" sx={{ width: "240px" }}>
            Event
          </Typography>
          <Typography variant="settingsCaption" sx={{ flexGrow: 1, ml: 1 }}>
            Prompt
          </Typography>
        </Stack>
        {eventPrompts.map((eventPrompt) => (
          <EventPromptRow eventPrompt={eventPrompt} />
        ))}
        <Button
          sx={{ width: "200px", alignSelf: "center" }}
          onClick={addNewEventPrompt}
        >
          Add new event prompt
        </Button>
      </Stack> */}
    </Box>
  );
}

function EventPromptRow(props) {
  const { eventPrompt } = props;
  return (
    <Stack direction="row" sx={{ mb: 1 }}>
      <Select
        size="small"
        sx={{ width: "240px", height: "min-content" }}
        value={eventPrompt.event}
      >
        <MenuItem value="earnings">Earnings Release</MenuItem>
        <MenuItem value="leadership">Leadership Change</MenuItem>
        <MenuItem value="merger">Merger & Acquisition</MenuItem>
      </Select>
      <TextField
        sx={{ flexGrow: 1, ml: 1 }}
        multiline
        size="small"
        value={eventPrompt.prompt}
      />
      <IconButton
        size="small"
        sx={{ width: "32px", height: "32px", ml: 0.5, mt: 0.25 }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
}

function ValidatedEmailChip(props) {
  const { email, getTagProps, index } = props;
  return (
    <Chip
      sx={{ pt: 0.25 }}
      color={validateEmailString(email) ? "primary" : "error"}
      variant="outlined"
      label={email}
      {...getTagProps({ index })}
    />
  );
}
