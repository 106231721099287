import React from 'react';
import Workspace from './Workspace';
import Toolbar from '../common/Toolbar';
import { Stack, Typography } from '@mui/material';

export default function WSTasks() {

  return (
    <div>
      <Toolbar>
        Tasks
      </Toolbar>
      <Workspace>
        <Stack alignItems="center" justifyContent="center" sx={{ flexGrow: '1', m: 10 }}>
          <Typography variant="h5" sx={{ opacity: 0.5 }}>Tasks page is under construction.</Typography>
        </Stack>
      </Workspace>
    </div>
  );
}