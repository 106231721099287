import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Image from "../../../../common/Image";

const useStyles = makeStyles(() => ({
  cardContent: {
    "&.MuiCardContent-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "20px",
      paddingRight: "20px",
      display: "flex",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  coName: {
    textAlign: "left",
    fontSize: "12pt",
    fontWeight: "bold",
    padding: "5px",
    whiteSpace: "nowrap",
  },
  colReview: {
    textAlign: "left",
    fontSize: "10pt",
    paddingLeft: "5px",
    paddingRight: "10px",
    whiteSpace: "nowrap",
  },
  ratings: {
    paddingLeft: "10px",
    textAlign: "center",
    fontSize: "10pt",
  },
  colDescr: {
    textAlign: "left",
    fontSize: "10pt",
  },
  ratingStar: {
    color: "blue",
    display: "inline-block",
    whiteSpace: "nowrap",
  },
  battleCardHeader: {
    textAlign: "left",
    verticalAlign: "top",
    fontWeight: "bold",
    fontSize: "10pt",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid gray",
  },
  battleCard: {
    textAlign: "left",
    fontSize: "10pt",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid gray",
  },
}));

function displayStars(ratingParm, classes) {
  var content = [1, 2, 3, 4, 5];
  var rating = ratingParm;
  var starSize = "0.8em";
  return (
    <div className={classes.ratingStar}>
      {content.map((row, index) => {
        if (rating > 1) {
          rating -= 1;
          return (
            <StarRateIcon
              key={index}
              style={{ height: starSize, width: starSize }}
            />
          );
        } else {
          if (rating > 0) {
            rating -= 1;
            return (
              <StarHalfIcon
                key={index}
                style={{ height: starSize, width: starSize }}
              />
            );
          } else
            return (
              <StarOutlineIcon
                key={index}
                style={{ height: starSize, width: starSize }}
              />
            );
        }
      })}
    </div>
  );
}

function Sellercomp(props) {
  const classes = useStyles();
  const previewData = props.previewData;
  const [sellerData, setSellerData] = React.useState(null);
  // TODO can just remove battlecard stuff?
  const [battleCard, setBattleCard] = React.useState({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // Menu Start, for battlecard
  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [state, setState] = React.useState(initialState);

  const handleClick = (event, row) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setBattleCard(row.BattleCard);
  };

  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    if (previewData) {
      setSellerData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId;
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            let rawdata = response.data;
            if (rawdata.Organizations) {
              setSellerData(rawdata);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
  }, [firstTime, openSnackbar, snackbarMessage, previewData]);

  if (!sellerData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={sellerData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <table>
          <tbody>
            {sellerData.Organizations.map((row, index) => {
              let item = displayStars(row.Rating, classes);
              return (
                <tr key={index} onClick={(e) => handleClick(e, row)}>
                  <td className={classes.coName}>{row.CompanyName}</td>
                  <td>
                    <Image src={row.Logo} alt="new" style={{ width: "80px" }} />
                  </td>
                  <td className={classes.ratings}>{item}</td>
                  <td className={classes.colReview}>
                    ({row.ReviewCount}) {row.Rating} out of 5
                  </td>
                  <td className={classes.colDescr}>{row.Description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}

export default Sellercomp;
