import { Button, Stack, Tooltip } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function PreviousNextPicker(props) {
  const {
    previousTooltip,
    nextTooltip,
    previousDisabled,
    nextDisabled,
    previousClick,
    nextClick,
    mini,
    ...rest
  } = props;
  return (
    <Stack direction="row" style={{ height: "36px" }} {...rest}>
      <Tooltip title={previousTooltip} arrow placement="top">
        <Button
          variant="outlined"
          disabled={previousDisabled}
          disableRipple
          sx={{
            borderRadius: "4px 0px 0px 4px",
            mr: "-1px",
            width: mini ? "20px" : "100px",
            p: 0,
            minWidth: "40px",
          }}
          onClick={previousClick}
        >
          {mini ? <ChevronLeftIcon /> : "Previous"}
        </Button>
      </Tooltip>
      <Tooltip title={nextTooltip} arrow placement="top">
        <Button
          variant="outlined"
          disabled={nextDisabled}
          disableRipple
          sx={{
            borderRadius: "0px 4px 4px 0px",
            width: mini ? "20px" : "100px",
            p: 0,
            minWidth: "40px",
          }}
          onClick={nextClick}
        >
          {mini ? <ChevronRightIcon /> : "Next"}
        </Button>
      </Tooltip>
    </Stack>
  );
}
