import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import {
  convertHtmlToRawContentState,
  formatUrl as formatStorageUrl,
} from "../common/utils";
import {
  Autocomplete,
  Button,
  Chip,
  Snackbar,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { rule5properties } from "../../properties";
import RichTextEditor from "../util/RichTextEditor";
import Box from "@mui/material/Box";
import { useDialog } from "../../context/DialogContext";
import EditCompanyPopup from "../modal/EditCompanyPopup";
import { isEmpty, isNull } from "lodash";

function getBulletListContentState(textTemplateArray) {
  const fieldsToNotBold = [
    "buyerStockSymbol",
    "buyerCompanyName",
    "firstCalendarYear",
    "lastCalendarYear",
  ];
  let textArray = textTemplateArray.map((textTemplateRow) => {
    let { textTemplate, ...textValues } = textTemplateRow;
    for (const [key, value] of Object.entries(textValues)) {
      if (fieldsToNotBold.includes(key)) {
        continue;
      }
      textValues[key] = "<strong>" + value + "</strong>";
    }
    return String(textTemplate).formatUnicorn(textValues);
  });

  const markup = "<ul><li>" + textArray.join("</li><li>") + "</li></ul>";
  return convertHtmlToRawContentState(markup);
}

export default function TrendsEditor(props) {
  const {
    register,
    control,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const formStyle = props.formStyle;
  const dialog = useDialog();

  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [companies, setCompanies] = React.useState(null);

  useEffect(() => {
    axios.get(rule5properties.detCompanies).then((response) => {
      setCompanies(response.data);
    });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // JS doesn't come with a string formatter.
  // Using from: https://xoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/4673436#4673436
  // This alters the String prototype, for the better AFAICT.
  // Should be no issues with it here in Newton.
  // eslint-disable-next-line no-extend-native
  String.prototype.formatUnicorn =
    String.prototype.formatUnicorn ||
    function () {
      "use strict";
      var str = this.toString();
      if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args =
          "string" === t || "number" === t
            ? Array.prototype.slice.call(arguments)
            : arguments[0];

        for (key in args) {
          str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
      }
      return str;
    };

  if (!companies) {
    return "Loading...";
  }

  return (
    <div>
      {!isEmpty(errors) && (
        <Typography color="red">
          Errors found below. Please check required fields.
        </Typography>
      )}
      <TitleInput register={register} />
      <br />
      <Button
        style={{ textTransform: "none" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          dialog.openModal("", EditCompanyPopup, {
            companies,
            updateCompaniesCallback: setCompanies,
          });
        }}
      >
        Manage companies
      </Button>
      <br />
      <br />
      Buyer:
      <br />
      <div style={{ display: "flex", flexFlow: "column", paddingLeft: "15px" }}>
        Stock symbol
        <input
          style={{ maxWidth: "200px" }}
          {...register(`BuyerStockSymbol`)}
        />
        or
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={companies.map((row, index) => {
                return { label: row.name, id: row.id };
              })}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Company name" />
              )}
              onChange={(e, data) => {
                return field.onChange(data);
              }}
            />
          )}
          name={`BuyerCompanyName`}
          control={control}
        />
      </div>
      <br />
      Peers:
      <br />
      <div style={{ display: "flex", flexFlow: "column", paddingLeft: "15px" }}>
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={[]}
              onChange={(e, data) => field.onChange(data)}
              freeSolo
              autoSelect
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Peer stock symbols"
                  placeholder="Press enter to add entries"
                />
              )}
            />
          )}
          name={`PeerStockSymbols`}
          type="select"
          control={control}
        />
        and/or
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={companies.map((row, index) => {
                return { label: row.name, id: row.id };
              })}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Peer company names" />
              )}
              onChange={(e, data) => {
                return field.onChange(data);
              }}
            />
          )}
          name={`PeerCompanyNames`}
          control={control}
        />
      </div>
      <br />
      <Button
        style={{ textTransform: "none" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          const buyerStockSymbol = getValues("BuyerStockSymbol");
          const buyerCompanyId = getValues("BuyerCompanyName")?.id;

          if (buyerStockSymbol && buyerCompanyId) {
            setSnackbarMessage(
              "ERROR:  please enter either buyer stock symbol OR buyer company name, not both."
            );
            setSnackbarOpen(true);
            return;
          }

          const peerStockSymbols = getValues("PeerStockSymbols");
          const peerCompanyNames = getValues("PeerCompanyNames");

          if (
            !buyerStockSymbol &&
            !buyerCompanyId &&
            (!peerStockSymbols || peerStockSymbols.length === 0) &&
            (!peerCompanyNames || peerCompanyNames.length === 0)
          ) {
            setSnackbarMessage(
              "ERROR: please enter at least one buyer or peer company"
            );
            setSnackbarOpen(true);
            return;
          }
          const peerCompanyIds = peerCompanyNames?.map((company) => company.id);

          const rimsPeerParams = {
            ...(buyerStockSymbol ? { buyerStockSymbol: buyerStockSymbol } : {}),
            ...(buyerCompanyId ? { buyerCompanyId: buyerCompanyId } : {}),
            ...(peerStockSymbols && peerStockSymbols.length > 0
              ? { peerStockSymbols: peerStockSymbols }
              : {}),
            ...(peerCompanyIds && peerCompanyIds.length > 0
              ? { peerCompanyIds: peerCompanyIds }
              : {}),
          };

          let requests = [
            axios.post(rule5properties.detRimsPeerGroup, rimsPeerParams),
            axios.post(rule5properties.detD3CPeerGroup, rimsPeerParams),
          ];

          if (buyerStockSymbol || buyerCompanyId) {
            requests.push(
              axios.post(rule5properties.detRevenueNetIncome, {
                ...(buyerStockSymbol
                  ? { buyerStockSymbol: buyerStockSymbol }
                  : {}),
                ...(buyerCompanyId ? { buyerCompanyId: buyerCompanyId } : {}),
              })
            );
          }

          Promise.all(requests)
            .then((body) => {
              const peerRims = body[0].data;
              const d3cPeerGroup = body[1].data;
              const revenueNetIncome = body[2]?.data;

              const invalid = body.find(
                (response) => response.data?.code === "INVALID_ARGUMENT"
              );
              if (invalid) {
                setSnackbarMessage("ERROR: " + invalid.data.message);
                setSnackbarOpen(true);
                return;
              }

              setValue(
                "TableAnalysisTextList",
                getBulletListContentState(peerRims.analysisTextList),
                { shouldDirty: true }
              );
              peerRims.peerGroupAverage.companyName = {
                label: "Peer Average",
                id: -100,
              };

              if (buyerStockSymbol || buyerCompanyId) {
                setValue("RevenueNetIncome", revenueNetIncome.trends);
                setValue(
                  "ChartAnalysisTextList",
                  getBulletListContentState(revenueNetIncome.analysisTextList)
                );

                const annualFinancials = d3cPeerGroup.map((year, index) => {
                  return year.trends[0];
                });
                setValue("AnnualFinancials", annualFinancials);
              } else {
                setValue("RevenueNetIncome", []);
                setValue("ChartAnalysisTextList", null);
                setValue("AnnualFinancials", null);
              }

              const recentYear = d3cPeerGroup?.[0];
              setValue(
                "D3cPeerGroupAnalysisTextList",
                getBulletListContentState(recentYear.analysisTextList)
              );

              const trends = peerRims.trends.map((trend, index) => {
                // indexOffset is here just as a convenience to place back in the
                // companyName from peerCompanyNames if we happen to have it.
                // The peer companies will be after buyer companies but before
                // peer companies which were inputted by symbol rather than name.
                const indexOffset =
                  buyerStockSymbol || buyerCompanyId ? index - 1 : index;
                return {
                  // Resetting the companyName.label to empty string explicitly because sometimes
                  // old values were somehow lingering.
                  companyName: peerCompanyNames?.[indexOffset]
                    ? peerCompanyNames[indexOffset]
                    : { id: null, label: "" },
                  ...trend,
                  ...recentYear.trends[index],
                };
              });
              const peerGroupData = {
                ...peerRims.peerGroupAverage,
                ...recentYear.peerGroupAverage,
              };

              setValue("KpiTrends", [...trends, peerGroupData]);

              if (buyerCompanyId) {
                setValue("KpiTrends[0].companyName", watch("BuyerCompanyName"));
              }
            })
            .catch((err) => {
              console.log(err);
              setSnackbarMessage("ERROR: " + err);
              setSnackbarOpen(true);
            });
        }}
      >
        Generate KPI
      </Button>
      <br />
      <hr></hr>
      <h5> Trends: </h5>
      <br />
      Chart Analysis text list
      <div>
        <Controller
          name="ChartAnalysisTextList"
          control={control}
          render={({ field }) => {
            return (
              <RichTextEditor value={field.value} onChange={field.onChange} />
            );
          }}
        />
      </div>
      <br />
      <br />
      Table Analysis text list
      <div>
        <Controller
          name="TableAnalysisTextList"
          control={control}
          render={({ field }) => {
            return (
              <RichTextEditor value={field.value} onChange={field.onChange} />
            );
          }}
        />
      </div>
      <br />
      <br />
      <Box sx={{ mb: 6 }}>
        D3cPeerGroup Table Analysis text list
        <div>
          <Controller
            name="D3cPeerGroupAnalysisTextList"
            control={control}
            render={({ field }) => {
              return (
                <RichTextEditor value={field.value} onChange={field.onChange} />
              );
            }}
          />
        </div>
      </Box>
      <RevenueNetIncome
        {...{ trigger, control, register, formStyle, getValues }}
      />
      <br />
      <br />
      <KpiTrends
        {...{
          trigger,
          control,
          register,
          errors,
          formStyle,
          getValues,
          setValue,
          companies,
        }}
      />
      <br />
      <br />
      <Trends
        {...{ trigger, control, register, formStyle, getValues, setValue }}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
        action={snackbarAction}
      />
    </div>
  );
}

/** Form array */
function KpiTrends({
  trigger,
  control,
  register,
  errors,
  formStyle,
  getValues,
  setValue,
  companies,
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `KpiTrends`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, kpiIndex) => {
        let peerGroup;
        if (item?.companyName) {
          peerGroup = item.companyName.label;
        }

        return (
          <div key={item.id}>
            <div className={formStyle}>
              stockSymbol
              <input {...register(`KpiTrends[${kpiIndex}].stockSymbol`)} />
              Company (required)
              <div>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      options={companies.map((row, index) => {
                        return { label: row.name, id: row.id };
                      })}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField {...params} label="Company" />
                      )}
                      onChange={(e, data) => {
                        if (data) {
                          const companyInfo = companies.find(
                            (company) => company.id === data.id
                          ).companyInfo;
                          if (companyInfo.icon) {
                            setValue(
                              `KpiTrends[${kpiIndex}].icon`,
                              companyInfo.icon
                            );
                          }
                        }
                        return field.onChange(data);
                      }}
                    />
                  )}
                  name={`KpiTrends[${kpiIndex}].companyName`}
                  control={control}
                  rules={{
                    validate: (value) =>
                      (!isEmpty(value) && !isNull(value?.id)) ||
                      "Error: missing company name",
                  }}
                />
                {errors?.KpiTrends?.[kpiIndex]?.companyName && (
                  <p style={{ color: "red" }}>Company name is required</p>
                )}
              </div>
              icon
              <input {...register(`KpiTrends[${kpiIndex}].icon`)} />
              currency
              <input {...register(`KpiTrends[${kpiIndex}].currency`)} />
              revenue
              <input {...register(`KpiTrends[${kpiIndex}].revenue`)} />
              netIncome
              <input {...register(`KpiTrends[${kpiIndex}].netIncome`)} />
              netIncomeCAGR
              <input {...register(`KpiTrends[${kpiIndex}].netIncomeCAGR`)} />
              netIncomePercent
              <input {...register(`KpiTrends[${kpiIndex}].netIncomePercent`)} />
              netIncomePercent4YearAvg
              <input
                {...register(`KpiTrends[${kpiIndex}].netIncomePercent4YearAvg`)}
              />
              roe
              <input {...register(`KpiTrends[${kpiIndex}].roe`)} />
              roe4YearAvg
              <input {...register(`KpiTrends[${kpiIndex}].roe4YearAvg`)} />
              accountsPayablesPercent
              <input
                {...register(`KpiTrends[${kpiIndex}].accountsPayablesPercent`)}
              />
              daysPayableOutstanding
              <input
                {...register(`KpiTrends[${kpiIndex}].daysPayableOutstanding`)}
              />
              accountsReceivablesPercent
              <input
                {...register(
                  `KpiTrends[${kpiIndex}].accountsReceivablesPercent`
                )}
              />
              cashConversionCycle
              <input
                {...register(`KpiTrends[${kpiIndex}].cashConversionCycle`)}
              />
              cogsPercent
              <input {...register(`KpiTrends[${kpiIndex}].cogsPercent`)} />
              daysSalesOutstanding
              <input
                {...register(`KpiTrends[${kpiIndex}].daysSalesOutstanding`)}
              />
              ebitdaPercent
              <input {...register(`KpiTrends[${kpiIndex}].ebitdaPercent`)} />
              inventoryPercent
              <input {...register(`KpiTrends[${kpiIndex}].inventoryPercent`)} />
              operatingCashFlowPercent
              <input
                {...register(`KpiTrends[${kpiIndex}].operatingCashFlowPercent`)}
              />
              {peerGroup !== "Peer Average" && (
                <>
                  firstCalendarYear
                  <input
                    {...register(`KpiTrends[${kpiIndex}].firstCalendarYear`)}
                  />
                  lastCalendarYear
                  <input
                    {...register(`KpiTrends[${kpiIndex}].lastCalendarYear`)}
                  />
                  accountsPayables
                  <input
                    {...register(`KpiTrends[${kpiIndex}].accountsPayables`)}
                  />
                  cogs
                  <input {...register(`KpiTrends[${kpiIndex}].cogs`)} />
                  accountsReceivables
                  <input
                    {...register(`KpiTrends[${kpiIndex}].accountsReceivables`)}
                  />
                  sales
                  <input {...register(`KpiTrends[${kpiIndex}].sales`)} />
                  operatingCashFlow
                  <input
                    {...register(`KpiTrends[${kpiIndex}].operatingCashFlow`)}
                  />
                  inventory
                  <input {...register(`KpiTrends[${kpiIndex}].inventory`)} />
                  ebitda
                  <input {...register(`KpiTrends[${kpiIndex}].ebitda`)} />
                  daysInventoryOutstanding
                  <input
                    {...register(
                      `KpiTrends[${kpiIndex}].daysInventoryOutstanding`
                    )}
                  />
                </>
              )}
              <MoveAutocomplete
                onChange={(moveToIndex) => {
                  move(kpiIndex, moveToIndex);
                  trigger();
                }}
                index={kpiIndex}
                options={[...Array(getValues("KpiTrends").length).keys()]}
              ></MoveAutocomplete>
            </div>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(kpiIndex);
                trigger();
              }}
            >
              Delete KpiTrends
            </DeleteButton>
            <br />
            <Divider
              light
              style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
            />
          </div>
        );
      })}

      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Trends
      </AddButton>
    </div>
  );
}

/** Form array */
function RevenueNetIncome({
  trigger,
  control,
  register,
  formStyle,
  getValues,
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `RevenueNetIncome`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, yearIndex) => {
        return (
          <div key={item.id}>
            <div className={formStyle}>
              calendarYear
              <input
                {...register(`RevenueNetIncome[${yearIndex}].calendarYear`)}
              />
              currency
              <input {...register(`RevenueNetIncome[${yearIndex}].currency`)} />
              revenue
              <input {...register(`RevenueNetIncome[${yearIndex}].revenue`)} />
              netIncome
              <input
                {...register(`RevenueNetIncome[${yearIndex}].netIncome`)}
              />
              <MoveAutocomplete
                onChange={(moveToIndex) => {
                  move(yearIndex, moveToIndex);
                  trigger();
                }}
                index={yearIndex}
                options={[
                  ...Array(getValues("RevenueNetIncome").length).keys(),
                ]}
              ></MoveAutocomplete>
            </div>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(yearIndex);
                trigger();
              }}
            >
              Delete RevenueNetIncome
            </DeleteButton>
            <br />
            <Divider
              light
              style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
            />
          </div>
        );
      })}

      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add RevenueNetIncome
      </AddButton>
    </div>
  );
}

/** Form array */
function Trends({
  trigger,
  control,
  register,
  formStyle,
  getValues,
  setValue,
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `Trends`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, trendsIndex) => {
        return (
          <div key={item.id}>
            <div className={formStyle}>
              Image Content
              <Controller
                control={control}
                defaultValue=""
                name={`Trends[${trendsIndex}].Image`}
                render={({ field }) => {
                  return (
                    <input
                      {...field}
                      onChange={(e) =>
                        field.onChange(formatStorageUrl(e.target.value))
                      }
                    />
                  );
                }}
              />
              <MoveAutocomplete
                onChange={(moveToIndex) => {
                  move(trendsIndex, moveToIndex);
                  trigger();
                }}
                index={trendsIndex}
                options={[...Array(getValues("Trends").length).keys()]}
              ></MoveAutocomplete>
            </div>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(trendsIndex);
                trigger();
              }}
            >
              Delete Trends
            </DeleteButton>
            <br />
            <Divider
              light
              style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
            />
          </div>
        );
      })}

      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Trends
      </AddButton>
    </div>
  );
}
