import { Stack, Typography } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";

export function NoSearchResultsOverlay(props) {
  return (
    <Stack
      sx={{ width: "100%", height: "100%", opacity: 0.5 }}
      alignItems="center"
      justifyContent="center"
    >
      <ManageSearchIcon
        fontSize="inherit"
        sx={{ mb: 1, fontSize: "128px", opacity: 0.6 }}
      />
      <Typography variant="h5">No results found</Typography>
    </Stack>
  );
}

export function NoCompanyAgentsOverlay(props) {
  return (
    <Stack
      sx={{ width: "100%", height: "100%", opacity: 0.5 }}
      alignItems="center"
      justifyContent="center"
    >
      <DomainDisabledIcon
        fontSize="inherit"
        sx={{ mb: 1, fontSize: "128px", opacity: 0.6 }}
      />
      <Typography variant="h5">No agents found</Typography>
    </Stack>
  );
}

export function NoPeopleAgentsOverlay(props) {
  return (
    <Stack
      sx={{ width: "100%", height: "100%", opacity: 0.5 }}
      alignItems="center"
      justifyContent="center"
    >
      <PersonOffIcon
        fontSize="inherit"
        sx={{ mb: 1, fontSize: "128px", opacity: 0.6 }}
      />
      <Typography variant="h5">No agents found</Typography>
    </Stack>
  );
}
