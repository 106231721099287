import { isValidCompanyUrl, isValidProfileUrl } from "../../../common/Utils";
import { rule5properties } from "../../../properties";

class AgentListColumn {
  constructor(
    key,
    displayName,
    validationCriteria = null,
    errorMessage,
    relativeWidth
  ) {
    this.key = key;
    this.displayName = displayName;
    this.validationCriteria = validationCriteria;
    this.errorMessage = errorMessage;
    this.relativeWidth = relativeWidth;
  }

  validate(value, rowData) {
    // If there's no validation criteria, consider it valid
    if (!this.validationCriteria) {
      return true;
    }
    return this.validationCriteria(value, rowData);
  }
}

export class AgentType {
  constructor(
    type,
    endpoint,
    displayName,
    singularText,
    helperText,
    consumptionKey,
    columns
  ) {
    this.type = type; // used in create endpoint and elsewhere
    this.endpoint = endpoint;
    this.displayName = displayName;
    this.singularText = singularText;
    this.helperText = helperText;
    this.consumptionKey = consumptionKey;
    this.columns = columns.map(
      (c) =>
        new AgentListColumn(
          c.key,
          c.displayName,
          c.validationCriteria,
          c.errorMessage,
          c.relativeWidth
        )
    );
  }

  getCsvTemplate() {
    return this.columns.map((c) => {
      let columnHeader = c.displayName;
      if (!c.validationCriteria) {
        columnHeader += OPTIONAL_TEXT;
      }
      return columnHeader;
    });
  }

  validateRows(rows) {
    return rows.map((row, rowIndex) => {
      const errors = {};

      this.columns.forEach((column) => {
        const value = row[column.key];

        // If the column has validation criteria, apply it
        if (!column.validate(value, row)) {
          // todo unused
          errors[column.key] = `Validation failed for ${
            column.displayName
          } in row ${rowIndex + 1}`;
        }
      });

      return {
        ...row,
        isValid: Object.keys(errors).length === 0,
      };
    });
  }
}

export const accountsAgentType = new AgentType(
  "Accounts",
  rule5properties.agentAccounts,
  "companies",
  "company",
  "Company name is required. Include domain and/or linkedIn URL to improve accuracy of results.",
  "monitorAccountsLimit",
  [
    new AgentListColumn(
      "companyName",
      "Company",
      (value) => typeof value === "string" && value.length > 0,
      "Company name is required",
      2
    ),
    new AgentListColumn("companyDomain", "Company domain", null, "", 1.5),
    new AgentListColumn(
      "linkedinCompanyUrl",
      "LinkedIn Company URL",
      (value) => !value || isValidCompanyUrl(value), // can be empty
      "Invalid LinkedIn company URL",
      2.5
    ),
  ]
);

function isValidPeopleRow(personRow) {
  return (
    isValidProfileUrl(personRow["linkedinProfileUrl"]) ||
    (personRow["companyName"] &&
      personRow["firstName"] &&
      personRow["lastName"]) //todo would prefer not hardcode fields here
  );
}

export const peopleAgentType = new AgentType(
  "People",
  rule5properties.agentPeople,
  "people",
  "person",
  `To ensure the most accurate tracking, please provide the LinkedIn profile URL if available; this will be prioritized over the first name and last name. If the LinkedIn URL is unavailable, enter the first name and last name. Company and company domain are optional. Include company if you're unsure of their LinkedIn URL, or if you want to track job changes for the person since leaving a particular company. If company is not specified, only future company job changes will be tracked for this person. Include company’s domain to improve accuracy of results.`,
  "monitorContactsLimit",
  [
    new AgentListColumn(
      "linkedinProfileUrl",
      "LinkedIn Profile URL",
      (value, rowData) => isValidPeopleRow(rowData),
      "Invalid LinkedIn profile URL",
      4
    ),
    new AgentListColumn(
      "companyName",
      "Company",
      (value, rowData) =>
        isValidProfileUrl(rowData["linkedinProfileUrl"]) || value,
      "Company name or valid LinkedIn profile is required",
      2
    ),
    new AgentListColumn("companyDomain", "Company Domain", null, "", 2),
    new AgentListColumn(
      "firstName",
      "First Name",
      (value, rowData) =>
        isValidProfileUrl(rowData["linkedinProfileUrl"]) || value,
      "First name or valid LinkedIn profile is required",
      2
    ),
    new AgentListColumn(
      "lastName",
      "Last Name",
      (value, rowData) =>
        isValidProfileUrl(rowData["linkedinProfileUrl"]) || value,
      "Last name or valid LinkedIn profile is required",
      2
    ),
  ]
);

export default AgentType;

export const OPTIONAL_TEXT = " (optional)";
