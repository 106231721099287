import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function CloseItem(props) {
  return <Tooltip title={props.title ? props.title : "Hide this item"} enterDelay={800} >
    <IconButton {...props}>
      <CloseIcon />
    </IconButton>
  </Tooltip>;
}