import React from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CancelConfirmButtons,
  StyledLabel,
  StyledTextfield,
} from "../common/StyledComponents";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDialog } from "../context/DialogContext";
import { rule5properties } from "../properties";
import axios from "axios";

export default function EditApiKey(props) {
  const { keyToEdit, loadApiKeys } = props;

  const originalKeyName = keyToEdit.apiKeyName;

  const dialogContext = useDialog();

  const [keyVisible, setKeyVisible] = React.useState(false);
  const [keyName, setKeyName] = React.useState(
    keyToEdit.apiKeyName || "EMPTY_NAME"
  );

  function obfuscateKey(key) {
    return "●".repeat(key.length - 4) + key.substring(key.length - 4);
  }

  function toggleVisibility() {
    setKeyVisible(!keyVisible);
  }

  function copyApiKey() {
    navigator.clipboard.writeText(keyToEdit.apiKey);
  }

  function saveApiKey() {
    const params = {
      newApiKeyName: keyName,
    };
    axios
      .patch(rule5properties.apiKeys + "/" + originalKeyName, params)
      .then((res) => {
        if (res.status === 200) {
          loadApiKeys();
          dialogContext.closeModal();
        } else {
          console.log("[rule5] Error while saving API key name.");
        }
      });
  }

  return (
    <Box>
      <StyledLabel sx={{ mb: 1 }}>Key name</StyledLabel>
      <StyledTextfield
        sx={{ mb: 2 }}
        fullWidth
        value={keyName}
        onChange={(e) => {
          setKeyName(e.target.value);
        }}
      />
      <StyledLabel sx={{ mb: 1 }}>Secret key</StyledLabel>
      <StyledTextfield
        inputProps={{
          style: { opacity: 0.5, marginRight: "8px", textAlign: "end" },
        }}
        sx={{ mb: 1 }}
        fullWidth
        readOnly
        value={keyVisible ? keyToEdit.apiKey : obfuscateKey(keyToEdit.apiKey)}
        endAdornment={[
          <Tooltip title={keyVisible ? "Hide" : "Reveal"}>
            <IconButton
              onClick={toggleVisibility}
              size="small"
              sx={{ mr: 0.5 }}
            >
              {keyVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>,
          <Tooltip title="Copy key">
            <IconButton onClick={copyApiKey} size="small">
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>,
        ]}
      />
      <Typography variant="settingsCaption" sx={{ mb: 2 }}>
        <strong>Important:</strong> Keep your API key safe! Treat it like a
        password and avoid sharing it publicly. This key grants access to
        sensitive data and actions on your behalf.{" "}
      </Typography>
      <Collapse in={originalKeyName !== keyName}>
        <CancelConfirmButtons
          sx={{ mt: 2 }}
          cancelText="Cancel"
          cancelFunction={dialogContext.closeModal}
          confirmText="Save changes"
          confirmFunction={saveApiKey}
        />
      </Collapse>
    </Box>
  );
}
