import React, { useEffect } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import RichTextEditor from "../util/RichTextEditor";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import {
  Autocomplete,
  TextField,
  IconButton,
  Snackbar,
  Button,
} from "@mui/material";
import axios from "axios";
import { rule5properties } from "../../properties";
import CloseIcon from "@mui/icons-material/Close";
import EditCompanyPopup from "../modal/EditCompanyPopup";
import { useDialog } from "../../context/DialogContext";
import ImageInput from "../util/ImageInput";

export default function TechnologyLandscapeEditor(props) {
  const formStyle = props.formStyle;

  const { register, control, trigger, getValues, setValue } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "Company",
  });

  const [companies, setCompanies] = React.useState(null);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [lastDeleted, setLastDeleted] = React.useState(null);

  const dialog = useDialog();

  useEffect(() => {
    axios
      .get(rule5properties.detCompanies)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarMessage("ERROR: " + error);
        setSnackbarOpen(true);
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  if (!companies) {
    return "Loading...";
  }

  return (
    <div>
      <TitleInput register={register} />
      <br />
      <Button
        style={{ textTransform: "none" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          dialog.openModal("", EditCompanyPopup, {
            companies,
            updateCompaniesCallback: setCompanies,
          });
        }}
      >
        Manage companies
      </Button>
      <br />
      <br />
      {fields.map((news, companyIndex) => (
        <div key={news.id}>
          <div className={formStyle}>
            Company
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  options={companies.map((row, index) => {
                    return { label: row.name, id: row.id };
                  })}
                  getOptionLabel={(option) => option.label || option}
                  renderInput={(params) => (
                    <TextField {...params} label="Company" />
                  )}
                  onChange={(e, data) => {
                    if (data) {
                      const companyInfo = companies.find(
                        (company) => company.id === data.id
                      ).companyInfo;
                      if (companyInfo.icon) {
                        setValue(
                          `Company[${companyIndex}].Logo`,
                          companyInfo.icon
                        );
                      }
                    }
                    return field.onChange(data);
                  }}
                />
              )}
              name={`Company[${companyIndex}].Name`}
              control={control}
            />
            Logo
            <ImageInput
              name={`Company[${companyIndex}].Logo`}
              control={control}
              setValue={setValue}
            />
            Product
            <input {...register(`Company[${companyIndex}].Product`)} />
            Details
            <input {...register(`Company[${companyIndex}].Details`)} />
            Remarks:
            <Controller
              name={`Company[${companyIndex}].Remarks`}
              control={control}
              render={({ field }) => {
                return (
                  <RichTextEditor
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            />
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(companyIndex, moveToIndex);
                trigger();
              }}
              index={companyIndex}
              options={[...Array(getValues("Company").length).keys()]}
            ></MoveAutocomplete>
          </div>
          <DeleteButton
            onClick={() => {
              setLastDeleted(news);
              remove(companyIndex);
              trigger();
            }}
          >
            Delete Company
          </DeleteButton>

          <Divider
            light
            style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
          />
        </div>
      ))}
      <AddButton
        onClick={() => {
          appendFuncClickHandler(append, lastDeleted);
        }}
      >
        Add Company
      </AddButton>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
        action={snackbarAction}
      />
    </div>
  );
}

/*
const stubData = {
  Title: "Technology & Solutions Landscape",
  Company: [
    {
      Name: "Blue Yonder",
      Logo: "https://drive.google.com/file/d/1W4m1UYkkSyJhwyQBILolI4dmyIbIBGdn/view?usp=sharing",
      Product: " WMS & TMS",
      IsUsed: "Yes",
      Remarks: {
        blocks: [
          {
            key: "6mld6",
            text: "Western Digital uses JTA (Blue Yonder) warehouse management and transportation management applications.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [
              { offset: 0, length: 103, style: "fontsize-12" },
            ],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
    },
    {
      Name: "SAP",
      Logo: "https://www.sap.com/content/dam/application/shared/logos/sap-logo-svg.svg",
      Product: "S4/HANA, ERP & SCM",
      IsUsed: "Yes",
      Remarks: {
        blocks: [
          {
            key: "6mld6",
            text: "Western Digital uses SAP for supply chain planning and ERP functions.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [
              { offset: 0, length: 100, style: "fontsize-12" },
            ],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
    },
  ],
};
*/
