import { CircularProgress, Stack, Switch, Tooltip } from "@mui/material";
import React from "react";
import axios from "axios";
import { rule5properties } from "../../properties";
import { isSuccessStatus } from "../../common/RequestUtils";
import useSnack from "../../context/Snack";

export default function AgentStatusSwitch(props) {
  const { cellValues } = props;

  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(
    cellValues.row?.status !== "Disabled"
  );
  const snackBar = useSnack();

  const Icon = () => (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: 15,
        height: 15,
        borderRadius: "50%",
        backgroundColor:
          checked && !loading ? "primary.main" : "background.default",
        boxShadow: 1,
      }}
    >
      {loading && <CircularProgress size={12} thickness={6} />}
    </Stack>
  );

  return (
    <Tooltip title={`Status: ${cellValues.row?.status}`}>
      <Switch
        checkedIcon={<Icon />}
        icon={<Icon />}
        checked={checked}
        size="small"
        color="primary"
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          e.stopPropagation();
          setLoading(true);
          const newStatus = {
            status:
              cellValues.row?.status !== "Disabled" ? "Disabled" : "Active",
          };
          axios
            .patch(
              rule5properties.agents + `/${cellValues.row?.agentId}`,
              newStatus
            )
            .then((resp) => {
              setLoading(false);
              if (isSuccessStatus(resp.status)) {
                setChecked((currChecked) => !currChecked);
              } else {
                snackBar.createSnack(
                  resp.data?.message ||
                    "Unexpected error updating agent status."
                );
              }
            })
            .catch((error) => {
              setLoading(false);
              snackBar.createSnack("Unexpected error updating agent status.");
            });
        }}
      />
    </Tooltip>
  );
}
