import * as React from "react";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import { DialogProvider } from "../context/DialogContext";
import ManageResearch from "./ManagerResearch";
import { DetMenuList } from "./DetMenuList";
import ManageCards from "./ManageCards";
import DetCardEditor from "./DetCardEditor";
import ManageDetUsers from "./ManageDetUsers";
import ManageCompanies from "./ManageCompanies";
import ManageOrgs from "./ManageOrgs";
import ResearchDetail from "./ResearchDetail";
import { useEffect } from "react";
import AuthService from "../user/AuthService";
import LastPage from "@mui/icons-material/LastPage";
import {
  useLocation,
  useHistory,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  Avatar,
  Fade,
  Collapse,
  Divider,
  Stack,
  Link,
  styled,
} from "@mui/material";
import { useUser, useUserUpdate } from "../context/UserContext";
import FilterFormProvider from "./context/FilterFormContext";

const researchAgent = "Research Agent";
const researchApprover = "Research Approver";
export const superUser = "Super User";
export const detRoles = [researchAgent, researchApprover, superUser];
export const approverRoles = [researchApprover, superUser];

const useStyles = makeStyles(() => ({
  drawer: {
    "& .MuiDrawer-paper": {
      background: "#FFFFFF",
    },
    zIndex: "900",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
  },
  avatarContainer: {
    margin: "2px 0px 0px 2px",
  },
  avatar: {
    cursor: "pointer",
    width: "38px",
    height: "38px",
    transition: "0.2s",
    backgroundColor: "rgba(0,0,0,0.3)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.4)",
    },
  },
  logoLarge: {
    display: "block",
    height: "24px",
    paddingLeft: "8px",
    margin: "12px auto 0px auto",
  },
  logoSmall: {
    height: "24px",
    margin: "auto",
  },
  logoLink: {
    cursor: "pointer",
    height: "100%",
    flexGrow: "1",
    transition: "0.2s",
    opacity: 0.6,
    "&:hover": {
      filter:
        "invert(38%) sepia(98%) saturate(744%) hue-rotate(179deg) brightness(85%) contrast(102%)",
      opacity: 1,
    },
  },
}));

const openedMixin = (theme) => ({
  width: theme.mainDrawer.width,
  transition: theme.transitions.create(["width", "padding"], {
    easing: theme.transitions.easing.sharp,
    duration: 300,
  }),
  padding: "0px 16px 0px 16px",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create(["width", "padding"], {
    easing: theme.transitions.easing.sharp,
    duration: 200,
  }),
  padding: "0px 8px 0px 8px",
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 4px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 4px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0px 0px 0px 0px",
  height: theme.toolbar.height,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: theme.mainDrawer.width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const navigation = ["Manage Cards", "Manage Research", "Super User"];

function DetMainPage(props) {
  const user = useUser();
  const updateUserContext = useUserUpdate();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [showHeaderItems, setShowHeaderItems] = React.useState(false);
  const [showUserInfo, setShowUserInfo] = React.useState(false);

  useEffect(() => {
    if (!detRoles.includes(user.role)) {
      // User is not permitted to access DET, redirect them to default page.
      history.push("/");
    } else if (open) {
      setTimeout(() => {
        setShowHeaderItems(true);
      }, 100);
    } else {
      setShowHeaderItems(false);
    }
  }, [open]);

  const handleLogout = (event) => {
    AuthService.logout();
    updateUserContext(null);
    history.push("/");
  };

  const handleCoreAppClick = (event) => {
    history.push("/main");
  };

  const handleMenuClick = (index) => {};

  const toggleDrawer = () => {
    setOpen((prevDrawer) => !prevDrawer);
  };

  const classes = useStyles();
  const location = useLocation();

  // Allow user menu to pop up temporarily and refresh on re-click.
  const [userInfoTimeoutId, setUserInfoTimeoutId] = React.useState(null);

  const openAvatarMenu = () => {
    clearTimeout(userInfoTimeoutId);
    setShowUserInfo((prev) => {
      // If previous state was NOT showing user info, start a close timer.
      if (!prev) {
        let id = setTimeout(() => {
          setShowUserInfo(false);
          setUserInfoTimeoutId(null);
        }, 4000);
        setUserInfoTimeoutId(id);
      }
      return !prev;
    });
  };

  return (
    <FilterFormProvider>
      <DialogProvider>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Drawer
            variant="permanent"
            open={open}
            classes={{ root: classes.drawer }}
          >
            <DrawerHeader>
              <div className={classes.header}>
                <CSSTransition
                  in={showHeaderItems}
                  unmountOnExit
                  timeout={{ enter: 500, exit: 100 }}
                  classNames="navHeader"
                >
                  <Stack
                    alignItems="center"
                    className="navHeader"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      height: "60px",
                    }}
                  >
                    <div className={classes.avatarContainer}>
                      <Avatar
                        className={classes.avatar}
                        onClick={openAvatarMenu}
                      >
                        {user.firstName.toUpperCase().charAt(0)}
                      </Avatar>
                    </div>
                    <div style={{ flexGrow: "1", marginBottom: "10px" }}>
                      <Fade
                        in={!showUserInfo}
                        timeout={{ appear: 400, enter: 800, exit: 200 }}
                      >
                        <img
                          className={classes.logoLarge}
                          src={require("../res/r5_logo.svg").default}
                          alt="Rule5 Logo Large"
                        />
                      </Fade>
                      <Collapse in={showUserInfo} timeout={400}>
                        <Box
                          sx={{
                            ml: 3,
                            mb: 5,
                            mt: 2,
                            fontSize: "12px",
                            width: "100px",
                          }}
                        >
                          <span
                            style={{
                              opacity: 0.7,
                              display: "block",
                              marginBottom: "4px",
                            }}
                          >
                            {user.email}
                          </span>
                          <Link
                            sx={{ mr: "4px", textDecoration: "none" }}
                            href="#"
                            onClick={handleLogout}
                          >
                            Log out
                          </Link>{" "}
                          |{" "}
                          <Link
                            sx={{ ml: "4px", textDecoration: "none" }}
                            href="#"
                            onClick={handleCoreAppClick}
                          >
                            Core app
                          </Link>
                        </Box>
                      </Collapse>
                    </div>
                    {/* <IconButton
                                        onClick={toggleDrawer}
                                        sx={{ width: "32px", height: "32px", margin: "auto" }}
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton> */}
                    <IconButton
                      onClick={toggleDrawer}
                      sx={{ width: "32px", height: "32px", margin: "auto" }}
                    >
                      <LastPage sx={{ transform: "rotate(180deg)" }} />
                    </IconButton>
                  </Stack>
                </CSSTransition>
                <CSSTransition
                  in={!open}
                  unmountOnExit
                  timeout={{ appear: 0, enter: 100, exit: 100 }}
                  classNames="logoSmall"
                >
                  <div
                    className="logoSmall"
                    style={{ flex: "1", height: "60px" }}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      className={classes.logoLink}
                      onClick={toggleDrawer}
                    >
                      <img
                        className={classes.logoSmall}
                        src={require("../res/r5_logo.svg").default}
                        alt="Rule5 Logo Small"
                      />
                    </Stack>
                  </div>
                </CSSTransition>
              </div>
            </DrawerHeader>
            <Divider sx={{ opacity: 0.5, mb: 3 }} />
            <DetMenuList handleMenuClick={handleMenuClick} />
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              padding: 0,
              height: "100vh",
              overflow: "auto",
              backgroundColor: "#FAFAFA",
            }}
          >
            <Switch location={location}>
              <Route exact path="/det">
                <Redirect to="/det/managecards" />
              </Route>
              <Route
                exact
                path="/det/manageresearch/:researchId"
                component={ResearchDetail}
              />
              <Route
                exact
                path="/det/manageresearch"
                component={ManageResearch}
              />
              <Route path="/det/managecards" component={ManageCards} />
              <Route path="/det/editcards" component={DetCardEditor} />
              <Route path="/det/managedetusers" component={ManageDetUsers} />
              <Route path="/det/managecompanies" component={ManageCompanies} />
              <Route path="/det/manageorgs" component={ManageOrgs} />
            </Switch>
          </Box>
        </Box>
      </DialogProvider>
    </FilterFormProvider>
  );
}
export default DetMainPage;
