import React from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDialog } from "../../../context/DialogContext";
import GenericConfirmation from "../../../modal/GenericConfirmation";
import { rule5properties } from "../../../properties";
import axios from "axios";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { formatDate } from "../../../common/Utils";
import { GrayCircularProgress } from "../../../common/StyledComponents";

export const COMPANIES_RUN_NOW_LIMIT = 20;

export default function RunAgentButton(props) {
  const { type, cellValues, updateAgentRows, timeZone } = props;

  const dialog = useDialog();

  const statusText =
    cellValues.row.monitorStatus === "Active"
      ? "Run agent"
      : cellValues.row.monitorStatus === "Processing"
      ? "Running..."
      : cellValues.row.monitorStatus === "Failed"
      ? "Error"
      : "Active";
  const running = cellValues.row.monitorStatus === "Processing";
  const maxRowsExceeded =
    type === "accounts" &&
    +cellValues.row.accountsActiveCount > COMPANIES_RUN_NOW_LIMIT;
  const date = new Date(cellValues.row?.lastRunTime);
  const dateFormat = new Intl.DateTimeFormat("en-US", {
    timeZone: timeZone ? timeZone : undefined,
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const exceededMsg = (
    <>
      Run now is only available for agents with {COMPANIES_RUN_NOW_LIMIT} or
      fewer companies
    </>
  );

  let tooltipText = "Run agent";
  if (cellValues.row?.lastRunTime) {
    tooltipText = <>Last run: {dateFormat.format(date)}</>;
    if (maxRowsExceeded) {
      tooltipText = (
        <>
          {tooltipText}
          <br />
          {exceededMsg}
        </>
      );
    }
  } else if (maxRowsExceeded) {
    tooltipText = exceededMsg;
  }
  return (
    <Tooltip title={tooltipText} arrow placement="right">
      <span
        style={{ cursor: "not-allowed" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* span wrapper is hack for tooltip to work on disabled button: https://github.com/mui/material-ui/issues/8416*/}
        <Button
          disabled={
            running || maxRowsExceeded || cellValues.row?.status === "Disabled"
          }
          onClick={(e) => {
            e.stopPropagation();
            dialog.openModal(
              cellValues.row.name,
              GenericConfirmation,
              {
                text: `Your request will run in the background. It may take several minutes - typically 1 to 10 minutes. Feel free to grab a cup of coffee while you wait. Thank you for your patience!`,
                confirmFunction: (dialogCallback) => {
                  const params = {
                    agentId: cellValues.row.agentId,
                  };
                  const options = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                  };
                  axios
                    .post(
                      cellValues.row.type === "Accounts"
                        ? rule5properties.runAccountAgent
                        : rule5properties.runPeopleAgent,
                      params,
                      options
                    )
                    .then((res) => {
                      updateAgentRows(cellValues.row.agentId, {
                        monitorStatus: "Processing",
                      });
                      if (dialogCallback) dialogCallback(res);
                    });
                },
              },
              "sm"
            );
          }}
          disableRipple
          color="inherit"
          sx={{ opacity: "0.6", borderRadius: "8px" }} // mui disabled style adds 0.5 alpha/opacity on element so non-disabled should be higher than that
        >
          {running ? (
            <GrayCircularProgress size={16} thickness={6} sx={{ mx: 0.5 }} />
          ) : (
            <AutorenewIcon />
          )}
          <StatusDisplay statusText={statusText} />
        </Button>
      </span>
    </Tooltip>
  );
}

function StatusDisplay(props) {
  const { statusText } = props;
  return (
    <>
      <Typography variant="caption" sx={{ ml: 0.5, mr: 0.75 }}>
        {statusText}
      </Typography>
    </>
  );
}
