import React from "react";
import Workspace from "./Workspace";
import Toolbar from "../common/Toolbar";
import { Button, useTheme } from "@mui/material";
import PromptBooksTable from "./chat/PromptBooksTable";
import { useDialog } from "../context/DialogContext";
import CreatePromptBook from "../modal/CreatePromptBook";

export default function WSPromptBooks() {
  const [shouldReload, setShouldReload] = React.useState(null);

  const dialog = useDialog();
  const theme = useTheme();

  return (
    <div>
      <Toolbar>
        <>
          Prompt books
          <Button
            color="primary"
            variant="contained"
            sx={{ textTransform: "none", height: "38px", mt: 1 }}
            aria-label="add"
            aria-haspopup="true"
            disableRipple
            onClick={() => {
              dialog.openModal(
                "Create prompt book",
                CreatePromptBook,
                { setShouldReload: setShouldReload },
                "sm"
              );
            }}
          >
            New prompt book
          </Button>
        </>
      </Toolbar>
      <Workspace>
        <div
          style={{
            minHeight: "500px",
            height: "100%",
            maxWidth: "100%",
            padding: `0px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PromptBooksTable
            shouldReload={shouldReload}
            setShouldReload={setShouldReload}
          ></PromptBooksTable>
        </div>
      </Workspace>
    </div>
  );
}
