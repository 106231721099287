import axios from "axios";
import JwtService from "./JwtService.js";
import { rule5properties } from "../properties.js";

const API_URL = rule5properties.authApiUrl;

class AuthService {
  login(username, password) {
    const params = {
      email: username,
      password: password,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
      withCredentials: true,
    };
    return axios
      .post(API_URL + "/login", params, options)
      .then((res) => {
        let data = res.data;
        if (data.accessToken) {
          JwtService.setToken(data.accessToken, data.expiresIn);
        }
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async logout() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    axios.get(API_URL + "/logout", options).then((res) => {});
    JwtService.deleteToken();
  }

  async register(registrationValues) {
    const {
      firstName,
      lastName,
      email,
      password,
      orgName,
      orgSubdomain,
      confirmPassword,
      planType,
      timeZone,
    } = registrationValues;
    const registerUserObject = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      orgName: orgName,
      orgSubdomain: orgSubdomain,
      confirmPassword: confirmPassword,
      planType: planType,
      timeZone: timeZone,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };
    return axios
      .post(API_URL + "/register", registerUserObject, options)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  }

  async invitationRegister(registrationValues) {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      token,
      planType,
      timeZone,
    } = registrationValues;
    const registerUserObject = {
      firstName: firstName,
      lastName: lastName,
      password: password,
      confirmPassword: confirmPassword,
      token: token,
      planType: planType,
      timeZone: timeZone,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
      withCredentials: true,
    };
    return axios
      .post(API_URL + "/invitation-register", registerUserObject, options)
      .then((res) => {
        let data = res.data;
        if (data.accessToken) {
          JwtService.setToken(data.accessToken, data.expiresIn);
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async verifyInvitationToken(token) {
    const params = {
      token: token,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };
    return axios
      .post(API_URL + "/verify-invite-token", params, options)
      .then((res) => res)
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  refreshToken() {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
        "Cache-Control": "no-cache",
      },
      withCredentials: true,
    };
    return axios
      .get(API_URL + "/refresh-token", options)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  verifyEmail(search) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
      timeout: 7000,
    };
    let url = new URL(API_URL + "/verify-email");
    url.search = new URLSearchParams(search).toString();
    return axios
      .get(url.toString(), options)
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  verifyPasswordResetToken(token) {
    const params = {
      token: token,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };

    return axios
      .post(API_URL + "/verify-reset-token", params, options)
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async resendEmailVerification(email) {
    const params = {
      email: email,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };

    return axios
      .post(API_URL + "/resend-email-verification", params, options)
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  requestPasswordReset(email) {
    const params = {
      email: email,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };
    return axios
      .post(API_URL + "/forgot-password", params, options)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  submitPasswordReset(token, passwordInput) {
    const params = {
      token: token,
      password: passwordInput.password,
      confirmPassword: passwordInput.confirmPassword,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };
    return axios
      .post(API_URL + "/reset-password", params, options)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}

export default new AuthService();
