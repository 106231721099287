import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles(() => ({
  actionButtons: {
    paddingRight: "0.5",
    display: "flex",
    justifyContent: "right",
    width: "100%",
  },
  buttonStyle: {
    position: "relative",
    textTransform: "none",
  },
}));

const defaultUserInformation = {
  id: -1,
  accountName: "",
  companyName: "",
  domain: "",
  salesForceId: "",
  lastSync: "",
};

const noOptions = ["None"];

export default function ManageSfdcAccountPopup(props) {
  const classes = useStyles();
  const [values, setValues] = useState(defaultUserInformation);

  const { updateCRM, recordForEdit, sfdcAccounts } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
      domain: " ",
      companyName: " ",
    });
  };

  const handleCompanyChange = (event, value, id) => {
    let account = sfdcAccounts[sfdcAccounts.findIndex((x) => x.Name === value)];

    if (account) {
      setValues({
        ...values,
        [id]: value,
        domain: account.Website !== null ? account.Website : " ",
        crmAccountId: account.Id,
        companyName: value,
      });
    } else {
      setValues({
        ...values,
        [id]: value,
        domain: " ",
        companyName: " ",
        crmAccountId: " ",
      });
    }
  };

  const resetForm = () => {
    setValues(defaultUserInformation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateCRM(values, resetForm);
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]);

  return (
    <div>
      <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "350px" },
          }}
        >
          <TextField
            name="Account Name"
            label="Account Name"
            value={values.name}
            size="small"
            variant="standard"
            required
            disabled={values.id !== -1}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {values.id !== -1 && (
            <TextField
              name="Domain"
              label="Domain"
              value={values.domain}
              size="small"
              variant="standard"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <FormControl
            variant="standard"
            required
            sx={{ position: "relative", top: "-3px", minWidth: 100 }}
          >
            <Autocomplete
              name="companyName"
              id="companyName"
              disabled={sfdcAccounts ? false : true}
              value={values.companyName}
              options={
                sfdcAccounts
                  ? sfdcAccounts?.map((option) => option.Name)
                  : noOptions
              }
              sx={{ width: 350 }}
              onChange={(e, value) =>
                handleCompanyChange(e, value, "companyName")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Salesforce Account Name"
                  variant="standard"
                />
              )}
            />
          </FormControl>

          <FormControl variant="standard" required sx={{ minWidth: 100 }}>
            {values.id !== -1 && (
              <TextField
                name="crmAccountId"
                label="Salesforce Account ID"
                value={values.crmAccountId}
                onChange={handleInputChange}
                size="small"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </FormControl>
        </Box>
        <div className={classes.actionButtons}>
          <Button
            className={classes.buttonStyle}
            type="submit"
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </Box>
    </div>
  );
}
