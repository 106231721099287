import React from "react";
import { useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

export default function PosthogPageTracker(props) {
  const location = useLocation();
  const posthog = usePostHog();

  React.useEffect(() => {
    if (posthog) {
      posthog.capture("$pageview");
    }
  }, [location, posthog]);

  return null;
}
