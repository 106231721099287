import React from "react";
import { Button, MobileStepper, Typography } from "@mui/material";
import "react-dots-loader/index.css";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

/** Used for stepping forward and backward in chat version history for regeneratable content ie generated emails */
export default function ChatVersionSelector(props) {
  const { handleNext, handleBack, activeStep, maxSteps, name } = props;

  return (
    <MobileStepper
      variant="text"
      steps={maxSteps}
      style={{
        backgroundColor: "transparent",
        width: "fit-content",
        color: "rgb(107,149,185)",
        padding: "0px",
        marginRight: "5px",
      }}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          size="small"
          sx={{ minWidth: "0", marginLeft: "5px" }}
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <>
          <Button
            size="small"
            sx={{ minWidth: "0", marginRight: "5px" }}
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
          </Button>
          <Typography paddingRight="5px">{name}</Typography>
        </>
      }
    />
  );
}
