import React, { useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import { alpha, darken, lighten, styled } from "@mui/material/styles";
import {
  autocompleteClasses,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Menu,
  Popper,
  Stack,
  TableCell,
  tableCellClasses,
  TableRow,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { getSrcFromApi } from "./Image";
import { useUser } from "../context/UserContext";
import CurrencyInput from "react-currency-input-field";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useImageMapContext } from "../context/ImageMapContext";

export const fieldStyle = {
  backgroundColor: "#FCFCFC",
  // height: '50px',
  fontSize: "16px",
  padding: "10px 14px 6px 14px",
  borderRadius: "6px",
  border: "1px solid #DDD",
  "& .MuiAutocomplete-endAdornment": {
    marginRight: "10px",
  },
};

export const labelStyle = {
  marginBottom: "4px",
  marginLeft: 1,
  fontSize: "14px",
};

export const errorStyle = {
  marginTop: "2px",
  fontSize: "12px",
  color: "#e64539",
  overflow: "visible",
  whiteSpace: "inherit",
  textOverflow: "ellipsis",
};

export const circularProgressStyle = {
  color: "rgba(0,0,0,0.4)",
};

export const linkStyle = {
  textDecoration: "underline 0.1em rgba(0,0,0,0)",
  fontWeight: 500,
  color: "rgb(25, 118, 210)",
  transition: "text-decoration-color 0.2s",
  "&:hover": {
    textDecorationColor: "rgb(25, 118, 210)",
  },
  "&:active": {
    color: "rgba(0,0,0,0.5)",
  },
  cursor: "pointer",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export const scrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "10px",
    height: "10px",
  },
  "&::-webkit-scrollbar-track": {
    margin: "5px 0px 5px 0px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0,0,0,.15)",
  },
};

export const scrollbarStyleThin = {
  "&::-webkit-scrollbar": {
    width: "4px",
    height: "8px",
  },
  "&::-webkit-scrollbar-track": {
    margin: "4px 0px 4px 0px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "2px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0,0,0,.15)",
  },
};

export const whiteButtonStyle = {
  color: "inherit",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,.03)",
  },
};

export const titleButton = {
  opacity: 1,
  transition: "0.5s",
};

export const titleButtonInOpp = {
  cursor: "pointer",
  opacity: 0.5,
  transition: "0.2s",
  "&:hover": {
    opacity: 1,
    color: "rgb(25, 118, 210)",
    marginLeft: "-4px",
  },
};

export const StyledLabel = styled(InputLabel)(({ theme }) => labelStyle);
export const ErrLabel = styled(InputLabel)(({ theme }) => errorStyle);
export const StyledTextfield = styled(InputBase)(({ theme }) => fieldStyle);
export const GrayCircularProgress = styled(CircularProgress)(
  ({ theme }) => circularProgressStyle
);
export const WhiteBorderlessButton = styled(Button)(
  ({ theme }) => whiteButtonStyle
);

export function LoadedAvatar(props) {
  const [src, setSrc] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);

  const { getImageData } = useImageMapContext();

  React.useEffect(() => {
    if (props.source) {
      getImageData(props.source, (data, error) => {
        setSrc(data);
        setLoaded(true);
      });
    } else {
      setSrc(null);
      setLoaded(true);
    }
  }, [props.source, getImageData]);

  return (
    <Fade in={loaded} timeout={200}>
      <Avatar {...props} src={src}>
        {props.children}
      </Avatar>
    </Fade>
  );
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#595959",
    color: "white",
    padding: "12px",
    minWidth: "100px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: 6,
  width: "100%",
  maxWidth: "750px",
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
  borderBottom: "1px solid gainsboro",
}));

export const CustomPopper = (props) => {
  return (
    <Popper {...props} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={{ enter: 400, exit: 250 }}>
          {props.children}
        </Fade>
      )}
    </Popper>
  );
};

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  height: "35vh",
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
    display: "flex",
    height: "100%",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    margin: "auto",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    height: "100%",
    width: "100%",
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "center",
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

export function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      justifyContent: "space-between",
      gap: "10px",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export function CancelConfirmButtons(props) {
  const {
    cancelFunction,
    confirmFunction,
    cancelText,
    confirmText,
    confirmDisabled,
    confirmProps,
    disabledTooltip,
    ...rest
  } = props;
  return (
    <Stack direction="row" {...rest}>
      {cancelFunction && (
        <Button
          disableElevation
          sx={{ mr: 1, textTransform: "none", flexGrow: 1, width: "140px" }}
          onClick={cancelFunction}
        >
          {cancelText || "Cancel"}
        </Button>
      )}
      <Button
        type="submit"
        {...confirmProps}
        disabled={confirmDisabled}
        variant="contained"
        disableElevation
        sx={{ textTransform: "none", flexGrow: 1, width: "140px" }}
        onClick={confirmFunction}
      >
        {confirmText || "Confirm"}
      </Button>
    </Stack>
  );
}

export const StyledNumericInput = styled(CurrencyInput)(({ theme }) => ({
  ...fieldStyle,
  width: "100%",
  height: "48px",
  outline: "none", // TODO would be good to have a theme for this. currently our textboxes have no focus styling
  // which is an a11y issue
}));

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  "& .MuiDataGrid-window": scrollbarStyle,
  "& .MuiDataGrid-virtualScroller": scrollbarStyle,
  border: 0,
  "& .MuiDataGrid-main": {
    // marginLeft: "30px",
    // marginRight: "30px",
  },
  minHeight: "40px",
  "& .MuiDataGrid-columnHeaderTitle": {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal",
    fontSize: "14px",
    fontWeight: "400",
  },
  "& .MuiDataGrid-columnsContainer": {
    minHeight: "100px",
  },
  "& .MuiDataGrid-columnHeaderWrapper": {
    height: "56px",
    color: theme.opportunityList?.headerColor,
    "& .MuiDataGrid-columnHeader": {
      "& .MuiDataGrid-columnHeaderDraggableContainer": {
        "& .MuiDataGrid-menuIcon": {
          "& .MuiButtonBase-root": {
            color: theme.opportunityList?.headerColor,
          },
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          justifyContent: "left",
          "& .MuiDataGrid-iconButtonContainer": {
            "& .MuiButtonBase-root": {
              color: theme.opportunityList?.headerColor,
            },
          },
        },
      },
    },
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "0px",
    "& .MuiTablePagination-root": {
      color: theme.opportunityList?.textColor,
      "& .MuiSelect-select": {
        color: theme.common?.selectColor,
        border: theme.common?.selectBorder,
      },
      "& .MuiSelect-icon": {
        color: theme.common?.selectIconColor,
      },
      "& .MuiTablePagination-actions": {
        "& .MuiButtonBase-root": {
          "& .MuiSvgIcon-root": {
            color: theme.opportunityList?.iconColor,
          },
        },
      },
    },
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
    outline: "none !important",
  },
  "& .MuiDataGrid-cell": {
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
    maxHeight: "fit-content !important",
    overflow: "hidden",
    whiteSpace: "initial !important",
    display: "flex !important",
    alignItems: "center !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    fontSize: "0.95rem",
    borderBottom: "none",
  },
  "& .MuiDataGrid-row": {
    borderBottom: "1px solid #ebeef1",
    borderRadius: "12px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.01)",
    },
    "&:active": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  "& .MuiGridPanelWrapper-root": {
    opacity: 0.1,
  },
  "& .MuiButton-root.Mui-disabled": {
    color: "rgba(0,0,0,0.5)",
  },
  "& .MuiDataGrid-editInputCell": {
    backgroundColor: "#FCFCFC",
    fontSize: "16px",
    padding: "10px 0px 6px 0px",
    borderRadius: "6px",
    border: "1px solid #DDD",
    margin: "0px 10px",
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
  "& .rule5-datagrid-error": {
    "& div": {
      backgroundColor: "#fef8f8",
      // margin: "5px",
      borderRadius: "5px",
      border: "1px solid #f08080",

      // marginBottom: "10px",
      "&:hover": {
        backgroundColor: getHoverBackgroundColor("#fef8f8", theme.palette.mode),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode
          ),
        },
      },
    },
  },
}));

export const listboxStyle = {
  backgroundColor: "",
  padding: 0,
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    margin: "5px 0px 5px 0px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0,0,0,.15)",
  },
};

export const InvertedIconButton = styled(IconButton)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: 1.5,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:focus": {
    backgroundColor: theme.palette.primary.light,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.contrastText,
  },
}));
export const GrayInvertedIconButton = styled(IconButton)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: 1.5,
  "&:hover": {
    backgroundColor: "#3e3e3e",
  },
  "&:focus": {
    backgroundColor: "#3e3e3e",
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "&.Mui-disabled .MuiSvgIcon-root": {
    color: "#9b9b9b",
  },
}));

export const StyledInfoBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgb(247,252,255)",
  color: "rgb(107,149,185)",
  border: "1px solid rgb(167,201,234)",
  borderRadius: "8px",
  padding: "16px",
}));

export const ErrorTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export const InvertedTable = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
  width: "100%",
  borderRadius: "12px",
  border: "1px solid gray",
  overflow: "hidden",
  backgroundColor: "gray",
  gap: "1px",
  maxWidth: "1000px",
}));

export const InvertedTableRow = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const InvertedTableKey = styled(Box)(({ theme }) => ({
  width: "200px",
  backgroundColor: "#595959",
  color: "white",
  padding: "12px",
  minWidth: "100px",
  textAlign: "left",
  height: "100%",
  flexGrow: 1,
}));

export const InvertedTableValue = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  height: "100%",
  paddingRight: "10px",
  minWidth: "80px",
  backgroundColor: "white",
}));

export const BlackButton = styled(Button)(({ theme }) => ({
  backgroundColor: "black",
  "&:hover": {
    backgroundColor: "#666362",
  },
  "&:focus": {
    backgroundColor: "#666362",
  },
}));
