import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import Toolbar from "../common/Toolbar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { cardTypes } from "../workspaces/opportunity/research/OPXResearch";
import { useImmer } from "use-immer";
import Placeholder from "../workspaces/opportunity/research/cards/Placeholder";
import NewsEditor from "./cards/NewsEditor";
import PeopleEditor from "./cards/PeopleEditor";
import KeyPeriodsEditor from "./cards/KeyPeriodsEditor";
import StockEditor from "./cards/StockEditor";
import SellerCompEditor from "./cards/SellerCompEditor";
import OrgChartEditor, {
  flattenTree,
  unflattenList,
} from "./cards/OrgChartEditor";
import OverviewEditor from "./cards/OverviewEditor";
import PeerGroupEditor from "./cards/PeerGroupEditor";
import CompanyUpdatesEditor from "./cards/CompanyUpdatesEditor";
import SpSiBcEditor from "./cards/SpSiBcEditor";
import SocialMediaEditor from "./cards/SocialMediaEditor";
import TrendsEditor from "./cards/TrendsEditor";
import { rule5properties } from "../properties";
import { FormProvider, useForm } from "react-hook-form";
import { useUser } from "../context/UserContext";
import { approverRoles } from "./DetMainPage";
import RiskFactors10KEditor from "./cards/RiskFactors10KEditor.js";
import IndustryTrendsEditor from "./cards/IndustryTrendsEditor";
import { Prompt } from "react-router";
import TextField from "@mui/material/TextField";
import { cardStatuses } from "./util/DetailsFilterForm";
import { formatDate } from "./ResearchDetail";
import AssigneeSelector from "./util/AssigneeSelector";
import { getUserlabel } from "./common/utils";
import { cloneDeep, isEmpty } from "lodash";
import { convertToRaw, EditorState } from "draft-js";
import TechnologyLandscapeEditor from "./cards/TechnologyLandscapeEditor";
import CompetitionIntelligenceEditor from "./cards/CompetitionIntelligenceEditor";
import ResearchSourcesEditor from "./cards/ResearchSourcesEditor";
import OperationsEditor from "./cards/OperationsEditor";
import ExecutiveSummaryEditor from "./cards/ExecutiveSummaryEditor";
import { useDialog } from "../context/DialogContext";
import DeleteConfirmationPopup from "../det/modal/DeleteConfirmationPopup";
import FlashCardEditor from "./cards/FlashCardEditor";
import AnnualReportsEditor from "./cards/AnnualReportsEditor";
import QuarterlyEarningsEditor from "./cards/QuarterlyEarningsEditor";
import DiscoveryEditor from "./cards/DiscoveryEditor";
import { exists } from "../common/Utils";
import CompanyInsightsEditor from "./cards/CompanyInsightsEditor.js";

const useStyles = makeStyles(() => ({
  findControls: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "25px",
  },
  infoTable: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "15px",
    "& td": {
      padding: "2px 6px",
      border: "1px solid black",
    },
  },
  sectionLabel: {
    color: "#525252",
    fontSize: "x-large",
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "fit-content(200px) 3fr",
    gridGap: "10px",
    padding: "15px",
    marginBottom: "15px",
    alignItems: "start",
  },
  // inputRoot: {
  //   "& .MuiOutlinedInput-notchedOutline": {
  //     borderColor: "#1976d2",
  //   },
  //   "&:hover .MuiOutlinedInput-notchedOutline": {
  //     borderColor: "#1976d2",
  //   },
  //   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //     borderColor: "#1976d2",
  //   },
  // },
}));

export const cardEditorTypes = [
  {
    cardType: "Company Overview",
    Dom: React.memo(OverviewEditor),
    newCardDefaultValues: {
      KeySpecs: [{}],
      Links: [{}],
      Sources: [{}],
    },
  },
  {
    cardType: "People",
    Dom: React.memo(PeopleEditor),
    newCardDefaultValues: {
      // Title dynamically set in CardEditor
      Person: [
        {
          Phone: [{}],
          SocialMedia: [{}],
        },
      ],
      Source: {},
    },
  },
  {
    cardType: "News",
    Dom: React.memo(NewsEditor),
    newCardDefaultValues: {
      // Title dynamically set in CardEditor
      Article: [
        {
          Source: [{}],
          Authors: [],
        },
      ],
    },
  },
  {
    cardType: "Competitor News",
    Dom: React.memo(NewsEditor),
    newCardDefaultValues: {
      Title: "Competitor News",
      Article: [
        {
          Source: [{}],
          Authors: [],
        },
      ],
    },
  },
  {
    cardType: "Shareholder Return",
    Dom: React.memo(StockEditor),
    newCardDefaultValues: {
      Title: "Shareholder Return",
      CompanyName: "",
    },
  },
  {
    cardType: "Seller Competition",
    Dom: React.memo(SellerCompEditor),
    newCardDefaultValues: {
      Organizations: [{}],
    },
  },
  {
    cardType: "Buyer Peer Group",
    Dom: React.memo(PeerGroupEditor),
    newCardDefaultValues: {
      Title: "Peer Group",
      Overview: {},
      Metrics: [{}],
      Measure: [{ orgs: [{}] }],
      Source: {},
    },
  },
  {
    cardType: "Org Chart",
    Dom: React.memo(OrgChartEditor),
    newCardDefaultValues: {
      Title: "Organization Chart",
      People: [
        {
          Phone: [{}],
        },
      ],
    },
  },
  {
    cardType: "Social Media",
    Dom: React.memo(SocialMediaEditor),
    newCardDefaultValues: {
      Title: "Social Media",
      SocialMedia: [
        {
          metrics: {},
        },
      ],
    },
  },
  {
    cardType: "Key Periods",
    Dom: React.memo(KeyPeriodsEditor),
    newCardDefaultValues: {
      Title: "Annual Budget Cycle",
      CurrentQuarterNumber: "",
    },
  },
  {
    cardType: "Trends",
    Dom: React.memo(TrendsEditor),
    newCardDefaultValues: {
      Title: "Financial Trends",
      Trends: [{}],
      KpiTrends: [{}],
      RevenueNetIncome: [{}],
      TableAnalysisTextList: convertToRaw(
        EditorState.createEmpty().getCurrentContent()
      ),
      ChartAnalysisTextList: convertToRaw(
        EditorState.createEmpty().getCurrentContent()
      ),
      D3cPeerGroupAnalysisTextList: convertToRaw(
        EditorState.createEmpty().getCurrentContent()
      ),
      AnnualFinancials: [{}],
    },
  },
  {
    cardType: "Company Updates",
    Dom: React.memo(CompanyUpdatesEditor),
    newCardDefaultValues: {
      Title: "Company Updates",
      News: [{}],
      Events: [{}],
      Source: [{}],
    },
  },
  {
    cardType: "Executive Summary",
    Dom: React.memo(ExecutiveSummaryEditor),
    newCardDefaultValues: {
      Title: "Executive Summary",
      Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
      IsAutomated: true,
    },
  },
  {
    cardType: "Risk Factors",
    Dom: React.memo(RiskFactors10KEditor),
    newCardDefaultValues: {
      Title: "Risk Factors",
      Risk: [
        {
          Excerpt: convertToRaw(EditorState.createEmpty().getCurrentContent()),
          Source: [{}],
        },
      ],
    },
  },
  {
    cardType: "Industry Trends",
    Dom: React.memo(IndustryTrendsEditor),
    newCardDefaultValues: {
      // Title dynamically set in CardEditor
      Trend: [
        {
          Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
          Source: [{}],
        },
      ],
    },
  },
  {
    cardType: "Strategic Priorities",
    Dom: React.memo(IndustryTrendsEditor),
    newCardDefaultValues: {
      // Title dynamically set in CardEditor
      Trend: [
        {
          Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
          Source: [{}],
        },
      ],
    },
  },
  {
    cardType: "Business Challenges",
    Dom: React.memo(IndustryTrendsEditor),
    newCardDefaultValues: {
      // Title dynamically set in CardEditor
      Trend: [
        {
          Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
          Source: [{}],
        },
      ],
    },
  },
  {
    cardType: "Strategic Initiatives",
    Dom: React.memo(IndustryTrendsEditor),
    newCardDefaultValues: {
      // Title dynamically set in CardEditor
      Trend: [
        {
          Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
          Source: [{}],
        },
      ],
    },
  },
  {
    cardType: "Management Quotes",
    Dom: React.memo(IndustryTrendsEditor),
    newCardDefaultValues: {
      Title: "Management Quotes",
      Trend: [
        {
          Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
          Source: [{}],
        },
      ],
    },
  },
  {
    cardType: "Research Sources",
    Dom: React.memo(ResearchSourcesEditor),
    newCardDefaultValues: {
      Title: "Research Sources",
      Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
    },
  },
  {
    cardType: "Technology Landscape",
    Dom: React.memo(TechnologyLandscapeEditor),
    newCardDefaultValues: {
      Title: "Technology & Solutions Landscape",
      Company: [
        {
          Remarks: convertToRaw(EditorState.createEmpty().getCurrentContent()),
        },
      ],
    },
  },
  {
    cardType: "Competition Intelligence",
    Dom: React.memo(CompetitionIntelligenceEditor),
    newCardDefaultValues: {
      Title: "Competition Intelligence",
      Competitors: [
        {
          Overview: convertToRaw(EditorState.createEmpty().getCurrentContent()),
          Items: [
            {
              Source: [{}],
            },
          ],
        },
      ],
    },
  },
  {
    cardType: "Operations",
    Dom: React.memo(OperationsEditor),
    newCardDefaultValues: {
      Title: "Operations",
      Logos: [],
      RteFields: [
        {
          RichText: convertToRaw(EditorState.createEmpty().getCurrentContent()),
        },
      ],
      PieCharts: [
        {
          Revenue: [{}],
        },
      ],
    },
  },
  {
    cardType: "Flash Card",
    Dom: React.memo(FlashCardEditor),
    newCardDefaultValues: {
      Title: "Flash Card",
      talkingPoints: convertToRaw(
        EditorState.createEmpty().getCurrentContent()
      ),
      insights: [
        {
          RichText: convertToRaw(EditorState.createEmpty().getCurrentContent()),
        },
      ],
    },
  },
  {
    cardType: "Quarterly Earnings Calls",
    Dom: React.memo(QuarterlyEarningsEditor),
    newCardDefaultValues: {
      Title: "Quarterly Earnings Summary",
      Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
    },
  },
  {
    cardType: "Annual Reports",
    Dom: React.memo(AnnualReportsEditor),
    newCardDefaultValues: {
      Title: "Annual Reports Summary",
      Content: convertToRaw(EditorState.createEmpty().getCurrentContent()),
    },
  },
  {
    cardType: "Company Insights",
    Dom: React.memo(CompanyInsightsEditor),
    newCardDefaultValues: {
      Title: "Company Insights",
      insights: [],
      job_openings: [],
      funding: {
        valuation: {},
        last_funding_amount: {},
        total_funding_amount: {},
      },
    },
  },
  {
    cardType: "Strategic Snapshot",
    Dom: React.memo(SpSiBcEditor),
    newCardDefaultValues: {
      Title: "Strategic Snapshot",
      "Strategic Priorities": [{}],
      "Strategic Initiatives": [{}],
      "Business Challenges": [{}],
    },
  },

  {
    cardType: "Benchmarks",
    Dom: React.memo(Placeholder),
  },
  {
    cardType: "Discovery",
    Dom: React.memo(DiscoveryEditor),
    newCardDefaultValues: {
      Title: "Discovery",
      discoveryInfo: [],
      currency: "",
    },
  },
];

function deepCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

export default function DetCardEditor(props) {
  const classes = useStyles();
  const hideToolbar = props.hideToolbar;
  const reloadCallback = props.reloadCallback;
  const [form, setForm] = useImmer(props.location.state);
  const [hasPreview, setHasPreview] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxVersion, setMaxVersion] = useState(null);
  const [removeCallback, setRemoveCallback] = useState(null);
  const user = useUser();
  const dialog = useDialog();

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      // GET request to see if there are existing versions of this card.
      const queryString = new URLSearchParams({
        type: form.type,
        scope: form.scope,
        ...(exists(form.companyId)
          ? { companyId: parseInt(form.companyId) }
          : {}),
        ...(exists(form.orgId) ? { orgId: parseInt(form.orgId) } : {}),
        ...(form.functionalArea
          ? { functionalArea: form.functionalArea }
          : { functionalArea: "NA" }),
        //TODO add maxpage size here or ideally something more intelligent. In case many results.
      });
      axios
        .get(rule5properties.detCards + `?` + queryString)
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
        })
        .then((response) => {
          const results = response.data?.results;
          if (results?.length === 0) {
            // Card does not exist yet. No preview or anything to display, unless Opportunity scoped card.
            if (form.scope === "Opportunity") {
              setHasPreview(true);
            }
            return;
          }
          // Get the most recent version.
          const maxVersion = results?.reduce(function (prev, current) {
            return parseInt(prev.version) > parseInt(current.version)
              ? prev
              : current;
          });
          if (!form.version) {
            // User came through NewCard flow if here.
            alert(
              "It looks like the card you're trying to create already exists. Taking you to the latest version."
            );
            setForm(maxVersion);
            // methods.reset({}, { keepValues: true });
          } else if (form.version !== maxVersion.version) {
            setMaxVersion(maxVersion);
          }

          setHasPreview(true);
        });
    } else if (reloadCallback) {
      // Update any background content if the form has changed.
      reloadCallback();
    } else {
      //TODO fix this whole useEffect
      methods.reset(getFormDefaults(form)); // So that default values are there in case "card you're trying to create already exists"
    }
    //TODO warning about firstTime but I don't want it to be here. Maybe need useCallBack or something
  }, [form, setForm, reloadCallback]);

  let versionLabel = form.version || "";
  if (maxVersion) {
    versionLabel += " (latest: " + maxVersion.version + ")";
  }
  //TODO ugly
  let functionalAreaLabel = form.functionalArea || "<None>";
  if (functionalAreaLabel === "NA") {
    functionalAreaLabel = "<None";
  }

  const cardType = cardTypes.find((cardType) => {
    return cardType.cardType === form.type;
  });

  const Preview = cardType.dom;
  const cardEditor = cardEditorTypes.find((cardEditorType) => {
    return cardEditorType.cardType === form.type;
  });

  const updateFromPatch = (responseCard) => {
    responseCard.buyerName = form.buyerName;
    responseCard.sellerName = form.sellerName;
    setForm({ ...responseCard, isDraftSaved: true });
  };

  const updateAssignee = (response) => {
    if (!response.id) {
      // Flow where card does not exist yet in backend. Still allow assignee listbox to be changed.
      setForm((draft) => {
        draft.actionInfo = { assignee: response }; // anything else needed in actionInfo at create time?
      });
    } else {
      // If card exists already.
      updateFromPatch(response);
    }
  };

  const handleSaveClick = (event) => {
    // New creates
    if (!form.id) {
      const params = {
        type: form.type,
        scope: form.scope,
        ...(exists(form.companyId)
          ? { company_id: parseInt(form.companyId) }
          : {}),
        ...(exists(form.orgId) ? { org_id: parseInt(form.orgId) } : {}),
        ...(methods.watch("opportunityId")
          ? { opportunity_id: parseInt(methods.watch("opportunityId")) }
          : {}),
        ...(form.opportunityId
          ? { opportunity_id: parseInt(form.opportunityId) }
          : {}),
        ...(form.functionalArea && form.functionalArea !== "NA"
          ? { functional_area: form.functionalArea }
          : {}),
        card_info: form.cardInfo,
        action_info: { created_by: getUserlabel(user), ...form.actionInfo },
      };
      axios
        .post(rule5properties.detCards, params)
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
        })
        .then((response) => {
          updateFromPatch(response.data);
        });
    } else {
      let id = form.id;
      let status = form.status;
      if (maxVersion) {
        let message =
          "You are not editing the most recent version of this card. The latest version is in " +
          maxVersion.status +
          " status. ";
        if (["Published", "Approved"].includes(maxVersion.status)) {
          message += "Would you like to upversion this card with these edits?";
        } else {
          message +=
            "Would you like to overwrite the latest draft with these edits?";
        }
        // eslint-disable-next-line no-restricted-globals
        const isSave = confirm(message);
        if (isSave === true) {
          id = maxVersion.id;
          status = maxVersion.status;
        } else {
          // Don't make any update if user rejects.
          return;
        }
      }
      // Updates
      const params = {
        id: parseInt(id),
        original_card_id: parseInt(form.originalCardId),
        card_info: form.cardInfo,
        ...(status === cardStatuses.readyForReview
          ? { status: cardStatuses.draft }
          : {}),
      };

      axios
        .patch(rule5properties.detCards, params)
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
        })
        .then((response) => {
          let updatedCard = response.data;
          // If a save was performed then response is the latest version.
          setMaxVersion(null);
          // Company names aren't part of the response, so hold on to them.
          updateFromPatch(updatedCard);
        });
    }
  };

  const handleRequestReview = (event) => {
    updateStatus(cardStatuses.inReview);
  };

  const handleApprove = (event) => {
    updateStatus(cardStatuses.approved);
  };

  function updateStatus(status) {
    if (form.id) {
      let formCopy = cloneDeep(form);
      // TODO surely a better way to instantiate this
      if (!formCopy.actionInfo) {
        formCopy.actionInfo = {};
      }
      if (status === "Approved") {
        formCopy.actionInfo["approved_by"] = getUserlabel(user);
        formCopy.actionInfo["approval_date"] = new Date(
          Date.now()
        ).toISOString();
      }
      const params = {
        id: parseInt(form.id),
        original_card_id: parseInt(form.originalCardId),
        status: status,
        action_info: formCopy.actionInfo,
      };
      axios
        .patch(rule5properties.detCards, params)
        .catch(function (error) {
          alert("ERROR: " + JSON.stringify(error));
        })
        .then((response) => {
          updateFromPatch(response.data);
        });
    }
  }

  const getFormDefaults = (card) => {
    // new card
    if (!card.cardInfo) {
      return cardEditor.newCardDefaultValues;
    }

    // org chart form editor needs data in list format
    if (cardEditor?.cardType === "Org Chart") {
      const list = flattenTree(card.cardInfo.People);
      let formCopy = deepCopy(card);
      formCopy.cardInfo.People = list;

      return formCopy.cardInfo;
    }

    return card.cardInfo;
  };

  const methods = useForm({
    defaultValues: getFormDefaults(form),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    let eventCopy = deepCopy(data); // Needed for some react-hook-form bug
    //TODO would be better if Org Chart data transforms happened in the OrgchartEditor
    if (cardEditor.cardType === "Org Chart") {
      const tree = unflattenList(eventCopy.People);
      eventCopy.People = tree;
    }
    setForm((draft) => {
      draft.cardInfo = eventCopy;
      draft.isDraftSaved = false;
    });
    if (!hasPreview) {
      setHasPreview(true);
    }
  };

  const resetForm = (event) => {
    // Feels like a hack to make a request. Had the info locally at some point..
    const queryString = new URLSearchParams({
      id: form.id,
    });
    axios
      .get(rule5properties.detCards + `?` + queryString)
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
      })
      .then((response) => {
        let card = response.data;
        setForm({
          ...card,
          isDraftSaved: true,
        });
        methods.reset(getFormDefaults(card));
      });
  };

  const openDeleteConfirmation = () => {
    dialog.openModal(
      "Delete Card",
      DeleteConfirmationPopup,
      {
        cardId: form.id,
      },
      "sm"
    );
  };

  // Hack because there's some issue where methods.formState.isDirty is true even when
  // it should be false (dirtyFields is empty).
  // Feel like it's probably some issue with how code is done here but not sure.
  const isDirty = !isEmpty(methods.formState.dirtyFields);

  return (
    <div style={{ marginBottom: "15px" }}>
      <Prompt
        when={
          // methods.formState.isDirty ||
          !form.isDraftSaved
        }
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {!hideToolbar && (
        <Toolbar>
          <div className={classes.title}>Create or Update Card</div>
        </Toolbar>
      )}
      <Box sx={{ padding: "0px 30px" }}>
        <Box
          sx={{
            "& > :not(style)": {
              m: 1,
              width: "225px",
            },
          }}
        >
          <TextField
            name="scope"
            label="Scope"
            value={form.scope || ""}
            size="small"
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          {exists(form.companyId) && (
            <TextField
              name="buyerCompany"
              label="Buyer Company"
              value={form.buyerName || ""}
              size="small"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          {exists(form.orgId) && (
            <TextField
              name="sellerCompany"
              label="Seller Company"
              value={form.sellerName || ""}
              size="small"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          <TextField
            name="functionalArea"
            label="Functional Area"
            value={functionalAreaLabel}
            size="small"
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            name="version"
            label="Version"
            value={versionLabel}
            size="small"
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            name="type"
            label="Type"
            value={form.type || ""}
            size="small"
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            name="status"
            label="Status"
            value={form.status || ""}
            size="small"
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            name="updatedAt"
            label="Updated On"
            InputLabelProps={{ shrink: true }}
            value={form.updatedAt && formatDate(form.updatedAt)}
            size="small"
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <div style={{ paddingTop: "5px", marginLeft: "1px" }}>
            <AssigneeSelector
              task={form}
              taskType={"card"}
              onUpdate={updateAssignee}
              value={form.actionInfo && form.actionInfo.assignee}
              classes={{ inputRoot: classes.inputRoot }}
            ></AssigneeSelector>
          </div>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {hasPreview && (
              <Box sx={{ padding: "15px 0px" }}>
                <Typography className={classes.sectionLabel} sx={{}}>
                  Preview
                </Typography>
                <Preview
                  viewed={true}
                  expanded={isExpanded}
                  previewData={form.cardInfo}
                  removeCallback={removeCallback}
                  opportunity={{ Account: form.buyerName }}
                  toggleExpanded={() => {
                    setIsExpanded((prev) => !prev);
                  }}
                  triggerSubmit={() => methods.handleSubmit(onSubmit)()}
                  // props for editing Opportunity scoped cards in Newton e.g. Discovery
                  sellerOrgId={form.sellerCompany?.id || form.orgId}
                  companyId={form.buyerCompany?.id || form.companyId}
                  functionalArea={form.functionalArea}
                  setForm={setForm}
                ></Preview>
              </Box>
            )}
            <Box sx={{ padding: "0px 0px" }}>
              {(form.version || !firstTime) && (
                <>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ padding: "15px 0px" }}
                  >
                    <Button
                      disabled={!isDirty}
                      variant="contained"
                      type="submit"
                    >
                      Generate Preview
                    </Button>
                    <Button
                      disabled={isDirty || form.isDraftSaved || !form.cardInfo}
                      onClick={handleSaveClick}
                      variant="contained"
                    >
                      Save Draft
                    </Button>
                    {!approverRoles.includes(user.role) && (
                      <Button
                        disabled={
                          // methods.formState.isDirty ||
                          !form.isDraftSaved ||
                          form.status !== "Draft" ||
                          !form.version
                        }
                        onClick={handleRequestReview}
                        variant="contained"
                      >
                        Request Approval
                      </Button>
                    )}
                    {approverRoles.includes(user.role) && (
                      <Button
                        disabled={
                          !form.isDraftSaved ||
                          (form.status !== "Draft" &&
                            form.status !== "In Review")
                        }
                        onClick={handleApprove}
                        variant="contained"
                      >
                        Approve
                      </Button>
                    )}
                    <Button
                      disabled={!form.version}
                      variant="contained"
                      onClick={resetForm}
                    >
                      Reset to last save
                    </Button>
                    <Button
                      disabled={form.version !== "1" && form.status === "Draft"}
                      variant="contained"
                      onClick={openDeleteConfirmation}
                    >
                      Delete
                    </Button>
                  </Stack>
                  <Typography className={classes.sectionLabel} sx={{}}>
                    Editor
                  </Typography>
                  <cardEditor.Dom
                    companyId={form.companyId}
                    functionalArea={form.functionalArea}
                    buyerName={form.buyerName}
                    sellerName={form.sellerName}
                    formStyle={classes.formGrid}
                    setRemoveCallback={setRemoveCallback}
                    setForm={setForm}
                    setHasPreview={setHasPreview}
                    cardType={cardEditor.cardType}
                  ></cardEditor.Dom>
                </>
              )}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </div>
  );
}
