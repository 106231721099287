import React from "react";
import { Box, Stack } from "@mui/material";
import Toolbar from "../../common/Toolbar";
import AccountAgentResults from "./AccountAgentResults";
import Workspace from "../Workspace";
import { JOB_CHANGE_LEADS_COLUMNS } from "./common/monitorColumns";
import PeopleAgentResults from "./PeopleAgentResults";

export default function JobChangeLeads(props) {
  return (
    <Box sx={{ height: "100vh" }}>
      <Toolbar>
        <Stack direction="row" alignItems="center">
          People signals
        </Stack>
      </Toolbar>
      <Workspace>
        <PeopleAgentResults columns={JOB_CHANGE_LEADS_COLUMNS} />
      </Workspace>
    </Box>
  );
}
