import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import makeStyles from "@mui/styles/makeStyles";

const styleMap = {
  HYPERLINK: {
    color: "rgb(25, 118, 210)",
    textDecoration: "underline",
  },
};

const useStyles = makeStyles(() => ({
  editorStyle: {
    "& div": {
      margin: 0,
      fontSize: (props) => (props.fontSize ? props.fontSize : 16),
      fontFamily: "Arial",
    },
    "& ol": {
      paddingLeft: "1em", // Default cuts off two digit li numbers e.g. 10, 11
    },
  },
}));

const RichTextReader = (props) => {
  const classes = useStyles(props);
  const editorState = props.editorState;

  return (
    <Editor
      customStyleMap={styleMap}
      editorClassName={classes.editorStyle}
      editorState={editorState}
      readOnly
      toolbarHidden
      toolbar={{
        link: {
          showOpenOptionOnHover: false,
        },
      }}
    />
  );
};

export default RichTextReader;
