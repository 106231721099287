import React from "react";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import CloseItem from "../../../../common/CloseItem";
import FlagItem from "../../../../modal/FlagItem";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
  cardstyle: {
    textAlign: "center",
    backgroundColor: "white",
    boxShadow: "0 3px 3px -2px rgba(0, 0, 0, 0.1)",
    height: "200px",
  },
  cardTitle: {
    fontSize: 14,
    textAlign: "left",
    color: "#333333",
  },
  cardContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
  },
}));

export default function Placeholder(props) {
  const classes = useStyles();
  const theme = useTheme();
  const CALLSTUB = true;

  const [data, setData] = React.useState({});

  // eslint-disable-next-line
  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);

      if (CALLSTUB) {
        setData({ content: "Place Content Here" });
      } else {
        // Call API
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "https://endpoint.com");
        xhr.setRequestHeader("aaa", "bbb");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            var rawdata = JSON.parse(xhr.responseText);
            if (rawdata) {
              // Validate content
              var data = rawdata; // Process content
              // process data here
            } else {
              // API call failed
            }
          }
        };
        xhr.send();
      }
    }
  }, [firstTime]);

  return (
    <Card className={classes.cardstyle} variant="outlined">
      <CardHeader
        sx={{ padding: theme.cards.header.padding }}
        avatar={<MiscellaneousServicesIcon />}
        action={
          <div style={{ display: "inline-block", whiteSpace: "nowrap" }}>
            <FlagItem
              opsId={props.opportunity.id}
              cardId={props.cardId}
              Title={props.cardType}
            />
            <CloseItem onClick={props.closeCard} />
          </div>
        }
        title={
          <div className={classes.cardTitle}>
            {props.cardType} Under Construction
          </div>
        }
      />
      <CardContent className={classes.cardContent}>
        <div>{data.content}</div>
      </CardContent>
    </Card>
  );
}
