import React from "react";
import axios from "axios";
import { useEffect } from "react";
import Researchcard from "./Researchcard";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import RichTextReader from "../../../../common/RichTextReader";
import { convertToEditorState } from "./SingleRteCard";

export default function SpSiBc(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  const [annualReportsData, setAnnualReportsData] = React.useState(null);

  const [editorState, setEditorState] = React.useState(null);

  useEffect(() => {
    if (previewData) {
      setAnnualReportsData(previewData);
      if (previewData.strategicSnapshot) {
        setEditorState(convertToEditorState(previewData.strategicSnapshot));
      }
    } else {
      // Call API on initial load
      let parameter = "/" + props.cardId;
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          if (
            rawdata.hasOwnProperty("10kDate") ||
            rawdata.hasOwnProperty("earningAnnouncementDate")
          ) {
            // Validate content
            setAnnualReportsData(rawdata);
            setEditorState(convertToEditorState(rawdata.strategicSnapshot));
          } else {
            // API call failed
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        });
    }
  }, [previewData]);

  if (!annualReportsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }
  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={annualReportsData.Title}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <RichTextReader editorState={editorState} />
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}
