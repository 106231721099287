import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import RichTextEditor from "../util/RichTextEditor";
import { formatUrl as formatStorageUrl } from "../common/utils";
import { TitleInput } from "../util/CardEditorButtons";
import { Button, CircularProgress } from "@mui/material";
import {
  ContentBlock,
  convertToRaw,
  ContentState,
  Modifier,
  SelectionState,
} from "draft-js";
import {} from "draft-js";
import axios from "axios";
import { rule5properties } from "../../properties";

export default function ResearchSourcesEditor(props) {
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [generatedValue, setGeneratedValue] = React.useState(null);
  const [sourcesLoading, setSourcesLoading] = React.useState(false);

  function generateSources() {
    setSourcesLoading(true);
    let urlParams = new URLSearchParams();
    urlParams.append("cardType", "Research Sources");
    urlParams.append("companyId", props.companyId);
    urlParams.append("functionalArea", props.functionalArea);
    axios
      .get(rule5properties.detCards + "/card-data?" + urlParams.toString())
      .then((res) => {
        setTimeout(() => {
          if (res.status === 200) {
            const data = res.data;
            if (data?.length > 0) {
              // soureMap has link URLs as keys, and contentBlock as values.
              // Source name in the block array will be from the first found
              // instance of a link, in case two cards have the same source URL
              // with different display title/name.
              let sourceMap = new Map();
              // Create the content blocks.
              data.forEach((cards, cardIndex) => {
                cards.Source?.forEach((source, sourceIndex) => {
                  // Only add if this source has not been added.
                  if (!sourceMap.has(source.URL)) {
                    const contentBlock = new ContentBlock({
                      key: "source" + cardIndex + "_" + sourceIndex,
                      type: "unordered-list-item",
                      text: source.Name,
                      depth: 0,
                    });
                    sourceMap.set(source.URL, contentBlock);
                  }
                });
              });

              // Create the content state.
              let contentState = ContentState.createFromBlockArray(
                Array.from(sourceMap.values())
              );

              // Create the link entities and apply them.
              sourceMap.forEach((contentBlock, url) => {
                let contentSelection = SelectionState.createEmpty(
                  contentBlock.getKey()
                );
                let selectedState = new SelectionState({
                  anchorKey: contentSelection.getAnchorKey(),
                  anchorOffset: contentSelection.getAnchorOffset(),
                  focusKey: contentSelection.getFocusKey(),
                  focusOffset: contentBlock.getLength(),
                });
                contentState = contentState.createEntity("LINK", "MUTABLE", {
                  url: url,
                  targetOption: "_blank",
                });
                const entityKey = contentState.getLastCreatedEntityKey();
                // Apply link entity to content state.
                contentState = Modifier.applyEntity(
                  contentState,
                  selectedState,
                  entityKey
                );
                contentState = Modifier.applyInlineStyle(
                  contentState,
                  selectedState,
                  "HYPERLINK"
                );
                contentState = Modifier.applyInlineStyle(
                  contentState,
                  selectedState,
                  "color: 'red'"
                );
              });
              let rawContentState = convertToRaw(contentState);

              setValue("Content", rawContentState, { shouldDirty: true });
              setGeneratedValue(rawContentState);
            }
          } else {
            console.log("Error generating sources, status " + res.status);
          }
          setSourcesLoading(false);
        }, 500);
      })
      .catch((err) => {
        setTimeout(() => {
          setSourcesLoading(false);
          console.log("Error generating sources: " + err);
        }, 500);
      });
  }

  return (
    <div>
      <Button
        disabled={sourcesLoading}
        disableElevation
        disableRipple
        variant="outlined"
        sx={{
          mt: 1,
          mb: 2,
          textTransform: "none",
          height: "36px",
          width: "160px",
        }}
        onClick={() => {
          generateSources();
        }}
      >
        {sourcesLoading ? (
          <CircularProgress size={20} sx={{ color: "rgba(140,140,140,1)" }} />
        ) : (
          "Generate Sources"
        )}
      </Button>
      <TitleInput register={register} />
      <br />
      Image Contents
      <br />
      <Controller
        control={control}
        defaultValue=""
        name={"Image"}
        render={({ field }) => {
          return (
            <input
              style={{ width: "100%" }}
              {...field}
              onChange={(e) => field.onChange(formatStorageUrl(e.target.value))}
            />
          );
        }}
      />
      <br />
      <br />
      {!getValues("Image") && (
        <>
          -OR-
          <br />
          <hr></hr>
          <h5>Content:</h5>
          <div>
            <Controller
              name="Content"
              control={control}
              rules={{
                required: { value: true, message: "RTE text is required" },
              }}
              render={({ field }) => {
                return (
                  <RichTextEditor
                    generatedValue={generatedValue}
                    clearGeneratedValue={() => {
                      setGeneratedValue(null);
                    }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            />
            {errors?.Content?.type === "required" && (
              <p style={{ color: "red" }}>{errors.Content.message}</p>
            )}
          </div>
          <br />
          <br />
        </>
      )}
    </div>
  );
}
