import { Button, Stack } from "@mui/material";
import axios from "axios";
import { cloneDeep } from "lodash";
import React from "react";
import { useDialog } from "../../context/DialogContext";
import { useUser } from "../../context/UserContext";
import { rule5properties } from "../../properties";
import { getUserlabel } from "../common/utils";
import { approverRoles } from "../DetMainPage";
import ResearchPopup from "../modal/ResearchPopup";

export default function ResearchButtons(props) {
  const research = props.research;
  const onUpdate = props.onUpdate;
  const allButtons = props.allButtons;
  const openDialog = useDialog();
  const user = useUser();

  const handlePublish = (event) => {
    updateStatus("Published");
  };

  const handleApprove = (event) => {
    updateStatus("Approved");
  };

  const handlePreview = (event) => {
    const title =
      research.buyerName +
      (research.functionalArea ? " / " + research.functionalArea : "");
    openDialog.openModal(title, ResearchPopup, research);
  };

  function updateStatus(status) {
    let researchCopy = cloneDeep(research);
    if (!researchCopy.actionInfo) {
      researchCopy.actionInfo = {};
    }

    if (status === "Approved") {
      researchCopy.actionInfo["approved_by"] = getUserlabel(user);
      researchCopy.actionInfo["approval_date"] = new Date(
        Date.now()
      ).toISOString();
    } else if (status === "Published") {
      //TODO this is lazy
      researchCopy.actionInfo["published_by"] = getUserlabel(user);
      researchCopy.actionInfo["published_date"] = new Date(
        Date.now()
      ).toISOString();
    }

    const params = {
      id: research.id,
      action_info: researchCopy.actionInfo,
      status: status,
    };
    axios
      .patch(rule5properties.detResearch, params)
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
        console.log(JSON.stringify(error));
      })
      .then((response) => {
        onUpdate(response.data);
      });
  }

  return (
    <div style={{}}>
      <Stack direction="row" spacing={2} sx={{}}>
        {allButtons && (
          <Button
            disabled={!research.cardInfo.info}
            onClick={handlePreview}
            variant="contained"
            sx={{ height: "38px" }}
          >
            Preview
          </Button>
        )}
        {approverRoles.includes(user.role) && (
          <>
            <Button
              disabled={
                !props.isApprovable ||
                ["Published", "Approved"].includes(research.status)
              }
              onClick={handleApprove}
              variant="contained"
              sx={{ height: "38px" }}
            >
              Approve
            </Button>
            <Button
              disabled={!props.isApprovable || research.status === "Published"}
              onClick={handlePublish}
              variant="contained"
              sx={{ height: "38px" }}
            >
              Publish
            </Button>
          </>
        )}
      </Stack>
    </div>
  );
}
