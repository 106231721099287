import React from "react";
import { Stack, Typography, InputBase, Button } from "@mui/material";
import { useDialog } from "../context/DialogContext";
import { onEnterPress } from "../common/Utils";

export default function GenerateEmail(props) {
  const dialog = useDialog();
  const [emailPrompt, setEmailPrompt] = React.useState("");

  function generateEmail() {
    const params = {
      emailPrompt: emailPrompt,
      promptCompletionIds: props.selectedCompletions,
      action: "generateEmail",
    };
    props.handleNewPrompt({
      requestFields: params,
      messagePrompt: [params],
      completion: { 0: [""] },
      activePromptIndex: 0,
      activeCompletionIndex: 0,
    });
    props.clearSelectedCompletions();
    dialog.closeModal();
  }

  return (
    <Stack direction="row">
      <Stack alignItems="center" sx={{ width: "100%" }}>
        <Typography sx={{ width: "100%" }}>
          {`You've selected ${props.selectedCompletions.length} response${
            props.selectedCompletions.length > 1 ? "s" : ""
          } to generate an email. Enter a prompt below to continue.`}
        </Typography>
        <InputBase
          autoFocus
          multiline
          onKeyDown={onEnterPress(generateEmail)}
          minRows={8}
          maxRows={16}
          fullWidth
          value={emailPrompt}
          onChange={(e) => {
            setEmailPrompt(e.target.value);
          }}
          sx={{
            backgroundColor: "rgb(244,244,244 )",
            mt: 2,
            p: 1,
            borderRadius: "8px",
          }}
        />
        <Stack
          alignSelf="flex-end"
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            sx={{
              textTransform: "none",
              flexGrow: 1,
              mr: 1,
              height: "42px",
            }}
            variant="contained"
            disableRipple
            disableElevation
            onClick={generateEmail}
            disabled={emailPrompt.trim().length === 0}
          >
            {`Generate`}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
