import * as React from "react";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import { ClickAwayListener } from "@mui/base";
import {
  PopperComponent,
  StyledPopper,
  StyledTextfield,
} from "../../common/StyledComponents";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import LabelChip from "./LabelChip";
import {
  highlightSubstring,
  mergePromptSearchResponses,
} from "../../common/Utils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import CreatePromptBook from "../../modal/CreatePromptBook";
import { useDialog } from "../../context/DialogContext";
import { isArray, isEqual } from "lodash";
import { getPromptBookSearchRequests } from "../../common/RequestUtils";
import useSnack from "../../context/Snack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const GroupHeader = styled(Button)(({ theme }) => ({
  position: "sticky",
  top: "0px",
  color: "black",
  justifyContent: "left",
  textAlign: "left",
  padding: "8px 10px",
  backgroundColor: "white",
  borderRadius: "0px",
  flexGrow: 1,
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:focus": {
    backgroundColor: theme.palette.action.focus,
  },
}));

const GroupItems = styled("ul")({
  padding: "10px 10px 10px 40px",
});

export default function PromptBooksAutocomplete(props) {
  const {
    anchorEl,
    open,
    handleClose,
    setValue,
    setSelectedContents,
    setSelectedCardTypes,
  } = props;
  const [options, setOptions] = React.useState([]);
  const [groupNames, setGroupNames] = React.useState([]);
  const [expandedArray, setExpandedArray] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const dialog = useDialog();
  const snackbar = useSnack();

  const loadPromptBooks = (searchValue) => {
    setLoading(true);
    const requests = getPromptBookSearchRequests(searchValue);
    Promise.all(requests)
      .then((body) => {
        setLoading(false);
        if (isArray(body?.[0]?.data)) {
          let tempRows = body[0].data;
          if (isArray(body?.[1]?.data)) {
            mergePromptSearchResponses(tempRows, body[1].data);
          }
          setGroupNames(tempRows.map((group) => group.name)); // Alternatively could skip maintaining this state and get it from unflattening the options state.
          if (searchValue) {
            // Expand all the groups while user is entering a searchValue.
            setExpandedArray(tempRows.map((group) => group.name));
          }
          setOptions(
            tempRows.flatMap((option) => {
              return option?.prompts?.map((prompt) => {
                return {
                  ...prompt,
                  promptBookName: option.name,
                  prompt: prompt.prompt,
                  id: prompt.promptId,
                  description: option?.promptBookInfo?.description,
                };
              });
            })
          );
        } else {
          snackbar.createSnack("Error loading prompt books.");
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          snackbar.createSnack("Error loading prompt books.");
        }
      });
  };

  React.useEffect(() => {
    if (open) {
      loadPromptBooks(inputValue);
    }
  }, [inputValue, open]);

  const allRowsExpanded = isEqual(groupNames, expandedArray);
  const id = open ? `prompt-books-autocomplete` : undefined;
  return (
    <StyledPopper anchorEl={anchorEl} open={open} id={id} placement="top-start">
      <ClickAwayListener onClickAway={handleClose}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "750px",
            width: "100%",
          }}
        >
          <Autocomplete
            id="prompt-books-autocomplete"
            open
            fullWidth
            loading={loading}
            options={options.sort(
              (a, b) => -b?.promptBookName.localeCompare(a?.promptBookName)
            )}
            PopperComponent={PopperComponent}
            onChange={(event, newValue) => {
              setValue("promptInput", newValue.prompt);
              setInputValue("");
              setSelectedCardTypes({
                types:
                  newValue?.cardTypes &&
                  !(
                    newValue?.cardTypesContext === "Include All" ||
                    newValue?.cardTypesContext === "Exclude All"
                  )
                    ? newValue?.cardTypes?.map((cardType) => cardType.type)
                    : [],
                excludeAll: newValue?.cardTypesContext === "Exclude All",
              });
              setSelectedContents(newValue?.contents);
              handleClose();
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onClose={(event, reason) => {
              if (reason === "escape") {
                handleClose();
              }
            }}
            clearOnBlur={true}
            disableClearable
            groupBy={(option) => option.promptBookName}
            getOptionLabel={(option) => option.prompt}
            noOptionsText="No prompts found."
            renderGroup={React.useCallback(
              (params) => {
                function addOrRemoveExpandedGroup(groupName) {
                  let newArray;
                  if (!expandedArray.includes(groupName)) {
                    newArray = expandedArray.concat(groupName);
                  } else {
                    newArray = expandedArray.filter(
                      (item) => item !== groupName
                    );
                  }
                  if (!isEqual(newArray, expandedArray)) {
                    setExpandedArray(newArray);
                  }
                }

                const isExpanded = expandedArray.includes(params.group);
                const description = options.find(
                  (option) => option.promptBookName === params.group
                )?.description;
                const numPrompts = params.children?.length;

                return (
                  <li key={params.key}>
                    <div style={{ display: "flex" }}>
                      <GroupHeader
                        onClick={() => {
                          addOrRemoveExpandedGroup(params.group);
                        }}
                        startIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: "rgba(0, 0, 0, 0.54)",
                              margin: "10px 5px 10px 10px",
                              "& svg": { fontSize: "20px" },
                              transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                              transition: (theme) =>
                                theme.transitions.create("transform", {
                                  duration: theme.transitions.duration.shortest,
                                }),
                            }}
                            size="small"
                          />
                        }
                      >
                        <div>
                          <Typography
                            sx={{ fontSize: 14, opacity: 0.9 }}
                            dangerouslySetInnerHTML={{
                              __html: highlightSubstring(
                                params.group,
                                inputValue
                              ),
                            }}
                          ></Typography>
                          <Typography
                            sx={{ fontSize: 12, opacity: 0.7 }}
                            dangerouslySetInnerHTML={{
                              __html: highlightSubstring(
                                description,
                                inputValue
                              ),
                            }}
                          ></Typography>
                          <Typography variant="promptCount" component="p">
                            {`${numPrompts} ${
                              numPrompts !== 1 ? "prompts" : "prompt"
                            }`}
                          </Typography>
                        </div>
                      </GroupHeader>
                    </div>
                    {isExpanded && <GroupItems>{params.children}</GroupItems>}
                  </li>
                );
              },
              [expandedArray, setExpandedArray, inputValue, options]
            )}
            renderOption={React.useCallback((props, option, { inputValue }) => {
              if (!option.prompt) {
                return null;
              }
              return (
                <Box
                  component="li"
                  sx={{
                    [`&.${autocompleteClasses.option}`]: {
                      borderRadius: "12px",
                      padding: "0px 8px !important",
                      "&:hover .promptContainer": {
                        backgroundColor: (theme) => "rgb(230,241,252)",
                      },
                    },
                    [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
                      {
                        backgroundColor: "inherit !important",
                      },
                  }}
                  {...props}
                >
                  <div
                    className="promptContainer"
                    style={{
                      display: "flex",
                      gap: "5px",
                      flexWrap: "wrap",
                      width: "fit-content",
                      padding: "8px",
                      borderRadius: "12px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      padding="8px"
                      variant="promptBookPrompt"
                      fontSize="inherit"
                      dangerouslySetInnerHTML={{
                        __html: highlightSubstring(option.prompt, inputValue),
                      }}
                    ></Typography>
                    {option?.labels?.map((label) => {
                      const regexPattern = new RegExp(inputValue, "gi");
                      const isHighlighted =
                        inputValue && regexPattern.test(label.name);
                      return (
                        <LabelChip
                          key={label.labelId}
                          isHighlighted={isHighlighted}
                          label={label}
                          childComponent={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: highlightSubstring(
                                  label.name,
                                  inputValue
                                ),
                              }}
                            ></div>
                          }
                        ></LabelChip>
                      );
                    })}
                  </div>
                </Box>
              );
            }, [])}
            filterOptions={(filteredOptions) => {
              return filteredOptions; // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
            }}
            renderInput={(params) => {
              const { InputLabelProps, ...rest } = params;
              const InputProps = {
                ...params.InputProps,
              };
              return (
                <div>
                  <StyledTextfield
                    autoFocus
                    placeholder={"Search prompt books…"}
                    {...rest}
                    {...InputProps}
                    {...params}
                    endAdornment={
                      <>
                        {params?.inputProps?.value && (
                          <Tooltip title="Clear search">
                            <IconButton
                              size="small"
                              sx={{}}
                              onClick={() => {
                                setInputValue("");
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Manage prompt books">
                          <IconButton
                            size="small"
                            sx={{}}
                            onClick={() => {}}
                            LinkComponent={"a"}
                            href={`/main/promptbooks`}
                            target="_blank"
                          >
                            <OpenInNewIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 5px",
                      borderBottom: "1px solid gainsboro",
                    }}
                  >
                    <Tooltip
                      title={allRowsExpanded ? "Collapse all" : "Expand all"}
                    >
                      <IconButton
                        sx={{}}
                        onClick={() => {
                          if (!allRowsExpanded) {
                            setExpandedArray(groupNames);
                          } else {
                            setExpandedArray([]);
                          }
                        }}
                      >
                        {allRowsExpanded ? (
                          <UnfoldLessIcon />
                        ) : (
                          <UnfoldMoreIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Create new prompt book">
                      <IconButton
                        sx={{}}
                        onClick={() => {
                          dialog.openModal(
                            "Create prompt book",
                            CreatePromptBook,
                            {},
                            "sm"
                          );
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </ClickAwayListener>
    </StyledPopper>
  );
}
