import React, { useContext } from "react";

const SearchFormContext = React.createContext({
  form: {},
  handleFormChange: () => {},
});

export function useSearchForm() {
  return useContext(SearchFormContext);
}

export default function SearchFormProvider({ children }) {
  const [searchForm, setSearchForm] = React.useState();
  const searchFormValues = React.useMemo(
    () => ({ searchForm, setSearchForm }),
    [searchForm]
  );

  return (
    <SearchFormContext.Provider value={searchFormValues}>
      {children}
    </SearchFormContext.Provider>
  );
}
