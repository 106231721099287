import React from "react";
import {
  Collapse,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  StyledTextfield,
  StyledLabel,
  ErrLabel,
} from "../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Feedback(props) {
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState("");

  const dialog = useDialog();

  const submitForm = (event) => {
    setLoading(true);
    event.preventDefault();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 7000,
    };
    const param = {
      feedbackDetails: {
        pageName: props.url,
        comment: comment,
      },
    };
    axios
      .post(rule5properties.feedback, param, options)
      .then((res) => {
        if (res.status === 200) {
          dialog.setModalTitle(
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon
                sx={{ display: "inline", mr: 1 }}
                color="primary"
              />
              <Typography
                sx={{ display: "inline", mt: "2px", fontWeight: 500 }}
              >
                Thank you for your feedback.
              </Typography>
            </Box>
          );
          setSuccess(true);
        } else {
          if (res.data.code === "VALIDATION_ERROR") {
            setError(
              "Invalid input. Please revise your feedback and try again."
            );
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {
        !success ? (
          <Box sx={{}}>
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={submitForm}
            >
              <StyledTextfield
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                  setError("");
                }}
                sx={{
                  display: "block",
                  fontSize: "16px",
                  minHeight: "160px",
                  maxHeight: "480px",
                  overflowY: "auto",
                }}
                multiline
                placeholder="Share your thoughts..."
              />
              <Collapse in={error !== ""}>
                <ErrLabel>{error}</ErrLabel>
              </Collapse>

              <Box sx={{width: '100%', display: "flex", flexDirection: "row", mt: 2, alignItems: 'flex-end' }}>
                <Button
                  type="submit"
                  disabled={loading || comment.trim() === ""}
                  sx={{ width: "140px", marginLeft: 'auto' }}
                  variant="outlined"
                >
                  <Typography
                    variant="button"
                    sx={{ textTransform: "none", mt: "3px" }}
                  >
                    Submit
                  </Typography>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      sx={{ ml: 2 }}
                    />
                  ) : null}
                </Button>
              </Box>
            </form>
          </Box>
        ) : null
        // <Box sx={{ display: 'flex', alignItems: 'center' }}>
        //   <CheckCircleIcon sx={{ display: 'inline', mr: 1 }} color="primary" />
        //   <Typography sx={{ display: 'inline', mt: '2px' }}>Thank you for your feedback.</Typography>
        // </Box>
      }
    </React.Fragment>
  );
}
