import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import Toolbar from "../common/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { useDialog } from "../context/DialogContext";
import CustomSnackbar from "../common/CustomSnackbar";
import { rule5properties } from "../properties";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCardPopup from "./modal/EditCardPopup";
import ResearchAddCard from "./util/ResearchAddCard";
import ResearchButtons from "./util/ResearchButtons";
import { Button, Checkbox, FormControlLabel, useTheme } from "@mui/material";
import { addCard, removeCard } from "./api/ResearchApi";
import { useHistory } from "react-router-dom";
import AssigneeSelector from "./util/AssigneeSelector";
import { dataGridStyles } from "./common/utils";

LicenseInfo.setLicenseKey(rule5properties.MUIXProLicenseKey);

const defaultHeader = {};

const useStyles = makeStyles(() => ({
  title: {},
  actionGroup: {
    marginLeft: "2%",
    marginTop: "1%",
    marginBottom: "1%",
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
  },
  spinnerContainer: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    zIndex: 1001,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.7)",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  spinner: {
    marginTop: "-50px",
    marginLeft: "-50px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const hidden = value !== index;

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        display: "flex",
        marginBottom: hidden ? 0 : "10px",
        height: hidden ? 0 : "100%",
      }}
      {...other}
    >
      {value === index && <div style={{ flexGrow: 1 }}>{children}</div>}
    </div>
  );
}

export function formatDataGridColumnDate(params) {
  return formatDate(params.value);
}

export function formatDate(date) {
  if (!date) {
    return;
  }
  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(new Date(date));
}

function ResearchDetailHeader(props) {
  const { header } = props;

  return (
    <div>
      <Box
        sx={{
          "& > :not(style)": {
            m: 1,
            width: "225px",
            marginLeft: "2%",
            marginRight: "2%",
          },
        }}
      >
        <TextField
          name="scope"
          label="Scope"
          value={header.scope}
          size="small"
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          name="functionalArea"
          label="Functional Area"
          value={header.functionalArea}
          size="small"
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          name="buyerName"
          label="Buyer Company"
          value={header.buyerName}
          size="small"
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
        />
        {header.scope === "Seller Company" && (
          <TextField
            name="sellerName"
            label="Seller Company"
            value={header.sellerName}
            size="small"
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
        )}
        <TextField
          name="version"
          label="Version"
          value={header.version}
          size="small"
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          name="status"
          label="Status"
          value={header.status}
          size="small"
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          name="updatedAt"
          label="Updated On"
          value={formatDate(header.updatedAt)}
          size="small"
          variant="standard"
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    </div>
  );
}

function ResearchDetailCardsTable(props) {
  const classes = dataGridStyles();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = React.useState(20);
  const dialog = useDialog();
  const {
    inputRows,
    reloadCallback,
    isAdded,
    research,
    filterModel,
    onFilterModelChange,
  } = props;

  useEffect(() => {
    if (inputRows != null) {
      setRows([...inputRows]);
    }
  }, [inputRows]);

  const handleEditClick = (id) => (event) => {
    const card = rows.find((row) => {
      return row.id === id;
    });
    dialog.openModal("Edit card", EditCardPopup, { card, reloadCallback });
  };

  const handleDeleteClick = (id) => (event) => {
    removeCard(id, research, reloadCallback);
  };

  const handleAddClick = (id) => (event) => {
    const card = rows.find((row) => {
      return row.id === id;
    });
    addCard(card, research, reloadCallback);
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id }) => {
        const disabled = ["Published", "Approved"].includes(research.status);
        const RowAction = isAdded ? (
          <GridActionsCellItem
            disabled={disabled}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="primary"
          />
        ) : (
          <GridActionsCellItem
            disabled={disabled}
            icon={<AddIcon />}
            label="Add"
            onClick={handleAddClick(id)}
            color="primary"
          />
        );
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Save"
            onClick={handleEditClick(id)}
            color="primary"
          />,
          RowAction,
        ];
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.type}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.type}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.status}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.status}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "version",
      headerName: "Version",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.version}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.version}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "assignee",
      headerName: "Assignee",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.assignee || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.assignee}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.approved_by || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.approved_by}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "approval_date",
      headerName: "Approved On",
      width: 200,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo &&
          cellValues.row.actionInfo.approval_date && (
            <span className={classes.tableCellTrucate}>
              {formatDate(cellValues.row.actionInfo.approval_date)}
            </span>
          )
        );
      },
    },
    {
      field: "published_by",
      headerName: "Published By",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.published_by || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.published_by}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "published_date",
      headerName: "Published On",
      width: 200,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo &&
          cellValues.row.actionInfo.published_date && (
            <span className={classes.tableCellTrucate}>
              {formatDate(cellValues.row.actionInfo.published_date)}
            </span>
          )
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated On",
      width: 200,
      valueGetter: formatDataGridColumnDate,
    },
  ];

  return (
    <div
      style={{
        height: "calc(100% - 150px)",
        margin: "2%",
      }}
    >
      <DataGridPro
        className={classes.root}
        components={{
          Toolbar: GridToolbar,
        }}
        rows={rows}
        columns={columns}
        pagination
        disableColumnSelector={true}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        filterModel={filterModel}
        onFilterModelChange={(model) => onFilterModelChange(model)}
      />
    </div>
  );
}

export default function ResearchDetail() {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [rows, setRows] = useState([]);
  const [availableRows, setAvailableRows] = useState([]);
  const [header, setHeader] = useState(defaultHeader);
  const [isApprovable, setIsApprovable] = useState(false);
  const [research, setResearch] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);
  const [maxVersionChecked, setMaxVersionChecked] = React.useState(true);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const history = useHistory();
  const openDialog = useDialog();
  const theme = useTheme();

  let params = useParams();

  const getResearch = () => {
    setIsLoading(true);
    const id = params.researchId;
    axios
      .get(rule5properties.detResearch + `?id=` + id)
      .then((response) => {
        if (response.status === 403) {
          setIsLoading(false);
          setSnackbarMessage("You don't have permission.");
          setOpenSnackbar(true);
          return;
        }
        if (response.data) {
          setHeader({
            buyerName: response.data.buyerName || "",
            functionalArea: response.data.functionalArea || "",
            sellerName: response.data.sellerName || "",
            scope: response.data.scope || "",
            version: response.data.version || "",
            status: response.data.status || "",
            updatedAt: response.data.updatedAt || "",
          });
          setResearch(response.data);
          // TODO this is all pretty awful. Maybe use promise.all. Not even bothering to chain available cards since it's
          // defaulted as a hidden tab, so it won't render before ready
          getAvailableCards(response.data);

          if (response.data.cardInfo && response.data.cardInfo.info) {
            getCards(response.data);
          } else {
            getLatestVersion(response.data);
          }
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          setSnackbarMessage("There was a problem with get research detail.");
          setOpenSnackbar(true);
        }
      });
  };

  //TODO this isn't right?
  useEffect(() => {
    if (params.researchId) {
      getResearch();
    }
  }, [params.researchId, maxVersionChecked]);

  const getCards = (research) => {
    const cards = research.cardInfo.info;
    let requests = cards.map((card) => {
      //create a promise for each API call
      return axios.get(rule5properties.detCards + `?id=${card.cardId}`);
    });
    Promise.all(requests)
      .then((body) => {
        //this gets called when all the promises have resolved/rejected.
        // setPreviewData(body);
        let rows = [];
        body.forEach((element) => {
          if (!element?.data?.id) {
            return;
          }
          rows.push(element.data);
        });
        setRows(rows);
        const something = body.some(
          (responseBody) =>
            !["Published", "Approved"].includes(responseBody.data.status)
        );
        setIsApprovable(!something);
        getLatestVersion(research);
      })
      .catch((err) => console.log(err));
  };

  const getAvailableCards = (research) => {
    const queryString = new URLSearchParams({
      scope: research.scope,
      ...(research.companyId
        ? { companyId: parseInt(research.companyId) }
        : {}),
      ...(research.orgId ? { orgId: parseInt(research.orgId) } : {}),
      ...(research.functionalArea
        ? { functionalArea: research.functionalArea }
        : { functionalArea: "NA" }),
      maxVersion: maxVersionChecked,
    });
    axios
      .get(rule5properties.detCards + `?` + queryString)
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
      })
      .then((response) => {
        const cards = response.data.results;
        if (!cards) {
          return;
        }
        const result = cards.filter(
          (card) =>
            !research.cardInfo.info ||
            !research.cardInfo.info
              .map((value) => value.cardId)
              .includes(parseInt(card.id))
        );
        setAvailableRows(result);
      });
  };

  const getLatestVersion = (research) => {
    const queryString = new URLSearchParams({
      ...(research.scope ? { scope: research.scope } : {}),
      ...(research.companyId
        ? { companyId: parseInt(research.companyId) }
        : {}),
      ...(research.orgId ? { orgId: parseInt(research.orgId) } : {}),
      ...(research.functionalArea
        ? { functionalArea: research.functionalArea }
        : { functionalArea: "NA" }),
      sortColumn: "version",
      sortOrder: "Desc",
      pageSize: 2, // could be 1, or new api has been talked about
    });
    axios
      .get(rule5properties.detResearch + `?` + queryString)
      .then((response) => {
        if (response.status === 403) {
          setSnackbarMessage("You don't have permission.");
          setOpenSnackbar(true);
          return;
        }
        if (response.data) {
          setLatestVersion(response.data.results[0]);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          setSnackbarMessage(
            "There was a problem with getting latest research version."
          );
          setOpenSnackbar(true);
        }
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const handleCheck = (event) => {
    setMaxVersionChecked(event.target.checked);
  };

  if (isLoading) {
    return (
      <div style={{ height: "80%", width: "100%" }}>
        <Toolbar>
          <div className={classes.title}>Research Detail</div>
        </Toolbar>
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinner} />
        </div>
      </div>
    );
  }
  const isLatestVersion = latestVersion.id === research.id;
  const versionButtonLabel = isLatestVersion
    ? "Up Version Research"
    : "Latest Version";

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        paddingBottom: `${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>
        <div className={classes.title}>Research Details</div>
      </Toolbar>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          pt: 1,
          borderRadius: "8px",
          height: "100%",
          flexDirection: "column",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        <div className={classes.actionGroup}>
          <div>
            <Button
              disabled={["Published", "Approved"].includes(research.status)}
              style={{ marginRight: "15px", textTransform: "none" }}
              onClick={(event) =>
                openDialog.openModal("Add card to research", ResearchAddCard, {
                  research,
                  onUpdate: getResearch,
                })
              }
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Add card
            </Button>
            {(latestVersion.id !== research.id ||
              ["Published", "Approved"].includes(research.status)) && (
              <Button
                style={{ textTransform: "none" }}
                disabled={false}
                onClick={(event) => {
                  // TODO Probably two separate buttons. Anything not this really
                  if (!latestVersion) {
                    return;
                  }
                  if (latestVersion.id === research.id) {
                    const preserveAssignee =
                      research.actionInfo && research.actionInfo.assignee;
                    axios
                      .post(rule5properties.detResearchUpversion, {
                        id: latestVersion.id,
                        action_info: { assignee: preserveAssignee },
                      })
                      .catch(function (error) {
                        alert("ERROR: " + JSON.stringify(error));
                      })
                      .then((response) => {
                        if (response.data.id) {
                          return history.push({
                            pathname: "/det/manageresearch/" + response.data.id,
                          });
                        }
                      });
                    return; //new axios create
                  }

                  return history.push({
                    pathname: "/det/manageresearch/" + latestVersion.id,
                  });
                }}
                variant="outlined"
                startIcon={isLatestVersion && <AddIcon />}
              >
                {versionButtonLabel}
              </Button>
            )}
          </div>
          <IconButton
            color="primary"
            aria-label="refresh"
            component="span"
            onClick={() => {
              getResearch();
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <ResearchDetailHeader header={header} />
        <div style={{ paddingTop: "10px", marginLeft: "1px", display: "flex" }}>
          <AssigneeSelector
            task={research}
            taskType={"research"}
            onUpdate={getResearch}
            value={research.actionInfo && research.actionInfo.assignee}
          ></AssigneeSelector>
        </div>

        {/* {TODO: researchButtons calls preview which calls researchButtons... } */}
        <div style={{ padding: "20px 0px", marginLeft: "2%" }}>
          <ResearchButtons
            research={research}
            onUpdate={getResearch}
            isApprovable={isApprovable}
            allButtons={true}
          ></ResearchButtons>
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Included Cards" sx={{ textTransform: "none" }} />
            <Tab label="Available Cards" sx={{ textTransform: "none" }} />
            <FormControlLabel
              control={
                <Checkbox checked={maxVersionChecked} onChange={handleCheck} />
              }
              label="Latest version"
            />
          </Tabs>
        </div>
        <TabPanel value={tabIndex} index={0}>
          <ResearchDetailCardsTable
            inputRows={rows}
            reloadCallback={getResearch}
            isAdded={true}
            research={research}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <ResearchDetailCardsTable
            inputRows={availableRows}
            reloadCallback={getResearch}
            isAdded={false}
            research={research}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
          />
        </TabPanel>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Card>
    </div>
  );
}
