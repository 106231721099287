import * as React from "react";
import { useEffect, useContext } from "react";
import axios from "axios";
import {
  DataGridPro,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Card from "@mui/material/Card";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useHistory, useLocation } from "react-router-dom";
import { rule5properties } from "../../properties";
import { useUser } from "../../context/UserContext.js";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CustomSnackbar from "../../common/CustomSnackbar";
import Button from "@mui/material/Button";
import Image from "../../common/Image";
import { OpListContext } from "../WSOpportunities";
import { getColorFromString } from "../../common/Utils";
import ShareIcon from "@mui/icons-material/Share";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckIcon from "@mui/icons-material/Check";
import ShareOpportunity from "../../modal/ShareOpportunity";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { useDialog } from "../../context/DialogContext";
import { StyledTextfield, scrollbarStyle } from "../../common/StyledComponents";
import ConnectionsList from "../../modal/ConnectionsList";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Link, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

LicenseInfo.setLicenseKey(rule5properties.MUIXProLicenseKey);

function deepCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

const useStyles = makeStyles(() => ({
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "30px",
      marginRight: "30px",
    },
    "& .MuiDataGrid-window": scrollbarStyle,
    "& .MuiDataGrid-virtualScroller": scrollbarStyle,
    card: {},
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "0px",
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          // color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-row": {
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
    "& .MuiButton-root.Mui-disabled": {
      color: "rgba(0,0,0,0.5)",
    },
  },
  columnText: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
  },
  menuButton: {
    minWidth: "10px",
  },
  toolbarButton: {
    padding: "10px 18px 10px 18px",
    margin: "7px",
    color: "rgba(0,0,0,0.5)",
    borderRadius: "8px",
    textTransform: "none",
  },
  summaryCard: {
    height: "72px",
    marginBottom: "16px",
  },
  customButton: {
    textTransform: "none",
  },
  contextMenu: { pointerEvents: "none" },
  contextMenuPaper: { pointerEvents: "auto" },
  popAvatarImage: {
    width: "80px",
    height: "80px",
    borderRadius: "70px",
    padding: "10px",
  },
  connectedAvatar: {
    width: "60px",
    height: "60px",
    borderRadius: "70px",
    padding: "10px",
  },
  popOverGradient: {
    overflowX: "hidden",
    overflowY: "hidden",
    "&::after": {
      position: "absolute",
      top: "80px",
      height: "5%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to top, rgba(255,255,255, 0), rgba(255,255,255, 0.7), rgba(255,255,255, 1)) !important;",
    },
  },
  listbox: {
    backgroundColor: "",
    padding: 0,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "5px 0px 5px 0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
}));

export function StageDisplay(stage) {
  let completed = true;
  let stageText = "S";
  if (stage && stage.stages && Array.isArray(stage.stages)) {
    let stages = stage.stages.map((row, index) => {
      if (completed) {
        stageText = row;
        if (stage.currentStage === row) {
          completed = false;
        }
        return (
          <Tooltip key={index} title={row}>
            <CheckBoxIcon
              fontSize="small"
              color="primary"
              sx={{ padding: 0 }}
            />
          </Tooltip>
        );
      } else
        return (
          <Tooltip key={index} title={row}>
            <CheckBoxOutlineBlankIcon
              fontSize="small"
              sx={{ padding: 0, opacity: 0.4 }}
            />
          </Tooltip>
        );
    });
    return {
      boxes: (
        <div style={{ display: "inline-block" }}>
          <div style={{ display: "flex" }}>{stages}</div>
        </div>
      ),
      text: stageText,
    };
  }
  return {
    boxes: (
      <div style={{ display: "inline-block" }}>
        <div style={{ display: "flex" }}></div>
      </div>
    ),
    text: "",
  };
}

function StageColumn(stage) {
  // const theme = useTheme();
  const stageInfo = StageDisplay(stage);
  return (
    <div>
      <div style={{ whiteSpace: "nowrap", lineHeight: "10px" }}>
        {stageInfo.boxes}
      </div>
      <div
        style={{
          margin: "0px",
          fontSize: "10px",
          fontStyle: "normal",
          textAlign: "left",
          color: "gray",
          whiteSpace: "nowrap",
          lineHeight: "10px",
        }}
      >
        {stageInfo.text}
      </div>
    </div>
  );
}

function GetValue(param) {
  const dialog = useDialog();
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  switch (param.field) {
    case "Account":
      // param.row.companyIcon`
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              width: "56px",
              height: "56px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 2,
            }}
          >
            {param.row.companyIcon !== null ? (
              <Image
                src={param.row.companyIcon}
                style={{
                  height: "auto",
                  maxWidth: 40,
                  maxHeight: 40,
                  display: "block",
                }}
              />
            ) : (
              <Avatar
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: getColorFromString(param.row.Account),
                }}
              >
                {param.row.Account.toUpperCase().charAt(0)}
              </Avatar>
            )}
          </Box>
          {param.row.peopleConnections !== null ? (
            <Stack>
              <Typography sx={{ textTransform: "capitalize" }}>
                {param.value ? param.value : "-"}
              </Typography>
              <div style={{ lineHeight: 0 }}>
                <Button
                  color="inherit"
                  size="mini"
                  variant="contained"
                  disableElevation
                  disableRipple
                  sx={{
                    backgroundColor: "rgb(235,235,235)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.15)",
                    },
                    px: 1,
                    py: 0.25,
                    borderRadius: "6px",
                    fontSize: "10px",
                    textTransform: "none",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dialog.openModal(
                      `Connections at ${param.row.companyName}`,
                      ConnectionsList,
                      {
                        peopleConnections: param.row.peopleConnections,
                        orgName: param.row.orgName,
                      },
                      "md"
                    );
                  }}
                >
                  {param.row.peopleConnectionsCount} Connections
                </Button>
              </div>
            </Stack>
          ) : (
            <Typography sx={{ textTransform: "capitalize" }}>
              {param.value ? param.value : "-"}
            </Typography>
          )}
        </div>
      );
    case "Stage":
      return StageColumn(param.value);
    case "Name":
      let unreadCount = param.row.unreadResearchCard;
      return (
        <Stack alignItems="flex-start">
          <Typography sx={{}}>{param.value}</Typography>
          {unreadCount > 0 ? (
            <Chip
              size="small"
              color="primary"
              label={`${unreadCount} ${unreadCount > 1 ? "updates" : "update"}`}
              sx={{ fontSize: "12px", height: "24px", width: "fit-content" }}
            />
          ) : null}
        </Stack>
      );
    default:
      if (param.colDef.origType) {
        switch (param.colDef.origType) {
          case "currency":
            return param.value ? formatter.format(param.value) : "-";
          case "percentage":
            return param.formattedValue ? param.formattedValue + "%" : "-";
          default:
            return param.value;
        }
      } else return param.value ? param.value : "-";
  }
}

function OpportunityTable(props) {
  const history = useHistory();
  const location = useLocation();
  const dialog = useDialog();

  const handleMenuClick = (opportunity, action, tabVal) => {
    history.push(`${location.pathname}/${opportunity.id}/${action}`);
  };

  const dgclasses = useStyles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const copyOpportunityURL = (opportunityId) => {
    navigator.clipboard.writeText(
      `${window.location.href}/${opportunityId}/research`
    );
  };

  const GetDisplayColumns = (gcols, ucolsParm) => {
    let userCols = deepCopy(gcols);
    let ucols = deepCopy(ucolsParm);
    let col = null;
    let changed = false;

    // Populate missing fields from user columns
    let after = ucols.length;
    for (var i = 0; i < userCols.length; i++) {
      // eslint-disable-next-line
      col = ucols.find((column) => {
        return column.columnName === userCols[i].columnName;
      });
      if (!col) {
        ucols.push({
          hide: true,
          index: after,
          columnName: userCols[i].columnName,
        });
        changed = true;
        after++;
      }
    }

    // Call API if there are changes to user preferences
    if (changed) {
      setUserOpsColumns(ucols);
      updateUserOpportunityConfigs(pageSize, ucols);
    }

    for (var i = 0; i < userCols.length; i++) {
      // eslint-disable-next-line
      col = ucols.find((column) => {
        return column.columnName === userCols[i].columnName;
      });
      if (col) {
        userCols[i].index = col.index;
        userCols[i].hide = col.hide;
      } else {
        userCols[i].index = after;
        userCols[i].hide = true;
        after++;
      }

      // rename columnName to field & displayName to headerName
      userCols[i].field = userCols[i].columnName;
      delete userCols[i]["columnName"];
      userCols[i].headerName = userCols[i].displayName;
      delete userCols[i]["displayName"];
      userCols[i].headerAlign = "left";
      userCols[i].minWidth = userCols[i].flex * 70;
      // delete userCols[i]['flex'];
      userCols[i].editable = false;
      userCols[i].sortable = true;
      userCols[i].cellClassName = dgclasses.columnText;
      userCols[i].disableColumnMenu = false;
      userCols[i].renderCell = GetValue;
      if (userCols[i].field === "Stage") {
        userCols[i].align = "left";
      }
      switch (userCols[i].type) {
        case "percentage":
          userCols[i].align = "left";
        // eslint-disable-next-line no-fallthrough
        case "currency":
          userCols[i].align = "left";
          userCols[i].origType = userCols[i].type;
          userCols[i].type = "number";
          break;
        case "date":
          userCols[i].align = "left";
          break;
        default:
          break;
      }
    }

    userCols.sort(function (a, b) {
      return a.index - b.index;
    });

    userCols.push({
      field: "actions",
      type: "actions",
      hideable: false,
      width: 80,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            id={"Action-" + params.id}
            sx={{ display: "none" }}
            icon={
              <Tooltip title="Copy URL" placement="left">
                <ContentCopyIcon sx={{ m: "6px" }} />
              </Tooltip>
            }
            onClick={() => {
              copyOpportunityURL(params.id);
              setSnackbarMessage("Opportunity URL copied!");
              setOpenSnackbar(true);
            }}
            label="Copy"
          />,
        ];
      },
    });

    /**
     * Currently, application only has research page implemented. We can disable the menu option for now until we decide
     * how this will be accessed later.
     */
    // userCols.push({
    //   field: "action",
    //   type: "actions",
    //   width: 80,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       label={
    //         <Badge
    //           color="secondary"
    //           badgeContent={params.row.unreadResearchCard}
    //         >
    //           <div>Research</div>
    //         </Badge>
    //       }
    //       onClick={(e) => {
    //         handleMenuClick(params.row, "research", 0);
    //       }}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       label="Huddle"
    //       onClick={(e) => {
    //         handleMenuClick(params.row, "huddle", 1);
    //       }}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       label="Collaborate"
    //       onClick={(e) => {
    //         handleMenuClick(params.row, "collaborate", 2);
    //       }}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       label="Update"
    //       onClick={(e) => {
    //         handleMenuClick(params.row, "update", 3);
    //       }}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       label="Track"
    //       onClick={(e) => {
    //         handleMenuClick(params.row, "track", 4);
    //       }}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       label="Review"
    //       onClick={(e) => {
    //         handleMenuClick(params.row, "review", 5);
    //       }}
    //       showInMenu
    //     />,
    //   ],
    // });

    return userCols;
  };

  const theme = useTheme();
  const classes = useStyles();
  const opListContext = useContext(OpListContext);

  const rows = props.rows;
  const rowsLoaded = props.rowsLoaded;

  const [globalOpsColumns, setGlobalOpsColumns] = React.useState(null);
  const [userOpsColumns, setUserOpsColumns] = React.useState(null);
  const [columns, setColumns] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectionMode, setSelectionMode] = React.useState(false);
  // Are all rows selected across all pages?
  const [selectAllAcross, setSelectAllAcross] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

  // Used to display the total number of opportunities even when opportunities list is filtered.
  const allOpportunitiesCount = React.useRef(props.totalRowCount);
  useEffect(() => {
    if (props.searchValue.trim().length === 0) {
      // There is no search filter applied.
      allOpportunitiesCount.current = props.totalRowCount;
    }
  }, [props.totalRowCount, props.searchValue]);

  useEffect(() => {
    if (selectionMode) {
      setSelectAllAcross(false);
    }
  }, [selectionMode]);

  const handleColumnOrderChange = (params, event, details) => {
    // Update User Ops Columns
    let localCols = deepCopy(userOpsColumns);
    localCols.sort((a, b) => {
      return a.index - b.index;
    });
    localCols[params.oldIndex].index = params.targetIndex;
    if (params.oldIndex > params.targetIndex) {
      // i.e. 5 dropped on 3
      for (let i = params.targetIndex; i < params.oldIndex; i++) {
        localCols[i].index += 1;
      }
    } else {
      // i.e. 2 dropped on 4
      for (let i = params.oldIndex + 1; i <= params.targetIndex; i++) {
        localCols[i].index -= 1;
      }
    }
    localCols.sort((a, b) => {
      return a.index - b.index;
    });
    // Call API to update Column Order preference
    updateUserOpportunityConfigs(pageSize, localCols);
    setUserOpsColumns(localCols);
  };

  const openShareOpportunity = () => {
    // Open dialog to share selected opportunities.
    let shareList = [];
    selectedRows.forEach((selId) => {
      shareList.push(rows.find((x) => x.id === selId));
    });
    setSelectedRows([]);
    setSelectionMode(false);
    dialog.openModal(
      "Share Opportunities",
      ShareOpportunity,
      {
        idList: selectedRows,
        opportunityList: shareList,
        selectAll: selectAllAcross,
        allOpportunitiesCount: allOpportunitiesCount.current,
      },
      "sm"
    );
  };

  // This will select all opportunities currently displayed on the page (no cross-page selection)
  const handleSelectAll = () => {
    let opportunties = [];
    rows.forEach((row) => {
      opportunties.push(row.id);
    });
    setSelectedRows(opportunties);
  };

  const getOpportunityColumns = () => {
    axios
      .get(rule5properties.getOpportunityColumns)
      .then((response) => {
        // let columns=JSON.parse(response.data);
        let columns = response.data;
        if (typeof columns === "object" && Array.isArray(columns)) {
          // Validate content
          setGlobalOpsColumns(columns);
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem retrieving Global Columns list"
          );
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setSnackbarMessage(
            "There was a problem retrieving Global Columns list"
          );
          setOpenSnackbar(true);
        }
      });
  };

  const handleColumnVisibilityModelChange = (newModel) => {
    setColumnVisibilityModel(newModel);
    let local = deepCopy(userOpsColumns).map((col) => {
      let visibility = newModel[col.columnName];
      if (visibility === undefined || visibility === null || visibility) {
        col.hide = false;
      } else if (visibility === false) {
        col.hide = true;
      }
      return col;
    });
    // Call API to update Column Visibility preference
    updateUserOpportunityConfigs(pageSize, local);
    setUserOpsColumns(local);
  };

  const getUserOpportunityConfigs = () => {
    // Call API
    let param = ""; // "/?userId=1";  // Hardcoded for now.  UserID will be removed when authentication is done
    axios
      .get(rule5properties.getUserOpportunityConfigs + param)
      .then((response) => {
        // let columns=JSON.parse(response.data);
        let data = response.data;
        if (data !== {}) {
          if (data.pageSize) {
            setPageSize(data.pageSize);
          }
          if (data.columns) {
            if (
              typeof data.columns === "object" &&
              Array.isArray(data.columns)
            ) {
              // Validate content
              setUserOpsColumns(data.columns);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem retrieving user preferences"
              );
              setOpenSnackbar(true);
            }
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setSnackbarMessage("There was a problem retrieving user preferences");
          setOpenSnackbar(true);
        }
      });
  };

  const updateUserOpportunityConfigs = (pageSize, userOpsColumnsParm) => {
    // Call API
    let updateData = {
      // "userId": 1, // Hardcoded for now.  UserID will be removed when authentication is done
      type: "OPPORTUNITY",
      configs: {
        pageSize: pageSize,
        columns: userOpsColumnsParm,
      },
    };
    axios
      .patch(rule5properties.updateUserOpportunityConfigs, updateData)
      .then((response) => {})
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setSnackbarMessage("There was a problem updating user preferences");
          setOpenSnackbar(true);
        }
      });
  };

  function CustomToolbar(props) {
    const { searchValue, setSearchValue, pageSize, totalRowCount } = props;
    const dgclasses = useStyles();
    return (
      <div style={{ padding: "0px 7px 10px 7px" }}>
        <GridToolbarContainer>
          <Stack>
            <StyledTextfield
              autoFocus
              placeholder="Search accounts"
              sx={{ width: "280px", ml: 1 }}
              endAdornment={
                <IconButton size="small" sx={{ mr: -0.5 }}>
                  <SearchIcon fontSize="small" />
                </IconButton>
              }
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </Stack>
          <div style={{ flex: 1 }}> </div>
          {selectionMode ? (
            // In selection mode, change menu items.
            <React.Fragment>
              <Button
                disabled={selectedRows.length < 1}
                variant="filled"
                color="inherit"
                className={dgclasses.toolbarButton}
                onClick={() => {
                  openShareOpportunity();
                }}
              >
                {selectedRows.length < 1 ? null : (
                  <CheckIcon sx={{ marginRight: "8px" }} />
                )}
                {selectedRows.length < 1
                  ? "Select one or more opportunities to share."
                  : `Share ${
                      selectAllAcross
                        ? allOpportunitiesCount.current
                        : selectedRows.length
                    } ${
                      selectedRows.length > 1 ? "opportunities" : "opportunity"
                    }`}
                {(selectedRows.length === totalRowCount ||
                  selectedRows.length === pageSize) &&
                  selectedRows.length < allOpportunitiesCount.current &&
                  !selectAllAcross && (
                    <Link
                      sx={{ ml: 1.5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectAllAcross(true);
                      }}
                    >{`Select all ${allOpportunitiesCount.current} opportunities?`}</Link>
                  )}
              </Button>
              <Button
                className={dgclasses.toolbarButton}
                onClick={handleSelectAll}
              >
                <SelectAllIcon sx={{ marginRight: "8px" }} />
                Select All
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <GridToolbarColumnsButton
                variant="filled"
                color="inherit"
                className={dgclasses.toolbarButton}
              />
              <GridToolbarExport
                variant="filled"
                color="inherit"
                className={dgclasses.toolbarButton}
              />
            </React.Fragment>
          )}
          <Button
            variant="filled"
            color="inherit"
            className={dgclasses.toolbarButton}
            onClick={() => {
              if (selectionMode) {
                setSelectedRows([]);
              }
              setSelectionMode((prevMode) => !prevMode);
            }}
          >
            {selectionMode ? (
              <DoNotDisturbAltIcon sx={{ marginRight: "8px" }} />
            ) : (
              <ShareIcon sx={{ marginRight: "8px" }} />
            )}

            {selectionMode ? "Cancel" : "Share"}
          </Button>
        </GridToolbarContainer>
      </div>
    );
  }

  useEffect(() => {
    getOpportunityColumns();
    getUserOpportunityConfigs();
  }, []);

  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    if (userOpsColumns && globalOpsColumns && rowsLoaded) {
      let columnsRetrieved = GetDisplayColumns(
        deepCopy(globalOpsColumns),
        deepCopy(userOpsColumns)
      );
      let initializedColumnModel = {};
      columnsRetrieved.forEach((column) => {
        if (column.hide) {
          initializedColumnModel[column.field] = false;
        } else {
          initializedColumnModel[column.field] = true;
        }
      });
      setColumnVisibilityModel(initializedColumnModel);
      setColumns(columnsRetrieved);
      setLoaded(true);
    }
  }, [userOpsColumns, globalOpsColumns, rowsLoaded]);

  const [replicatedFilter, setReplicatedFilter] = React.useState({
    items: [],
    quickFilterValues: [],
  });

  // Filters only persist when controlled after the grid is loaded.
  useEffect(() => {
    if (loaded) {
      setReplicatedFilter(opListContext.currFilter);
    }
  }, [loaded, opListContext]);

  // Allows for monitoring events on datagrid.
  const apiRef = useGridApiRef();

  // Monitor mouse hovering row.
  React.useEffect(() => {
    return apiRef.current.subscribeEvent("rowMouseEnter", (params) => {
      let control = document.getElementById("Action-" + params.id);
      if (control) {
        control.style.display = "inline-flex";
      }
    });
  }, [apiRef]);

  // Monitor mouse exiting row.
  React.useEffect(() => {
    return apiRef.current.subscribeEvent("rowMouseLeave", (params) => {
      let control = document.getElementById("Action-" + params.id);
      if (control) {
        control.style.display = "none";
      }
    });
  }, [apiRef]);

  return (
    <React.Fragment>
      {!loaded ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {snackbarMessage === "" && <CircularProgress size={40} />}
          {snackbarMessage !== "" && snackbarMessage}
          <CustomSnackbar
            openSnackbar={openSnackbar}
            setOpenSnackbar={setOpenSnackbar}
            snackbarMessage={snackbarMessage}
          />
        </div>
      ) : null}
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexGrow: "1",
          pt: 1,
          borderRadius: "8px",
          opacity: loaded ? 1 : 0,
          flexDirection: "column",
        }}
      >
        <DataGridPro
          apiRef={apiRef}
          className={classes.root}
          rowHeight={theme.opportunityList.rowHeight}
          rows={rows}
          columns={columns}
          pagination={true}
          rowCount={props.totalRowCount}
          loading={props.loading}
          pageSizeOptions={[10, 20, 50, 100]}
          paginationModel={props.paginationModel}
          paginationMode="server"
          onPaginationModelChange={(newModel) => {
            updateUserOpportunityConfigs(newModel.pageSize, userOpsColumns);
            props.setPaginationModel(newModel);
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onColumnOrderChange={handleColumnOrderChange}
          disableSelectionOnClick
          disableColumnMenu
          filterModel={replicatedFilter}
          onFilterModelChange={(gridFilterModel) => {
            opListContext.setFilter(gridFilterModel);
            // Hack to return focus to the input elem. Not sure why it leaves on debounce.
            const searchInput = document.querySelector(
              "input[type=search].MuiInputBase-inputTypeSearch"
            );
            searchInput.focus();
          }}
          rowSelectionModel={selectedRows}
          // Current default click behavior goes to research.
          onRowClick={(params) => {
            if (selectionMode) {
              let index = selectedRows.indexOf(params.row.id);
              if (index > -1) {
                // This item is already selected, remove it from the list.
                let spliced = [...selectedRows];
                spliced.splice(index, 1);
                setSelectedRows(spliced);
              } else {
                setSelectedRows([...selectedRows, params.row.id]);
              }
            } else {
              let sortedVisibleRows = [];
              apiRef.current.getSortedRows().forEach((row) => {
                // Only push this to the sorted row context list if it's visible.
                sortedVisibleRows.push({
                  id: row.id,
                  preview: `${row.Account}: ${row.Name}`,
                });
              });
              opListContext.setSortedRows(sortedVisibleRows);
              handleMenuClick(params.row, "research", 0);
            }
          }}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              searchValue: props.searchValue,
              setSearchValue: props.setSearchValue,
              pageSize: pageSize,
              totalRowCount: props.totalRowCount,
            },
          }}
        />
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Card>
    </React.Fragment>
  );
}

export default function OpportunityList(props) {
  const theme = useTheme();
  const user = useUser();

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        padding: `0px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <OpportunityTable user={user} {...props} />
    </div>
  );
}
