import React, { useRef } from "react";
import { useEffect } from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "../common/Toolbar";
import { makeStyles } from "@mui/styles";
import {
  COREAPP_USER_ROLES,
  PLAN_TYPES,
  useUser,
  useUserUpdate,
} from "../context/UserContext";
import InputLabel from "@mui/material/InputLabel";
import Collapse from "@mui/material/Collapse";
import { capitalize, isEmpty } from "lodash";
import axios from "axios";
import { rule5properties } from "../properties.js";
import { getFileUrlFromApi } from "../common/Utils";
import { getSrcFromApi } from "../common/Image";
import Image from "../common/Image";
import FormData from "form-data";
import { Controller, useForm, useFormState } from "react-hook-form";
import { StyledTextfield, StyledLabel } from "../common/StyledComponents";
import { linkStyle } from "../common/StyledComponents";
import {
  Autocomplete,
  CircularProgress,
  Fade,
  Link,
  Popper,
} from "@mui/material";

const USER_API_URL = rule5properties.usersApiUrl;

const useStyles = makeStyles((theme) => ({
  panel: {
    height: "100%",
    flexGrow: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  styledInput: {
    backgroundColor: "#F8F8F8",
    height: "40px",
    fontSize: "16px",
    padding: "24px 10px 20px 10px",
    borderRadius: "6px",
    border: "1px solid #DDD",
  },
  linkStyle: linkStyle,
  autocomplete: {
    height: "auto",
    marginBottom: "6px",
  },
  listbox: {
    backgroundColor: "",
    padding: 0,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "5px 0px 5px 0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
  option: {
    backgroundColor: "white",
    boxSizing: "border-box",
    height: 60,
    margin: "5px",
    padding: "10px",
    transition: ".2s",
    borderRadius: "5px",
    textTransform: "none",
  },
  optionText: {
    textTransform: "none",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export default function Profile(props) {
  const classes = useStyles();
  const user = useUser();
  const updateUserContext = useUserUpdate();

  const [originalProfile, setOriginalProfile] = React.useState({});
  const [avatarDirty, setAvatarDirty] = React.useState(false);
  const [avatarFile, setAvatarFile] = React.useState({
    file: null,
    preview: null,
  });
  const [jobFunctions, setJobFunctions] = React.useState([]);
  const { register, handleSubmit, control, reset, getValues } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      orgName: "",
      timeZone: "",
      jobFunction: "",
      profilePicture: null,
    },
  });
  const uploadRef = useRef(null);

  const { dirtyFields } = useFormState({
    control,
  });
  const displayJobFunction =
    user.userSubscription?.planType !== PLAN_TYPES.premiumContent;

  useEffect(() => {
    axios
      .get(USER_API_URL + "/profile")
      .then((res) => {
        if (res.status === 200) {
          setOriginalProfile(res.data);
          reset(res.data);
          if (res.data.userSettingInfo.profilePicture) {
            getSrcFromApi(res.data.userSettingInfo.profilePicture, user).then(
              (pic) => {
                setAvatarFile({
                  file: null,
                  preview: pic,
                });
              }
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    if (!displayJobFunction) {
      return;
    }
    axios.get(`${rule5properties.values}?name=JobFunction`).then((res) => {
      if (res.data.values) {
        setJobFunctions(res.data.values);
      } else {
        console.log("Error loading Job Functions: " + res);
      }
    });
  }, []);

  const handleImageUpload = (event) => {
    if (event.target.files[0]) {
      setAvatarFile({
        file: event.target.files[0],
        preview: URL.createObjectURL(event.target.files[0]),
      });
      setAvatarDirty(true);
    }
  };

  const revertProfile = () => {
    reset(originalProfile);
    setAvatarDirty(false);
    if (originalProfile.userSettingInfo.profilePicture) {
      getSrcFromApi(originalProfile.userSettingInfo.profilePicture, user).then(
        (pic) => {
          setAvatarFile({
            file: null,
            preview: pic,
          });
        }
      );
    } else {
      setAvatarFile({
        file: null,
        preview: null,
      });
    }
  };

  const saveProfile = (data, e) => {
    const formData = new FormData();
    let changes = false;
    if (dirtyFields.firstName) {
      formData.append("firstName", getValues("firstName"));
      changes = true;
    }
    if (dirtyFields.lastName) {
      formData.append("lastName", getValues("lastName"));
      changes = true;
    }
    if (dirtyFields.timeZone) {
      formData.append("timeZone", getValues("timeZone"));
      changes = true;
    }
    if (dirtyFields.jobFunction) {
      formData.append("jobFunction", getValues("jobFunction"));
      changes = true;
    }
    if (avatarDirty) {
      if (avatarFile.file) {
        formData.append("profilePicture", avatarFile.file);
        changes = true;
      } else {
        axios
          .delete(USER_API_URL + "/delete-profile-picture")
          .then((res) => {
            if (res.status === 200) {
              if (!changes) {
                updateUserContext({
                  ...res.data,
                });
                reset(res.data);
                setAvatarDirty(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    if (changes) {
      axios
        .patch(USER_API_URL + "/update-profile", formData)
        .then((res) => {
          if (res.status === 200) {
            updateUserContext({
              ...user,
              ...res.data,
            });
            // If user uploaded a new avatar, replace the default avatar src.
            if (avatarFile.preview) {
              let newAvatar = avatarFile.preview;
              setAvatarFile({
                file: null,
                preview: newAvatar,
              });
            }
            reset(res.data);
            setAvatarDirty(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const removePhoto = () => {
    uploadRef.current.value = null;
    setAvatarFile({
      file: null,
      preview: null,
    });
    setAvatarDirty(true);
  };

  const { getTimeZones, timeZonesNames } = require("@vvo/tzdb");
  const { LOS_ANGELES_TIMEZONE } = "America/Los_Angeles";

  const CustomPopper = (props) => {
    return (
      <Popper {...props} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{ enter: 400, exit: 250 }}>
            {props.children}
          </Fade>
        )}
      </Popper>
    );
  };

  // Gets a time zone object with name, alternativeName, mainCities etc.
  const getTimeZone = (timeZoneName) => {
    return getTimeZones().find(
      (timeZone) =>
        timeZone.name === timeZoneName || timeZone.group.includes(timeZoneName)
    );
  };

  const isValidTimeZone = (timeZoneName) => {
    return timeZonesNames.includes(timeZoneName);
  };

  // Gets the display time zone as "(GMT-08:00) Pacific Time - Los Angeles"
  const getDisplayTimeZone = (timeZoneName) => {
    const timeZone = getTimeZone(timeZoneName);
    return timeZone && timeZone.currentTimeFormat
      ? "(GMT" +
          timeZone.currentTimeFormat.split(" ")[0] +
          ") " +
          timeZone.alternativeName +
          " - " +
          timeZone.mainCities[0]
      : null;
  };

  // Gets all display time zones as "(GMT-08:00) Pacific Time - Los Angeles"
  const getDisplayTimeZones = () => {
    return getTimeZones().map((timeZone) => {
      return {
        name:
          "(GMT" +
          timeZone.currentTimeFormat.split(" ")[0] +
          ") " +
          timeZone.alternativeName +
          " - " +
          timeZone.mainCities[0],
        value: timeZone.name,
      };
    });
  };

  // Gets the time zone which is stored in database like America/Los_Angeles"
  const getDbTimeZone = (displayTimeZone) => {
    if (!displayTimeZone || !displayTimeZone.includes(")")) {
      return LOS_ANGELES_TIMEZONE;
    }
    const timeZone = getTimeZones().find((timeZone) =>
      timeZone.currentTimeFormat.includes(displayTimeZone.split(")")[1])
    );
    return timeZone && timeZone.name ? timeZone.name : LOS_ANGELES_TIMEZONE;
  };

  if (isEmpty(originalProfile)) {
    return (
      <div
        style={{
          height: "70vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
      }}
    >
      <Grid container sx={{}} columns={12} justifyContent="center">
        <Grid item xs={5}>
          <div className={classes.panel}>
            <Avatar
              sx={{
                width: "110px",
                height: "110px",
                fontSize: "44px",
                fontWeight: 500,
              }}
              src={avatarFile.preview}
            >
              {user.firstName.toUpperCase().charAt(0)}
            </Avatar>
            <Collapse in={avatarFile.preview !== null} sx={{ mt: 1 }}>
              <Link
                className={classes.linkStyle}
                sx={{ fontSize: "12px" }}
                onClick={removePhoto}
              >
                Remove Photo
              </Link>
            </Collapse>
            <Button
              disableRipple
              variant="outlined"
              sx={{ textTransform: "none", mt: 2 }}
              component="label"
            >
              Upload image
              <input
                ref={uploadRef}
                type="file"
                hidden
                accept=".png, .jpg, .jpeg"
                onChange={handleImageUpload}
              />
            </Button>
          </div>
        </Grid>
        {/* <Divider orientation="vertical" flexItem sx={{ ml: 4, mr: 4 }} /> */}
        <Grid container item xs>
          <div className={classes.panel} style={{ width: "100%" }}>
            <form
              onSubmit={handleSubmit(saveProfile)}
              style={{ width: "100%" }}
            >
              <StyledLabel>First Name</StyledLabel>
              <StyledTextfield
                {...register("firstName")}
                size="small"
                fullWidth
                sx={{ mb: 1 }}
              />
              <StyledLabel>Last Name</StyledLabel>
              <StyledTextfield
                {...register("lastName")}
                size="small"
                fullWidth
                sx={{ mb: 1 }}
              />
              <StyledLabel>Email Address</StyledLabel>
              <StyledTextfield
                disabled
                {...register("email")}
                size="small"
                fullWidth
                sx={{ mb: 1 }}
              />
              <StyledLabel>Organization</StyledLabel>
              <StyledTextfield
                disabled
                {...register("orgName")}
                size="small"
                fullWidth
                sx={{ mb: 1 }}
              />
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    autoComplete
                    autoHighlight
                    openOnFocus
                    blurOnSelect
                    includeInputInList
                    onChange={(e, data) => field.onChange(data?.value)}
                    className={classes.autocomplete}
                    noOptionsText={"No options found."}
                    classes={{
                      option: classes.option,
                      listbox: classes.listbox,
                    }}
                    renderOption={(props, option) => {
                      return (
                        <Box {...props}>
                          <Typography
                            variant="body1"
                            className={classes.optionText}
                          >
                            {option.name || option}
                            {/* {option} */}
                          </Typography>
                        </Box>
                      );
                    }}
                    ListboxProps={{ style: { maxHeight: 350 } }}
                    PopperComponent={CustomPopper}
                    options={getDisplayTimeZones()}
                    isOptionEqualToValue={(option, value) => {
                      return option.value === value;
                    }}
                    getOptionLabel={(option) =>
                      option.name
                        ? option.name
                        : getDisplayTimeZone(option) || ""
                    }
                    renderInput={(params) => {
                      const { InputLabelProps, InputProps, ...rest } = params;
                      return (
                        <React.Fragment>
                          <StyledLabel>Time Zone</StyledLabel>
                          <StyledTextfield {...params.InputProps} {...rest} />
                        </React.Fragment>
                      );
                    }}
                  />
                )}
                name="timeZone"
                control={control}
              />
              {displayJobFunction && (
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      autoComplete
                      autoHighlight
                      openOnFocus
                      blurOnSelect
                      includeInputInList
                      onChange={(e, data) => field.onChange(data)}
                      className={classes.autocomplete}
                      noOptionsText={"No options found."}
                      classes={{
                        option: classes.option,
                        listbox: classes.listbox,
                      }}
                      renderOption={(props, option) => {
                        return (
                          <Box {...props}>
                            <Typography
                              variant="body1"
                              className={classes.optionText}
                            >
                              {option}
                            </Typography>
                          </Box>
                        );
                      }}
                      ListboxProps={{ style: { maxHeight: 350 } }}
                      PopperComponent={CustomPopper}
                      options={jobFunctions}
                      renderInput={(params) => {
                        const { InputLabelProps, InputProps, ...rest } = params;
                        return (
                          <React.Fragment>
                            <StyledLabel>Job Function</StyledLabel>
                            <StyledTextfield {...params.InputProps} {...rest} />
                          </React.Fragment>
                        );
                      }}
                    />
                  )}
                  name="jobFunction"
                  control={control}
                />
              )}
            </form>
          </div>
        </Grid>
        <Grid item xs={8} sx={{ alignSelf: "center" }}>
          <Collapse
            in={
              avatarDirty ||
              !(
                !dirtyFields.firstName &&
                !dirtyFields.lastName &&
                !dirtyFields.timeZone &&
                !dirtyFields.jobFunction
              )
            }
          >
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="center"
              sx={{ height: "60px", pl: 4, pr: 4 }}
            >
              <Button
                disabled={
                  !avatarDirty &&
                  !dirtyFields.firstName &&
                  !dirtyFields.lastName &&
                  !dirtyFields.timeZone &&
                  !dirtyFields.jobFunction
                }
                type="submit"
                variant="outlined"
                sx={{
                  flexGrow: 1,
                  ml: 1,
                  mr: 1,
                  textTransform: "none",
                  flexBasis: 0,
                }}
                onClick={revertProfile}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !avatarDirty &&
                  !dirtyFields.firstName &&
                  !dirtyFields.lastName &&
                  !dirtyFields.timeZone &&
                  !dirtyFields.jobFunction
                }
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  flexGrow: 1,
                  width: 300,
                  ml: 1,
                  mr: 1,
                  textTransform: "none",
                  flexBasis: 0,
                }}
                onClick={saveProfile}
              >
                Save
              </Button>
            </Stack>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
}
