import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return (
    <Tooltip
      title={
        props.title
          ? props.title
          : expand
          ? "Collapse this item"
          : "Expand this item"
      }
      enterDelay={800}
    >
      <IconButton {...other}>
        <ExpandMoreIcon />
      </IconButton>
    </Tooltip>
  );
})(({ theme, expand, sideways, side }) => ({
  // transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  // transform: !expand ? 'rotate(-90deg)' : 'rotate(0deg)',
  transform:
    sideways === "true"
      ? !expand
        ? "rotate(-90deg)"
        : "rotate(0deg)"
      : !expand
      ? "rotate(0deg)"
      : "rotate(-180deg)",
  marginLeft: side === "left" ? "0px" : "auto",
  transition: theme.transitions.create("transform", {
    duration: 500, // theme.transitions.duration.shortest,
  }),
}));

export default ExpandMore;
