import AuthService from "./AuthService";

const JwtService = () => {
  let inMemoryJWT = null;
  // ID of async call for refreshing timeout ID, referenced to abort refresh cycle.
  let refreshTimeoutId;
  const storageKey = "r5logout";

  window.addEventListener("storage", (event) => {
    if (event.key === storageKey) {
      inMemoryJWT = null;
      window.location.reload();
    }
  });

  const getToken = () => inMemoryJWT;

  const setToken = (token, tokenExpiration) => {
    if (!tokenExpiration) {
      // if token expiration is undefined, abort in order to prevent memory leaks.
      return;
    }
    inMemoryJWT = token;
    refreshToken(tokenExpiration);
    return true;
  };

  const deleteToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    window.localStorage.setItem(storageKey, Date.now());
    return true;
  };

  const refreshToken = (expirationInMs) => {
    // Fire five seconds before JWT expires
    const timeoutTrigger = expirationInMs - 5000;

    refreshTimeoutId = window.setTimeout(() => {
      AuthService.refreshToken()
        .then((res) => {
          if (res.accessToken) {
            const { accessToken, expiresIn } = res;
            setToken(accessToken, expiresIn);
          }
        })
        .catch(console.error);
    }, timeoutTrigger);
  };

  const abortRefreshToken = () => {
    if (refreshTimeoutId) {
      window.clearTimeout(refreshTimeoutId);
    }
  };

  return {
    getToken,
    setToken,
    deleteToken,
  };
};

export default JwtService();
