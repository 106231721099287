import React, { useContext } from "react";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { scrollbarStyle } from "../common/StyledComponents";

const SidepanelContext = React.createContext();

export function useSidepanel() {
  return useContext(SidepanelContext);
}

export default function SidepanelProvider({ children }) {
  const [sidepanelOpen, setSidepanelOpen] = React.useState(false);
  const [sidepanelProps, setSidepanelProps] = useState({
    title: "Sidepanel Title",
    content: "This is the dialog content.",
  });

  function openSidepanel(title, content, props) {
    setSidepanelOpen(true);
    if (content) {
      setSidepanelProps({
        title: title,
        content: content,
        props: props,
      });
    }
  }

  function closeSidepanel() {
    setSidepanelOpen(false);
  }

  function setPanelTitle(newTitle) {
    setSidepanelProps({
      ...sidepanelProps,
      title: newTitle,
    });
  }

  return (
    <SidepanelContext.Provider
      value={{
        openSidepanel: openSidepanel,
        closeSidepanel: closeSidepanel,
        setPanelTitle: setPanelTitle,
      }}
    >
      <Drawer
        anchor="right"
        open={sidepanelOpen}
        onClose={closeSidepanel}
        BackdropProps={{
          style: { backgroundColor: "rgb(0,0,0,0.3)" },
          transitionDuration: 300,
        }}
        PaperProps={{
          sx: { ...scrollbarStyle, overflowY: "scroll" },
        }}
      >
        <Box sx={{ py: 3, pl: "34px", pr: 3 }}>
          <Box>
            <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
              {sidepanelProps.title}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "500px",
              mt: 3,
            }}
          >
            {<sidepanelProps.content {...sidepanelProps.props} />}
          </Box>
        </Box>
      </Drawer>
      {children}
    </SidepanelContext.Provider>
  );
}
