import React from "react";
import Workspace from "./Workspace";
import Toolbar from "../common/Toolbar";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  IconButton,
  Button,
  Link,
  Menu,
  Typography,
  Tooltip,
  useTheme,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Image from "../common/Image";
import { linkStyle, StyledTextfield } from "../common/StyledComponents";
import { throttle, capitalize } from "lodash";
import axios from "axios";
import { rule5properties } from "../properties";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";

import CustomSnackbar from "../common/CustomSnackbar";
import {
  aggregateConnections,
  checkPersonProfileExists,
  getColorFromString,
  regulateProfile,
} from "../common/Utils";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Phone from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import { Fade, Stack } from "@mui/material";
import ConnectionsInPersonPopup from "../common/ConnectionsInPersonPopup";
import PersonProfile from "../modal/PersonProfile";
import { useDialog } from "../context/DialogContext";
import { useCSVReader } from "react-papaparse";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

const useStyles = makeStyles(() => ({
  autocomplete: {
    padding: `0px 16px 16px 16px`,
    width: "600px",
    height: "auto",
    alignSelf: "center",
  },
  textfield: {
    marginTop: 25,
    marginBottom: 0,
  },
  option: {
    backgroundColor: "white",
    boxSizing: "border-box",
    height: 60,
    margin: "5px",
    padding: "10px",
    transition: ".2s",
    borderRadius: "5px",
    textTransform: "none",
  },
  optionText: {
    textTransform: "none",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  listbox: {
    backgroundColor: "",
    padding: 0,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "5px 0px 5px 0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
  optionDomain: {
    marginLeft: "20px",
    textAlign: "right",
    width: "100%",
    paddingTop: "3px",
    paddingRight: "20px",
    opacity: 0.7,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  stackFill: {
    flexGrow: 1,
  },
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "30px",
      marginRight: "30px",
    },
    card: {},
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "0px",
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          // color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
      maxHeight: "fit-content !important",
      overflow: "hidden",
      whiteSpace: "initial !important",
      display: "flex !important",
      alignItems: "center !important",
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      fontSize: "1rem",
    },
    "& .MuiDataGrid-row": {
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
    "& .MuiButton-root.Mui-disabled": {
      color: "rgba(0,0,0,0.5)",
    },
  },
  toolbarButton: {
    padding: "10px 18px 10px 18px",
    margin: "7px",
    color: "rgba(0,0,0,0.5)",
    borderRadius: "8px",
    textTransform: "none",
  },
  numberConnections: {
    justifyContent: "center !important",
  },
  personDetail: {
    padding: "20px",
  },
  linkStyle: linkStyle,
  connectionsProfilePic: {
    position: "relative",
    padding: 0,
    width: "20px",
    height: "20px",
    borderRadius: "70px",
  },
  textFieldRoot: {
    "& .MuiInputBase-root": {
      paddingRight: "100px !important",
      maxHeight: "150px",
      overflow: "scroll",
    },
    "& .MuiInputAdornment-positionEnd": {
      position: "absolute",
      right: "10px",
      order: 3,
    },

    // Clear icon
    "& .MuiAutocomplete-endAdornment": {
      position: "absolute",
      right: "40px",
      paddingTop: "3px",
      order: 2,
    },
  },
}));

export default function WSConnections() {
  const [connections, setConnections] = React.useState(null);
  const [loadingConnections, setLoadingConnections] = React.useState(false);

  return (
    <div id="focusout">
      <Toolbar>Connections</Toolbar>
      <Workspace>
        <FindCompaniesAutocomplete
          setConnections={setConnections}
          loadingConnections={loadingConnections}
          setLoadingConnections={setLoadingConnections}
        ></FindCompaniesAutocomplete>
        <ConnectionsList
          connections={connections}
          loadingConnections={loadingConnections}
        ></ConnectionsList>
      </Workspace>
    </div>
  );
}

function FindCompaniesAutocomplete(props) {
  const classes = useStyles();
  const { CSVReader } = useCSVReader();

  const { setConnections, loadingConnections, setLoadingConnections } = props;

  const [values, setValues] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);

  const handleCompanyChange = (event, newInputValue) => {
    setSearchValue(newInputValue.replace(/\w+/g, capitalize));
    if (newInputValue.length > 0) {
      if (values) {
        if (values.name !== newInputValue) {
          setSearchOpen(true);
        }
      } else {
        setSearchOpen(true);
      }
    } else {
      setSearchOpen(false);
    }
  };

  const fetchCompanies = React.useMemo(
    () =>
      throttle((input, callback) => {
        axios.get(`${rule5properties.companies}?name=${input}`).then((res) => {
          if (res.data) {
            callback(res.data);
          } else {
          }
        });
      }, 200),
    []
  );

  const getConnections = (event, newValue) => {
    setValues(newValue);
    // Get connections.
    if (newValue && newValue.length > 0) {
      if (!loadingConnections) {
        setLoadingConnections(true);
      }
      const params = {
        companyNames: newValue.map((value) => value.name),
      };
      axios
        .post(rule5properties.connections, params)
        .catch(function (error) {
          console.log(error);
        })
        .then((response) => {
          setConnections(aggregateConnections(response.data));
          setLoadingConnections(false);
        });
    } else {
      setConnections(null);
    }
  };

  React.useEffect(() => {
    let active = true;

    if (searchValue === "") {
      setCompanies(values);
      return undefined;
    }

    if (searchValue.length > 1) {
      setSearching(true);
      fetchCompanies(encodeURIComponent(searchValue), (results) => {
        if (active) {
          let newOptions = [];

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          if (newOptions.find((x) => x.name === searchValue) === undefined) {
            newOptions.unshift({
              id: "-1",
              name: searchValue,
              company_info: {},
            });
          }
          setSearching(false);
          setCompanies(newOptions);
        }
      });
    } else {
      setCompanies([]);
      setSearching(true);
    }

    return () => {
      active = false;
    };
  }, [values, searchValue, fetchCompanies]);

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      autoSelect
      selectOnFocus
      autoComplete
      autoHighlight
      popupIcon={""}
      classes={{
        option: classes.option,
        listbox: classes.listbox,
        root: classes.textFieldRoot,
      }}
      className={classes.autocomplete}
      loading={searching}
      loadingText={
        searchValue.length < 2 ? "Continue typing..." : "Searching..."
      }
      open={searchOpen}
      onClose={() => setSearchOpen(false)}
      onInputChange={handleCompanyChange}
      onChange={(event, newValue) => getConnections(event, newValue)}
      value={values}
      id="companySearch"
      noOptionsText="No options found."
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      renderOption={(props, option) => {
        return (
          <Box
            {...props}
            sx={{
              border:
                option.id === "-1" ? "1px dashed rgba(0,0,0,0.2)" : "none",
            }}
          >
            <div
              style={{
                minWidth: "30px",
                display: "flex",
                marginRight: "16px",
              }}
            >
              {option.id === "-1" ? (
                <AddCircleOutlineIcon
                  sx={{
                    opacity: 0.5,
                    height: "100%",
                    alignItems: "center",
                    ml: "3px",
                  }}
                />
              ) : option.companyInfo.icon ? (
                <Image
                  style={{ maxHeight: "30px", maxWidth: "30px" }}
                  src={option.companyInfo.icon}
                />
              ) : (
                <img
                  loading="lazy"
                  width="30"
                  src={`https://logo.clearbit.com/${option.domain}?s=128`}
                  alt=""
                />
              )}
            </div>
            <Typography
              variant="body1"
              className={classes.optionText}
              sx={{ width: "700px", mt: "2px" }}
            >
              {option.id === "-1" ? option.name : option.name}
            </Typography>
            <Typography variant="caption" className={classes.optionDomain}>
              {option.domain}
            </Typography>
          </Box>
        );
      }}
      ListboxProps={{ style: { maxHeight: 400 } }}
      onKeyDown={(event) => {
        if (event.key === "Tab") {
          // Prevent's default 'Enter' behavior.
        }
      }}
      options={companies}
      renderInput={(params) => {
        const { InputLabelProps, ...rest } = params;
        const InputProps = {
          ...params.InputProps,
          endAdornment: (
            <>
              {params.InputProps.endAdornment}
              <InputAdornment position="end">
                <CSVReader
                  onUploadAccepted={(results) => {
                    setSearching(false);
                    setLoadingConnections(true);
                    let companyRequests = [];
                    let companyNames = [];

                    results.data.forEach((company) => {
                      let companyName = company[0]?.toLowerCase(); // 0 is colIndex
                      if (!companyNames.includes(companyName)) {
                        companyNames.push(companyName);
                        companyRequests.push(
                          axios.get(
                            `${
                              rule5properties.companies
                            }?name=${encodeURIComponent(companyName)}`
                          )
                        );
                      }
                    });

                    Promise.all(companyRequests).then((responses) => {
                      let csvList = [];
                      responses.forEach((response) => {
                        const responseCompanies = response.data;
                        responseCompanies.forEach((responseCompany) => {
                          if (
                            companyNames.includes(
                              responseCompany?.name?.toLowerCase()
                            ) &&
                            !csvList.find(
                              (company) =>
                                company?.name?.toLowerCase() ===
                                responseCompany?.name?.toLowerCase()
                            )
                          ) {
                            csvList.push(responseCompany);
                          }
                        });
                      });
                      getConnections(undefined, csvList);
                    });
                  }}
                >
                  {({
                    getRootProps,
                    acceptedFile,
                    ProgressBar,
                    getRemoveFileProps,
                  }) => (
                    <>
                      <Tooltip title="Upload list of companies via CSV file">
                        <IconButton {...getRootProps()} size="small">
                          <ManageSearchIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CSVReader>
              </InputAdornment>
            </>
          ),
        };
        return (
          <>
            <StyledTextfield
              autoFocus
              placeholder="Enter a company name..."
              {...rest}
              {...InputProps}
            />
          </>
        );
      }}
    />
  );
}

function ConnectionsList(props) {
  const theme = useTheme();
  const connections = props.connections;

  // User is looking but not finding any connections.
  if (connections && Object.keys(connections).length === 0) {
    return (
      <Workspace>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ flexGrow: "1" }}
        >
          <Typography variant="h5" sx={{ opacity: 0.5 }}>
            No connections found.
          </Typography>
        </Stack>
      </Workspace>
    );
  }

  return (
    <div
      style={{
        minHeight: "500px",
        height: "100%",
        maxWidth: "100%",
        padding: `0px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ConnectionsTable {...props} />
    </div>
  );
}

function ConnectionsTable(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickedPerson, setClickedPerson] = React.useState(null);
  const controller = new AbortController();

  const { connections, loadingConnections } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const classes = useStyles();

  const rows = connections ? Object.values(connections) : [];

  const [orgName, setOrgName] = React.useState();
  axios.get(rule5properties.userProfileApiUrl).then((response) => {
    if (response?.data?.orgName) {
      setOrgName(response?.data?.orgName);
    }
  });

  const [pageSize, setPageSize] = React.useState(10);

  const columns = [
    {
      field: "personInfo.full_name",
      headerName: "Name",
      valueGetter: (params) => params.row.personInfo?.full_name,
      width: 300,
      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: "56px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: 2,
              }}
            >
              <Avatar
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: getColorFromString(
                    cellValues.row.personInfo.full_name
                  ),
                }}
              >
                {cellValues.row.personInfo.avatar_url ? (
                  <Image
                    src={cellValues.row.personInfo.avatar_url}
                    style={{
                      height: "auto",
                      maxWidth: 40,
                      maxHeight: 40,
                      display: "block",
                    }}
                  />
                ) : (
                  cellValues.row.personInfo.full_name?.toUpperCase().charAt(0)
                )}
              </Avatar>
            </Box>
            <Typography sx={{ textTransform: "capitalize" }}>
              {cellValues.row.personInfo.full_name
                ? cellValues.row.personInfo.full_name
                : "-"}
            </Typography>
          </div>
        );
      },
    },
    { field: "title", headerName: "Title", flex: 1 },
    { field: "companyName", headerName: "Company", width: 150 },
    {
      field: "extendConnections",
      headerName: "Ecosystem Connections",
      headerAlign: "center",
      type: "number",
      width: 180,
      cellClassName: classes.numberConnections,
      valueGetter: (cellValues) =>
        cellValues.row.connectionPersonInfo?.filter(
          (connection) => connection.connectionOrgName !== orgName
        ).length,
      renderCell: (cellValues) => {
        return (
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              bgcolor: "#1f76b8",
              fontSize: "1rem",
            }}
          >
            {
              cellValues.row.connectionPersonInfo?.filter(
                (connection) => connection.connectionOrgName !== orgName
              ).length
            }
          </Avatar>
        );
      },
    },
    {
      field: "totalConnections",
      headerName: "Total Connections",
      headerAlign: "center",
      type: "number",
      width: 160,
      cellClassName: classes.numberConnections,
      valueGetter: (cellValues) => cellValues.row.connectionPersonInfo?.length,
      renderCell: (cellValues) => {
        return (
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              bgcolor: "#1f76b8",
              fontSize: "1rem",
            }}
          >
            {cellValues.row.connectionPersonInfo.length}
          </Avatar>
        );
      },
    },
    {
      field: "connectedEmails",
      headerName: "Connection Emails",
      type: "text",
      width: 250,

      valueGetter: (cellsValues) =>
        getConnectedEmails(cellsValues.row.connectionPersonInfo),
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.row.connectionPersonInfo?.map((person, index) => {
              if (person.connectionEmail !== null) {
                return (
                  <div key={person.connectionEmail}>
                    {person.connectionEmail}
                  </div>
                );
              } else {
                return "";
              }
            })}
          </div>
        );
      },
    },
  ];

  function getConnectedEmails(connections) {
    let emails = "";

    connections.forEach((connection) => {
      if (connection.connectionEmail !== null)
        emails += connection.connectionEmail + "\n";
    });

    return emails;
  }

  function CustomToolbar() {
    const dgclasses = useStyles();
    return (
      <div style={{ padding: "0px 7px 10px 7px" }}>
        <GridToolbarContainer>
          <GridToolbarQuickFilter
            className={dgclasses.toolbarButton}
            sx={{ width: "250px" }}
            debounceMs={500}
          />
          <div style={{ flex: 1 }}> </div>
          <GridToolbarExport
            variant="filled"
            color="inherit"
            className={dgclasses.toolbarButton}
            csvOptions={{ allColumns: true }}
          />
        </GridToolbarContainer>
      </div>
    );
  }

  const updatePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  if ((!loadingConnections && !rows?.length > 0) || !orgName) {
    return null;
  }

  return (
    <Card
      id="card"
      variant="outlined"
      sx={{ display: "flex", flexGrow: "1", pt: 1, borderRadius: "8px" }}
    >
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          className={classes.root}
          rowHeight={theme.opportunityList.rowHeight}
          rows={rows}
          columns={columns}
          loading={loadingConnections}
          pagination={true}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={updatePageSize}
          disableSelectionOnClick
          disableColumnMenu
          getRowId={(row) => row.linkedinProfileId}
          onRowClick={(params, event) => {
            setAnchorEl(event.currentTarget);
            setClickedPerson(params.row);
          }}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { allColumns: true },
              printOptions: { allColumns: true },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                connectedEmails: true,
              },
            },
          }}
        />
        <PersonPopup
          controller={controller}
          anchorEl={anchorEl}
          handleClose={handleClose}
          row={clickedPerson}
          allConnections={rows}
          orgName={orgName}
        ></PersonPopup>
      </div>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Card>
  );
}

function PersonPopup(props) {
  const classes = useStyles();
  const dialog = useDialog();

  const anchorEl = props.anchorEl;
  const handleClose = props.handleClose;
  const row = props.row;
  const allConnections = props.allConnections;
  const open = Boolean(anchorEl);
  const orgName = props.orgName;

  const [loadingProfile, setLoadingProfile] = React.useState(false);
  const [personProfileExists, setPersonProfileExists] = React.useState(true);
  const [personProfile, setPersonProfile] = React.useState(null);

  const handleShowAdditionalInfo = () => {
    if (!personProfile) {
      setLoadingProfile(true);
      let urlParams = new URLSearchParams();
      urlParams.append(
        "profileUrl",
        "https://www.linkedin.com/in/" + row.linkedinProfileId
      );
      axios
        .get(
          rule5properties.coreKeyPeopleProfile + "?" + urlParams.toString(),
          {
            signal: props.controller.signal,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            let profile = regulateProfile(data);
            if (checkPersonProfileExists(profile)) {
              setPersonProfile(profile);
              setPersonProfileExists(true);
              dialog.openModal(
                `Profile: ${row.firstName} ${row.lastName}`,
                PersonProfile,
                {
                  data: {
                    PersonProfile: profile,
                    Title: row.title,
                  },
                  customPb: row.title ? "0px" : "20px",
                },
                "md"
              );
            } else {
              setPersonProfile(null);
              setPersonProfileExists(false);
            }
          } else {
            console.log(res);
          }
          setLoadingProfile(false);
        })
        .catch((err) => {
          setLoadingProfile(false);
          console.log(err);
        });
    } else {
      dialog.openModal(
        `Profile: ${row.firstName} ${row.lastName}`,
        PersonProfile,
        {
          data: {
            PersonProfile: personProfile,
            Title: row.title,
          },
          customPb: row.title ? "0px" : "20px",
        },
        "md"
      );
    }
  };

  if (!row || !anchorEl) {
    return null;
  }

  return (
    <Menu
      id="person-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        setPersonProfile(null);
        setLoadingProfile(false);
        props.controller.abort();
        handleClose();
      }}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: -120,
      }}
      sx={{ boxShadow: 0 }}
      MenuListProps={{
        sx: {
          padding: "4px",
        },
      }}
      PaperProps={{
        elevation: 1,
        sx: {
          borderRadius: "10px",
          overflow: "visible",
          ml: 1.5,
          "&:before": {
            borderLeft: "1px solid rgba(0,0,0,0.1)",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            content: '""',
            display: "block",
            position: "absolute",
            ml: -0.8,
            left: 0,
            top: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <Box className={classes.personDetail}>
        <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
          <Link
            sx={{ width: "fit-content", mr: 6 }}
            className={classes.linkStyle}
            href={`https://www.linkedin.com/in/${row.linkedinProfileId}/`}
            target="_blank"
          >
            <div
              style={{
                cursor: "pointer",
                color: "#176cbf",
                display: "flex",
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                {row.personInfo.full_name}
              </Typography>{" "}
              <LinkedIn sx={{ ml: 1, mt: "-2px" }} color="inherit" />
            </div>
          </Link>
        </div>
        <Typography sx={{ mt: 1 }}>
          {row.title} {row.Department ? `, ${row.Department}` : null}
        </Typography>
        {(row.personInfo.city || row.personInfo.state) && (
          <Typography sx={{ mt: "2px" }}>
            {row.personInfo.city +
              (row.personInfo.state && row.personInfo.city ? ", " : "") +
              row.personInfo.state}
          </Typography>
        )}
        <Box sx={{ mt: 1, mb: 1, display: "flex", alignItems: "center" }}>
          <MailOutlineIcon sx={{ mr: 1, color: "#176cbf" }} />
          {row.personInfo.contact_info?.email ? (
            <Link
              className={classes.linkStyle}
              href={"mailto:" + row.personInfo.contact_info.email}
            >
              {row.personInfo.contact_info.email}
            </Link>
          ) : (
            "Unspecified"
          )}
        </Box>
        <Box
          sx={{
            width: "320px",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography sx={{ mt: "2px" }} variant="caption">
            Start a conversation:
          </Typography>
          <IconButton sx={{ ml: 1 }}>
            <ChatBubbleOutlineIcon />
          </IconButton>
          <IconButton>
            <VideoCameraFrontIcon />
          </IconButton>
          <IconButton>
            <MailOutlineIcon />
          </IconButton>
          <IconButton>
            <Phone />
          </IconButton>
        </Box>
        <Tooltip
          title={
            loadingProfile
              ? ""
              : personProfileExists
              ? ""
              : "We don't have additional information for this person."
          }
          placement="right"
        >
          <span>
            <Button
              disabled={!personProfileExists || loadingProfile}
              disableElevation
              disableRipple
              variant="contained"
              onClick={handleShowAdditionalInfo}
              sx={{
                textTransform: "none",
                width: "200px",
                borderRadius: "6px",
              }}
            >
              {loadingProfile ? "Loading..." : "Additional Info"}
            </Button>
          </span>
        </Tooltip>
        {row.connectionPersonInfo?.length > 0 && (
          <ConnectionsInPersonPopup
            personConnections={row.connectionPersonInfo}
            allConnections={allConnections}
            orgName={orgName}
            companyName={row.companyName}
          ></ConnectionsInPersonPopup>
        )}
      </Box>
    </Menu>
  );
}
