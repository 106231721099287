import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDialog } from "../context/DialogContext";

export default function GenericConfirmation(props) {
  const dialog = useDialog();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { confirmFunction, text, children } = props;

  return (
    <Stack alignItems="left">
      {text && <Typography sx={{ width: "100%" }}>{text}</Typography>}
      {children}
      {error?.trim().length > 0 && (
        <Typography sx={{ width: "100%", mt: 1, color: "red" }}>
          {error}
        </Typography>
      )}
      {loading ? (
        <CircularProgress sx={{ mt: 3, alignSelf: "center" }} size={36} />
      ) : (
        <Stack direction="row" sx={{ mt: 3, marginLeft: "auto" }}>
          <Button
            sx={{
              textTransform: "none",
              mr: 2,
              width: "120px",
            }}
            color="primary"
            variant="outlined"
            onClick={() => dialog.closeModal()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableRipple
            disableElevation
            sx={{
              textTransform: "none",
              flexGrow: 1,
              flexBasis: 1,
              width: "120px",
            }}
            onClick={() => {
              setLoading(true);
              confirmFunction(function (response) {
                if (response.status > 199 && response.status < 300) {
                  setTimeout(() => {
                    dialog.closeModal();
                  }, 1000);
                } else {
                  setLoading(false);
                  setError("Error " + response.status);
                }
              });
            }}
          >
            Continue
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
