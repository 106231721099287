import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import ImageInput from "../util/ImageInput";

export default function CompanyUpdatesEditor(props) {
  const { register, control, trigger, getValues, setValue } = useFormContext();
  const formStyle = props.formStyle;

  return (
    <div>
      <TitleInput register={register} />
      <h5> News: </h5>
      <News {...{ trigger, control, register, getValues, formStyle }} />
      <Divider
        light
        style={{ width: "100%", marginBottom: "2%", marginTop: "2%" }}
      />
      <h5> Events: </h5>
      <Events {...{ trigger, control, register, getValues, formStyle }} />
      <Divider
        light
        style={{ width: "100%", marginBottom: "2%", marginTop: "2%" }}
      />
      <h5> Sources: </h5>
      <Source
        {...{ trigger, control, register, getValues, formStyle, setValue }}
      />
    </div>
  );
}

/** Form array */
function News({ trigger, control, register, getValues, formStyle }) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `News`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, newsIndex) => {
        return (
          <div key={item.id} className={formStyle}>
            Article Title
            <input
              {...register(`News[${newsIndex}].ArticleTitle`)}
              control={control}
            />
            Article Link
            <input
              {...register(`News[${newsIndex}].ArticleLink`)}
              control={control}
            />
            Short Description
            <TextareaAutosize
              {...register(`News[${newsIndex}].ShortDescription`, {
                // required: 'error message' // JS only: <p>error message</p> TS only support string
              })}
              aria-label=""
              minRows={3}
              style={{ width: "100%" }}
            />
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(newsIndex, moveToIndex);
                trigger();
              }}
              index={newsIndex}
              options={[...Array(getValues("News").length).keys()]}
            ></MoveAutocomplete>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(newsIndex);
                trigger();
              }}
            >
              Delete News
            </DeleteButton>
          </div>
        );
      })}

      <AddButton onClick={() => appendFuncClickHandler(append, fields)}>
        Add News
      </AddButton>
    </div>
  );
}

/** Form array */
function Events({ trigger, control, register, getValues, formStyle }) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `Events`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, eventsIndex) => {
        return (
          <div key={item.id} className={formStyle}>
            Event Title
            <input
              {...register(`Events[${eventsIndex}].EventTitle`)}
              control={control}
            />
            Event Link
            <input
              {...register(`Events[${eventsIndex}].EventLink`)}
              control={control}
            />
            {/*TODO: use datepicker*/}
            Event Date mm/dd/yyyy
            <input
              {...register(`Events[${eventsIndex}].EventDate`)}
              control={control}
            />
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(eventsIndex, moveToIndex);
                trigger();
              }}
              index={eventsIndex}
              options={[...Array(getValues("Events").length).keys()]}
            ></MoveAutocomplete>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(eventsIndex);
                trigger();
              }}
            >
              Delete Events
            </DeleteButton>
          </div>
        );
      })}

      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Events
      </AddButton>
    </div>
  );
}

/** Form array */
function Source({
  trigger,
  control,
  register,
  getValues,
  formStyle,
  setValue,
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `Source`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, sourceIndex) => {
        return (
          <div key={item.id} className={formStyle}>
            Source name
            <input
              {...register(`Source[${sourceIndex}].Name`)}
              control={control}
            />
            Source logo
            <ImageInput
              name={`Source[${sourceIndex}].Logo`}
              control={control}
              setValue={setValue}
            ></ImageInput>
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(sourceIndex, moveToIndex);
                trigger();
              }}
              index={sourceIndex}
              options={[...Array(getValues("Source").length).keys()]}
            ></MoveAutocomplete>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(sourceIndex);
                trigger();
              }}
            >
              Delete Source
            </DeleteButton>
          </div>
        );
      })}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Source
      </AddButton>
    </div>
  );
}

// let testData = {
//     "Title": "Company Updates",
//     "News": [
//         {
//             "ArticleTitle": "Pfizer declares fourth-quarter 2021 Dividend",
//             "ArticleLink": "https://www.pfizer.com/news/press-release/press-release-detail/pfizer-declares-fourth-quarter-2021-dividend",
//             "ShortDescription": "Pfizer Inc. (NYSE: PFE) today announced that its board of directors declared a 39-cent fourth-quarter 2021 dividend on the company’s common stock, payable December 6, 2021, to holders of the Common Stock of record at the close of business on November 5, 2021. The fourth-quarter 2021 cash dividend will be the 332nd consecutive quarterly dividend paid by Pfizer."
//         },
//         {
//             "ArticleTitle": "Pfizer Starts Study of mRNA-Based Next Generation Flu Vaccine Program",
//             "ArticleLink": "https://www.pfizer.com/news/press-release/press-release-detail/pfizer-starts-study-mrna-based-next-generation-flu-vaccine",
//             "ShortDescription": "Influenza results in approximately 5 million cases of severe illness and 290,000 to up to 650,000 deaths worldwide every year,1 with current seasonal vaccines preventing 40% to 60% of the disease in the best-matched seasons 2. mRNA-based vaccine design requires only the genetic sequences of the viruses, enabling more flexible, rapid manufacturing and the potential opportunity to improve upon the efficacy of current flu vaccines"
//         }
//     ],
//     "Events": [
//         {
//             "EventTitle": "Pfizer Quarterly Corporate Performance",
//             "EventDate": "11/2/2021",
//             "EventLink": "https://investors.pfizer.com/events-and-presentations/event-details/2021/Pfizer-Quarterly-Corporate-Performance--Third-Quarter-2021/default.aspx"
//         }
//     ],
//     "Source": [
//         {
//             "Name": "Glassdoor",
//             "Logo": "https://drive.google.com/file/d/1anDgN2aYvKIkS7O9TDdbtOMRnPN3Hb2A/view?usp=sharing"
//         }
//     ],
//     "NewUpdate": false
// }
