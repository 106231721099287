import {
  Autocomplete,
  TextField,
  Typography,
  Chip,
  Button,
  Grow,
  Box,
  CircularProgress,
  Stack,
} from "@mui/material";
import { rule5properties } from "../properties";
import React from "react";
import { TransitionGroup } from "react-transition-group";
import axios from "axios";
import { useDialog } from "../context/DialogContext";
import { useEffect } from "react";
import { useUser } from "../context/UserContext";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { StyledTextfield } from "../common/StyledComponents";
import CheckIcon from "@mui/icons-material/Check";
import { PLEASE_UPGRADE_TEXT } from "../common/Utils";

const Joi = require("joi");

const emailSchema = Joi.string().email({ tlds: { allow: false } });

export default function ShareOpportunity(props) {
  const dialog = useDialog();
  const user = useUser();

  const [emails, setEmails] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [userInfoMap, setUserInfoMap] = React.useState(null);
  const [hasError, setHasError] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [currentValue, setCurrentValue] = React.useState("");
  const [shareAll, setShareAll] = React.useState(false);

  const [copied, setCopied] = React.useState(false);

  const allowedActions = user.userSubscription?.allowedActions;
  const disallowed =
    !allowedActions || !allowedActions?.includes("Share Opportunities");

  // Load user list from current user's domain.

  useEffect(() => {
    axios.get(rule5properties.usersApiUrl).then((res) => {
      let result = [];
      let infoMap = new Map();
      res.data.forEach((val) => {
        result.push(val.email);
        infoMap.set(val.email, val.firstName + " " + val.lastName);
      });
      setUsers(result);
      setUserInfoMap(infoMap);
    });
  }, []);

  const handleInputChange = (event, value) => {
    setStatus("");
    let hasError = false;
    value.forEach((val) => {
      if (emailSchema.validate(val).error) {
        hasError = true;
      }
    });

    setHasError(hasError);

    setEmails(value);
  };

  const handleShareAll = (event) => {
    setShareAll(event.target.checked);
  };

  const shareOpportunities = (event, value) => {
    let params = {};

    if (props.selectAll) {
      params = { ...params, ...{ shareAllOpportunities: props.selectAll } };
    } else {
      params = { ...params, ...{ ids: props.idList } };
    }

    if (shareAll) {
      params = { ...params, ...{ shareWithAllUsers: shareAll } };
    } else {
      params = { ...params, ...{ emails: emails } };
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Call API to send emails.
    setLoading(true);
    axios
      .post(rule5properties.shareOpportunities, params, options)
      .then((res) => {
        if (res.status === 200) {
          setComplete(true);
        } else if (res.data.code === "NOT_FOUND_ERROR") {
          setStatus("One or more users were invalid.");
        } else {
          console.log(res);
          setStatus(res.data.message || res.data.code);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.reponse);
        if (err.response.data.message === "DOMAIN_MISMATCH") {
          setStatus(
            `Emails must belong to the ${user.email.split("@")[1]} domain.`
          );
        } else {
          console.log(err.response.data);
          setStatus(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const addInviteChip = () => {
    let trimmed = currentValue.trim();
    setEmails([...emails, trimmed]);
    setCurrentValue("");
  };

  return (
    <div>
      {complete ? (
        <React.Fragment>
          <Typography variant="body2">
            Opportunities have been shared.
          </Typography>
          <Stack alignItems="flex-end" sx={{ mt: "30px" }}>
            <Button
              sx={{ width: 170 }}
              variant="outlined"
              onClick={dialog.closeModal}
            >
              Dismiss
            </Button>
          </Stack>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {props.specificOpportunity ? (
            <Stack sx={{ mb: 3 }} direction="row">
              <StyledTextfield
                disabled
                value={`${window.location.href}`}
                sx={{ flexGrow: 1, height: "40px" }}
              />
              <Button
                variant="contained"
                disabled={disallowed || copied}
                disableRipple
                disableElevation
                sx={{
                  textTransform: "none",
                  borderRadius: "6px",
                  ml: 1,
                  width: "100px",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(`${window.location.href}`);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
              >
                {copied ? <CheckIcon /> : "Copy URL"}
              </Button>
            </Stack>
          ) : null}
          {props.selectAll ? (
            <Typography variant="body2" sx={{ mb: 1 }}>
              {props.allOpportunitiesCount > 1
                ? props.allOpportunitiesCount + " opportunities"
                : "opportunity"}{" "}
              have been selected for sharing.
            </Typography>
          ) : (
            <>
              <Typography variant="body2" sx={{ mb: 1 }}>
                The following{" "}
                {props.idList.length > 1
                  ? props.idList.length + " opportunities"
                  : "opportunity"}{" "}
                will be shared:
              </Typography>
              {props.opportunityList.map((opp, index) => {
                return (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{ opacity: 0.4, ml: 1 }}
                  >
                    · {opp.Account} - {opp.Name}
                  </Typography>
                );
              })}
            </>
          )}
          <Autocomplete
            sx={{ mb: 1, mt: 2 }}
            multiple
            freeSolo
            autoSelect
            fullWidth
            options={users}
            onChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                error={hasError}
                variant="outlined"
                label="Email List"
                placeholder="Add an email..."
              />
            )}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
              <Box {...props} sx={{ height: "60px" }}>
                <Typography sx={{ flexGrow: 1, ml: 1 }}>
                  {`${userInfoMap.get(option)}`}
                </Typography>
                <Typography sx={{ ml: 2, mr: 1, opacity: 0.5 }}>
                  {option}
                </Typography>
              </Box>
            )}
            renderTags={(value, getTagProps) => (
              <TransitionGroup>
                {value.map((option, index) => {
                  return (
                    <Grow key={index}>
                      <Chip
                        variant="filled"
                        label={`${option}`}
                        {...getTagProps({ index })}
                        color={
                          emailSchema.validate(option).error
                            ? "error"
                            : "default"
                        }
                        sx={{ opacity: 0.6 }}
                      />
                    </Grow>
                  );
                })}
              </TransitionGroup>
            )}
            value={emails}
            inputValue={currentValue}
            onInputChange={(event, value) => {
              setStatus("");
              setCurrentValue(value);
            }}
            onKeyDown={(event) => {
              if (
                event.key === "Tab" ||
                event.key === "," ||
                event.key === "Enter" ||
                event.key === " "
              ) {
                if (currentValue.trim().length > 0) {
                  event.preventDefault();
                  addInviteChip();
                } else {
                  event.preventDefault();
                }
              }
            }}
          />
          <FormGroup>
            <FormControlLabel
              checked={shareAll}
              control={
                <Checkbox
                  control={<Checkbox size="small" />}
                  onChange={handleShareAll}
                />
              }
              label={
                <Typography sx={{ fontSize: 14 }}>
                  Share with entire organization
                </Typography>
              }
            />
          </FormGroup>
          <Box
            sx={{
              pl: 1,
              pr: 1,
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="caption"
              sx={{ flexGrow: 1, mt: 1, mr: 2, opacity: 0.6 }}
            >
              {status}
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {disallowed && (
                  <Typography
                    sx={{
                      mb: 1,
                      mt: 2,
                      width: "100%",
                      textAlign: "center",
                    }}
                    variant="caption"
                  >
                    {PLEASE_UPGRADE_TEXT}
                  </Typography>
                )}
                <div
                  style={{
                    display: "flex",
                    mt: "5px",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    sx={{ width: 180, textTransform: "none" }}
                    disabled={
                      disallowed ||
                      ((hasError || emails.length === 0) && !shareAll)
                    }
                    variant="outlined"
                    onClick={shareOpportunities}
                  >
                    Share Opportunities
                  </Button>
                </div>
              </>
            )}
          </Box>
        </React.Fragment>
      )}
    </div>
  );
}
