import React, { useEffect, useState } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import ImageInput from "../util/ImageInput";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { rule5properties } from "../../properties";
import { LoadingButton } from "@mui/lab";

export default function NewsEditor(props) {
  const formStyle = props.formStyle;

  const [loading, setLoading] = useState(false);

  const {
    register,
    control,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "Article",
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  useEffect(() => {
    props.setRemoveCallback(() => remove);
  }, []);

  const title = props.functionalArea ? props.functionalArea + " News" : "News";

  return (
    <div>
      <TitleInput register={register} defaultValue={title} />
      <LoadingButton
        loading={loading}
        style={{ textTransform: "none", marginTop: "2%" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          setLoading(true);
          const params = {
            functionalArea: props.functionalArea,
            cardType: "News",
            companyId: props.companyId,
            companyName: props.buyerName,
            scope: "Global",
          };
          axios
            .post(rule5properties.detNewsOnDemand, params)
            .then((res) => {
              if (res.status === 200) {
                const data = res.data;
                props.setForm((draft) => {
                  draft.id = data.id;
                  draft.isDraftSaved = false;
                  draft.cardInfo = data.cardInfo;
                  draft.version = data.version;
                  draft.status = data.status;
                  draft.originalCardId = data.originalCardId;
                });
                props.setHasPreview(true);
                setLoading(false);
              } else {
                setLoading(false);
                window.alert(
                  `Pipeline response code: ${
                    res.status
                  }. Details: ${JSON.stringify(res.data)}`
                );
              }
            })
            .catch((err) => {
              window.alert("Error retrieving on demand news: " + err);
              setLoading(false);
            });
        }}
      >
        Generate News
      </LoadingButton>
      {fields.map((news, newsIndex) => (
        <div key={news.id}>
          <div className={formStyle}>
            <Controller
              name={`Article[${newsIndex}].IsHighlightedArticle`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  style={{ width: "45px" }}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
            <label>Include in Exec Summary</label>
            Title
            <input {...register(`Article[${newsIndex}].Title`)} />
            Link (required)
            <div>
              <input
                style={{ width: "100%" }}
                {...register(`Article[${newsIndex}].Link`, {
                  required: "Link is required.",
                })}
              />
              {errors?.Article?.[newsIndex]?.Link && (
                <p style={{ color: "red" }}>
                  {errors.Article[newsIndex].Link.message}
                </p>
              )}
            </div>
            Chosen by pipeline?
            <input
              {...register(`Article[${newsIndex}].RelevancyStatus`)}
              disabled
            />
            Short Description
            <TextareaAutosize
              {...register(`Article[${newsIndex}].ShortDescription`)}
              aria-label=""
              minRows={3}
            />
            Thumbnail
            <ImageInput
              name={`Article[${newsIndex}].Thumbnail`}
              control={control}
              setValue={setValue}
            ></ImageInput>
            Sources
            <Sources
              nestIndex={newsIndex}
              {...{ control, register, formStyle }}
            />
            Published Date
            <TextField
              {...register(`Article[${newsIndex}].PublishedDate`)}
              placeholder="mm/dd/yyyy"
              // type="date" //TODO make this a datepicker
            />
            Authors* (required)
            <div>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={[]}
                    onChange={(e, data) => field.onChange(data)}
                    freeSolo
                    autoSelect
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Authors"
                        placeholder="Press enter to add entries"
                      />
                    )}
                  />
                )}
                name={`Article[${newsIndex}].Authors`}
                type="select"
                control={control}
                rules={{
                  required: { value: true, message: "Author is required" },
                }}
              />
              {errors?.Article?.[newsIndex]?.Authors?.type === "required" && (
                <p style={{ color: "red" }}>
                  {errors.Article[newsIndex].Authors.message}
                </p>
              )}
            </div>
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(newsIndex, moveToIndex);
                trigger();
              }}
              index={newsIndex}
              options={[...Array(getValues("Article").length).keys()]}
            ></MoveAutocomplete>
          </div>
          <DeleteButton
            onClick={() => {
              setLastDeleted(news);
              remove(newsIndex);
            }}
          >
            Delete Article
          </DeleteButton>
          <Divider
            light
            style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
          />
        </div>
      ))}
      <AddButton
        onClick={() => {
          appendFuncClickHandler(append, lastDeleted);
        }}
      >
        Add Article
      </AddButton>
    </div>
  );
}

/** Nested form array */
function Sources({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Article.${nestIndex}.Source`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            Name:
            <input
              {...register(`Article.${nestIndex}.Source.${k}.Name`, {
                // required: true
              })}
            />
            URL:
            <input {...register(`Article.${nestIndex}.Source.${k}.URL`)} />
            <DeleteButton onClick={() => remove(k)}>Delete Source</DeleteButton>
          </div>
        );
      })}
      <AddButton
        onClick={() =>
          append({
            Name: "",
            URL: "",
          })
        }
      >
        Add Source
      </AddButton>
    </div>
  );
}

// let testData =
// {
//     "Title": "News",
//     "Article": [
//         {
//             "Title": "Moderna vs. Pfizer: Both Knockouts, but One Seems to Have the Edge",
//             "Link": "https://www.nytimes.com/2021/09/22/health/covid-moderna-pfizer-vaccines.html",
//             "ShortDescription": "A series of studies found that the Moderna vaccine seemed to be more protective as the months passed than the Pfizer-BioNTech vaccine.",
//             "Thumbnail": "https://drive.google.com/file/d/1YdpgLau-a4xB4sw-hckLD-cwKobl0LG_/view?usp=sharing",
//             "Source": [
//                 {
//                     "Name": "New York Times",
//                     "URL": "https://www.nytimes.com"
//                 }
//             ],
//             "PublishedDate": "9/22/2021",
//             "Authors": [
//                 "Apoorva Mandavilli"
//             ]
//         },
//         {
//             "Title": "I want a normal Thanksgiving’: Covid booster shot recipients say of third Pfizer dose",
//             "Link": "https://www.cnbc.com/2021/09/30/pfizer-covid-booster-recipients-want-a-normal-thanksgiving-after-getting-third-shots.html",
//             "ShortDescription": "Four people who received Pfizer’s booster shot said they felt relieved after getting the extra dose and experienced minimal side effects.They said they got the extra doses over the fear that they could expose themselves or their loved ones to the delta variant and become severely sick. “I didn’t want to bring it home to my wife or other members of my family,” California resident Wayne Adams said.",
//             "Thumbnail": "https://drive.google.com/file/d/1chlE8bwsT4bmasDgQXOKL1FFWr_Q2fjW/view?usp=sharing",
//             "Source": [
//                 {
//                     "Name": "CNBC",
//                     "URL": "https://www.cnbc.com"
//                 }
//             ],
//             "PublishedDate": "9/30/2021",
//             "Authors": [
//                 "Berkeley Lovelace Jr."
//             ]
//         }
//     ],
//     "NewUpdate": false
// };
