import React from "react";
import axios from "axios";
import { rule5properties } from "../properties";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useDialog } from "../context/DialogContext";

export default function FlagItem(props) {
  const dialog = useDialog();

  const [userComments, setUserComments] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event) => {
    // Call API to save the comment
    const params = {
      operation: "Edit",
      module: "Research",
      opportunityId: props.opsId,
      cardId: props.cardId,
      comment: userComments,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    setLoading(true);

    axios
      .post(rule5properties.requestDataOPS, params, options)
      .then((response) => {
        if (response.data.ticket_number) {
          setStatus(
            "Successfully posted comment. Ticket number: " +
              response.data.ticket_number
          );
        } else if (response.data.code) {
          setStatus(
            "There was a problem with posting the comment: " +
              response.data.code
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setStatus("There was a problem with posting the comment.");
        }
        setLoading(false);
      });
  };

  const handleTextFieldChange = (event) => {
    setUserComments(event.target.value);
  };

  return (
    <div>
      {status === "" ? (
        <React.Fragment>
          Please provide comments or suggestions regarding this card:
          <TextField
            sx={{ mt: 2 }}
            autoComplete="off"
            rows={3}
            type="text"
            autoFocus
            fullWidth
            multiline
            placeholder="Enter comments here..."
            size="md"
            onChange={(e) => handleTextFieldChange(e)}
          />
          {loading ? (
            <CircularProgress size={36} sx={{ mt: 2, float: "right", mr: 6 }} />
          ) : (
            <Button
              sx={{ mt: 2, width: "140px", float: "right" }}
              onClick={handleSubmit}
              variant="outlined"
            >
              Submit
            </Button>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ opacity: 0.4, mb: 1 }}>{status}</Typography>
          <Button
            sx={{ mt: 2, width: "140px", float: "right" }}
            onClick={dialog.closeModal}
            variant="outlined"
          >
            Dismiss
          </Button>
        </React.Fragment>
      )}
    </div>
  );
}

FlagItem.propTypes = {
  opsId: PropTypes.number.isRequired,
  cardId: PropTypes.number.isRequired,
  // cardType: PropTypes.string.isRequired
};
