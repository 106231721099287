import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Person, { MemoPerson } from "./Person";
import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { aggregateConnections } from "../../../../common/Utils";
import {
  SALESFORCE_CRM_SYSTEM,
  useUser,
} from "../../../../context/UserContext";
import { InputAdornment, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  cardstyle: {
    textAlign: "center",
    backgroundColor: "white",
    boxShadow: "0 3px 3px -2px rgba(0, 0, 0, 0.1)",
  },
  cardTitle: {
    fontSize: 14,
    textAlign: "left",
    color: "#333333",
  },
  cardContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
    textAlign: "left",
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  deleteClass: {
    position: "absolute",
    backgroundColor: "",
    bottom: 0,
    right: -10,
    visibility: "hidden",
  },
  addPersonContent: {
    width: "200px",
    display: "inline-block",
    margin: "20px",
    textAlign: "center",
  },
}));

export default function People(props) {
  const classes = useStyles();
  const theme = useTheme();
  const previewData = props.previewData;
  const user = useUser();

  const [peopleData, setPeopleData] = React.useState(null);
  const [peopleQuery, setPeopleQuery] = React.useState("");
  const [reversed, setReversed] = React.useState(false);

  // eslint-disable-next-line
  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    if (previewData) {
      // Call Stub
      setPeopleData(previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId; // cardId hardcoded for now
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            let rawdata = response.data;
            if (rawdata.connections) {
              rawdata.connections = aggregateConnections(rawdata.connections);
            }
            if (rawdata.Person) {
              // Validate content
              setPeopleData(rawdata);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
  }, [firstTime, previewData]);

  // Add Person Menu Start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Add Person Menu End

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleLinkedInURL = (e) => {
    if (e.keyCode === 13) {
      // Call API to add a Person to list from LinkedIn URL
      // setSnackbarMessage('LinkedIn Profile Added to People List');
      setSnackbarMessage("Add person functionality under construction");
      setOpenSnackbar(true);
      handleClose();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.keyCode === 35 || e.keyCode === 36) {
      // End | Home key
      e.stopPropagation();
    }
  };

  const handleSync = (e) => {
    if (props.opportunity.crmOpportunityId !== null) {
      const params = {
        personIds: peopleData.Person.map((person) => person.personId),
        sfdcAccountId: props.opportunity.crmOpportunityId,
      };
      axios.post(rule5properties.syncContacts, params).then((response) => {
        if (response.status === 200) {
          setSnackbarMessage("People card successfully synced to Salesforce.");
          setOpenSnackbar(true);
        }
      });
    } else if (props.opportunity.crmOpportunityId === null) {
      setSnackbarMessage(
        "This account does not have a Salesforce id assigned to it."
      );
      setOpenSnackbar(true);
    }

    e.stopPropagation();
  };

  // The memo doesn't work very well with previewData because people can be edited
  // in any number of ways and the memo equality check just looks for personId.
  const PersonObj = previewData ? Person : MemoPerson;

  if (!peopleData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={peopleData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
        hasSync={user.crmSystem === SALESFORCE_CRM_SYSTEM}
        handleSync={handleSync}
      >
        <PeopleSearch
          peopleQuery={peopleQuery}
          reversed={reversed}
          setPeopleQuery={setPeopleQuery}
          setReversed={setReversed}
        ></PeopleSearch>
        <div className={classes.flexContainer}>
          {_.chain(_.clone(peopleData.Person))
            .tap(reversed ? _.reverse : _.noop)
            .filter((person) => {
              const searchableFields = _.pick(person, SEARCHABLE);
              if (peopleQuery === "") {
                return true;
              } else if (
                Object.values(searchableFields).some((personField) =>
                  personField
                    ?.toLowerCase()
                    .includes(peopleQuery?.toLowerCase())
                )
              ) {
                return true;
              } else {
                return false;
              }
            })
            .map((row, index) => {
              return (
                <PersonObj
                  key={row.personId}
                  rowIndex={index}
                  row={row}
                  managementQuotesCardId={props.managementQuotesCardId}
                  {...peopleData}
                ></PersonObj>
              );
            })
            .value()}
          <div className={classes.addPersonContent}>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              tabIndex="-1"
            >
              <div style={{ paddingLeft: 10, paddingRight: 10, width: "25em" }}>
                <TextField
                  id="outlined-basic"
                  label="LinkedIn URL"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  type="text"
                  autoFocus
                  placeholder="Enter LinkedIn URL..."
                  onKeyDown={handleLinkedInURL}
                />
              </div>
            </Menu>
            <Tooltip title="Add Person">
              <IconButton style={{ padding: 0 }} onClick={handleClick}>
                <ControlPointIcon
                  style={{
                    width: 50,
                    height: 50,
                    color: theme.cards.peopleCard.addColor,
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}

export function PeopleSearch(props) {
  const { peopleQuery, reversed, setPeopleQuery, setReversed } = props;
  return (
    <div
      style={{
        textAlign: "right",
      }}
    >
      <TextField
        style={{ width: "230px" }}
        placeholder="Search..."
        aria-label="search key people"
        variant="standard"
        defaultValue={peopleQuery}
        value={peopleQuery}
        onChange={(event) => {
          setPeopleQuery(event.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          ...(peopleQuery
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="clear search"
                      size="small"
                      onClick={() => {
                        setPeopleQuery("");
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}),
        }}
      ></TextField>
      <IconButton
        aria-label="sort"
        size="small"
        onClick={() => {
          const myBool = _.clone(reversed);
          setReversed(!myBool);
        }}
      >
        {reversed ? <SouthIcon /> : <NorthIcon />}
      </IconButton>
    </div>
  );
}

export const SEARCHABLE = [
  "FullName",
  "Title",
  "State",
  "City",
  "Country",
  "Department",
];
