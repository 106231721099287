import React, { useRef, useEffect } from "react";
import { Autocomplete, Box, Button, Collapse, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Image from "../../common/Image";
import { StyledTextfield } from "../../common/StyledComponents";

const useStyles = makeStyles(() => ({
  autocomplete: {
    width: "100%",
    maxWidth: "600px",
    height: "auto",
    alignSelf: "center",
    margin: "auto",
  },
  option: {
    backgroundColor: "white",
    boxSizing: "border-box",
    height: 60,
    margin: "5px",
    padding: "10px",
    transition: ".2s",
    borderRadius: "5px",
    textTransform: "none",
  },
  optionText: {
    textTransform: "none",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  listbox: {
    backgroundColor: "",
    padding: 0,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "5px 0px 5px 0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
  optionDomain: {
    marginLeft: "20px",
    textAlign: "right",
    width: "100%",
    paddingTop: "3px",
    paddingRight: "20px",
    opacity: 0.7,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export default function ChatCompaniesAutocomplete(props) {
  const {
    setConversation,
    userOpportunityCompanies,
    setOpenAutocomplete,
    openAutocomplete,
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  const functionalAreasRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    setOpenAutocomplete(false);
    inputRef.current?.focus();
  }, [openAutocomplete]);

  if (!userOpportunityCompanies || userOpportunityCompanies.length === 0) {
    return null;
  }

  return (
    <div>
      <Autocomplete
        filterSelectedOptions
        selectOnFocus
        autoFocus
        openOnFocus={openAutocomplete}
        autoComplete
        autoHighlight
        popupIcon={""}
        classes={{
          option: classes.option,
          listbox: classes.listbox,
        }}
        className={classes.autocomplete}
        onChange={(event, newValue) => {
          setValue(newValue);
          // Set the conversation if this company has only one opportunity.
          // Otherwise use must select FA before conversation gets set.
          if (newValue?.oppties?.length === 1) {
            setConversation((draft) => {
              draft.company = newValue;
              draft.functionalArea = newValue.oppties[0].functionalArea;
            });
          }
        }}
        value={value}
        id="companySearch"
        noOptionsText="No options found."
        getOptionLabel={(option) => option.companyInfo?.name}
        isOptionEqualToValue={(option, value) =>
          option.companyId === value.companyId
        }
        renderOption={(props, option) => {
          return (
            <Box {...props}>
              <div
                style={{
                  minWidth: "30px",
                  display: "flex",
                  marginRight: "16px",
                }}
              >
                {option.companyInfo?.icon ? (
                  <Image
                    style={{ maxHeight: "30px", maxWidth: "30px" }}
                    src={option.companyInfo?.icon}
                  />
                ) : (
                  <img
                    loading="lazy"
                    width="30"
                    src={`https://logo.clearbit.com/${option.companyInfo?.domain}?s=128`}
                    alt=""
                  />
                )}
              </div>
              <Typography
                variant="body1"
                className={classes.optionText}
                sx={{ width: "700px", mt: "2px" }}
              >
                {option.companyInfo?.name}
              </Typography>
              <Typography variant="caption" className={classes.optionDomain}>
                {option.companyInfo?.domain}
              </Typography>
            </Box>
          );
        }}
        ListboxProps={{ style: { maxHeight: 400 } }}
        onKeyDown={(event) => {
          if (event.key === "Tab") {
            // Prevent's default 'Enter' behavior.
          }
        }}
        options={userOpportunityCompanies}
        renderInput={(params) => {
          const { InputLabelProps, ...rest } = params;
          const InputProps = {
            ...params.InputProps,
          };
          return (
            <>
              <StyledTextfield
                inputRef={inputRef}
                autoFocus
                placeholder={
                  "Enter an account name from your available list of accounts in AccountIQ…"
                }
                {...rest}
                {...InputProps}
              />
            </>
          );
        }}
      />
      <Collapse
        in={value?.oppties.length > 1}
        mountOnEnter
        // timeout="auto"
        onEntered={() => {
          functionalAreasRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }}
      >
        <FunctionalAreaSelector
          ref={functionalAreasRef}
          company={value}
          setConversation={setConversation}
        ></FunctionalAreaSelector>
      </Collapse>
    </div>
  );
}

const FunctionalAreaSelector = React.forwardRef(function (props, ref) {
  const { company, setConversation } = props;
  return (
    <Box
      ref={ref}
      {...props}
      sx={{
        display: "flex",
        margin: "auto",
        gap: "10px",
        alignItems: "center",
        maxWidth: "600px",
        flexDirection: "column",
        paddingTop: "10px",
        height: "100%",
        marginBottom: "20px",
      }}
    >
      <Typography color="#525252" fontSize="16px">
        Please select a functional area…
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        }}
      >
        {company?.oppties.map((oppty, index) => (
          <Button
            key={index}
            sx={{ textTransform: "none", flexShrink: 0 }}
            autoFocus={index === 0}
            variant="text"
            disableElevation
            onClick={() =>
              setConversation((draft) => {
                draft.company = company;
                draft.functionalArea = oppty.functionalArea;
              })
            }
          >
            {oppty.functionalArea}
          </Button>
        ))}
      </div>
    </Box>
  );
});
