import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Checkbox,
  FormGroup,
  Stack,
  Typography,
  FormControlLabel,
  Box,
  Button,
  Divider,
  Fade,
  Radio,
  RadioGroup,
} from "@mui/material";
import { StyledTextfield, StyledLabel } from "../common/StyledComponents";
import { linkStyle } from "../common/StyledComponents";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { rule5properties } from "../properties";

export default function Gpt(props) {
  const {
    control,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm();

  const [loaded, setLoaded] = React.useState(false);

  function saveGptSettings() {
    let params = getValues();
    axios.patch(rule5properties.gptPreferences, params).then((res) => {
      reset(params);
    });
  }

  useEffect(() => {
    axios.get(rule5properties.gptPreferences).then((res) => {
      if (res.status === 200) {
        reset(res.data);
        setLoaded(true);
      }
    });
    setLoaded(true);
  }, []);

  return (
    <Fade in={loaded}>
      <Stack sx={{ width: "500px", flexGrow: 1 }}>
        <Typography variant="h5" sx={{ width: "100%", mb: 2, fontWeight: 500 }}>
          GPT Preferences
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Typography variant="settingsHeader">About Yourself</Typography>
            <Typography variant="settingsCaption">
              Personalize and optimize rule5 GPT to better suit your needs.
              Context will be considered when rule5 GPT receives a prompt.
            </Typography>
          </Box>
          <Controller
            name="about_yourself"
            control={control}
            defaultValue={""}
            render={({ field, onChange }) => (
              <StyledTextfield
                multiline
                minRows={4}
                placeholder="I'm an experienced Account Executive with over a decade of experience in automation software sales to mid-size and large enterprise."
                inputProps={{ maxLength: 1000 }}
                {...field}
              />
            )}
          />
          <Divider sx={{ my: 3 }} />
          {/* <Typography sx={{ mb: 2 }} variant="caption">
            Personalize and optimize rule5 GPT to better suit your needs.
          </Typography> */}
          <Box>
            {/* <Typography variant="settingsHeader">
              Default Email-to Persona
            </Typography>
            <Controller
              name="email_preferences.email_to_persona"
              control={control}
              defaultValue={""}
              render={({ field, onChange }) => (
                <StyledTextfield
                  sx={{ mt: 1, mb: 2 }}
                  placeholder="CFO"
                  fullWidth
                  {...field}
                />
              )}
            /> */}

            <Typography variant="settingsHeader">Email Tone</Typography>
            <Typography variant="settingsCaption">
              If not specified in the prompt, emails will attempt to convey the
              selected tone.
            </Typography>

            <Controller
              name="email_preferences.tone"
              control={control}
              defaultValue={""}
              render={({ field, onChange }) => (
                <RadioGroup {...field} sx={{ flexGrow: 1, mb: 2 }}>
                  <FormControlLabel
                    sx={{ fontSize: "10px" }}
                    value={"Professional"}
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                        Professional
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{ fontSize: "10px" }}
                    value={"Casual"}
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                        Casual
                      </Typography>
                    }
                  />
                </RadioGroup>
              )}
            />

            <Typography variant="settingsHeader">Email Length</Typography>
            <Typography variant="settingsCaption">
              If not specified in the prompt, emails will refer to this for a
              default length.
            </Typography>
            <Controller
              name="email_preferences.length"
              control={control}
              defaultValue={""}
              render={({ field, onChange }) => (
                <RadioGroup {...field} sx={{ flexGrow: 1 }}>
                  <FormControlLabel
                    sx={{ fontSize: "10px" }}
                    value={"Concise"}
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                        Concise
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{ fontSize: "10px" }}
                    value={"Descriptive"}
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                        Descriptive
                      </Typography>
                    }
                  />
                </RadioGroup>
              )}
            />
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ mb: 1 }}>
            <Typography variant="settingsHeader">Completion Tone</Typography>
            <Typography variant="settingsCaption">
              If not specified, rule5 GPT will use these settings to determine
              the length and depth of the response.
            </Typography>
          </Box>
          <Controller
            name="prompt_preferences.tone"
            control={control}
            defaultValue={""}
            render={({ field, onChange }) => (
              <RadioGroup {...field} sx={{ flexGrow: 1, mb: 2 }}>
                <FormControlLabel
                  sx={{ fontSize: "10px" }}
                  value={"Professional"}
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                      Professional
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ fontSize: "10px" }}
                  value={"Casual"}
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                      Casual
                    </Typography>
                  }
                />
              </RadioGroup>
            )}
          />
          <Box sx={{ mb: 1 }}>
            <Typography variant="settingsHeader">Completion Length</Typography>
          </Box>
          <Controller
            name="prompt_preferences.length"
            control={control}
            defaultValue={""}
            render={({ field, onChange }) => (
              <RadioGroup {...field} sx={{ mb: 2 }}>
                <FormControlLabel
                  sx={{ fontSize: "10px" }}
                  value={"Concise"}
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                      Concise
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ fontSize: "10px" }}
                  value={"Descriptive"}
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
                      Descriptive
                    </Typography>
                  }
                />
              </RadioGroup>
            )}
          />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ my: 2 }}
          >
            <Button
              disabled={!isDirty}
              type="submit"
              variant="outlined"
              sx={{ textTransform: "none", width: "200px" }}
              onClick={saveGptSettings}
            >
              Save preferences
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Fade>
  );
}
