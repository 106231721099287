import * as React from "react";
import CardTypesChip, { DEFAULT_CARD_TYPES_OBJ } from "../chat/CardTypesChip";
import ContentsChip from "../chat/ContentsChip";

export default function NewPromptOptions(props) {
  const { setPromptParams, initCardTypes, initContent } = props;

  const [selectedCardTypes, setSelectedCardTypes] = React.useState(
    initCardTypes ? initCardTypes : DEFAULT_CARD_TYPES_OBJ
  );
  const [selectedContents, setSelectedContents] = React.useState(
    initContent ? initContent : []
  );

  React.useEffect(() => {
    setPromptParams({
      cardTypes: selectedCardTypes.types,
      contentIds: selectedContents.map((content) => content.contentId),
      cardTypesContext: selectedCardTypes.excludeAll
        ? "Exclude All"
        : selectedCardTypes.types?.length > 0
        ? "Specified"
        : "Include All",
    });
  }, [selectedCardTypes, selectedContents, setPromptParams]);

  return (
    <div
      style={{
        marginLeft: "auto",
        marginBottom: "15px",
        flexBasis: "100%",
        display: "flex",
        gap: "10px",
      }}
    >
      <CardTypesChip
        setSelectedCardTypes={setSelectedCardTypes}
        selectedCardTypes={selectedCardTypes}
      />
      <ContentsChip
        setSelectedContents={setSelectedContents}
        selectedContents={selectedContents}
      />
    </div>
  );
}
