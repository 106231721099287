import React, { useEffect } from "react";
import AuthService from "./AuthService";
import { makeStyles } from "@mui/styles";
import {
  Card,
  Stack,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  StyledTextfield,
  StyledLabel,
  ErrLabel,
} from "../common/StyledComponents";

const Joi = require("joi");

const emailSchema = Joi.string().email({ tlds: { allow: false } });

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100vh",
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formCard: {
    borderRadius: "10px",
    width: 470,
    boxSizing: "border-box",
    padding: 42,
    boxShadow: "0 3px 3px -2px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    minHeight: 230,
    position: "absolute",
    left: "50%",
    top: "50%",
    WebkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  stack: {
    flexGrow: 1,
    width: "100%",
  },
  password: {
    width: "100%",
  },
}));

export default function PasswordReset() {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  // If in reset mode, prompt use to input new password / confirmation. Reset mode is reached when token verification
  // successful.
  const [errorText, setErrorText] = React.useState("");
  const [confirmErrorText, setConfirmErrorText] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [showForms, setShowForms] = React.useState(false);
  const [resetMode, setResetMode] = React.useState(false);
  const [passwordInput, setPasswordInput] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    let searchParam = new URLSearchParams(location.search);
    let resetToken = searchParam.get("reset");
    if (!resetToken) {
      setShowForms(true);
    } else {
      AuthService.verifyPasswordResetToken(resetToken).then((res) => {
        let data = res.data;
        if (res.status === 200) {
          setStatusMessage("Link ");
          setResetMode(true);
          setShowForms(true);
        } else {
          setResetMode(false);
          setShowForms(false);
          setStatusMessage("Password reset link is expired or invalid.");
        }
      });
    }
  }, []);

  const handlePasswordInput = (event) => {
    const newVal = event.target.value;
    const newInput = {
      ...passwordInput,
      [event.target.name]: newVal,
    };
    setPasswordInput(newInput);
  };

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const submitForm = (event) => {
    event.preventDefault();
    setStatusMessage("");
    if (resetMode) {
      // Submitting password + confirmation password
      const regex = /^(?=.*\d)(?=.*[!@?#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (regex.test(passwordInput.password)) {
        if (passwordInput.password === passwordInput.confirmPassword) {
          setLoading(true);
          setShowForms(false);
          setConfirmErrorText("");
          setErrorText("");
          let searchParam = new URLSearchParams(location.search);
          AuthService.submitPasswordReset(
            searchParam.get("reset"),
            passwordInput
          ).then((res) => {
            if (res.status === 200) {
              setStatusMessage(`Password has been reset successfully.`);
            } else if (res.data.code === "PASSWORD_REUSE") {
              setLoading(false);
              setShowForms(true);
              setErrorText("You can't reuse your previous password.");
            } else if (res.data.code === "NOT_FOUND") {
              setLoading(false);
              setShowForms(true);
              setErrorText("Sorry, we don't recognize this email.");
            } else {
              console.log(res);
              setStatusMessage(`${res.data.message}`);
            }
            setLoading(false);
          });
        } else {
          // Password and confirmation does not match.
          setErrorText("");
          setConfirmErrorText("Confirmation password does not match.");
        }
      } else {
        // Password doesn't meet requirements.
        setConfirmErrorText("");
        setErrorText(
          "Password requires minimum 8 characters (one uppercase, one lowercase, one special char, one number)."
        );
      }
    } else {
      // Submitting email address for reset
      if (!emailSchema.validate(email).error) {
        setErrorText("");
        setStatusMessage("");
        setShowForms(false);
        setLoading(true);
        AuthService.requestPasswordReset(email).then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setStatusMessage(
              `A password reset email has been sent to ${email}. This link will expire in 24 hours.`
            );
          } else {
            if (res.data.message) {
              setErrorText(res.data.message);
              setShowForms(true);
            } else {
              setErrorText(`Error occurred: ${res.data.code}`);
              setShowForms(true);
            }
          }
        });
      } else {
        setErrorText("Please enter a valid email address.");
      }
    }
  };

  return (
    <div className={classes.container}>
      <Card variant="outlined" className={classes.formCard}>
        <form className={classes.formContainer} onSubmit={submitForm}>
          <Typography
            className={classes.thinTitle}
            variant="h5"
            sx={{ mt: 1, mb: 1, width: "100%", fontWeight: "500" }}
          >
            Reset Password
          </Typography>
          {/* <Typography sx={{ mb: 2, opacity: 0.8 }} variant="caption">Password must be at least 8 characters and contain one uppercase, one lowercase, one </Typography> */}
          <Stack
            className={classes.stack}
            alignItems="center"
            justifyContent="center"
          >
            {showForms ? (
              <React.Fragment>
                {resetMode ? (
                  <React.Fragment>
                    <Typography
                      sx={{ mt: 3, mb: 1, opacity: 0.5 }}
                      variant="body1"
                    >
                      Enter your new password.
                    </Typography>
                    <TextField
                      error={errorText.length > 0}
                      helperText={errorText}
                      value={passwordInput.password}
                      sx={{ mt: 2 }}
                      className={classes.password}
                      name="password"
                      type="password"
                      autoComplete="off"
                      label="New Password"
                      variant="outlined"
                      margin="dense"
                      onChange={handlePasswordInput}
                    />
                    <TextField
                      error={confirmErrorText.length > 0}
                      helperText={confirmErrorText}
                      value={passwordInput.confirmPassword}
                      sx={{ mt: 2 }}
                      fullWidth
                      name="confirmPassword"
                      type="password"
                      autoComplete="off"
                      label="Confirm Password"
                      variant="outlined"
                      margin="dense"
                      onChange={handlePasswordInput}
                    />
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        type="submit"
                        sx={{ mt: 3, width: 130, textTransform: "none" }}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography
                      sx={{ mt: 1, mb: 1, opacity: 0.5 }}
                      variant="body1"
                    >
                      Forgot your password? Enter your account's registered
                      email address to receive a link to reset your password.
                    </Typography>
                    <StyledLabel sx={{ width: "100%", ml: 1, mt: 2 }}>
                      Email Address
                    </StyledLabel>
                    <StyledTextfield
                      error={errorText.length > 0}
                      value={email}
                      fullWidth
                      label="Email Address"
                      variant="outlined"
                      margin="dense"
                      onChange={handleEmailInput}
                    />
                    <ErrLabel sx={{ width: "96%", textAlign: "right" }}>
                      {errorText}
                    </ErrLabel>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        type="submit"
                        sx={{ mt: 3, width: 130, textTransform: "none" }}
                        variant="outlined"
                      >
                        Submit
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {statusMessage === "" ? (
                  <CircularProgress />
                ) : (
                  <React.Fragment>
                    <Typography
                      sx={{ mt: 3, mb: 1, opacity: 0.5, width: "100%" }}
                      variant="body1"
                    >
                      {statusMessage}
                    </Typography>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Button
                        sx={{ mt: 2, mb: 1, textTransform: "none" }}
                        variant="outlined"
                      >
                        Return to Login
                      </Button>
                    </Link>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Stack>
        </form>
      </Card>
    </div>
  );
}
