import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  IconButton,
} from "@mui/material";
import {
  PopperComponent,
  StyledPopper,
  StyledTextfield,
} from "../../common/StyledComponents";
import { cloneDeep, isEmpty, isEqual, sortBy } from "lodash";
import axios from "axios";
import { rule5properties } from "../../properties";
import useSnack from "../../context/Snack";
import { isSuccessStatus } from "../../common/RequestUtils";
import CloseIcon from "@mui/icons-material/Close";
import { ALLOWED_ACTIONS, useUser } from "../../context/UserContext";

const CARD_TYPES_TO_REMOVE = ["Recommended Actions", "Methodology"];
export const DEFAULT_CARD_TYPES_OBJ = { types: [], excludeAll: false };

function filterCardTypes(cardTypes) {
  return cardTypes?.filter(
    (cardType) =>
      !CARD_TYPES_TO_REMOVE.some((toRemove) => {
        return cardType === toRemove;
      })
  );
}

const CardTypesChip = React.memo(function CardTypesChip(props) {
  const { selectedCardTypes, setSelectedCardTypes, opportunityId } = props;

  const filteredSelection = filterCardTypes(selectedCardTypes?.types);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cardTypes, setCardTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [displayedSelection, setDisplayedSelection] =
    React.useState(filteredSelection);

  const snackBar = useSnack();
  const user = useUser();

  let label = "Considering all card types";
  if (selectedCardTypes?.excludeAll) {
    label = "Considering 0 card types";
  } else if (filteredSelection?.length > 0) {
    label = `Considering ${filteredSelection?.length} card type${
      filteredSelection?.length === 1 ? "" : "s"
    }`;
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (isEmpty(cardTypes)) {
      return;
    }

    if (!selectedCardTypes.excludeAll && filteredSelection.length === 0) {
      // Include all
      setDisplayedSelection(cardTypes);
    } else {
      // Specificed or exclude all
      setDisplayedSelection(filteredSelection);
    }
  }, [opportunityId, cardTypes, selectedCardTypes]);

  React.useEffect(() => {
    let endpoint = rule5properties.getCardTypes;
    if (opportunityId) {
      endpoint =
        rule5properties.getOpportunityList + "/" + opportunityId + "/research";
    }
    if (open) {
      setLoading(true);
      setCardTypes([]);
      axios
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          if (isSuccessStatus(response.status)) {
            // Account for different endpoints having fields named cardType vs type
            const cardTypeArray = response.data.map((cardType) =>
              cardType.cardType ? cardType.cardType : cardType.type
            );
            const availableCardTypes = filterCardTypes(response.data);
            setCardTypes(
              sortAndMoveSelectedItems(
                filterCardTypes(cardTypeArray.sort()),
                selectedCardTypes.types
              )
            );
            // todo below attempt at filtering options based on availability not working
            if (opportunityId) {
              const selectedCopy = cloneDeep(selectedCardTypes);
              setSelectedCardTypes({
                ...selectedCopy,
                types: selectedCopy.types?.filter((givenType) =>
                  availableCardTypes.some(
                    (foundType) => givenType === foundType.cardType
                  )
                ),
              });
            }
          } else {
            snackBar.createSnack("Unexpected error loading card types.");
          }
        })
        .catch(function (error) {
          setLoading(false);
          snackBar.createSnack("Unexpected error loading card types.");
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
          }
        });
    }
  }, [open]);

  if (
    !user.userSubscription?.allowedActions?.includes(
      ALLOWED_ACTIONS.viewResearch
    )
  ) {
    return null;
  }

  return (
    <>
      <Chip
        size="small"
        label={label}
        variant="outlined"
        onClick={handleClick}
        style={{
          fontSize: "12px",
          height: "24px",
          width: "fit-content",
          borderColor: "#b0c7da",
          color: "#525252",
        }}
      />
      <StyledPopper
        anchorEl={anchorEl}
        open={open}
        id={"Card-types-select"}
        placement="top-start"
        sx={{ width: "450px" }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "750px",
              width: "100%",
            }}
          >
            <Autocomplete
              onChange={(event, newSelectedCardTypes) => {
                const newSelection = newSelectedCardTypes.map((cardType) =>
                  cardType?.cardType ? cardType.cardType : cardType
                );

                if (isEqual(sortBy(newSelection), sortBy(cardTypes))) {
                  // "Considering all cards"
                  setSelectedCardTypes(DEFAULT_CARD_TYPES_OBJ);
                } else {
                  // Specified or exclude all case
                  setSelectedCardTypes({
                    types: newSelection,
                    excludeAll: newSelection.length === 0,
                  });
                }
              }}
              value={displayedSelection}
              loading={loading}
              multiple
              PopperComponent={PopperComponent}
              selectOnFocus
              open
              clearIcon={false}
              clearOnBlur
              handleHomeEndKeys
              id="card-types-autocomplete"
              options={cardTypes}
              popupIcon={""} // Remove the little dropdown arrow
              noOptionsText="No card types found."
              onClose={(event, reason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              renderTags={() => {}}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              sx={{ width: "450px" }}
              renderInput={(params) => {
                const { InputLabelProps, ...rest } = params;
                const InputProps = {
                  ...params.InputProps,
                };
                return (
                  <>
                    <CloseBox handleClose={handleClose} />
                    <StyledTextfield
                      autoFocus
                      placeholder={"Search card types…"}
                      {...rest}
                      {...InputProps}
                      {...params}
                    />
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid gainsboro",
                        justifyContent: "space-between",
                        padding: "5px 10px",
                      }}
                    >
                      <Button
                        disableRipple
                        variant="flat"
                        size="small"
                        disabled={isEmpty(displayedSelection)}
                        color="rgb(107,149,185)"
                        sx={{ color: "#506f8a" }}
                        onClick={() => {
                          setSelectedCardTypes({ types: [], excludeAll: true });
                        }}
                      >
                        Clear all
                      </Button>
                      <Button
                        disableRipple
                        variant="flat"
                        disabled={
                          isEmpty(cardTypes) ||
                          isEqual(sortBy(cardTypes), sortBy(displayedSelection))
                        }
                        size="small"
                        sx={{ color: "#506f8a" }}
                        onClick={() => {
                          setSelectedCardTypes(DEFAULT_CARD_TYPES_OBJ);
                        }}
                      >
                        Select all
                      </Button>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
});

export default CardTypesChip;

export function CloseBox(props) {
  const { handleClose } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        height: "40px",
      }}
    >
      <IconButton
        sx={{ marginRight: "10px", height: "fit-content" }}
        size="small"
        aria-label="close"
        color="#525252"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}

function sortAndMoveSelectedItems(options, selected) {
  // Create a set for faster lookup.
  const selectedSet = new Set(selected);

  options.sort((a, b) => {
    const aInSelected = selectedSet.has(a);
    const bInSelected = selectedSet.has(b);

    // Move common items to the top.
    if (aInSelected && !bInSelected) {
      return -1;
    } else if (!aInSelected && bInSelected) {
      return 1;
    } else {
      return 0;
    }
  });

  return options;
}

export function getSelectedCardTypesFromConversationPrompt(conversationPrompt) {
  const cardTypes = conversationPrompt?.cardTypes;
  return {
    types:
      cardTypes &&
      !(
        conversationPrompt?.includeAllCards ||
        conversationPrompt?.excludeAllCards
      )
        ? cardTypes
        : [],
    excludeAll: conversationPrompt?.excludeAllCards,
  };
}
