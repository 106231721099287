import React from "react";
import axios from "axios";
import { useEffect } from "react";
import Researchcard from "./Researchcard";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { QuestionAndAnswerContent } from "./AnnualReports";
import { Typography } from "@mui/material";
import {
  InvertedTable,
  InvertedTableKey,
  InvertedTableRow,
  InvertedTableValue,
} from "../../../../common/StyledComponents";
import { numberAbbrev } from "../../../../common/Utils";
import { isEmpty, isFinite } from "lodash";

export default function CompanyInsights(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  const [companyInsightsData, setCompanyInsightsData] = React.useState(null);

  useEffect(() => {
    if (previewData) {
      setCompanyInsightsData(previewData);
    } else {
      // Call API on initial load
      let parameter = "/" + props.cardId;
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          // Validate content
          setCompanyInsightsData(rawdata);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        });
    }
  }, [previewData]);

  if (!companyInsightsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }
  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={companyInsightsData.Title}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <Funding funding={companyInsightsData.funding} />
      <JobOpenings job_openings={companyInsightsData.job_openings} />
      {companyInsightsData?.insights?.map((insight, index) => (
        <QuestionAndAnswerContent
          item={insight}
          key={index}
        ></QuestionAndAnswerContent>
      ))}

      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}

function JobOpenings(props) {
  const { job_openings } = props;

  if (isEmpty(job_openings)) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        margin: "10px",
      }}
    >
      <Typography
        variant="body1"
        fontSize="16px"
        color="#525252"
        marginBottom="10px"
        fontWeight="600"
      >
        Job openings
      </Typography>
      <InvertedTable>
        {job_openings?.map((job_opening, index) => {
          return (
            <InvertedTableRow key={index}>
              <InvertedTableKey>{job_opening.department}</InvertedTableKey>
              <InvertedTableValue>
                {numberAbbrev(null, job_opening.open_positions)}
              </InvertedTableValue>
            </InvertedTableRow>
          );
        })}
      </InvertedTable>
    </div>
  );
}

function Funding(props) {
  const { funding } = props;

  if (isEmpty(funding)) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        margin: "10px",
      }}
    >
      <Typography
        variant="body1"
        fontSize="16px"
        color="#525252"
        marginBottom="10px"
        fontWeight="600"
      >
        Funding
      </Typography>
      <InvertedTable sx={{ gridTemplateColumns: "1fr", maxWidth: "500px" }}>
        {isFinite(parseFloat(funding.total_funding_amount?.amount)) && (
          <InvertedTableRow>
            <InvertedTableKey>Total funding amount</InvertedTableKey>
            <InvertedTableValue sx={{ width: "280px" }}>
              {numberAbbrev(
                funding.total_funding_amount.currency,
                funding.total_funding_amount.amount
              )}
            </InvertedTableValue>
          </InvertedTableRow>
        )}
        {isFinite(parseFloat(funding.valuation?.amount)) && (
          <InvertedTableRow>
            <InvertedTableKey>Valuation</InvertedTableKey>
            <InvertedTableValue sx={{ width: "280px" }}>
              {numberAbbrev(
                funding.valuation.currency,
                funding.valuation.amount
              )}
            </InvertedTableValue>
          </InvertedTableRow>
        )}
        {typeof funding.funding_status === "string" &&
          funding.funding_status.length > 0 && (
            <InvertedTableRow>
              <InvertedTableKey>Status</InvertedTableKey>
              <InvertedTableValue sx={{ width: "280px" }}>
                {funding.funding_status}
              </InvertedTableValue>
            </InvertedTableRow>
          )}
        {/* {funding.funding_round && (
          <InvertedTableRow>
            <InvertedTableKey>Last funding round</InvertedTableKey>
            <InvertedTableValue sx={{ width: "280px" }}>
              {funding.funding_round}
            </InvertedTableValue>
          </InvertedTableRow>
        )} */}
        {isFinite(parseFloat(funding.last_funding_amount?.amount)) && (
          <InvertedTableRow>
            <InvertedTableKey>Last funding amount</InvertedTableKey>
            <InvertedTableValue sx={{ width: "280px" }}>
              {numberAbbrev(
                funding.last_funding_amount.currency,
                funding.last_funding_amount.amount
              )}
            </InvertedTableValue>
          </InvertedTableRow>
        )}
        {typeof funding.last_funding_date === "string" &&
          funding.last_funding_date.length > 0 && (
            <InvertedTableRow>
              <InvertedTableKey>Last funding date</InvertedTableKey>
              <InvertedTableValue sx={{ width: "280px" }}>
                {funding.last_funding_date}
              </InvertedTableValue>
            </InvertedTableRow>
          )}
      </InvertedTable>
    </div>
  );
}
