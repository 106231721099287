import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import { rule5properties } from "../../../../properties";
import {
  Autocomplete,
  Box,
  Button,
  Fade,
  IconButton,
  Popper,
} from "@mui/material";
import {
  StyledLabel,
  StyledNumericInput,
  StyledTextfield,
} from "../../../../common/StyledComponents";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import { cloneDeep, isEmpty, isEqual, isNumber, omit, sortBy } from "lodash";
import { detRoles } from "../../../../det/DetMainPage";
import {
  SALESFORCE_CRM_SYSTEM,
  useUser,
} from "../../../../context/UserContext";
import useSnack from "../../../../context/Snack";
import {
  CURRENCIES,
  isValidAmount,
  numberAbbrev,
} from "../../../../common/Utils";
import { useHistory } from "react-router-dom";
import { isSuccessStatus } from "../../../../common/RequestUtils";

const useStyles = makeStyles(() => ({
  item: {
    "& label": {
      textAlign: "left",
    },
  },
  numericValsContainer: {
    display: "grid",
    position: "relative",
    "&:hover": {
      "& $numericClearButton": {
        visibility: "visible",
      },
    },
  },
  numericClearButton: {
    position: "absolute",
    right: "15px",
    top: "9px",
    visibility: "hidden",
  },
}));

export default function Discovery(props) {
  const [discoveryData, setDiscoveryData] = React.useState(null);
  const [newData, setNewData] = React.useState(null);

  const user = useUser();
  const snackBar = useSnack();
  const history = useHistory();
  const methods = useFormContext(); // For Newton flow only

  const getCardData = () => {
    const cardDataParams = new URLSearchParams({
      cardType: "Discovery",
      ...(props.functionalArea && props.functionalArea !== "NA"
        ? { functionalArea: props.functionalArea }
        : {}),
      companyId: props.companyId,
      sellerOrgId: props.sellerOrgId,
      expandedOutput: false,
    });

    return axios
      .get(rule5properties.detCardData + "?" + cardDataParams)
      .catch((err) => {
        console.log(err);
        snackBar.createSnack(
          "There was a problem loading Discovery content: " + err
        );
      });
  };

  useEffect(() => {
    if (props.previewData) {
      // Newton flow where a version already exists in Newton.
      if (!props.forResearchPreview) {
        getCardData().then((resp) => {
          if (resp.status !== 200) {
            snackBar.createSnack(
              "Error fetching latest discovery card data: " + resp.data.message
            );
          }
          const sortedNewCardData = sortBy(
            resp.data?.discoveryInfo,
            "question"
          );
          const sortedCurrentCardData = sortBy(
            props.previewData?.discoveryInfo,
            "question"
          );
          // Use isEqual to compare arrays after omitting the answers field
          if (
            !isEqual(
              sortedNewCardData.map((obj) => omit(obj, "answers")),
              sortedCurrentCardData.map((obj) => omit(obj, "answers"))
            )
          ) {
            setNewData(resp.data);
          }
          setDiscoveryData(props.previewData);
        });
      } else {
        snackBar.createSnack(
          "Discovery card cannot be viewed in research preview. Please click the edit button next to the card to view its preview"
        );
      }
    } else if (props.cardId) {
      // Core app flow.
      let parameter = "/" + props.cardId;
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          if (rawdata.discoveryInfo) {
            // Validate content
            setDiscoveryData(rawdata);
          } else {
            // API call failed
            snackBar.createSnack(
              "There was a problem with the " + props.cardType + " Card"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            snackBar.createSnack(
              "There was a problem with the " + props.cardType + " Card"
            );
          }
        });
    } else {
      // Newton new card flow.
      getCardData().then((resp) => {
        if (resp.status !== 200) {
          snackBar.createSnack(
            "Unable to create discovery card: " + resp.data.message
          );
          props.setForm((draft) => {
            draft.isDraftSaved = true; // just a hack to get around prompt message about "are you sure you want to leave?"
          });
          history.push(`/det/managecards`);
          return;
        }
        methods?.reset(resp.data);
      });
    }
  }, []);

  /** Sync info with Salesforce. Only applies in core app. */
  const handleSync = (e) => {
    const params = {
      accountId: props?.opportunity?.account?.accountId,
      cardId: props.cardId,
    };

    axios
      .post(rule5properties.sfdcSync, params)
      .then((response) => {
        if (isSuccessStatus(response.status)) {
          snackBar.createSnack(
            "Discovery card successfully synced to Salesforce."
          );
        } else {
          snackBar.createSnack(
            "Error syncing to Salesforce: " + response?.data?.message
          );
        }
      })
      .catch((error) => snackBar.createSnack("Error syncing to Salesforce."));

    e.stopPropagation();
  };

  function mergeNewData() {
    let newDataCopy = cloneDeep(newData);
    newDataCopy.discoveryInfo?.forEach((newItem) => {
      const existing = props.previewData?.discoveryInfo?.find(
        (oldItem) => oldItem.question === newItem.question
      );
      if (!isEmpty(existing?.answers)) {
        newItem.answers = existing.answers;
      }
    });

    setNewData(null);
    // TODO would be nice if could somehow set the form to dirty while resetting
    methods?.reset(newDataCopy, { keepDirty: true });
  }

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={discoveryData?.Title || methods?.watch("Title")}
      closeCard={props.closeCard}
      viewed={props.viewed}
      hasSync={
        user.crmSystem === SALESFORCE_CRM_SYSTEM &&
        props.cardId &&
        props?.opportunity?.account?.accountId
      }
      handleSync={handleSync}
    >
      {!detRoles.includes(user.role) ? (
        discoveryData && (
          <FormWrappedContent
            discoveryData={discoveryData}
            {...props}
          ></FormWrappedContent>
        )
      ) : methods ? (
        <>
          {newData && detRoles.includes(user.role) && (
            <Button
              variant="contained"
              sx={{ marginBottom: "5px" }}
              onClick={mergeNewData}
            >
              Merge new data
            </Button>
          )}
          <DiscoveryContent />
        </>
      ) : (
        "Discovery card cannot be previewed. Please try again from within the card editor."
      )}
    </Researchcard>
  );
}

function DiscoveryContent(props) {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { fields } = useFieldArray({
    control: control,
    name: "discoveryInfo",
  });

  const classes = useStyles();
  const amountItems = watch("discoveryInfo")?.filter(
    (item) => item.dataType === "amount"
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        justifyContent: "left",
        gap: "25px",
      }}
    >
      <>
        {!isEmpty(amountItems) && (
          <Controller
            render={({ field }) => (
              <Autocomplete
                {...field}
                autoComplete
                autoHighlight
                openOnFocus
                blurOnSelect
                includeInputInList
                onChange={(e, data) => field.onChange(data?.value)}
                className={classes.autocomplete}
                noOptionsText={"No options found."}
                classes={{
                  option: classes.option,
                  listbox: classes.listbox,
                }}
                renderOption={(props, option) => {
                  return (
                    <Box {...props}>
                      <Typography
                        variant="body1"
                        className={classes.optionText}
                      >
                        {option.name || option}
                      </Typography>
                    </Box>
                  );
                }}
                ListboxProps={{ style: { maxHeight: 350 } }}
                PopperComponent={CustomPopper}
                options={CURRENCIES.map((option) => option)}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value;
                }}
                getOptionLabel={(option) =>
                  option.name
                    ? option.name
                    : CURRENCIES.find((currency) => currency.value === option)
                        ?.name || ""
                }
                renderInput={(params) => {
                  const { InputLabelProps, InputProps, ...rest } = params;
                  return (
                    <React.Fragment>
                      <StyledLabel style={{ textAlign: "left" }}>
                        Currency
                      </StyledLabel>
                      <StyledTextfield {...params.InputProps} {...rest} />
                      {errors?.currency?.type === "validate" && (
                        <p style={{ color: "red" }}>
                          Currency is required when setting amount fields.
                        </p>
                      )}
                    </React.Fragment>
                  );
                }}
              />
            )}
            name="currency"
            control={control}
            rules={{
              validate: (value) => {
                return Boolean(
                  !amountItems.some((item) => !isEmpty(item.answers)) || value
                );
              },
            }}
          />
        )}
        {fields.map((item, itemIndex) => (
          <DiscoveryItem item={item} key={item.id} itemIndex={itemIndex} />
        ))}
      </>
    </div>
  );
}

function DiscoveryItem(props) {
  const { item, itemIndex } = props;
  const { watch, setValue, control } = useFormContext();
  const classes = useStyles();

  const answersField = `discoveryInfo[${itemIndex}].answers`;
  const minValueForAbbrevLabel = 1_000_000;

  switch (item.dataType) {
    case "textbox":
      return (
        <div className={classes.item}>
          <StyledLabel htmlFor={item.id}>{item.question}</StyledLabel>
          <Controller
            render={({ field }) => (
              <StyledTextfield
                {...field}
                id={item.id}
                size="small"
                fullWidth
                value={field.value?.[0] || ""}
                onChange={(e, data) => {
                  let out = [e.target.value];
                  field.onChange(out);
                }}
                sx={{ mb: 1 }}
                endAdornment={
                  <IconButton
                    size="small"
                    sx={{
                      visibility: !isEmpty(watch(answersField))
                        ? "visible"
                        : "hidden",
                    }}
                    onClick={() => setValue(answersField, "")}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                }
              />
            )}
            name={answersField}
            control={control}
          />
        </div>
      );
    case "bigTextbox":
      return (
        <div
          className={classes.item}
          style={{
            gridColumnStart: "1",
            gridColumnEnd: "-1",
          }}
        >
          <StyledLabel htmlFor={item.id}>{item.question}</StyledLabel>
          <Controller
            render={({ field }) => (
              <StyledTextfield
                {...field}
                id={item.id}
                multiline
                style={{ minHeight: "80px", alignItems: "start" }}
                fullWidth
                value={field.value?.[0] || ""}
                onChange={(e, data) => {
                  let out = [e.target.value];
                  field.onChange(out);
                }}
                sx={{ mb: 1 }}
                endAdornment={
                  <IconButton
                    size="small"
                    sx={{
                      visibility: !isEmpty(watch(answersField))
                        ? "visible"
                        : "hidden",
                    }}
                    onClick={() => setValue(answersField, "")}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                }
              />
            )}
            name={answersField}
            control={control}
          />
        </div>
      );
    case "dropdownList":
      return (
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              autoComplete
              autoHighlight
              multiple={item.multiple}
              openOnFocus
              value={item.multiple ? field.value : field.value?.[0] || ""}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              blurOnSelect
              onChange={(e, data) => {
                let out = data;
                if (!item.multiple) {
                  // Storing the values as arrays even if it's not a multiple-value input.
                  if (data) {
                    out = [data];
                  } else {
                    out = [];
                  }
                }
                // No idea really why timeout is needed here. form's values don't get updated consistently wihtout it.
                setTimeout(() => {
                  field.onChange(out);
                }, 10);
              }}
              className={classes.autocomplete}
              noOptionsText={"No options found."}
              classes={{
                option: classes.option,
                listbox: classes.listbox,
              }}
              renderOption={(props, option) => {
                return (
                  <Box {...props}>
                    <Typography variant="body1" className={classes.optionText}>
                      {option}
                    </Typography>
                  </Box>
                );
              }}
              ListboxProps={{ style: { maxHeight: 350 } }}
              PopperComponent={CustomPopper}
              options={item.values}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <div className={classes.item}>
                    <StyledLabel>{item.question}</StyledLabel>
                    <StyledTextfield {...params.InputProps} {...rest} />
                  </div>
                );
              }}
            />
          )}
          name={answersField}
          control={control}
        />
      );
    case "amount":
      return (
        <div className={classes.item}>
          <div className={classes.numericValsContainer}>
            <StyledLabel>{item.question}</StyledLabel>
            <Controller
              render={({ field }) => (
                <div className={classes.numericValsContainer}>
                  <StyledNumericInput
                    step={100000000}
                    allowDecimals={false}
                    onValueChange={(value) => {
                      const intVal = parseInt(value);
                      if (!isValidAmount(intVal)) {
                        return field.onChange([]);
                      }
                      let out = [intVal];
                      field.onChange(out);
                    }}
                    value={isNumber(field?.value?.[0]) ? field.value[0] : ""}
                    intlConfig={{
                      locale: "en-US",
                      currency: watch("currency"),
                    }}
                  ></StyledNumericInput>
                  <IconButton
                    size="small"
                    className={classes.numericClearButton}
                    onClick={() => setValue(answersField, [])}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
              name={answersField}
              control={control}
            />{" "}
            {watch(answersField) > minValueForAbbrevLabel && (
              <Typography variant="caption" className={classes.moneyValLabel}>
                {numberAbbrev(watch("currency"), watch(answersField))}
              </Typography>
            )}
          </div>
        </div>
      );
    default:
      console.log("unexpected dataType: " + item.dataType);
      return null;
  }
}

const CustomPopper = (props) => {
  return (
    <Popper {...props} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={{ enter: 400, exit: 250 }}>
          {props.children}
        </Fade>
      )}
    </Popper>
  );
};

/** For use in core app. Not needed in DET because the card editor form already wraps the content in that case. */
function FormWrappedContent(props) {
  const methods = useForm({
    defaultValues: props.discoveryData,
    mode: "onChange",
  });
  const snackBar = useSnack();
  const user = useUser();

  const onSubmit = (data) => {
    const params = {
      id: props.cardId,
      card_info: data,
    };

    axios
      .patch(rule5properties.getCard, params)
      .then((response) => {
        if (response.status === 200) {
          methods.reset(data);
          if (user.crmSystem === SALESFORCE_CRM_SYSTEM) {
            // Try to sync to sfdc. Unknown at this point if seller preferences are setup, so it may fail.
            // Ignore errors in this case.
            const params = {
              accountId: props?.opportunity?.account?.accountId,
              cardId: props.cardId,
            };

            axios.post(rule5properties.sfdcSync, params).then((response) => {
              // Don't tell the user if sync was not successful.
              if (isSuccessStatus(response.status)) {
                snackBar.createSnack(
                  "Discovery updates successfully saved and synced to Salesforce."
                );
              } else {
                snackBar.createSnack("Discovery updates successfully saved.");
              }
            });
          } else {
            snackBar.createSnack("Discovery updates successfully saved.");
          }
        } else {
          snackBar.createSnack(
            "There was a problem updating the Discovery card."
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          // Caught exception could come from either endpoint. Usually means Internal Server Error.
          snackBar.createSnack(
            "There was a problem updating the Discovery card."
          );
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(onSubmit)}>
        <DiscoveryContent {...props} />
        <Button
          type="submit"
          variant="contained"
          disabled={!methods.formState.isDirty}
          disableElevation
          sx={{
            textTransform: "none",
            margin: "15px",
            alignSelf: "center",
          }}
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
}
