import {
  Autocomplete,
  TextField,
  Typography,
  Chip,
  Button,
  Grow,
  Box,
  CircularProgress,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { rule5properties } from "../properties";
import React, { useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import axios from "axios";
import { useDialog } from "../context/DialogContext";
import { COREAPP_USER_ROLES, useUser } from "../context/UserContext";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { map } from "lodash";

const Joi = require("joi");

const emailSchema = Joi.string().email({ tlds: { allow: false } });

export default function InviteUsers(props) {
  const dialog = useDialog();
  const user = useUser();

  const [inviteApp, setInviteApp] = React.useState(true);
  const [inviteExtension, setInviteExtension] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const [hasError, setHasError] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [currentValue, setCurrentValue] = React.useState("");
  const [role, setRole] = React.useState("");

  useEffect(() => {
    checkChipsValid(emails);
  }, [emails]);

  const checkChipsValid = (chipsArray) => {
    let hasError = false;
    chipsArray.forEach((val) => {
      if (emailSchema.validate(val).error) {
        hasError = true;
      }
    });
    if (hasError) {
      setHasError(true);
    } else {
      setHasError(false);
    }
    if (hasError) {
      return false;
    }
    return true;
  };

  const handleInputChange = (event, value) => {
    setStatus("");
    checkChipsValid(value);
    setEmails(value);
  };

  const submitEmails = (event, value) => {
    const optionsArray = [];
    if (inviteApp) {
      optionsArray.push("app");
    }
    if (inviteExtension) {
      optionsArray.push("extension");
    }
    let tempInviteList = [];
    if (currentValue.trim() !== "") {
      tempInviteList = [...emails, currentValue];
      addInviteChip();
      if (!checkChipsValid(tempInviteList)) {
        // Current value is an invalid email.
        return;
      }
    } else {
      tempInviteList = emails;
    }
    let params = {
      emailOptions: optionsArray,
      inviteList: tempInviteList.map((val) => {
        return {
          email: val,
          ...(user.role === COREAPP_USER_ROLES.orgAdmin ? { role: role } : {}),
        };
      }),
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    // Call API to send emails.
    setLoading(true);
    axios
      .post(rule5properties.inviteEmailList, params, options)
      .then((res) => {
        if (res.status === 200) {
          let filteredList = filterOffDomainEmails(
            tempInviteList,
            user.email.split("@")[1]
          );
          // Call offDomainConnectionRequests if inviting to extension.
          if (inviteExtension && filteredList.length > 0) {
            axios
              .post(
                rule5properties.offDomainConnectionRequests,
                { contributorEmailList: filteredList },
                options
              )
              .then((res) => {
                if (res.status === 200) {
                  setComplete(true);
                } else {
                  setStatus(res.data.code);
                }
              })
              .catch((err) => {
                setStatus(err.response.data.message);
                setLoading(false);
              });
          } else {
            setComplete(true);
          }
        } else {
          if (res.data.code === "INVALID_ARGUMENT") {
            setStatus(res.data.message);
          }
          if (res.data.code === "USER_EXISTS") {
            setStatus(
              "One or more of the provided users is already registered with rule5."
            );
          }
          if (res.data.code === "VALIDATION_ERROR") {
            setStatus(
              "There was an issue with one or more of the provided emails."
            );
          }
          if (res.data.code === "UNAUTHORIZED") {
            setStatus("User is not authorized to create invitations.");
          }
          if (!res.data.code) {
            setStatus("Error occurred.");
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response?.data.message === "DOMAIN_MISMATCH") {
            setStatus(
              `Invited users must belong to the ${
                user.email.split("@")[1]
              } domain.`
            );
          } else {
            setStatus(err.response?.data.message);
          }
          setLoading(false);
        }
      });
  };

  const filterOffDomainEmails = (list, domain) => {
    let filtered = [];
    list.forEach((val) => {
      if (val.split("@")[1] !== domain) {
        filtered.push(val);
      }
    });
    return filtered;
  };

  const addInviteChip = () => {
    let trimmed = currentValue.trim();
    setEmails([...emails, trimmed]);
    setCurrentValue("");
  };

  return (
    <div>
      {complete ? (
        <React.Fragment>
          <Typography variant="body2">
            Invitations have been sent. Thank you for sharing the goodness!
          </Typography>
          <Stack alignItems="flex-end" sx={{ mt: "30px" }}>
            <Button
              sx={{ width: 170 }}
              variant="outlined"
              onClick={dialog.closeModal}
            >
              Dismiss
            </Button>
          </Stack>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant="body2">
            The following users will receive email invitations to register for
            rule5 under your organization.
          </Typography>
          <Autocomplete
            sx={{ mb: 2, mt: 4 }}
            freeSolo
            multiple
            fullWidth
            options={[]}
            onChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                error={hasError}
                variant="outlined"
                label="Invitation List"
                placeholder="Add an email..."
              />
            )}
            renderTags={(value, getTagProps) => (
              <TransitionGroup>
                {value.map((option, index) => {
                  return (
                    <Grow key={index}>
                      {emailSchema.validate(option).error ? (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                          color="error"
                          sx={{ opacity: 0.6 }}
                        />
                      ) : (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                          color="default"
                        />
                      )}
                    </Grow>
                  );
                })}
              </TransitionGroup>
            )}
            value={emails}
            inputValue={currentValue}
            onInputChange={(event, value) => {
              setStatus("");
              setCurrentValue(value.trim());
            }}
            onKeyDown={(event) => {
              if (
                event.key === "Tab" ||
                event.key === "," ||
                event.key === "Enter" ||
                event.key === " "
              ) {
                if (currentValue.trim().length > 0) {
                  event.preventDefault();
                  addInviteChip();
                } else {
                  event.preventDefault();
                }
              }
            }}
          />
          <FormGroup>
            {user.role === COREAPP_USER_ROLES.orgAdmin && (
              <FormControl
                variant="standard"
                required
                sx={{ width: 250, mb: 1 }}
              >
                <InputLabel>Role</InputLabel>
                <Select
                  value={role}
                  onChange={(event) => {
                    setRole(event.target.value);
                  }}
                  label="Role"
                  name="role"
                >
                  <MenuItem value="">None</MenuItem>
                  {map(COREAPP_USER_ROLES, (role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControlLabel
              checked={inviteExtension}
              onChange={(event) => {
                setInviteExtension(event.target.checked);
                setHasError(false);
                setStatus("");
              }}
              control={<Checkbox size="small" />}
              label={
                <Typography sx={{ fontSize: 14 }}>
                  Share connections using the rule5 Chrome extension
                </Typography>
              }
            />
            <FormControlLabel
              checked={inviteApp}
              onChange={(event) => {
                setInviteApp(event.target.checked);
                setHasError(false);
                setStatus("");
              }}
              control={<Checkbox size="small" />}
              label={
                <Typography sx={{ fontSize: 14 }}>
                  Sign up to use rule5
                </Typography>
              }
            />
          </FormGroup>
          <Box
            sx={{
              mt: 2,
              pl: 1,
              pr: 1,
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", flexGrow: 1, mt: 1, color: "#e64539" }}
            >
              {hasError
                ? "There's an issue with an entered email address."
                : status}
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{ width: 170, textTransform: "none" }}
                disabled={
                  status.trim() !== "" ||
                  hasError ||
                  (emails.length === 0 && currentValue.trim() === "") ||
                  (!inviteApp && !inviteExtension)
                }
                variant="outlined"
                onClick={submitEmails}
              >
                Send Invites
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </div>
  );
}
