import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { ClickAwayListener } from "@mui/base";
import {
  PopperComponent,
  StyledPopper,
  StyledTextfield,
} from "../../common/StyledComponents";
import { Button, IconButton, Tooltip } from "@mui/material";
import CreatePromptBook from "../../modal/CreatePromptBook";
import { useDialog } from "../../context/DialogContext";
import axios from "axios";
import { rule5properties } from "../../properties";
import { isArray } from "lodash";
import saveToList from "../../res/icons/outline/save-to-list.svg";
import useSnack from "../../context/Snack";
import { isSuccessStatus } from "../../common/RequestUtils";

export default function AddToPromptBookButton(props) {
  const { prompt } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tooltipText, setTooltipText] = React.useState("Add to prompt book");
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const dialog = useDialog();
  const snackbar = useSnack();
  const buttonRef = React.useRef();

  const open = Boolean(anchorEl);
  const id = open ? `add-to-prompt-books-autocomplete` : undefined;

  const loadPromptBooks = () => {
    setLoading(true);
    axios
      .get(rule5properties.promptBooks)
      .then((response) => {
        setLoading(false);
        if (isArray(response?.data)) {
          setOptions(
            response.data?.map((promptBook) => {
              delete promptBook["prompts"];
              return promptBook;
            })
          );
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          snackbar.createSnack("Error loading prompt books.");
        }
      });
  };

  React.useEffect(() => {
    if (open) {
      loadPromptBooks();
    }
  }, [open]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreatePromptBook = () => {
    handleClose();
    dialog.openModal(
      "Create prompt book",
      CreatePromptBook,
      {
        initialPrompt: prompt,
      },
      "sm"
    );
  };

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleAdd = (promptBook) => {
    handleClose();

    let cardTypesContext = "Specified";
    if (prompt.excludeAllCards) {
      cardTypesContext = "Exclude All";
    } else if (prompt.includeAllCards) {
      cardTypesContext = "Include All";
    }

    const params = {
      promptBookId: promptBook.id,
      prompts: [
        {
          prompt: prompt.prompt,
          cardTypes: prompt.cardTypes ? prompt.cardTypes : [],
          contentIds: prompt.contents
            ? prompt.contents?.map((content) => content.contentId)
            : [],
          cardTypesContext: cardTypesContext,
        },
      ],
    };
    axios
      .post(rule5properties.promptBookPrompts, params)
      .then((resp) => {
        if (isSuccessStatus(resp.status)) {
          setTooltipText("Added!");
          setTooltipOpen(true);
          // TODO should only need one timeout but it wasn't working right
          setTimeout(() => {
            setTooltipOpen(false);
          }, 2000);
          setTimeout(() => {
            setTooltipText("Add to prompt book");
          }, 2200);
        } else {
          snackbar.createSnack("Error adding prompt.");
        }
        handleClose();
        buttonRef.current?.focus();
      })
      .catch(function (error) {
        console.log(error.response.status);
        console.log(error.response.data);
        snackbar.createSnack("Error adding prompt.");
      });
  };

  return (
    <div>
      <Tooltip
        title={tooltipText}
        open={tooltipOpen}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
      >
        <IconButton
          style={{ color: "#1976d2" }}
          ref={buttonRef}
          aria-label="Add to prompt book"
          size="small"
          id="add-to-prompt-book-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <img
            style={{ maxHeight: "16px", maxWidth: "16px" }}
            src={saveToList}
            alt="notifications"
          />
        </IconButton>
      </Tooltip>
      <StyledPopper
        anchorEl={anchorEl}
        open={open}
        id={id}
        placement="bottom-start"
        sx={{ width: "300px" }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Autocomplete
              onChange={(event, newValue) => {
                handleClose();
                handleAdd(newValue);
              }}
              loading={loading}
              PopperComponent={PopperComponent}
              selectOnFocus
              open
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              popupIcon={""} // Remove the little dropdown arrow
              options={options}
              noOptionsText="No prompt books found."
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              sx={{ width: 300 }}
              renderInput={(params) => {
                const { InputLabelProps, ...rest } = params;
                const InputProps = {
                  ...params.InputProps,
                };
                return (
                  <>
                    <StyledTextfield
                      autoFocus
                      placeholder={"Search prompt books…"}
                      {...rest}
                      {...InputProps}
                      {...params}
                    />
                  </>
                );
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid gainsboro",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                sx={{
                  textTransform: "none",
                  alignSelf: "center",
                  justifySelf: "center",
                  margin: "10px",
                }}
                onClick={handleCreatePromptBook}
              >
                Create new
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </div>
  );
}
