import React from "react";
import { Box, Button, Link, Stack, Tooltip, Typography } from "@mui/material";
import { StyledDataGrid, StyledInfoBox } from "../common/StyledComponents";
import {
  GridActionsCellItem,
  GridFooter,
  GridFooterContainer,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDialog } from "../context/DialogContext";
import CreateApiKey from "../modal/CreateApiKey";
import EditApiKey from "../modal/EditApikey";
import WarningIcon from "@mui/icons-material/Warning";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import { rule5properties } from "../properties";
import GenericConfirmation from "../modal/GenericConfirmation";
import { formatDate } from "../common/Utils";

function obfuscateApiKey(key) {
  return (
    key.substring(0, 3) + "..." + key.substring(key.length - 4, key.length - 1)
  );
}

export default function Api(props) {
  const dialogContext = useDialog();

  const [rows, setRows] = React.useState([]);

  function loadApiKeys() {
    axios.get(rule5properties.apiKeys).then((res) => {
      if (res.data && Array.isArray(res.data)) {
        setRows(res.data);
      } else {
        console.log("[rule5] Failed to fetch API keys.");
      }
    });
  }

  const columns = [
    {
      field: "apiKeyName",
      headerName: "Name",
      flex: 2,
      sortable: false,
    },
    {
      field: "apiKey",
      headerName: "Key",
      sortable: false,
      renderCell: (cellValues) => {
        return <span>{obfuscateApiKey(cellValues.row.apiKey)}</span>;
      },
      flex: 1,
    },
    {
      field: "apiKeyCreatedAt",
      headerName: "Created",
      sortable: false,
      flex: 2,
      renderCell: (cellValues) => {
        const date = new Date(cellValues.row.apiKeyCreatedAt);
        return <span>{formatDate(date)}</span>;
      },
    },
    {
      field: "apiKeyLastUsedAt",
      headerName: "Last used",
      sortable: false,
      flex: 2,
      renderCell: (cellValues) => {
        const date = new Date(cellValues.row.apiKeyLastUsedAt);
        return (
          <span>
            {cellValues.row.apiKeyLastUsedAt ? formatDate(date) : "-"}
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (cellValues) => {
        return [
          <Tooltip title="Rotate key">
            <GridActionsCellItem
              icon={<RefreshIcon />}
              label="Rotate"
              onClick={() => {
                dialogContext.openModal(
                  "Rotate API key",
                  GenericConfirmation,
                  {
                    text: `The API key "${cellValues.row.apiKeyName}" will be rotated. Applications and integrations using the previous API key will need to be updated.`,
                    confirmFunction: (callback) => {
                      axios
                        .patch(
                          rule5properties.apiKeys +
                            `/rotate/${encodeURIComponent(
                              cellValues.row.apiKeyName
                            )}`
                        )
                        .then((res) => {
                          if (callback) callback(res);
                          loadApiKeys();
                        });
                    },
                  },
                  "sm"
                );
              }}
              color="primary"
            />
          </Tooltip>,
          <Tooltip title="Delete key">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                dialogContext.openModal(
                  "Delete API key",
                  GenericConfirmation,
                  {
                    text: `The API key "${cellValues.row.apiKeyName}" will be deleted. Applications and integrations using the API key will need to be updated.`,
                    confirmFunction: (callback) => {
                      axios
                        .delete(
                          rule5properties.apiKeys +
                            "/" +
                            encodeURIComponent(cellValues.row.apiKeyName)
                        )
                        .then((res) => {
                          if (callback) callback(res);
                          loadApiKeys();
                        });
                    },
                  },
                  "sm"
                );
              }}
              color="primary"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  React.useEffect(() => {
    loadApiKeys();
  }, []);

  return (
    <Stack sx={{ maxWidth: "840px" }}>
      <StyledInfoBox sx={{ mb: 2 }}>
        <Stack direction="row">
          <WarningIcon sx={{ mr: 2 }} />
          <Typography>
            Do not share your API key with others, or expose it in the browser
            or other client-side code. In order to protect the security of your
            account, we may also automatically disable any publicly exposed API
            keys.
          </Typography>
        </Stack>
      </StyledInfoBox>
      <StyledDataGrid
        onRowClick={(params, event) => {
          dialogContext.openModal(
            "Edit API key",
            EditApiKey,
            { keyToEdit: params.row, loadApiKeys },
            "sm"
          );
        }}
        disableColumnReorder
        disableColumnMenu
        getRowId={(row) => row.apiKey}
        rows={rows}
        columns={columns}
        pageSizeOptions={[10, 25, 50]}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: NoApiKeysOverlay,
          footer: CustomApiKeyFooter,
        }}
        slotProps={{
          noRowsOverlay: {
            loadApiKeys,
          },
          footer: {
            showButton: rows.length > 0,
            loadApiKeys,
          },
        }}
        sx={{ width: "840px", minHeight: "180px" }}
      />
    </Stack>
  );
}

function CustomApiKeyFooter(props) {
  const { showButton, loadApiKeys } = props;
  const dialogContext = useDialog();
  return (
    <GridFooterContainer>
      {showButton && (
        <Button
          sx={{ width: "160px" }}
          disableElevation
          onClick={() => {
            dialogContext.openModal(
              "Create API Key",
              CreateApiKey,
              { loadApiKeys },
              "sm"
            );
          }}
        >
          Create new key
        </Button>
      )}
      <GridFooter
        sx={{
          border: "none", // To delete double border.
        }}
      />
    </GridFooterContainer>
  );
}

function NoApiKeysOverlay(props) {
  const { loadApiKeys } = props;
  const dialogContext = useDialog();
  return (
    <Stack
      sx={{ width: "100%", height: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      No API keys.
      <Link
        sx={{ cursor: "pointer" }}
        onClick={() => {
          dialogContext.openModal(
            "Create API Key",
            CreateApiKey,
            {
              loadApiKeys,
            },
            "sm"
          );
        }}
      >
        Create a new key?
      </Link>
    </Stack>
  );
}
