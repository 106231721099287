import { IconButton, Stack, Button, Divider } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { StyledTextfield } from "../../../common/StyledComponents";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export default function AgentResultsToolbar(props) {
  const { searchValue, setSearchValue } = props;
  return (
    <Stack direction="row" justifyContent="space-between">
      <StyledTextfield
        autoFocus
        placeholder="Search results"
        sx={{ width: "360px", py: 0.75 }}
        endAdornment={
          searchValue?.trim().length > 0 ? (
            <IconButton
              size="small"
              sx={{ mr: -0.5 }}
              onClick={() => {
                setSearchValue("");
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton size="small" sx={{ mr: -0.5 }}>
              <SearchIcon fontSize="small" />
            </IconButton>
          )
        }
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </Stack>
  );
}
