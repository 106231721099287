import React from "react";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getColorFromString } from "../../common/Utils";
import { useImageMapContext } from "../../context/ImageMapContext";
import {
  COREAPP_USER_ROLES,
  PLAN_TYPES,
  useUser,
} from "../../context/UserContext";
import { isEmpty } from "lodash";
import { rule5properties } from "../../properties";
import axios from "axios";
import { isSuccessStatus } from "../../common/RequestUtils";

const useStyles = makeStyles(() => ({
  opportunityTile: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(227 239 248)",
    border: "none",
    borderRadius: "12px",
    outline: "none",
    padding: "15px",
    height: "100%",
    minHeight: "150px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddebf7",
    },
    "&:focus": {
      backgroundColor: "#ddebf7",
    },
  },
  questionText: {
    color: " rgb(75 106 133)",
    width: "100%",
    textAlign: "center",
    marginBottom: "12px",
    textOverflow: "ellipsis",
  },
}));

export default function ChatLandingPage(props) {
  const {
    userOpportunityCompanies,
    handleNewPrompt,
    selectedCompany,
    setPromptText,
  } = props;

  const classes = useStyles();
  const user = useUser();

  const [org, setOrg] = React.useState(null);

  const DEFAULT_QUESTIONS = [
    "What does this company do?",
    "What are their business goals and growth plans?",
    "Describe their strategic initiatives.",
    "Describe their business challenges.",
    "What are the top 5 things I should know about this company?",
    "What are 5 questions I should ask in a discovery call?",
  ];

  const CONTENT_USER_QUESTIONS = [
    "Summarize this content",
    "Write a 300 word blog post",
    "List key facts and figures",
    "Create a one page poster",
    "Translate this content to Spanish",
    "Write an informative and engaging email to the employees",
  ];

  const isContentUser =
    user.userSubscription?.planType === PLAN_TYPES.premiumContent;

  const questions = isContentUser ? CONTENT_USER_QUESTIONS : DEFAULT_QUESTIONS;

  React.useEffect(() => {
    getUserOrg();
  }, []);

  function getUserOrg() {
    if (isContentUser) {
      axios.get(rule5properties.orgDetails).then((resp) => {
        if (isSuccessStatus(resp.status)) {
          setOrg(resp.data);
        }
      });
    }
  }
  // Don't render if still waiting for user companies.
  if (!isContentUser && !userOpportunityCompanies) {
    return null;
  }

  let instructionText = "";
  if (isContentUser) {
    instructionText = "Select content and enter prompt below.";
  } else if (!isEmpty(userOpportunityCompanies)) {
    instructionText =
      "Click on one of the tiles or select an account and ask a question";
  } else {
    instructionText = "You don't have any accounts.";
  }

  return (
    <>
      {!selectedCompany ? (
        <>
          {isContentUser && org && (
            <CompanyAvatar
              name={org?.name}
              icon={org.organizationInfo?.icon || org.organizationInfo?.logo}
            ></CompanyAvatar>
          )}
          <Typography color="#525252" textAlign="center">
            {instructionText}
          </Typography>
        </>
      ) : (
        <CompanyAvatar
          name={selectedCompany?.companyInfo?.name}
          icon={selectedCompany?.companyInfo?.icon}
        ></CompanyAvatar>
      )}
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          [theme.breakpoints?.down("md")]: {
            gridTemplateColumns: "1fr",
          },
          gridAutoRows: "1fr",
          gap: "20px",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 30px",
          maxWidth: "1000px",
          margin: "auto",
          height: "100%",
          overflow: "auto",
        })}
      >
        {questions.map((question, index) => {
          const tileOpptyCompany =
            !isEmpty(userOpportunityCompanies) &&
            userOpportunityCompanies[index % userOpportunityCompanies?.length];
          return (
            <button
              className={classes.opportunityTile}
              key={index}
              onClick={() => {
                if (isContentUser || selectedCompany) {
                  setPromptText(question);
                } else if (tileOpptyCompany) {
                  const tileFunctionalArea =
                    tileOpptyCompany?.oppties[0]?.functionalArea;
                  const prompt = {
                    prompt: question,
                    action: "userPrompt",
                    companyId: tileOpptyCompany?.companyId?.toString(),
                    functionalArea: tileFunctionalArea,
                    opportunityId:
                      tileOpptyCompany?.oppties?.[0]?.id?.toString(),
                  };
                  handleNewPrompt({
                    requestFields: prompt,
                    company: tileOpptyCompany,
                    functionalArea: tileFunctionalArea, //oof have it here and in prompt obj above
                  });
                }
              }}
            >
              {tileOpptyCompany && !selectedCompany && (
                <CompanyAvatar
                  name={tileOpptyCompany?.companyInfo?.name}
                  icon={tileOpptyCompany?.companyInfo?.icon}
                ></CompanyAvatar>
              )}
              <Typography className={classes.questionText}>
                {question}
              </Typography>
            </button>
          );
        })}
      </Box>
    </>
  );
}

function CompanyAvatar(props) {
  const { name, icon } = props;
  const [imageSrc, setImageSrc] = React.useState();

  const { getImageData } = useImageMapContext();

  React.useEffect(() => {
    if (icon) {
      getImageData(icon, (data, error) => {
        if (data) {
          setImageSrc(data);
        }
      });
    }
  }, [icon]);

  return (
    <Tooltip title={name}>
      <Avatar
        variant="rounded"
        style={{
          backgroundColor: icon ? "transparent" : getColorFromString(name),
          width: "40px",
          height: "40px",
          alignSelf: "start",
          margin: "0px auto",
          justifySelf: "center",
        }}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            style={{
              height: "auto",
              maxWidth: 40,
              maxHeight: 40,
              display: "block",
            }}
            alt={name}
          />
        ) : (
          name?.charAt(0)
        )}
      </Avatar>
    </Tooltip>
  );
}
