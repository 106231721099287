import React, { useState } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import RichTextEditor from "../util/RichTextEditor";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import { convertToRaw, EditorState } from "draft-js";
import { formatUrl as formatStorageUrl } from "../common/utils";
import ImageInput from "../util/ImageInput";

export default function RiskFactors10KEditor(props) {
  const formStyle = props.formStyle;

  const {
    register,
    control,
    trigger,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "Risk",
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      <TitleInput register={register} />
      {fields.map((news, riskIndex) => (
        <div key={news.id}>
          <div className={formStyle}>
            Image Content
            <Controller
              control={control}
              defaultValue=""
              name={`Risk[${riskIndex}].Image`}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    onChange={(e) =>
                      field.onChange(formatStorageUrl(e.target.value))
                    }
                  />
                );
              }}
            />
            {!getValues(`Risk[${riskIndex}].Image`) && (
              <>
                -OR-
                <hr></hr>
                Title
                <input {...register(`Risk[${riskIndex}].Title`)} />
                Link
                <input {...register(`Risk[${riskIndex}].Link`)} />
                Assessment
                <TextareaAutosize
                  {...register(`Risk[${riskIndex}].Assessment`, {
                    // required: 'error message' // JS only: <p>error message</p> TS only support string
                  })}
                  aria-label=""
                  minRows={3}
                />
                Excerpt:
                <div>
                  <Controller
                    name={`Risk[${riskIndex}].Excerpt`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "RTE text is required",
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <RichTextEditor
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                  {errors?.Risk?.[riskIndex]?.Excerpt?.type === "required" && (
                    <p style={{ color: "red" }}>
                      {errors.Risk[riskIndex].Excerpt.message}
                    </p>
                  )}
                </div>
                Thumbnail
                <ImageInput
                  name={`Risk[${riskIndex}].Thumbnail`}
                  control={control}
                  setValue={setValue}
                ></ImageInput>
                Sources
                <Sources
                  nestIndex={riskIndex}
                  {...{ control, register, formStyle }}
                />
                Published Date
                <TextField
                  {...register(`Risk[${riskIndex}].PublishedDate`)}
                  placeholder="mm/dd/yyyy"
                  // type="date" //TODO make this a datepicker
                />
                Authors
                <div>
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={[]}
                        onChange={(e, data) => field.onChange(data)}
                        freeSolo
                        autoSelect
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            label="Authors"
                            placeholder="Press enter to add entries"
                          />
                        )}
                      />
                    )}
                    name={`Risk[${riskIndex}].Authors`}
                    type="select"
                    control={control}
                  />
                  {errors?.Risk?.[riskIndex]?.Authors?.type === "required" && (
                    <p style={{ color: "red" }}>
                      {errors.Risk[riskIndex].Authors.message}
                    </p>
                  )}
                </div>
              </>
            )}
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(riskIndex, moveToIndex);
                trigger();
              }}
              index={riskIndex}
              options={[...Array(getValues("Risk").length).keys()]}
            ></MoveAutocomplete>
          </div>
          <DeleteButton
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              const isDelete = confirm("Remove this Risk?");
              if (isDelete === true) {
                setLastDeleted(news);
                remove(riskIndex);
                trigger();
              }
            }}
          >
            Delete Risk
          </DeleteButton>
          <br />
          <br />
          <Divider light style={{ width: "100%" }} />
        </div>
      ))}
      <AddButton
        onClick={() => {
          appendFuncClickHandler(append, lastDeleted)
        }}
      >
        Add Risk
      </AddButton>

      {/* <button type="button" onClick={() => {
                append({ Source: [] });
            }}>
                Add Risk
            </button> */}
      <br />
      <br />
    </div>
  );
}

/** Nested form array */
function Sources({ nestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Risk.${nestIndex}.Source`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            Name:
            <input
              {...register(`Risk.${nestIndex}.Source.${k}.Name`, {
                // required: true
              })}
            />
            URL:
            <input {...register(`Risk.${nestIndex}.Source.${k}.URL`)} />
            <DeleteButton onClick={() => remove(k)}>Delete Source</DeleteButton>
          </div>
        );
      })}

      <AddButton
        onClick={() =>
          append({
            Name: "",
            URL: "",
          })
        }
      >
        Add Source
      </AddButton>
    </div>
  );
}

// const stubData = {
//     "Title": "Risks",
//     "Risk": [
//         {
//             "Title": "Risk factors point to the need for a more agile and adaptive technology foundation",
//             "Link": "https://www.google.com/url?q=https://app.quotemedia.com/data/downloadFiling?webmasterId%3D101533%26ref%3D114814511%26type%3DHTML%26symbol%3DGPC%26companyName%3DGenuine%2BParts%2BCompany%26formType%3D10-K%26dateFiled%3D2020-02-21%26CK%3D40987&sa=D&source=editors&ust=1634591799253000&usg=AOvVaw29zQbOGQiGx8_LyQ6gF1-U",
//             "Assessment": "Several of the Genuine Parts risk factors in the 10K are relevant to TIBCO.  1. There is an overall theme of a need for flexibility to mitigate risks associated with pricing, consumer preferences, entering new markets, manage costs, grow through acquisitions and integration,  adapting to new technologies, weather related risks, and risks due to political unrest.  2. There are specific needs relative to the successful implementation and operation of eCommerce capabilities.",
//             "Excerpt": {
//                 "blocks": [
//                     {
//                         "key": "b4b1l",
//                         "text": "We may not be able to successfully implement our business initiatives in each of our business segments to grow our sales and earnings, which could adversely affect our business, financial condition, results of operations and cash flows.",
//                         "type": "unstyled",
//                         "depth": 0,
//                         "inlineStyleRanges": [],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "8grmi",
//                         "text": "the competitive environment in our end markets may force us to reduce prices below our desired pricing level or to increase promotional spending;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 145,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "mpra",
//                         "text": "our ability to anticipate changes in consumer preferences and to meet customers’ needs for our products in a timely manner;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 123,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "cbn4g",
//                         "text": "our ability to successfully enter new markets, including by successfully identifying and acquiring suitable acquisition targets in these new markets;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 149,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "3jknh",
//                         "text": "our ability to effectively manage our costs;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 44,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "6hoj",
//                         "text": "our ability to continue to grow through acquisitions and successfully integrate acquired businesses in our existing operations, including in particular the challenges associated with the integration of foreign operations to ensure the adequacy of internal controls;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 265,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "dbnbb",
//                         "text": "our ability to identify and successfully implement appropriate technological, digital and e-commerce solutions;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 111,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "bcjlg",
//                         "text": "the rate of adoption of electric vehicles, hybrid vehicles, ride sharing services, alternative transportation means and autonomously driven vehicles and future legislation related thereto;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 188,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "fep3l",
//                         "text": "the occurrence of unusually severe weather events, which can disrupt our operations (forcing temporary closure of retail and distribution centers, prohibiting shipment of inventory and products) and negatively impact our results in the affected geographies;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 257,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "9bs7j",
//                         "text": "the occurrence of political unrest and strikes, which can disrupt our operations and negatively impact our results in the affected geographies;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 143,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "96a5g",
//                         "text": "volatility in oil prices, which could have a negative impact on the global economy and the economy of each of the nations in which we operate, in particular;",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 157,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "7pam",
//                         "text": "the adequacy of our disclosure controls and procedures and internal controls over financial reporting; and",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 106,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "3tve5",
//                         "text": "the economy of each of the nations in which we operate in general, including the monetary policies of the Federal Reserve, which are influenced by various factors, including inflation, unemployment and short-term and long-term changes in the international trade balance and the fiscal policies of the U.S. government.",
//                         "type": "unordered-list-item",
//                         "depth": 0,
//                         "inlineStyleRanges": [
//                             {
//                                 "offset": 0,
//                                 "length": 317,
//                                 "style": "SMALL"
//                             }
//                         ],
//                         "entityRanges": [],
//                         "data": {}
//                     },
//                     {
//                         "key": "ajbpt",
//                         "text": "We recognize the growing demand for business-to-business and business-to-customer e-commerce options and solutions, and we could lose business if we fail to provide the e-commerce options and solutions our customers wish to use.",
//                         "type": "unstyled",
//                         "depth": 0,
//                         "inlineStyleRanges": [],
//                         "entityRanges": [],
//                         "data": {}
//                     }
//                 ],
//                 "entityMap": {}
//             },
//             "Thumbnail": "",
//             "Source": [
//                 {
//                     "Name": "10K",
//                     "URL": "https://genuineparts.investorroom.com/sec-filings?year=2020&cat=1"
//                 }
//             ],
//             "PublishedDate": "02/21/2020",
//             "Authors": [
//                 "Prashant Vaze"
//             ]
//         }
//     ],
//     "NewUpdate": false
// }
