import React from 'react';
import { Stack, Typography } from '@mui/material';

export default function OPXUpdate() {

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ flexGrow: '1', m: 10, height: '100%' }}>
      <Typography variant="h4" sx={{ opacity: 0.6, mb: 3 }}>
        Update coming soon.
      </Typography>
      <Typography variant="h5" sx={{ opacity: 0.5 }}>
        We are working on this feature and will launch in Jan ‘22!
      </Typography>
    </Stack>
  );
}