import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    overflow: "hidden",
  },
  workspace: {
    maxWidth: "100%",
    overflowY: "auto",
    padding: `0px`,
    backgroundColor: "#FAFAFA",
    color: "#000000",
    height: (props) => (props.height ? props.height : "calc(100vh - 72px)"),
    display: "flex",
    flexDirection: "column",
  },
}));

export default function Workspace(props) {
  const classes = useStyles(props);

  return (
    <div id="workspaceContainer" className={classes.container}>
      <div className={classes.workspace}>
        {props.children ? props.children : ""}
      </div>
    </div>
  );
}
