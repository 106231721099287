/* global chrome */

import React, { useEffect } from "react";
import { useImmer } from "use-immer";

const GptConversationContext = React.createContext();

export function useGptConversationContext() {
  return React.useContext(GptConversationContext);
}

export function GptConversationProvider(props) {
  const DEFAULT_SELECTION = {
    selectedMessages: [],
    selectionMode: false,
    activePromptId: null,
  };
  const [activeConversation, setActiveConversation] = React.useState(null);
  const [selection, setSelection] = useImmer(DEFAULT_SELECTION);
  const [controller, setController] = React.useState(new AbortController());

  const generateButtonText =
    selection.selectedMessages.length === 0
      ? "Select up to 5 responses to generate an email"
      : `Compose email using ${
          selection.selectedMessages.length
        } selected message${
          selection.selectedMessages.length === 1 ? "" : "s"
        }`;

  function resetSelection() {
    setSelection(DEFAULT_SELECTION);
  }

  function abortChat() {
    if (controller) {
      controller.abort();
      setController(new AbortController());
    }
  }

  return (
    <GptConversationContext.Provider
      value={{
        activeConversation: activeConversation,
        setActiveConversation: setActiveConversation,
        selection: selection,
        setSelection: setSelection,
        generateButtonText: generateButtonText,
        resetSelection: resetSelection,
        abortChat: abortChat,
        controller: controller,
      }}
    >
      {props.children}
    </GptConversationContext.Provider>
  );
}
