import React, { useState } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import RichTextEditor from "../util/RichTextEditor";
import Divider from "@mui/material/Divider";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import ImageInput from "../util/ImageInput";

export default function CompetitionIntelligenceEditor(props) {
  const formStyle = props.formStyle;

  const { register, control, trigger, getValues, setValue } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "Competitors",
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      <TitleInput register={register} />
      {fields.map((news, competitorIndex) => (
        <div key={news.id}>
          <div className={formStyle}>
            Competitor Name
            <input {...register(`Competitors[${competitorIndex}].Name`)} />
            Logo
            <input {...register(`Competitors[${competitorIndex}].Logo`)} />
            Overview:
            <Controller
              name={`Competitors[${competitorIndex}].Overview`}
              control={control}
              render={({ field }) => {
                return (
                  <RichTextEditor
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            />
            Items
            <Items
              nestIndex={competitorIndex}
              {...{
                control,
                register,
                formStyle,
                move,
                trigger,
                getValues,
                setValue,
              }}
            />
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(competitorIndex, moveToIndex);
                trigger();
              }}
              index={competitorIndex}
              options={[...Array(getValues("Competitors").length).keys()]}
            ></MoveAutocomplete>
          </div>
          <DeleteButton
            onClick={() => {
              setLastDeleted(news);
              remove(competitorIndex);
              trigger();
            }}
          >
            Delete Competitor
          </DeleteButton>

          <Divider
            light
            style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
          />
        </div>
      ))}
      <AddButton
        onClick={() => {
          appendFuncClickHandler(append, lastDeleted);
        }}
      >
        Add Competitor
      </AddButton>
    </div>
  );
}

/** Nested form array */
function Items({
  nestIndex,
  control,
  register,
  formStyle,
  move,
  trigger,
  getValues,
  setValue,
}) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Competitors.${nestIndex}.Items`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            Title
            <input {...register(`Competitors.${nestIndex}.Items.${k}.Title`)} />
            Link
            <input {...register(`Competitors.${nestIndex}.Items.${k}.Link`)} />
            Content
            <input
              {...register(`Competitors.${nestIndex}.Items.${k}.Content`)}
            />
            Thumbnail
            <ImageInput
              name={`Competitors.${nestIndex}.Items.${k}.Thumbnail`}
              control={control}
              setValue={setValue}
            ></ImageInput>
            Sources
            <Sources
              nestIndex={nestIndex}
              nestedNestIndex={k}
              {...{ control, register, formStyle }}
            />
            Published Date
            <TextField
              {...register(`Competitors.${nestIndex}.Items.${k}.PublishedDate`)}
              placeholder="mm/dd/yyyy"
              // type="date" //TODO make this a datepicker
            />
            Authors
            <div>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={[]}
                    onChange={(e, data) => field.onChange(data)}
                    freeSolo
                    autoSelect
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Authors"
                        placeholder="Press enter to add entries"
                      />
                    )}
                  />
                )}
                name={`Competitors.${nestIndex}.Items.${k}.Authors`}
                type="select"
                control={control}
              />
            </div>
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(k, moveToIndex);
                trigger();
              }}
              index={k}
              options={[
                ...Array(
                  getValues(`Competitors.${nestIndex}.Items`).length
                ).keys(),
              ]}
            ></MoveAutocomplete>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(k);
              }}
            >
              Delete Item
            </DeleteButton>
          </div>
        );
      })}

      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Item
      </AddButton>
    </div>
  );
}

/** Nested form array */
function Sources({ nestIndex, nestedNestIndex, control, register, formStyle }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Competitors.${nestIndex}.Items.${nestedNestIndex}.Source`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className={formStyle}>
            Name:
            <input
              {...register(
                `Competitors.${nestIndex}.Items.${nestedNestIndex}.Source.${k}.Name`
              )}
            />
            URL:
            <input
              {...register(
                `Competitors.${nestIndex}.Items.${nestedNestIndex}.Source.${k}.URL`
              )}
            />
            <DeleteButton onClick={() => remove(k)}>Delete Source</DeleteButton>
          </div>
        );
      })}

      <AddButton
        onClick={() =>
          append({
            Name: "",
            URL: "",
          })
        }
      >
        Add Source
      </AddButton>
    </div>
  );
}

/*
const stubData =  {
  Title: "Competition Intelligence",
  Competitors: [
    {
      Name: "Blue Yonder",
      Logo: "https://drive.google.com/file/d/1W4m1UYkkSyJhwyQBILolI4dmyIbIBGdn/view?usp=sharing",
      Overview: {
        blocks: [
          {
            key: "6mld6",
            text: "Western Digital uses JTA (Blue Yonder) warehouse management and transportation management applications.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [
              { offset: 0, length: 103, style: "fontsize-12" },
            ],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      Items: [
        {
          Title:
            "Blue Yonder  announces Q-4 2021 results, making record-breaking year",
          Link: "https://media.blueyonder.com/item1",
          Content:
            "Western Digital uses JTA (Blue Yonder) warehouse management and transportation management applications.",
          Thumbnail:
            "https://drive.google.com/file/d/1W4m1UYkkSyJhwyQBILolI4dmyIbIBGdn/view?usp=sharing",
          Source: [
            {
              Name: "Press Release - Feb 2, 2022",
              URL: "https://www.bleyonder.com/news/item1",
            },
          ],
          PublishedDate: "2/2/2022",
          Authors: ["Apoorva Mandavilli"],
        },
        {
          Title: "Blue Yonder announces CEO transition",
          Link: "https://media.blueyonder.com/item2",
          Content:
            "Western Digital uses JTA (Blue Yonder) warehouse management and transportation management applications.",
          Thumbnail:
            "https://drive.google.com/file/d/1W4m1UYkkSyJhwyQBILolI4dmyIbIBGdn/view?usp=sharing",
          Source: [
            {
              Name: "Press Release - Feb 7, 2022",
              URL: "https://www.bleyonder.com/news/item2",
            },
          ],
          PublishedDate: "2/7/2022",
          Authors: ["Andrew Black"],
        },
        {
          Title: "Blue Yonder  named one of “100 Best” Arizona companies",
          Link: "https://www.arizonatimes.com/bestcompanies",
          Content:
            "Blue Yonder, a world leader in digital supply chain and omni-channel commerce fulfillment, has been named one of the “100 Best Arizona Companies” by BestCompaniesAZ as part of its 20th anniversary serving Arizona’s business community. Blue Yonder is recognized in the “Best of Wings” category.",
          Source: [
            {
              Name: "Arizona Times",
              URL: "https://www.arizonatimes.com",
            },
          ],
          PublishedDate: "1/18/2022",
          Authors: ["Jason Witman"],
        },
        {
          Title:
            "Lenovo to digitally transform suppply chain using Blue Yonder",
          Link: "https://www.blueyonder.com/newsroom",
          Content:
            "Technology leader will improve planning and forecasting capabilities SCOTTSDALE, Ariz. – Feb. 17, 2022 – The ability to forecast demand and plan inventory is crucial for today’s manufacturing supply chain.” category.",
          Source: [
            {
              Name: "New Blog",
              URL: "https://www.blueyonder.com/newsroom",
            },
          ],
          PublishedDate: "2/17/2022",
          Authors: ["John Doe"],
        },
      ],
    },
    {
      Name: "Kinaxis",
      Logo: "https://drive.google.com/file/d/1QjvoTKi92jc8Sg1wV-EgpvT80_frtbo-/view?usp=sharing",
      Overview: {
        blocks: [
          {
            key: "6mld6",
            text: "Western Digital uses JTA (Blue Yonder) warehouse management and transportation management applications.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [
              { offset: 0, length: 103, style: "fontsize-12" },
            ],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      Items: [
        {
          Title:
            "Kinaxis  announces Q-4 2021 results, making record-breaking year",
          Link: "https://media.kinaxis.com/item1",
          Content:
            "Western Digital uses JTA (Blue Yonder) warehouse management and transportation management applications.",
          Thumbnail:
            "https://drive.google.com/file/d/1QjvoTKi92jc8Sg1wV-EgpvT80_frtbo-/view?usp=sharing",
          Source: [
            {
              Name: "Press Release - Feb 2, 2022",
              URL: "https://www.kinaxis.com/news/item1",
            },
          ],
          PublishedDate: "2/2/2022",
          Authors: ["Candy Short"],
        },
        {
          Title: "Kinaxis announces New CFO",
          Link: "https://media.blueyonder.com/item2",
          Content:
            "Western Digital uses JTA (Blue Yonder) warehouse management and transportation management applications.",

          Thumbnail:
            "https://drive.google.com/file/d/1QjvoTKi92jc8Sg1wV-EgpvT80_frtbo-/view?usp=sharing",
          Source: [
            {
              Name: "Press Release - Feb 7, 2022",
              URL: "https://www.kinaxis.com/news/item2",
            },
          ],
          PublishedDate: "2/7/2022",
          Authors: ["Andrew Black"],
        },
        {
          Title: "Kinaxis  named top supply chain company",
          Link: "https://www.arizonatimes.com/bestcompanies",
          Content:
            "Kinaxis, a world leader in digital supply chain and omni-channel commerce fulfillment, has been named one of the “100 Best Arizona Companies” by BestCompaniesAZ as part of its 20th anniversary serving Arizona’s business community. Kinaxis is recognized in the “Best of Wings” category.",
          Source: [
            {
              Name: "Arizona Times",
              URL: "https://www.arizonatimes.com",
            },
          ],
          PublishedDate: "1/18/2022",
          Authors: ["Jason Witman"],
        },
        {
          Title: "KPMG to open suppply chain practice using Kinaxis",
          Link: "https://www.kinaxis.com/newsroom",
          Content:
            "Technology leader will improve planning and forecasting capabilities SCOTTSDALE, Ariz. – Feb. 17, 2022 – The ability to forecast demand and plan inventory is crucial for today’s manufacturing supply chain.” category.",
          Source: [
            {
              Name: "New Blog",
              URL: "https://www.kinaxis.com/newsroom",
            },
          ],
          PublishedDate: "2/17/2022",
          Authors: ["John Doe"],
        },
      ],
    },
  ],
};
*/
