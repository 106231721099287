import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { rule5properties } from "../../properties";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useDialog } from "../../context/DialogContext";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    width: "200px",
  },
}));

export default function DeleteConfirmationPopup(props) {
  const classes = useStyles();

  const history = useHistory();
  const dialog = useDialog();

  const deleteCard = (event) => {
    axios
      .delete(rule5properties.detCards, { data: { id: props.cardId } })
      .catch((error) => {
        alert("ERROR: " + JSON.stringify(error));
      })
      .then((response) => {
        if (response.status === 200) {
          history.push("/det/managecards");
          dialog.closeModal();
        }
      });
  };

  const closeModal = () => {
    dialog.closeModal();
  };

  return (
    <Box>
      <Stack sx={{ alignItems: "center" }}>
        <Typography>
          Please confirm that you want to delete this card
        </Typography>
        <div>
          <Button
            className={classes.buttonStyle}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              marginRight: "20px",
            }}
            color="primary"
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className={classes.buttonStyle}
            color="primary"
            variant="contained"
            onClick={(event) => deleteCard(event)}
          >
            Delete
          </Button>
        </div>
      </Stack>
    </Box>
  );
}
