import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TitleInput } from "../util/CardEditorButtons";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { rule5properties } from "../../properties";
import RichTextEditor from "../util/RichTextEditor";
import { convertHtmlToRawContentState } from "../common/utils";

export default function SpSiBcEditor(props) {
  const formStyle = props.formStyle;

  const { register, control } = useFormContext();

  const [loading, setLoading] = useState(false);
  const [generatedValue, setGeneratedValue] = useState(null);

  return (
    <div>
      <TitleInput register={register} />
      <LoadingButton
        loading={loading}
        style={{ textTransform: "none", marginTop: "2%" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          setLoading(true);
          const cardDataParams = new URLSearchParams({
            functionalArea: props.functionalArea,
            cardType: "Strategic Snapshot",
            companyId: props.companyId,
          });

          axios
            .get(rule5properties.detCardData + "?" + cardDataParams)
            .then((res) => {
              setLoading(false);
              if (res.status === 200) {
                let rawContentState;
                if (res.data.strategicSnapshot) {
                  rawContentState = convertHtmlToRawContentState(
                    res.data.strategicSnapshot
                  );
                  setGeneratedValue(rawContentState);
                }

                // Generated RTE content cannot be directly assigned so only spread the non-RTE data.
                let { strategicSnapshot, ...nonGeneratedData } = res.data;
                props.setForm((draft) => {
                  draft.cardInfo = {
                    strategicSnapshot: rawContentState,
                    ...nonGeneratedData,
                    Title: "Strategic Snapshot",
                  };
                  draft.isDraftSaved = false;
                });
                props.setHasPreview(true);
              } else {
                window.alert(
                  `Card data response code: ${
                    res.status
                  }. Details: ${JSON.stringify(res.data)}`
                );
              }
            })
            .catch((err) => {
              window.alert("Error retrieving on card data: " + err);
              setLoading(false);
            });
        }}
      >
        Generate Data
      </LoadingButton>
      <div className={formStyle}>
        10kDate:
        <input {...register(`10kDate`)} />
        Earnings date:
        <input {...register(`earningAnnouncementDate`)} />
        Strategic Snapshot:
        <Controller
          name="strategicSnapshot"
          control={control}
          rules={{
            required: { value: true, message: "RTE text is required" },
          }}
          render={({ field }) => {
            return (
              <RichTextEditor
                generatedValue={generatedValue}
                clearGeneratedValue={() => {
                  setGeneratedValue(null);
                }}
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
