import React, { useContext } from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontSize: "24px",
    boxSizing: "border-box",
  },
}));

const DialogContext = React.createContext();

// Hook to allow access to openModal(val)
export function useDialog() {
  return useContext(DialogContext);
}

export function DialogProvider({ children }) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    title: "Dialog Title",
    content: "This is the dialog content.",
  });
  const handleClose = (e) => {
    setDialogOpen(false);
    e.stopPropagation();
  };

  function openModal(title, content, props, modalSize) {
    if (content) {
      setDialogProps({
        title: title,
        content: content,
        props: props,
        size: modalSize ? modalSize : "md",
      });
    }
    setDialogOpen(true);
  }

  function closeModal() {
    setDialogOpen(false);
  }

  function setModalTitle(newTitle) {
    setDialogProps({
      ...dialogProps,
      title: newTitle,
    });
  }

  return (
    <DialogContext.Provider
      value={{
        openModal: openModal,
        closeModal: closeModal,
        setModalTitle: setModalTitle,
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={dialogProps.props?.fixed ? null : handleClose}
        fullWidth={true}
        BackdropProps={{
          style: { backgroundColor: "rgb(0,0,0,0.3)" },
          transitionDuration: 300,
        }}
        PaperProps={{
          elevation: 4,
          sx: {
            p: 3,
            borderRadius: "10px",
            "&::-webkit-scrollbar": {
              width: "10px",
              height: "10px",
            },
            "&::-webkit-scrollbar-track": {
              margin: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.1)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgba(0,0,0,.15)",
            },
          },
        }}
        maxWidth={dialogProps.size}
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="alert-dialog-title"
          sx={{
            pl: "20px",
            pt: "16px",
            pr: "20px",
            pb: dialogProps.props?.customPb
              ? dialogProps.props?.customPb
              : "16px",
          }}
        >
          {dialogProps.title}
          {!dialogProps.props?.fixed && (
            <IconButton
              size="small"
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 20,
                top: 20,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          sx={{ pl: "20px", paddingBottom: "20px", overflowY: "initial" }}
        >
          {<dialogProps.content {...dialogProps.props} />}
        </DialogContent>
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
}
