import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import RichTextEditor from "../util/RichTextEditor";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import { Autocomplete, Chip, TextField } from "@mui/material";
import ImageInput from "../util/ImageInput";

export default function FlashCardEditor(props) {
  const { register, control, getValues, setValue } = useFormContext();

  const { formStyle, setRemoveCallback } = props;

  return (
    <div>
      <input
        {...register(`companyId`)}
        hidden={true}
        defaultValue={props.companyId}
      />
      <input
        {...register(`functionalArea`)}
        hidden={true}
        defaultValue={props.functionalArea}
      />
      <TitleInput register={register} />
      <br />
      <h5>Insights:</h5>
      <Insights
        {...{
          control,
          register,
          getValues,
          formStyle,
          setValue,
          setRemoveCallback,
        }}
      />
      <h5>Talking Points:</h5>
      <div>
        <Controller
          name="talkingPoints"
          control={control}
          render={({ field }) => {
            return (
              <RichTextEditor value={field.value} onChange={field.onChange} />
            );
          }}
        />
      </div>
    </div>
  );
}

/** Form array */
function Insights({
  trigger,
  control,
  register,
  getValues,
  formStyle,
  setValue,
  setRemoveCallback,
}) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: "insights",
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  useEffect(() => {
    setRemoveCallback(() => remove);
  }, []);

  return (
    <>
      {fields.map((insight, insightIndex) => (
        <div key={insight.id} className={formStyle}>
          Title
          <input {...register(`insights[${insightIndex}].title`)} />
          Link
          <input {...register(`insights[${insightIndex}].link`)} />
          Content
          <Controller
            name={`insights[${insightIndex}].rteContent`}
            control={control}
            render={({ field }) => {
              return (
                <RichTextEditor value={field.value} onChange={field.onChange} />
              );
            }}
          />
          Thumbnail
          <ImageInput
            name={`insights[${insightIndex}].thumbnail`}
            control={control}
            setValue={setValue}
          ></ImageInput>
          Source
          <input {...register(`insights[${insightIndex}].source`)} />
          Published Date
          <TextField
            {...register(`insights[${insightIndex}].publishedDate`)}
            placeholder="mm/dd/yyyy"
            // type="date" //TODO make this a datepicker
          />
          Authors
          <div>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={[]}
                  onChange={(e, data) => field.onChange(data)}
                  freeSolo
                  autoSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Authors"
                      placeholder="Press enter to add entries"
                    />
                  )}
                />
              )}
              name={`insights[${insightIndex}].authors`}
              type="select"
              control={control}
            />
          </div>
          <MoveAutocomplete
            onChange={(moveToIndex) => {
              move(insightIndex, moveToIndex);
            }}
            index={insightIndex}
            options={[...Array(getValues("insights").length).keys()]}
          ></MoveAutocomplete>
          <DeleteButton
            onClick={() => {
              setLastDeleted(insight);
              remove(insightIndex);
            }}
          >
            Delete Insight
          </DeleteButton>
          <hr />
        </div>
      ))}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Insight
      </AddButton>
    </>
  );
}
