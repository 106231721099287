import * as React from "react";
import axios from "axios";
import { rule5properties } from "../../properties";
import { Controller } from "react-hook-form";
import { Autocomplete, Chip, TextField } from "@mui/material";

export default function ImageInput(props) {
  const { control, name, setValue } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <input
            {...field}
            onChange={(e) =>
              field.onChange(
                updateWithCustomAvatar(e.target.value, name, setValue)
              )
            }
          />
        );
      }}
    />
  );
}

export function MultiImageInput(props) {
  const { control, name, setValue } = props;
  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          options={[]}
          onChange={(e, data) => {
            updateMultiImageInput(data, name, setValue); // This is async and will update the value again after promises complete
            return field.onChange(data);
          }}
          freeSolo
          autoSelect
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Images"
              placeholder="Press enter to add entries"
            />
          )}
        />
      )}
      name={name}
      type="select"
      control={control}
    />
  );
}

const updateWithCustomAvatar = (publicUrl, name, setValue) => {
  if (!publicUrl || publicUrl.includes("storage.googleapis.com")) {
    return publicUrl;
  }
  const params = [
    {
      override: true,
      url: publicUrl,
    },
  ];
  axios
    .post(rule5properties.detPersonsUpload, params)
    .then((response) => {
      setValue(name, response.data[0].storageUrl + "?" + new Date().getTime());
    })
    .catch(function (error) {
      alert("ERROR: " + JSON.stringify(error));
      console.log(JSON.stringify(error));
      setValue(name, ""); // Remove the link if it failed. A failed bucket link will cause issues with slides download
    });

  //TODO kinda weird to return the publicUrl here when it is intended to get overridden in the promise response.
  //Probably this function should be async.
  return publicUrl;
};

const updateMultiImageInput = (data, name, setValue) => {
  let requests = [];
  data.forEach((publicUrl, index) => {
    if (!publicUrl || publicUrl.includes("storage.googleapis.com")) {
      requests.push(Promise.resolve({ data: [{ storageUrl: publicUrl }] }));
    } else {
      const params = [
        {
          override: true,
          url: publicUrl,
        },
      ];
      requests.push(
        axios
          .post(rule5properties.detPersonsUpload, params)
          .catch((error) => window.alert("Error uploading image: " + publicUrl))
      );
    }
  });

  Promise.all(requests).then((body) => {
    const output = body
      .filter((element) => element !== undefined)
      .map((imageResponse, index) => {
        let url = imageResponse.data[0].storageUrl;
        if (url.indexOf("?") < 0) {
          url += "?" + new Date().getTime();
        }
        return url;
      });
    setValue(name, output);
  });
};
