import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, LinearProgress, Stack, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import ManageUserPopup from "../modal/ManageUserPopup";
import Image from "../common/Image";
import { getColorFromString } from "../common/Utils";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import Toolbar from "../common/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { useDialog } from "../context/DialogContext";
import CustomSnackbar from "../common/CustomSnackbar";
import { rule5properties } from "../properties";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { COREAPP_USER_ROLES, useUser } from "../context/UserContext";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import UserDetailPopup from "../modal/UserDetailPopup";
import UserOpportunities from "../modal/UserOpportunities";
import { StyledTextfield } from "../common/StyledComponents";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

LicenseInfo.setLicenseKey(rule5properties.MUIXProLicenseKey);

const defaultRow = [
  {
    id: -1,
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    status: "",
    role: "",
    createdBy: "",
    creationDate: "",
    lastUpdatedBy: "",
    lastUpdateDate: "",
  },
];

const useStyles = makeStyles(() => ({
  tableCellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  spinnerContainer: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    zIndex: 1001,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.7)",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  spinner: {
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "30px",
      marginRight: "30px",
    },
    card: {},
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            marginLeft: "-6px",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          // color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,1)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiDataGrid-pinnedColumns--left": {
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,1)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none",
    },
  },
  columnText: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
  },
  menuButton: {
    minWidth: "10px",
  },
  summaryCard: {
    height: "72px",
    marginBottom: "16px",
  },
  toolbarButton: {
    padding: "8px 12px",
    color: "rgba(0,0,0,0.5)",
    borderRadius: "8px",
    textTransform: "none",
  },
  avatar: {
    cursor: "pointer",
    width: "38px",
    height: "38px",
    transition: "0.2s",
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "20px",
    "-webkit-transition": "-webkit-filter 500ms linear",
    "&:hover": {
      "-webkit-filter": "brightness(90%)",
    },
  },
}));

function CustomToolbar(props) {
  const { getUsers, addOrEdit } = props;
  const dialog = useDialog();
  const classes = useStyles();

  const [searchValue, setSearchValue] = React.useState("");
  const [exportLoading, setExportLoading] = React.useState(false);

  // Search effect that triggers after a delay from typing.
  const searchThrottleTimeout = React.useRef();
  useEffect(() => {
    clearTimeout(searchThrottleTimeout.current);
    searchThrottleTimeout.current = setTimeout(() => {
      getUsers(searchValue);
    }, 300);
  }, [searchValue, getUsers]);

  // Get and process users with opportunities.
  const exportUsersAndOpportunities = () => {
    setExportLoading(true);
    axios
      .get(rule5properties.usersAndOpportunitiesExport)
      .then((res) => {
        console.log(res);
        if (res.data && Array.isArray(res.data)) {
          // Generate the CSV.
          const userOpportunities = res.data;
          const csvRows = [
            "First name, Last name, Email, Account name, Business function",
          ];
          userOpportunities.forEach((userOpp) => {
            if (userOpp.opportunities?.length > 0) {
              userOpp.opportunities.forEach((opportunity) => {
                const newRow = `"${userOpp.firstName}", "${userOpp.lastName}", "${userOpp.email}", "${opportunity.Account}", "${opportunity.Name}"`;
                csvRows.push(newRow);
              });
            }
          });
          let csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
          const encodedUri = encodeURI(csvContent);
          const downloadLink = document.createElement("a");
          const currDate = new Date();
          downloadLink.href = encodedUri;
          downloadLink.download = `users_export_${currDate.getDate()}${currDate.getMonth()}${currDate.getFullYear()}.csv`;
          downloadLink.click();
        }
        setExportLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setExportLoading(false);
      });
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <StyledTextfield
        autoFocus
        placeholder="Search results"
        sx={{ width: "360px", py: 0.75 }}
        endAdornment={
          searchValue?.trim().length > 0 ? (
            <IconButton
              size="small"
              sx={{ mr: -0.5 }}
              onClick={() => {
                setSearchValue("");
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton size="small" sx={{ mr: -0.5 }}>
              <SearchIcon fontSize="small" />
            </IconButton>
          )
        }
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      <GridToolbarContainer
        sx={{
          position: "relative",
          justifyContent: "flex-end",
        }}
      >
        <GridToolbarFilterButton className={classes.toolbarButton} />
        <div style={{ flex: 1 }}> </div>
        <GridToolbarDensitySelector className={classes.toolbarButton} />
        <Button
          className={classes.toolbarButton}
          sx={{ fontSize: "13px" }}
          startIcon={<SaveAltIcon />}
          onClick={exportUsersAndOpportunities}
        >
          Export CSV
        </Button>
        <IconButton
          color="primary"
          aria-label="refresh"
          component="span"
          onClick={() => {
            getUsers();
          }}
          className={classes.toolbarButton}
        >
          <RefreshIcon />
        </IconButton>
        <Button
          color="primary"
          style={{
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            dialog.openModal("Add new user", UserDetailPopup, {
              addOrEdit,
              recordForEdit: null,
              isCoreApp: true,
            });
          }}
        >
          Add new user
        </Button>
      </GridToolbarContainer>
    </Stack>
  );
}

export default function ManageUsers(props) {
  const classes = useStyles();
  const dialog = useDialog();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState(defaultRow);
  const [pageSize, setPageSize] = React.useState(50);
  const user = useUser();
  const theme = useTheme();

  const getUsers = React.useCallback(
    (searchValue) => {
      setLoading(true);

      if (user.role !== COREAPP_USER_ROLES.orgAdmin) {
        setLoading(false);
        setSnackbarMessage("You don't have permission.");
        setOpenSnackbar(true);
        return;
      }

      const params = new URLSearchParams();
      if (searchValue?.trim().length > 1) {
        params.append("searchValue", searchValue);
      }

      axios
        .get(
          `${rule5properties.getUsers}${
            searchValue?.trim().length > 1 ? "?" + params.toString() : ""
          }`
        )
        .then((response) => {
          if (response.status === 403) {
            setLoading(false);
            setSnackbarMessage("You don't have permission.");
            setOpenSnackbar(true);
            return;
          }

          if (response.data && response.data.length > 0) {
            let id = 0;
            response.data.forEach((element) => {
              element.id = id;
              id += 1;
            });
          }
          setRows(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response) {
            setSnackbarMessage("There was a problem with listing users.");
            setOpenSnackbar(true);
          }
        });
    },
    [user]
  );

  const onSelectionChange = (event) => {
    const row = rows.find((row) => {
      return row.id === event[0];
    });
    dialog.openModal("User Information", ManageUserPopup, {
      addOrEdit,
      recordForEdit: row,
    });
  };

  const addOrEdit = (user, resetForm, response) => {
    if (user.id === -1) {
      createNewUser(user, resetForm, response);
    } else {
      updateUser(user, resetForm);
    }
  };

  function updateUser(user, resetForm) {
    setLoading(true);
    axios
      .patch(`${rule5properties.updateUsers}`, {
        email: user.email,
        role: user.role,
        status: user.status,
      })
      .then((response) => {
        if (response.status === 200) {
          dialog.closeModal();
          resetForm();
          setLoading(false);
          getUsers();
          return;
        }
        setLoading(false);
        setSnackbarMessage("There was a problem with updating user.");
        setOpenSnackbar(true);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          setSnackbarMessage("There was a problem with updating user.");
          setOpenSnackbar(true);
        }
      });
  }

  function createNewUser(user, resetForm, response) {
    if (response === 200) {
      dialog.closeModal();
      resetForm();
      setLoading(false);
      getUsers();
      return;
    }

    setLoading(false);
    setSnackbarMessage("There was a problem with creating user.");
    setOpenSnackbar(true);
  }

  const columns = [
    {
      field: "profileAvatar",
      headerName: "",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      width: 80,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.firstName || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.userSettingInfo !== null ? (
                <Image
                  alt={cellValues.row.firstName + " " + cellValues.row.lastName}
                  src={cellValues.row.userSettingInfo?.profilePicture}
                  className={classes.avatar}
                />
              ) : (
                <Avatar
                  style={{
                    backgroundColor: getColorFromString(
                      cellValues.row.firstName + cellValues.row.lastName
                    ),
                  }}
                >
                  {cellValues.row.firstName.charAt(0)}
                </Avatar>
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.firstName || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.firstName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.lastName || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.lastName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.email || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.email}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.status || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.status}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.role || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.role}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "userOpportunitiesCount",
      headerName: "Accounts",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: ({ value }) => value && parseInt(value),
      renderCell: (cellValues) => {
        return (
          <Button
            disableRipple
            sx={{ width: "100px" }}
            onClick={(e) => {
              e.stopPropagation();
              console.log(cellValues.row);
              dialog.openModal(
                "User accounts: " + cellValues.row.email,
                UserOpportunities,
                {
                  userEmail: cellValues.row.email,
                }
              );
            }}
          >
            {cellValues.row.userOpportunitiesCount} accounts
          </Button>
        );
      },
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        paddingBottom: `${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>Manage Users</Toolbar>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          pt: 1,
          borderRadius: "8px",
          height: "calc(100vh - " + theme.toolbar.height + "px - 20px)",
          paddingBottom: `${theme.workspace.commonPadding}px`,
          flexDirection: "column",
          margin: "0px 16px",
        }}
      >
        <div style={{ margin: "16px 24px", height: "100%" }}>
          <DataGridPro
            loading={loading}
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: "firstName", sort: "asc" }],
              },
            }}
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: LinearProgress,
            }}
            slotProps={{
              toolbar: {
                getUsers,
                addOrEdit,
              },
            }}
            className={classes.root}
            rowHeight={theme.opportunityList.rowHeight}
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableColumnSelector={true}
            onRowSelectionModelChange={onSelectionChange}
            disableMultipleSelection={true}
          />
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Card>
    </div>
  );
}
