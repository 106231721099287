import { Bubble } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import worldMap from "../../../../res/world_map.svg";
import { numberAbbrev } from "../../../../common/Utils";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const REGIONS = [
  { name: "North America", x: 19, y: 72 },
  { name: "South America", x: 45, y: 22 },
  { name: "Oceania", x: 195, y: 10 },
  { name: "APAC", x: 165, y: 50 },
  { name: "EMEA", x: 100, y: 79 },
  { name: "Americas", x: 22, y: 42 },
  { name: "LATAM", x: 30, y: 37 },
];

export default function Map(props) {
  const { mapDetail, isExecSummary } = props;

  const image = new Image();
  image.src = worldMap;

  const plugin = {
    id: "backgroundImage",
    beforeDraw: (chart, args, options) => {
      if (!options.display) {
        return;
      }
      if (image.complete) {
        const ctx = chart.ctx;
        ctx.drawImage(image, 0, 50, chart.width, chart.height - 50);
      } else {
        image.onload = () => chart.draw();
      }
    },
    defaults: {
      display: false,
    },
  };
  ChartJS.register(plugin);

  const yearText = mapDetail.Year ? `(FY ${mapDetail.Year})` : "";
  const radiusCutoff = 25;

  const options = {
    animation: false,
    clip: 100,
    scales: {
      y: {
        display: false,
        beginAtZero: true,
        min: 0,
        max: 100,
      },
      x: {
        display: false,
        beginAtZero: true,
        min: 0,
        max: 202,
      },
    },
    plugins: {
      backgroundImage: { display: true },
      legend: { display: false },
      title: {
        display: true,
        text: `${mapDetail.Title} ${yearText}`.trim(),
        position: "top",
        align: "start",
        fullSize: "true",
        padding: { top: -30 },
        font: {
          size: 14,
          family: "'Roboto','Helvetica','Arial',sans-serif",
          weight: "550",
        },
      },
      tooltip: false,
      datalabels: {
        labels: {
          name: {
            align: "top",
            anchor: "end",
            font: { size: 16 },
            offset: -5,
            formatter: function (value, ctx) {
              return ctx.dataset.label[ctx.dataIndex]?.[0];
            },
          },
          percent: {
            align: "bottom",
            anchor: function (ctx) {
              const value = ctx.dataset.data[ctx.dataIndex];
              return value.r > radiusCutoff ? "center" : "start";
            },
            offset: function (ctx) {
              const value = ctx.dataset.data[ctx.dataIndex];
              return value.r > radiusCutoff ? 5 : 15;
            },
            font: { size: 14, weight: 700 },
            color: function (ctx) {
              var value = ctx.dataset.data[ctx.dataIndex];
              return value.r > radiusCutoff ? "white" : "rgba(54, 162, 235, 1)";
            },
            formatter: function (value, ctx) {
              return ctx.dataset.label[ctx.dataIndex]?.[2];
            },
            padding: 4,
          },
          value: {
            align: function (ctx) {
              const value = ctx.dataset.data[ctx.dataIndex];
              return value.r > radiusCutoff ? "center" : "bottom";
            },
            anchor: function (ctx) {
              const value = ctx.dataset.data[ctx.dataIndex];
              return value.r > radiusCutoff ? "center" : "start";
            },
            offset: -5,
            font: { size: 18, weight: 700 },
            color: function (ctx) {
              const value = ctx.dataset.data[ctx.dataIndex];
              return value.r > radiusCutoff ? "white" : "rgba(54, 162, 235, 1)";
            },
            formatter: function (value, ctx) {
              return numberAbbrev(
                mapDetail.Currency,
                ctx.dataset.label[ctx.dataIndex]?.[1]
              );
            },
            padding: 4,
          },
        },
      },
    },
  };

  let total = 0;
  mapDetail.Revenue.forEach((item) => {
    total += item.Amount;
  });

  /** Kinda wonky. Will mess up REGIONS coordinates display if changed (not really sure why). */
  function calculateRadius(percent) {
    const num = Math.sqrt((percent * 100) / Math.PI);
    return num;
  }

  const data = {
    datasets: [
      {
        label: mapDetail.Revenue.map((revenue) => {
          const region = REGIONS.find((r) => r.name === revenue.Region);
          return [
            region.name,
            revenue.Amount,
            `${((100 * revenue.Amount) / total).toFixed(0)}%`,
          ];
        }),
        data: mapDetail.Revenue.map((revenue) => {
          const region = REGIONS.find((r) => r.name === revenue.Region);
          return {
            x: region.x,
            y: region.y,
            r: calculateRadius((revenue.Amount / total) * 100),
          };
        }),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <Bubble
      height="248px"
      width="400px"
      className={isExecSummary ? "splitFlag" : ""}
      style={{
        height: "248px",
        width: "400px",
      }}
      options={options}
      data={data}
    />
  );
}
