import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { convertFromRaw, EditorState } from "draft-js";
import Image from "../../../../common/Image";
import RichTextReader from "../../../../common/RichTextReader";
import { isString } from "lodash";
import { convertHtmlToRawContentState } from "../../../../det/common/utils";

// eslint-disable-next-line
const stubData = {
  Title: "Executive Overview",
  Content: {
    blocks: [
      {
        key: "b4b1l",
        text: "GPS is a laggard among its peer group in virtually every qualitative and quantitative measure, including financial performance, culture, NPS, and stock performance.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "4btl0",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "44bk9",
        text: "The automotive industry, including the aftermarket parts industry, is experiencing rapid and unprecedented change due to factors that include supply chain challenges caused by COVID; the shift to EV; and the new industry dynamics of mobility.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "5n064",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "2918t",
        text: "Margin improvements and business agility are themes that should resonate with GPS.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 19,
            style: "BOLD",
          },
          {
            offset: 24,
            length: 16,
            style: "BOLD",
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: "1k4je",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "17m8o",
        text: "TIBCO should lead with a message of how TIBCO drives margin improvements and should also promote themes of business agility and adaptability, enabled by the TIBCO ability to Integrate, Unify, and Connect data and systems, helping GPS prepare for the dynamic new landscape of their business.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  },
  NewUpdate: false,
};

const useStyles = makeStyles(() => ({
  cardContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    position: "relative",
    marginTop: "20px",
    border: "1px solid #EEE",
    borderRadius: "5px",
  },
  flexContent: {
    padding: "10px",
    paddingBottom: "0px",
    textAlign: "left",
  },
  flexCollapsed: {
    padding: "10px",
    paddingBottom: "0px",
    textAlign: "left",
    "&::after": {
      position: "absolute",
      bottom: 0,
      height: "30%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(255,255,255, 0.4), rgba(255,255,255, 0.8), rgba(255,255,255, 0.9));",
      pointerEvents: "none" /* so the text is still selectable */,
    },
  },
  image: {
    maxWidth: "100%",
    marginBottom: "16px",
    border: "1px solid #EEE",
    borderRadius: "5px",
  },
}));

export const convertToEditorState = (editorContent) => {
  // In case format changed e.g. during dev, still make it display nice
  if (isString(editorContent)) {
    return EditorState.createWithContent(
      convertFromRaw(convertHtmlToRawContentState(editorContent))
    );
  }
  const content = convertFromRaw(editorContent);
  const editorState = EditorState.createWithContent(content);
  return editorState;
};

export default function SingleRteCard(props) {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;

  const [editorState, setEditorState] = React.useState(null);
  const [executiveData, setExecutiveData] = React.useState(null);

  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    let isMounted = true; // note mutable flag
    if (previewData) {
      // Call Stub
      setExecutiveData(previewData);
      setEditorState(convertToEditorState(previewData.Content));
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId;
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            if (isMounted) {
              let rawdata = response.data;
              if (rawdata.Content) {
                // Validate content
                setExecutiveData(rawdata);
                setEditorState(convertToEditorState(rawdata.Content));
              } else {
                // API call failed
                setSnackbarMessage(
                  "There was a problem with the " + props.cardType + " Card"
                );
                setOpenSnackbar(true);
              }
            }
          })
          .catch((error) => {
            if (error.response && isMounted) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
    return () => {
      isMounted = true;
    };
  }, [firstTime, previewData]);

  if (!executiveData || !editorState) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={executiveData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        {executiveData.Image ? (
          <Image
            src={executiveData.Image}
            className={classes.image}
            alt={"Executive Summary"}
          />
        ) : (
          <div
            style={{
              padding: "0px 40px",
              marginTop: "0px",
              marginBottom: "25px",
            }}
          >
            <RichTextReader editorState={editorState} />
          </div>
        )}
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}
