import React from "react";

export default function EmbeddedVideo(props) {
  const { url } = props;

  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          paddingBottom: "56%",
          height: 0,
        }}
      >
        <iframe
          src={url}
          allowFullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "0px",
          }}
          title="video"
        ></iframe>
      </div>
    </React.Fragment>
  );
}
