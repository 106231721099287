import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useFormContext } from "react-hook-form";
import { TitleInput } from "../util/CardEditorButtons";

const useStyles = makeStyles(() => ({
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gridGap: "10px",
    padding: "15px",
    marginBottom: "15px",
    alignItems: "start",
  },
}));

export default function KeyPeriodsEditor(props) {
  const classes = useStyles();
  const { register } = useFormContext();

  return (
    <div>
      <TitleInput register={register} />
      <div className={classes.formGrid}>
        Current Quarter Number:
        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          {...register(`CurrentQuarterNumber`, {
            valueAsNumber: true,
          })}
        />
        Current Quarter Year:
        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          {...register(`CurrentQuarterYear`, {
            valueAsNumber: true,
          })}
        />
        Fiscal Year End (Month number e.g. 8 for August):
        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          {...register(`FiscalYearEnd`, {
            valueAsNumber: true,
          })}
        />
        Source Name:
        <input {...register(`Source.Name`)} />
        Source Logo:
        <input {...register(`Source.Logo`)} />
      </div>
    </div>
  );
}

// let testData =
// {
//     "Title": "Key Decision Period",
//     "CurrentQuarterNumber": 3,
//     "CurrentQuarterYear": 2021,
//     "FiscalYearEnd": 8,
//     "Source": {
//         "Name": "Comparably",
//         "Logo": "https://drive.google.com/file/d/1j0QpV_eNCcXOsTDIJtuS2kD-MXh2VfMn/view?usp=sharing"
//     }
// };
