import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useFormContext } from "react-hook-form";
import { TitleInput } from "../util/CardEditorButtons";

const useStyles = makeStyles(() => ({
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gridGap: "10px",
    padding: "15px",
    marginBottom: "15px",
    alignItems: "start",
  },
}));

export default function StockEditor(props) {
  const classes = useStyles();
  const { register } = useFormContext();

  return (
    <>
      <TitleInput register={register} />
      <div className={classes.formGrid}>
        Company Name:
        <input {...register(`CompanyName`)} />
        Stock Symbol:
        <input {...register(`StockSymbol`)} />
        Source Name:
        <input {...register(`Source.Name`)} />
        Source Logo:
        <input {...register(`Source.Logo`)} />
      </div>
    </>
  );
}

// let testData =
// {
//     "Title": "Shareholder Return",
//     "CompanyName": "Pfizer",
//     "StockSymbol": "PFE",
//     "stockExchangeCountry": "US",
//     "StockExchangeName": "NYSE",
//     "Source": {
//         "Name": "Bloomberg Finance",
//         "Logo": "https://drive.google.com/file/d/1j0QpV_eNCcXOsTDIJtuS2kD-MXh2VfMn/view?usp=sharing"
//     },
//     "NewUpdate": false
// };
