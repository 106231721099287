import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { rule5properties } from "../../../../properties";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { hasRteContent, numberAbbrev } from "../../../../common/Utils";
import { linkStyle } from "../../../../common/StyledComponents";
import OpenInNew from "@mui/icons-material/OpenInNew";
import RichTextReader from "../../../../common/RichTextReader";
import { convertFromRaw, EditorState } from "draft-js";
import { daySuffix } from "../../../../common/Utils.js";

const useStyles = makeStyles(() => ({
  cardContent: {},
  leftContent: {
    paddingBottom: "10px",
    width: "65%",
    minWidth: "200px",
    flexGrow: 1,
  },
  rightContent: {
    paddingLeft: "40px",
    paddingBottom: "10px",
    width: "35%",
    flexGrow: 1,
    display: "flex",
    flexFlow: "row wrap",
    rowGap: "40px",
    columnGap: "20px",
    justifyContent: "start",
    alignContent: "start",
  },
  execSummaryLeftContent: {
    paddingBottom: "10px",
    width: "50%",
    minWidth: "200px",
    paddingRight: "24px",
    flexGrow: 1,
    borderRight: "solid 1px rgba(0, 0, 0, 0.4)",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
  },
  keySpecContent: {
    paddingLeft: "24px",
    paddingBottom: "10px",
    width: "50%",
    flexGrow: 1,
    minWidth: "260px",
    display: "flex",
    flexDirection: "column",
  },
  linkContent: {
    justifyContent: "start",
    flexFlow: "row wrap",
    display: "flex",
    columnGap: "30px",
    height: "fit-content",
  },
  execSummaryKeySpec: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "15px",
  },
  linkStyle: linkStyle,

  flexContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
}));

function formatKeySpecValue(keySpec) {
  if (keySpec.Type === "Amount") {
    return numberAbbrev(keySpec.Currency, keySpec.Value);
  }
  if (keySpec.Type === "Integer") {
    return parseInt(keySpec.Value).toLocaleString();
  }

  if (keySpec.Name === "Fiscal Year End" && keySpec.Type === "Date") {
    let date = new Date(keySpec.Value);
    let options = { month: "long", day: "numeric" };

    const stringDate = new Intl.DateTimeFormat("en-US", options)
      .format(date)
      .split(" ");

    return stringDate[0] + " " + daySuffix(stringDate[1]);
  }

  return keySpec.Value;
}

export default function Overview(props) {
  const defaultOverviewDetail = {
    Title: "",
    Description: "",
    KeySpecs: [],
    Links: [],
  };
  const classes = useStyles();
  const [overviewDetail, setOverviewDetail] = useState(defaultOverviewDetail);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [hasError, setError] = React.useState(false);
  const previewData = props.previewData;

  useEffect(() => {
    setIsLoading(true);
    if (previewData) {
      setOverviewDetail({ ...defaultOverviewDetail, ...previewData });
      setIsLoading(false);
      return;
    }
    axios
      .get(`${rule5properties.getCard}/${props.cardId}`)
      .then((response) => {
        let rawdata = response.data;
        if (rawdata.Title) {
          // Validate content
          setOverviewDetail({ ...defaultOverviewDetail, ...rawdata });
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setError(true);
          setSnackbarMessage(
            "There was a problem with the " + props.cardType + " Card"
          );
          setOpenSnackbar(true);
        }
      });
  }, [previewData]);

  if (isLoading) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType || ""}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <CircularProgress />
      </Researchcard>
    );
  }

  if (hasError) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType || ""}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={overviewDetail.Title || ""}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <div className={classes.flexContainer}>
        <div className={classes.leftContent}>
          {hasRteContent(overviewDetail.rteDescription) ? (
            <RichTextReader
              editorState={EditorState.createWithContent(
                convertFromRaw(overviewDetail.rteDescription)
              )}
            />
          ) : (
            <Typography
              variant="body2"
              align="left"
              style={{ lineHeight: 1.5 }}
            >
              {overviewDetail.Description}
            </Typography>
          )}
        </div>
        <div className={classes.rightContent}>
          <div
            style={{
              maxWidth: "200px",
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
            }}
          >
            <h4 style={{ textAlign: "left", margin: "0px 0px 20px 0px" }}>
              Company Facts:
            </h4>
            {overviewDetail.KeySpecs.map((keySpec) => (
              <>
                {keySpec && (
                  <Typography variant="body2" align="left" key={keySpec.Name}>
                    <strong>{keySpec.Name}:</strong>{" "}
                    {formatKeySpecValue(keySpec)}
                  </Typography>
                )}
              </>
            ))}
          </div>
          <div className={classes.linkContent} style={{ width: "100px" }}>
            <h4 style={{ textAlign: "left", margin: "0px 0px 20px 0px" }}>
              Links:
            </h4>
            {overviewDetail.Links.length === 0 ? (
              <Typography
                sx={{ width: "100%", textAlign: "left", opacity: 0.5 }}
              >
                No links available.
              </Typography>
            ) : (
              overviewDetail.Links.map((link, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body2" align="left" noWrap>
                    <a
                      className={classes.linkStyle}
                      href={`//${link.Value}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link.Name}
                    </a>
                  </Typography>
                  <OpenInNew
                    sx={{
                      ml: "4px",
                      opacity: 0.7,
                      width: "18px",
                      pb: "2px",
                      color: "#176abd",
                    }}
                    color="inherit"
                  />
                </div>
              ))
            )}
          </div>
        </div>

        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </div>
    </Researchcard>
  );
}

/** Special component for use in automated exec summary card.
 * Has some display differences from regular Overview card. */
export function OverviewContentForExecSummary(props) {
  const classes = useStyles();
  const { overviewDetail, opportunityId } = props;

  let description;
  if (overviewDetail.Description?.length < 300) {
    description = overviewDetail.Description;
  } else {
    description = (
      <>
        {overviewDetail.Description?.substring(0, 299).concat("...")}
        <a
          className={classes.linkStyle}
          href={`/main/opportunities/${opportunityId}/research#company-overview`}
          target="_blank"
          rel="noreferrer"
        >
          <OpenInNew
            sx={{
              ml: "4px",
              opacity: 0.7,
              width: "18px",
              color: "#176abd",
              height: "auto",
            }}
            color="inherit"
          />
        </a>
      </>
    );
  }

  return (
    <div className={classes.flexContainer}>
      <div className={classes.execSummaryLeftContent}>
        {hasRteContent(overviewDetail.rteDescription) ? (
          <RichTextReader
            editorState={EditorState.createWithContent(
              convertFromRaw(overviewDetail.rteDescription)
            )}
          />
        ) : (
          <Typography variant="body2" align="left" style={{ lineHeight: 1.5 }}>
            {description}
          </Typography>
        )}
        <div className={classes.linkContent}>
          {overviewDetail.Links.length === 0 ? (
            <Typography sx={{ width: "100%", textAlign: "left", opacity: 0.5 }}>
              No links available.
            </Typography>
          ) : (
            overviewDetail.Links.map((link, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" align="left" noWrap>
                  <a
                    className={classes.linkStyle}
                    href={`//${link.Value}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.Name}
                  </a>
                </Typography>
                <OpenInNew
                  sx={{
                    ml: "4px",
                    opacity: 0.7,
                    width: "18px",
                    pb: "2px",
                    color: "#176abd",
                  }}
                  color="inherit"
                />
              </div>
            ))
          )}
        </div>
      </div>
      <div className={classes.keySpecContent}>
        <div className={classes.execSummaryKeySpec}>
          {overviewDetail.KeySpecs.map((keySpec) => (
            <Typography key={keySpec.Name} variant="body2" align="left">
              <strong>{keySpec.Name}:</strong> {formatKeySpecValue(keySpec)}
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
}
