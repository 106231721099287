import React from "react";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import axios from "axios";
import "./react-pdf-styles.css";
import { useInView } from "react-intersection-observer";
import { Document, Page, pdfjs, Thumbnail } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { rule5properties } from "../../properties";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { usePdfPageHighlighter } from "./usePdfPageHighlighter";
import { SizeMe } from "react-sizeme";
import { GrayInvertedIconButton } from "../../common/StyledComponents";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function PageWithObserver({
  pageNumber,
  visiblePage,
  setVisiblePage,
  ...otherProps
}) {
  const onIntersectionChange = React.useCallback(
    (inView, entry) => {
      if (inView) {
        setVisiblePage(pageNumber);
      }
    },
    [pageNumber, setVisiblePage]
  );

  function scrollPageIntoView(alignToTop) {
    if (visiblePage === pageNumber && !inView) {
      ref.current?.scrollIntoView(alignToTop);
    }
  }
  function onPageLoadSuccess(page) {
    scrollPageIntoView(true);
  }

  const ref = React.useRef();
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
    onChange: onIntersectionChange,
  });

  // For using same ref for two things https://www.npmjs.com/package/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
  const setRefs = React.useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  // In case user clicks on prev or next button
  React.useEffect(() => {
    scrollPageIntoView(false);
  }, [visiblePage, pageNumber]);

  return (
    <Page
      onLoadSuccess={onPageLoadSuccess}
      inputRef={setRefs}
      pageNumber={pageNumber}
      {...otherProps}
    />
  );
}

function ThumbnailWithScrollIntoView({
  pageNumber,
  visiblePage,
  ...otherProps
}) {
  const ref = React.useRef();

  React.useEffect(() => {
    // TODO would be nice to only scroll if the thumbnail isn't already visible
    if (visiblePage === pageNumber) {
      ref.current?.scrollIntoView(false);
    }
  }, [visiblePage, pageNumber]);

  return (
    <div
      style={{
        borderRadius: "12px",
        overflow: "visible",
      }}
    >
      <Thumbnail
        inputRef={ref}
        className={`thumbnailPage${
          visiblePage === pageNumber ? " visiblePage" : ""
        }`}
        width={120}
        key={`page1_${pageNumber}`}
        pageNumber={pageNumber}
        {...otherProps}
      />
      <Typography fontSize={12} textAlign="center" color="#525252">
        {pageNumber}
      </Typography>
    </div>
  );
}

export const MemoizedViewContent = React.memo(
  ViewContent,
  (prevProps, nextProps) => {
    if (prevProps.content && prevProps.content?.id === nextProps.content?.id) {
      return true;
    }
    return false;
  }
);

export function ViewContent(props) {
  const { content } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const initPage = parseInt(queryParams.get("page"));

  const [numPages, setNumPages] = React.useState();
  const [fileUrl, setFileUrl] = React.useState();
  const [visiblePage, setVisiblePage] = React.useState(initPage ? initPage : 1);
  const [loading, setLoading] = React.useState(true);
  const [pageScale, setPageScale] = React.useState(1);

  const highlightedPageTextItems = usePdfPageHighlighter(
    fileUrl,
    parseInt(queryParams.get("page")),
    parseInt(queryParams.get("startIndex")),
    parseInt(queryParams.get("length"))
  );

  function onDocumentLoadSuccess(props) {
    setNumPages(props.numPages);
    setLoading(false);
  }

  function onLoadProgress(props) {
    //todo progress bar
  }

  React.useEffect(() => {
    setLoading(true);
    downloadPdfFileUrl(content?.contentInfo?.storageUrl);
  }, [content]);

  function downloadPdfFileUrl(encodedUrl) {
    if (!encodedUrl) {
      return;
    }
    let parameter = "?url=" + encodedUrl;
    let options = {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    };
    axios
      .get(rule5properties.getSecureFile + parameter, options)
      .then((response) => {
        let rawdata = response.data;
        if (rawdata) {
          const file = new Blob([response.data], { type: "application/pdf" });
          setFileUrl(URL.createObjectURL(file));
        } else {
          // API call failed
          console.log("pdf retrieval fail: " + encodedUrl);
          console.log(response);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log("pdf retrieval fail: " + encodedUrl);
          console.log(error.response.status);
          console.log(error.response.data);
        }
        return;
      });
  }

  function changePage(offset) {
    setVisiblePage((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function changeScale(offset) {
    setPageScale((prevPageScale) => prevPageScale + offset);
  }

  function increaseScale() {
    changeScale(0.1);
  }

  function decreaseScale() {
    changeScale(-0.1);
  }

  const textRenderer = React.useCallback(
    (textItem) => {
      if (
        queryParams.has("startIndex") &&
        queryParams.has("length") &&
        queryParams.has("page")
      ) {
        const pageToHighlight = queryParams.get("page");
        if (parseInt(pageToHighlight) !== textItem.pageNumber) {
          // Only highlight the page from the queryString
          return textItem.str;
        }

        const highlighted = highlightedPageTextItems?.[textItem.itemIndex];
        return highlighted ? highlighted.str : textItem.str;
      } else {
        return textItem.str;
      }
    },
    [location.search, highlightedPageTextItems]
  );

  if (!fileUrl) {
    return null;
  }

  return (
    <div style={{ margin: "0 auto" }}>
      {loading && <CircularProgress sx={{ margin: "auto" }} />}
      <Document
        className="documentDiv"
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        externalLinkTarget="_blank"
      >
        <div
          style={{
            display: "flex",
            gap: "15px",
            height: "100%",
          }}
        >
          <Card
            sx={{
              height: "100%",
              overflow: "auto",
              borderRadius: "12px",
            }}
            variant="outlined"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                borderBottom: "1px solid gainsboro",
                backgroundColor: "rgb(89, 89, 89)",
              }}
            >
              <GrayInvertedIconButton
                size="small"
                disabled={visiblePage <= 1}
                onClick={previousPage}
              >
                <NavigateBeforeIcon />
              </GrayInvertedIconButton>
              <Typography color="white" padding="0px 3px">
                Page {visiblePage || (numPages ? 1 : "--")} of{" "}
                {numPages || "--"}
              </Typography>
              <GrayInvertedIconButton
                size="small"
                disabled={visiblePage >= numPages}
                onClick={nextPage}
              >
                <NavigateNextIcon />
              </GrayInvertedIconButton>

              <GrayInvertedIconButton
                size="small"
                sx={{ marginLeft: "auto" }}
                onClick={decreaseScale}
              >
                <ZoomOutIcon />
              </GrayInvertedIconButton>
              <Typography color="white" padding="0px 3px">
                Zoom {pageScale ? (pageScale * 100).toFixed(0) + "%" : "100%"}
              </Typography>
              <GrayInvertedIconButton size="small" onClick={increaseScale}>
                <ZoomInIcon />
              </GrayInvertedIconButton>
            </Box>
            <SizeMe>
              {({ size }) => (
                <div
                  style={{
                    overflow: "scroll",
                    borderRadius: "12px",
                    height: "90%",
                    width: "60vw",
                  }}
                >
                  {size.width &&
                    Array.from(new Array(numPages), (el, index) => (
                      <PageWithObserver
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderAnnotationLayer={false}
                        visiblePage={visiblePage}
                        setVisiblePage={setVisiblePage}
                        className="pageDiv"
                        scale={pageScale}
                        width={size.width}
                        customTextRenderer={textRenderer}
                      />
                    ))}
                </div>
              )}
            </SizeMe>
          </Card>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              width: "144px",
              overflow: "scroll",
              alignItems: "center",
            }}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <ThumbnailWithScrollIntoView
                className={`thumbnailPage${
                  visiblePage === index + 1 ? " visiblePage" : ""
                }`}
                width={120}
                key={`page1_${index + 1}`}
                pageNumber={index + 1}
                visiblePage={visiblePage}
                loading={null}
              />
            ))}
          </Box>
        </div>
      </Document>
    </div>
  );
}
