import { Box, CircularProgress, Fade, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import axios from "axios";
import { rule5properties } from "../properties";
import {
  AddToSlackButton,
  SlackIntegration,
} from "./integrations/SlackIntegration";
import { useDialog } from "../context/DialogContext";

export default function Integrations(props) {
  const location = useLocation();
  const userContext = useUser();
  const dialog = useDialog();

  const [loaded, setLoaded] = React.useState(false);
  const [configuredSlackChannels, setConfiguredSlackChannels] =
    React.useState(null);

  function fetchSlackConfig() {
    axios
      .get(rule5properties.userMessageChannels + "?system=Slack")
      .then((res) => {
        if (res.status === 200) {
          setConfiguredSlackChannels(res.data);
        }
        if (!loaded) {
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Check for existing slack config / setup
  useEffect(() => {
    fetchSlackConfig();
  }, []);

  // integration code is present, perform integration setup
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    if (
      searchParams.get("code") &&
      searchParams.get("state") === userContext.email
    ) {
      // An authorization code is present and email state match, call Slack callback.
      const params = { code: searchParams.get("code") };
      dialog.openModal(
        "",
        () => (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ),
        { fixed: true },
        "sm"
      );
      axios
        .post(rule5properties.slackCallback, params)
        .then((res) => {
          if (res.status === 200) {
            fetchSlackConfig();
            dialog.closeModal();
          } else {
            dialog.closeModal();
            console.log("Failed to set up integration.");
            console.log(res);
          }
        })
        .catch((err) => {
          dialog.closeModal();
          console.log("Failed to set up integration:");
          console.log(err);
        });
    }
  }, [location.search, userContext.email]);

  return (
    <Fade in={loaded}>
      <Stack sx={{ width: "500px", flexGrow: 1 }}>
        <SlackIntegration
          configuredSlackChannels={configuredSlackChannels}
          setConfiguredSlackChannels={setConfiguredSlackChannels}
        />
      </Stack>
    </Fade>
  );
}
