import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  InputAdornment,
  Card,
  Stack,
  Collapse,
  Button,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
  Link,
} from "@mui/material";
import {
  StyledTextfield,
  StyledLabel,
  ErrLabel,
} from "../common/StyledComponents";
import AuthService from "./AuthService";
import JwtService from "./JwtService";
import { useHistory, useLocation } from "react-router-dom";
import { useUserUpdate } from "../context/UserContext";
import { detRoles } from "../det/DetMainPage";
import { rule5BaseUrl } from "../properties";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import rule5logo from "../res/r5_logo_color.svg";
import { LoadingButton } from "@mui/lab";
import ThirdPartyAuthButton from "./ThirdPartyAuth";

const Joi = require("joi");

export const emailSchema = Joi.string().email({ tlds: { allow: false } });

const useStyles = makeStyles(() => ({
  formCard: {
    margin: "0px 140px 140px 140px",
    borderRadius: "10px",
    width: 470,
    boxSizing: "border-box",
    padding: 42,
    boxShadow: "0 3px 3px -2px rgba(0, 0, 0, 0.1)",
  },
  formContainer: {},
  inputField: {
    boxSizing: "border-box",
    width: "100%",
  },
  thinTitle: {
    opacity: 0.8,
  },
  errorTitle: {
    color: "red",
    opacity: 1,
  },
  link: {
    textDecoration: "underline 0.1em rgba(0,0,0,0)",
    fontWeight: 500,
    color: "rgb(25, 118, 210)",
    transition: "text-decoration-color 0.2s",
    "&:hover": {
      textDecorationColor: "rgb(25, 118, 210)",
    },
    "&:active": {
      color: "rgba(0,0,0,0.5)",
    },
  },
  linkStyle: {
    fontSize: "13px",
    opacity: 0.8,
  },
  confirmationMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "30px",
  },
  headerLogo: {
    height: "80px",
    display: "flex",
    justifyContent: "center",
    marginTop: "100px",
  },
}));

export const PRICING_PAGE = rule5BaseUrl.includes("dev")
  ? " https://dev-pricing.webflow.io"
  : "https://rule5.io/pricing";

export default function Registration(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const planType = searchParams.get("planType");
  const timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
  const inviteToken = searchParams.get("invite");
  const defaultError = searchParams.get("error");

  const emptySignupForm = {
    firstName: "",
    lastName: "",
    orgName: "",
    email: "",
    password: "",
    confirmPassword: "",
    planType: planType,
    timeZone: timeZone,
  };

  const emptyInviteForm = {
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    token: "",
    planType: planType,
    timeZone: timeZone,
  };

  const [formValues, setFormValues] = React.useState(emptySignupForm);
  const [formSuccess, setFormSuccess] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [inviteMode, setInviteMode] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  // Validation and feedback - submission is only enabled when no helper text is present (errors)
  const [helperTexts, setHelperTexts] = React.useState(emptySignupForm);
  const [loginStatus, setLoginStatus] = React.useState("");

  const [status, setStatus] = React.useState(defaultError || "");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [isEmailFlow, setIsEmailFlow] = React.useState(false);

  const [invitedData, setInvitedData] = React.useState(null);

  const updateUserContext = useUserUpdate();

  useEffect(() => {
    let userToken = JwtService.getToken();
    if (userToken) {
      history.push("/main");
    }

    if (inviteToken !== null) {
      // Verify token.
      AuthService.verifyInvitationToken(inviteToken).then((res) => {
        if (res.status === 200) {
          setInviteMode(true);
          setFormValues({
            ...emptyInviteForm,
            token: inviteToken,
          });
          // DET users come through same invite flow, they don't need planType though.
          const detUser = detRoles.includes(res.data.role);
          // Set invited data so that Okta integration does not need to ask for email.
          setInvitedData(res.data);
          if (!planType && !detUser) {
            // Pass the invite along to pricing page.
            window.location.href = `${PRICING_PAGE}?invite=${inviteToken}`;
          }
        } else {
          setSuccessMessage(
            "The invitation link is either invalid or has expired. Let your teammate know that you'd like to request a new invitation."
          );
          setFormSuccess(false);
        }
      });
      // axios.get();
    } else {
      // Kick the user back to pricing page to get a plan, if they don't have one
      if (!planType) {
        window.location.href = PRICING_PAGE;
      }
      setInviteMode(false);
    }
  }, []);

  const handleFormChange = (event) => {
    setStatus("");
    setLoginStatus("");
    const newVal = event.target.value;
    const newFormVals = {
      ...formValues,
      [event.target.name]: newVal,
    };
    let newHelperTexts = {
      ...helperTexts,
      [event.target.name]: "",
    };
    if (event.target.name === "password") {
      newHelperTexts.confirmPassword = "";
    }
    setFormValues(newFormVals);
    // No errors left in the form.
    if (
      newHelperTexts.firstName === "" &&
      newHelperTexts.lastName === "" &&
      newHelperTexts.orgName === "" &&
      newHelperTexts.email === "" &&
      newHelperTexts.password === "" &&
      newHelperTexts.confirmPassword === ""
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    // Remove any helper texts for a field when it is modified.
    setHelperTexts(newHelperTexts);
  };

  const onSubmit = (event) => {
    // There are two submit buttons in the email registration form.
    // The first initiates email flow and enables rest of form fields.
    event.preventDefault();
    if (!isEmailFlow) {
      setIsEmailFlow(true);
    } else {
      processRegistration(event);
    }
  };

  const processRegistration = (event, mode) => {
    setDisabled(true);
    setStatus("");
    let newHelperTexts = Object.assign({}, emptySignupForm);
    let preSubmitErrors = false;
    // Perform basic validation prior to calling API.
    // First name validation / feedback
    if (formValues.firstName === "") {
      newHelperTexts.firstName = "First name is required.";
      preSubmitErrors = true;
    }
    // Last name validation / feedback
    if (formValues.lastName === "") {
      newHelperTexts.lastName = "Last name is required.";
      preSubmitErrors = true;
    }
    // Organization validation / feedback
    if (formValues.orgName === "") {
      newHelperTexts.orgName = "Organization name is required.";
      preSubmitErrors = true;
    }
    // Email validation / feedback
    if (formValues.email === "") {
      newHelperTexts.email = "Email address is required.";
      preSubmitErrors = true;
    } else {
      if (emailSchema.validate(formValues.email).error) {
        newHelperTexts.email = "Email address is invalid.";
        preSubmitErrors = true;
      }
    }
    // Password validation / feedback
    if (formValues.password === "") {
      newHelperTexts.password = "Password is required.";
      preSubmitErrors = true;
    } else {
      const regex = /^(?=.*\d)(?=.*[!@#$%^&?*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!regex.test(formValues.password)) {
        newHelperTexts.password =
          "Password requires minimum 8 characters (one uppercase, one lowercase, one special char, one number).";
        preSubmitErrors = true;
      }
    }
    // Confirmation password validation.
    if (formValues.confirmPassword !== formValues.password) {
      newHelperTexts.confirmPassword = "Confirmation password does not match.";
      preSubmitErrors = true;
    }
    if (!preSubmitErrors) {
      setLoading(true);
      if (inviteMode) {
        AuthService.invitationRegister(formValues).then((res) => {
          let data = res.data;
          setLoading(false);
          if (data.accessToken) {
            setSuccessMessage("Redirecting...");
            setFormSuccess(true);
            updateUserContext(data);
            if (detRoles.includes(data.role)) {
              history.push("/det/managecards");
            } else {
              history.push("/main");
            }
          } else if (data.code === "NOT_FOUND") {
            setStatus(data.status);
          } else if (data.code === "INVALID_ARGUMENT") {
            // Passthrough detailed message from backend for INVALID_ARGUMENT.
            setStatus(data.message);
          } else {
            setStatus(data.code);
          }
        });
      } else {
        AuthService.register(formValues).then((res) => {
          let data = res.data;
          if (data?.code === "VALIDATION_ERROR") {
            console.log("Validation error from service.");
          } else if (data?.code === "ORG_EXISTS") {
            newHelperTexts.email = "Organization subdomain is already in use.";
          } else if (data?.code === "EMAIL_EXISTS") {
            newHelperTexts.email = "Email address is already in use.";
          } else if (
            data === "OK" ||
            data?.status === "Pending Email Verification" // TODO should remove this.
          ) {
            setSuccessMessage(
              `A verification link has been sent to ${formValues.email}.`
            );
            setFormSuccess(true);
          } else {
            setStatus(data.message);
          }
          setLoading(false);
        });
      }
      setDisabled(false);
    }
    // Update helper text object.
    setHelperTexts(newHelperTexts);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      className={classes.pageWrapper}
    >
      <form className={classes.formContainer} onSubmit={onSubmit}>
        <div className={classes.headerLogo}>
          <img
            src={rule5logo}
            alt="logo"
            style={{ height: "50px", marginTop: "8px" }}
          />
        </div>
        <Card className={classes.formCard} variant="outlined">
          {successMessage ? (
            <div className={classes.confirmationMessage}>
              {formSuccess && (
                <Typography variant="h5" textAlign="center">
                  Registration Successful
                </Typography>
              )}
              <Typography variant="body2" textAlign="center">
                {successMessage}
              </Typography>
              {formValues.email ? (
                <LoadingButton
                  variant="outlined"
                  disableElevation
                  loading={loading}
                  sx={{ textTransform: "none", width: "175x" }}
                  onClick={() => {
                    setLoading(true);
                    AuthService.resendEmailVerification(formValues.email).then(
                      (data) => {
                        setLoading(false);
                        if (data === "OK") {
                          setSuccessMessage(
                            `Verification email re-sent to ${formValues.email}.`
                          );
                        } else {
                          setStatus(data.message);
                        }
                      }
                    );
                  }}
                >
                  Resend Verification Email
                </LoadingButton>
              ) : (
                <Button
                  variant="outlined"
                  disableElevation
                  href="/login"
                  sx={{ textTransform: "none", width: "175x" }}
                >
                  Return to Login
                </Button>
              )}
            </div>
          ) : (
            <React.Fragment>
              <Stack alignItems="center">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    className={classes.thinTitle}
                    variant="h5"
                    sx={{ mb: 1, fontWeight: "500" }}
                  >
                    Sign Up
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }} textAlign="center">
                    <Link className={classes.link} href="/login">
                      I have an account
                    </Link>
                  </Typography>
                </div>
                <Collapse
                  in={!isEmailFlow}
                  sx={{ textAlign: "center", width: "100%" }}
                >
                  <ThirdPartyAuthButton
                    type="sfdc"
                    statusUpdateFunction={setLoginStatus}
                  />
                  <ThirdPartyAuthButton
                    type="google"
                    statusUpdateFunction={setLoginStatus}
                  />
                  {invitedData?.oktaEnabled && (
                    <ThirdPartyAuthButton
                      email={invitedData?.email}
                      type="okta"
                      inviteToken={inviteToken}
                      timeZone={timeZone}
                      statusUpdateFunction={setLoginStatus}
                    />
                  )}
                  <Collapse
                    in={loginStatus?.trim().length > 0}
                    sx={{ textAlign: "center", width: "96%" }}
                  >
                    <ErrLabel sx={{ mt: 1 }}>{loginStatus}</ErrLabel>
                  </Collapse>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 5fr 2fr",
                      alignItems: "center",
                      marginTop: "20px",
                      padding: "0px 30px",
                    }}
                  >
                    <Divider sx={{ width: "100%" }} />
                    <Typography
                      textAlign="center"
                      variant="caption"
                      className={classes.linkStyle}
                    >
                      Or sign up through email
                    </Typography>
                    <Divider sx={{ width: "100%" }} />
                  </div>
                </Collapse>
                {!inviteMode && (
                  <>
                    <StyledLabel sx={{ width: "100%", ml: 1, mt: 2 }}>
                      Email
                    </StyledLabel>
                    <StyledTextfield
                      name="email"
                      className={classes.inputField}
                      variant="outlined"
                      label="Email"
                      onChange={handleFormChange}
                    />
                    <Collapse
                      in={helperTexts.email}
                      sx={{ textAlign: "left", width: "96%" }}
                    >
                      <ErrLabel>{helperTexts.email}</ErrLabel>
                    </Collapse>
                  </>
                )}
                {!isEmailFlow ? (
                  <Button
                    disabled={
                      !inviteMode &&
                      emailSchema.validate(formValues.email).error
                    }
                    variant="outlined"
                    sx={{ mt: 3, mb: 3, width: 200, textTransform: "none" }}
                    type="submit"
                  >
                    Continue
                  </Button>
                ) : (
                  <>
                    <StyledLabel sx={{ width: "100%", ml: 1, mt: 2 }}>
                      First Name
                    </StyledLabel>
                    <StyledTextfield
                      name="firstName"
                      className={classes.inputField}
                      variant="outlined"
                      label="First Name"
                      onChange={handleFormChange}
                      autoFocus
                    />
                    <Collapse
                      in={helperTexts.firstName}
                      sx={{ textAlign: "left", width: "96%" }}
                    >
                      <ErrLabel>{helperTexts.firstName}</ErrLabel>
                    </Collapse>

                    <StyledLabel sx={{ width: "100%", ml: 1, mt: 2 }}>
                      Last Name
                    </StyledLabel>
                    <StyledTextfield
                      name="lastName"
                      className={classes.inputField}
                      variant="outlined"
                      label="Last Name"
                      onChange={handleFormChange}
                    />
                    <Collapse
                      in={helperTexts.lastName}
                      sx={{ textAlign: "left", width: "96%" }}
                    >
                      <ErrLabel>{helperTexts.lastName}</ErrLabel>
                    </Collapse>

                    {!inviteMode && (
                      <>
                        <StyledLabel sx={{ width: "100%", ml: 1, mt: 2 }}>
                          Organization
                        </StyledLabel>
                        <StyledTextfield
                          name="orgName"
                          className={classes.inputField}
                          variant="outlined"
                          label="Organization"
                          onChange={handleFormChange}
                        />
                        <Collapse
                          in={helperTexts.orgName}
                          sx={{ textAlign: "left", width: "96%" }}
                        >
                          <ErrLabel>{helperTexts.orgName}</ErrLabel>
                        </Collapse>
                      </>
                    )}

                    <Typography
                      className={classes.thinTitle}
                      variant="h5"
                      sx={{ mt: 4, mb: 1, width: "100%", fontWeight: 500 }}
                    >
                      Credentials
                    </Typography>

                    <StyledLabel sx={{ width: "100%", ml: 1, mt: 2 }}>
                      Password
                    </StyledLabel>
                    <StyledTextfield
                      name="password"
                      error={true}
                      className={classes.inputField}
                      variant="outlined"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      onChange={handleFormChange}
                      endAdornment={
                        <InputAdornment
                          sx={{
                            backgroundColor: "none",
                            mr: "4px",
                            opacity: 0.5,
                          }}
                          position="end"
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword((prevShow) => !prevShow);
                            }}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Collapse
                      in={helperTexts.password}
                      sx={{ textAlign: "left", width: "96%" }}
                    >
                      <ErrLabel>{helperTexts.password}</ErrLabel>
                    </Collapse>

                    <StyledLabel sx={{ width: "100%", ml: 1, mt: 2 }}>
                      Confirm Password
                    </StyledLabel>
                    <StyledTextfield
                      name="confirmPassword"
                      className={classes.inputField}
                      variant="outlined"
                      label="Confirm Password"
                      type="password"
                      autoComplete="off"
                      onChange={handleFormChange}
                    />
                    <Collapse
                      in={helperTexts.confirmPassword}
                      sx={{ textAlign: "left", width: "96%" }}
                    >
                      <ErrLabel>{helperTexts.confirmPassword}</ErrLabel>
                    </Collapse>
                    <Collapse
                      in={status}
                      sx={{ textAlign: "left", width: "96%" }}
                    >
                      <ErrLabel>{status}</ErrLabel>
                    </Collapse>
                    {loading ? (
                      <CircularProgress sx={{ m: 3 }} />
                    ) : (
                      <Button
                        disabled={disabled}
                        variant="outlined"
                        type="submit"
                        sx={{ mt: 3, mb: 3, width: 200, textTransform: "none" }}
                      >
                        Continue
                      </Button>
                    )}
                  </>
                )}
                <Typography
                  variant="caption"
                  sx={{ mt: 1, opacity: 0.8 }}
                  textAlign="center"
                  className={classes.linkStyle}
                >
                  By signing up, you agree to the{" "}
                  <Link
                    className={classes.link}
                    component="a"
                    href="https://www.rule5.io/terms"
                    target="_blank"
                  >
                    Terms of Service
                  </Link>
                  ,{" "}
                  <Link
                    className={classes.link}
                    component="a"
                    href="https://docs.google.com/document/u/1/d/e/2PACX-1vTdRT5odGEDCMl0C5YGBcysvFE_OlaXotg2A3xnFRHlJAzYGz-U6pm5ahLQv69Q5NmTB7JyedpQo3wl/pub"
                    target="_blank"
                  >
                    Data Processing Agreement
                  </Link>
                  , and{" "}
                  <Link
                    className={classes.link}
                    component="a"
                    href="https://www.rule5.io/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Stack>
            </React.Fragment>
          )}
        </Card>
      </form>
    </Stack>
  );
}
