import React, { useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RichTextEditor from "../util/RichTextEditor";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const names = [
  { name: "Revenue", initialType: "Amount" },
  { name: "Profit", initialType: "Amount" },
  { name: "Operating Income", initialType: "Amount" },
  { name: "Market Cap", initialType: "Amount" },
  { name: "Industry", initialType: "Text" },
  { name: "Employee Count", initialType: "Integer" },
  { name: "Open Jobs Count", initialType: "Integer" },
  { name: "Year Founded", initialType: "Text" },
  { name: "Type", initialType: "Text" },
  { name: "Headquarters", initialType: "Text" },
  { name: "Ownership", initialType: "Text" },
  { name: "Stock Tickers", initialType: "Text" },
  { name: "Fiscal Year End", initialType: "Date" },
];

const linkNames = [
  { name: "Website" },
  { name: "Events" },
  { name: "Values & Culture" },
];

const keySpecTypes = [
  { value: "Integer", label: "Integer" },
  { value: "Amount", label: "Amount" },
  { value: "Text", label: "Text" },
  { value: "Link", label: "Link" },
  { value: "Date", label: "Date" },
];

const ValueInput = ({ control, keySpecIndex, keySpec }) => {
  const value = useWatch({
    name: "KeySpecs",
    control,
  });

  return (
    <Controller
      control={control}
      name={`KeySpecs[${keySpecIndex}].Value`}
      render={({ field }) => {
        const inputType = ["Integer", "Amount"].includes(
          value?.[keySpecIndex]?.Type
        )
          ? "number"
          : "text";
        return (
          <input {...field} type={inputType} onWheel={(e) => e.target.blur()} />
        );
      }}
    />
  );
};

export default function OverviewEditor(props) {
  const formStyle = props.formStyle;
  const {
    register,
    control,
    trigger,
    getValues,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "KeySpecs",
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      <TitleInput
        register={register}
        defaultValue={props.buyerName + " Overview"}
      />
      <h5>Description</h5>
      <TextareaAutosize
        {...register(`Description`, {})}
        aria-label=""
        minRows={3}
        style={{ width: "100%" }}
      />
      -OR-
      <hr></hr>
      <Controller
        name="rteDescription"
        control={control}
        render={({ field }) => {
          return (
            <RichTextEditor value={field.value} onChange={field.onChange} />
          );
        }}
      />
      <Divider
        light
        style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
      />
      <h5> Key Specs: </h5>
      {fields.map((keySpec, keySpecIndex) => (
        <div key={keySpec.id} className={formStyle}>
          Name
          <div>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={names.map((row, index) => {
                    return row.name;
                  })}
                  onChange={(e, data) => {
                    const inputType = names.find((name) => {
                      return name.name === data;
                    });

                    if (inputType && inputType.initialType) {
                      setValue(
                        `KeySpecs[${keySpecIndex}].Type`,
                        inputType.initialType
                      );
                    }

                    return field.onChange(data);
                  }}
                  freeSolo
                  autoSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Key Spec"
                      // placeholder=""
                    />
                  )}
                />
              )}
              name={`KeySpecs[${keySpecIndex}].Name`}
              type="select"
              control={control}
              defaultValue=""
              rules={{ required: { value: true, message: "Name is required" } }}
            />
            {errors?.KeySpecs?.[keySpecIndex]?.Name?.type === "required" && (
              <p>{errors.KeySpecs?.[keySpecIndex]?.Name.message}</p>
            )}
          </div>
          Type
          <div>
            <Controller
              name={`KeySpecs[${keySpecIndex}].Type`}
              control={control}
              defaultValue=""
              rules={{ required: { value: true, message: "Type is required" } }}
              render={({ field }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="type-label">Type</InputLabel>
                  <Select
                    {...field}
                    labelId="type-label"
                    id="type-select"
                    label="type"
                  >
                    {keySpecTypes.map((row, index) => {
                      return (
                        <MenuItem key={index} value={row.value}>
                          {row.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
            {errors?.KeySpecs?.[keySpecIndex]?.Type?.type === "required" && (
              <p>{errors.KeySpecs?.[keySpecIndex]?.Type.message}</p>
            )}
          </div>
          <>
            {watch(`KeySpecs[${keySpecIndex}].Type`) === "Date" ? (
              <>
                Date
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name={`KeySpecs[${keySpecIndex}].Value`}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: { value: true, message: "Date is required" },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        openTo="month"
                        views={["month", "day"]}
                        label="month and date"
                        value={field.value}
                        onChange={(e, data) => {
                          return field.onChange(data);
                        }}
                        inputFormat="MMMM dd"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={null}
                            sx={{ width: "200px" }}
                          />
                        )}
                        {...field}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            ) : (
              <>
                Value
                <ValueInput {...{ control, keySpecIndex, keySpec }} />
                {watch(`KeySpecs[${keySpecIndex}].Type`) === "Amount" && (
                  <>
                    Currency
                    <input
                      {...register(`KeySpecs[${keySpecIndex}].Currency`)}
                      defaultValue="USD"
                    />
                  </>
                )}
              </>
            )}
          </>
          <MoveAutocomplete
            onChange={(moveToIndex) => {
              move(keySpecIndex, moveToIndex);
              trigger();
            }}
            index={keySpecIndex}
            options={[...Array(getValues("KeySpecs").length).keys()]}
          ></MoveAutocomplete>
          <DeleteButton
            onClick={() => {
              setLastDeleted(keySpec);
              remove(keySpecIndex);
              trigger();
            }}
          >
            Delete Key Spec
          </DeleteButton>
          <hr />
        </div>
      ))}
      <AddButton
        onClick={() => {
          appendFuncClickHandler(append, lastDeleted);
        }}
      >
        Add Key Spec
      </AddButton>
      <Divider
        light
        style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
      />
      <h5> Links: </h5>
      <Links {...{ trigger, control, register, getValues, formStyle }} />
    </div>
  );
}

/** Form array */
function Links({ trigger, control, register, getValues, formStyle }) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `Links`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  const removeHttp = (url) => {
    //TODO kinda lazy
    let urlCopy = url.replace("http://", "");
    urlCopy = urlCopy.replace("https://", "");
    return urlCopy;
  };

  return (
    <div>
      {fields.map((item, linksIndex) => {
        return (
          <div key={item.id} className={formStyle}>
            Name
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={linkNames.map((row, index) => {
                    return row.name;
                  })}
                  onChange={(e, data) => {
                    return field.onChange(data);
                  }}
                  freeSolo
                  autoSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      // placeholder=""
                    />
                  )}
                />
              )}
              name={`Links[${linksIndex}].Name`}
              type="select"
              control={control}
              defaultValue=""
            />
            Value
            {/* <input {...register(`Links[${linksIndex}].Value`)} /> */}
            <Controller
              control={control}
              defaultValue=""
              name={`Links[${linksIndex}].Value`}
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    onChange={(e) => field.onChange(removeHttp(e.target.value))}
                  />
                );
              }}
            />
            <MoveAutocomplete
              onChange={(moveToIndex) => {
                move(linksIndex, moveToIndex);
                trigger();
              }}
              index={linksIndex}
              options={[...Array(getValues("Links").length).keys()]}
            ></MoveAutocomplete>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(linksIndex);
                trigger();
              }}
            >
              Delete Link
            </DeleteButton>
          </div>
        );
      })}
      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Link
      </AddButton>
    </div>
  );
}

// let testData =
// {
//     "Title": "Pfizer Overview",
//     "Description": "Pfizer applies science and global reLinks to bring therapies to people that extend and significantly improve their lives. We strive to set the standard for quality, safety, and value in the discovery, development and manufacturing of medicines for people. Our diversified global health care portfolio includes biologic and small molecule medicines and vaccines, as well as many of the world's best-known consumer. Every day, Pfizer colleagues all over the world work to advance wellness, prevention, treatments and cures that challenge the most feared diseases of our time.",
//     "KeySpecs": [
//         {
//             "Name": "Revenue",
//             "Value": 46400000000,
//             "Type": "Amount",
//             "Currency": "USD"
//         },
//         {
//             "Name": "Profit",
//             "Value": 16570000000,
//             "Type": "Amount",
//             "Currency": "USD"
//         },
//         {
//             "Name": "Industry",
//             "Value": "Biotech & Pharma"
//         },
//         {
//             "Name": "Employee Count",
//             "Value": 78500,
//             "Type": "Integer"
//         },
//         {
//             "Name": "OpenJobs Count",
//             "Value": 1900,
//             "Type": "Integer"
//         },
//         {
//             "Name": "Year Founded",
//             "Value": 1849,
//             "Type": "Integer"
//         },
//         {
//             "Name": "Type",
//             "Value": "Public Company (PTE)"
//         },
//         {
//             "Name": "Headquarters",
//             "Value": "New York"
//         }
//     ],
//     "Sources": [
//         {
//             "Name": "pfizer.com",
//             "Logo": "https://drive.google.com/file/d/1IXlvw8c-KguehZ1LDjwXTyl-h8-dm_YJ/view?usp=sharing"
//         },
//         {
//             "Name": "pfizer.com/events",
//             "Logo": "https://drive.google.com/file/d/1IXlvw8c-KguehZ1LDjwXTyl-h8-dm_YJ/view?usp=sharing"
//         }
//     ],
//     "NewUpdate": false
// }
