import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  numberAbbrev,
  percentFormatter,
  formatDate,
} from "../../../../common/Utils";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../common/StyledComponents";
import RichTextReader from "../../../../common/RichTextReader";
import { convertToEditorState } from "./SingleRteCard";

const useStyles = makeStyles(() => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  flexContent: {
    textAlign: "left",
    flexGrow: 1,
    position: "relative",
    margin: "10px",
    width: 320,
    "&:hover button": {
      display: "inherit",
    },
  },
}));

export default function QuarterlyEarnings(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  const [quarterlyEarningsData, setQuarterlyEarningsData] =
    React.useState(null);
  const [editorState, setEditorState] = React.useState(null);

  useEffect(() => {
    if (previewData) {
      // previewData in case of Newton user editing card
      setQuarterlyEarningsData(previewData);
      if (previewData.earningsCallSummary) {
        setEditorState(convertToEditorState(previewData.earningsCallSummary));
      }
    } else {
      // Call API on initial load
      let parameter = "/" + props.cardId;
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          if (rawdata.earningDate) {
            // Validate content
            setQuarterlyEarningsData(rawdata);
            setEditorState(convertToEditorState(rawdata.earningsCallSummary));
          } else {
            // API call failed
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        });
    }
  }, [previewData]);

  if (!quarterlyEarningsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }
  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={quarterlyEarningsData.Title}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <QuarterlyEarningsContent
        editorState={editorState}
        quarterlyEarningsData={quarterlyEarningsData}
        {...props}
      ></QuarterlyEarningsContent>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}

export function QuarterlyEarningsContent(props) {
  const { quarterlyEarningsData, editorState } = props;
  const classes = useStyles();

  let latestEarningDate;
  let latestAnnouncementDate;
  if (quarterlyEarningsData?.earningValues?.length > 0) {
    const earningDate = new Date(
      quarterlyEarningsData.earningValues[0].fiscalQuarterEndDate
    );
    const announcementDate = new Date(
      quarterlyEarningsData.earningValues[0].announcementDate
    );
    // Can't really believe below line is required but I guess it is. Otherwise dates from browsers in e.g.
    // Pacific time will subtract 7 or 8 hours from the date, thereby making it the wrong date (ie march 2nd becomes march 1st)
    earningDate.setMinutes(
      earningDate.getMinutes() + earningDate.getTimezoneOffset()
    );
    announcementDate.setMinutes(
      announcementDate.getMinutes() + announcementDate.getTimezoneOffset()
    );
    latestEarningDate = earningDate.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    latestAnnouncementDate = formatDate(announcementDate);
  }

  return (
    <div className={classes.flexContainer}>
      <div className={classes.flexContent}>
        <Typography variant="body1" gutterBottom component="div">
          {`Summary of ${latestEarningDate} Earnings`}
          <i>{` (reported on ${latestAnnouncementDate})`}</i>
        </Typography>

        <RichTextReader editorState={editorState} />
        <EarningsTable
          rows={quarterlyEarningsData.earningValues}
          currency={quarterlyEarningsData.currency}
        ></EarningsTable>
      </div>
    </div>
  );
}

function EarningsTable(props) {
  const { rows, currency } = props;

  const epsFormatter = new Intl.NumberFormat("en-US", {
    ...(currency ? { style: "currency", currency: currency } : {}),
    notation: "compact",
    minimumFractionDigits: 2,
  });

  if (rows != null) {
    return (
      <>
        {" "}
        <Typography variant="body1" gutterBottom component="div">
          Recent history:
        </Typography>
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="kpi table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Quarter end</StyledTableCell>
                <StyledTableCell align="right">
                  Expected revenue
                </StyledTableCell>
                <StyledTableCell align="right">Actual revenue</StyledTableCell>
                <StyledTableCell align="left">Revenue results</StyledTableCell>
                <StyledTableCell align="right">Expected EPS</StyledTableCell>
                <StyledTableCell align="right">Actual EPS</StyledTableCell>
                <StyledTableCell align="left">EPS results</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const revenueBeatPercent =
                  (row.revenue - row.revenueEstimated) / row.revenueEstimated;
                const epsBeatPercent =
                  (row.eps - row.epsEstimated) / row.epsEstimated;
                const date = new Date(row.fiscalQuarterEndDate);
                const formattedDate = date.toLocaleString("default", {
                  month: "short",
                  year: "numeric",
                });

                return (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell>{formattedDate}</StyledTableCell>

                    <StyledTableCell align="right">
                      {row.revenueEstimated
                        ? numberAbbrev(currency, row.revenueEstimated)
                        : "--"}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {+row.revenue
                        ? numberAbbrev(currency, row.revenue)
                        : "Not available"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <PercentValue
                        actualValue={row.revenue}
                        beatPercent={revenueBeatPercent}
                        estimatedValue={row.revenueEstimated}
                        beatOrMiss={row.revenueBeatOrMiss}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {row.epsEstimated
                        ? epsFormatter.format(row.epsEstimated)
                        : "--"}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {+row.eps
                        ? epsFormatter.format(row.eps)
                        : "Not available"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <PercentValue
                        actualValue={row.eps}
                        beatPercent={epsBeatPercent}
                        estimatedValue={row.epsEstimated}
                        beatOrMiss={row.epsBeatOrMiss}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  } else {
    return <div></div>;
  }
}

function PercentValue(props) {
  const { beatPercent, actualValue, estimatedValue, beatOrMiss } = props;

  if (!+estimatedValue || !+actualValue) {
    return "--";
  }

  function getColor(beatOrMiss) {
    if (beatOrMiss.toLowerCase() === "beat") {
      return "green";
    } else if (beatOrMiss.toLowerCase() === "miss") {
      return "red";
    } else {
      return "black";
    }
  }

  return (
    <>
      <span
        style={{
          color: getColor(beatOrMiss),
          whiteSpace: "pre-line",
        }}
      >
        {beatOrMiss} {percentFormatter(beatPercent)}
      </span>
    </>
  );
}
