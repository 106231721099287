import * as React from "react";
import { ClickAwayListener } from "@mui/base";
import { StyledPopper } from "../../common/StyledComponents";
import { Box, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { isEmpty } from "lodash";
import { formatDate, formatName } from "../../common/Utils";

export default function PromptInfo(props) {
  const { prompt } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "prompt-info" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCardTypeString = () => {
    if (!prompt.cardTypes) {
      return "--";
    }

    if (prompt.cardTypesContext === "Include All") {
      return "All";
    } else if (prompt.cardTypesContext === "Exclude All") {
      return "None";
    } else {
      const typeArray = prompt?.cardTypes.map((card) => card.type);
      return typeArray?.join(", ");
    }
  };

  const getContentString = () => {
    if (isEmpty(prompt.contents)) {
      return "None";
    }

    const contentsArray = prompt?.contents.map(
      (content) => content.contentName
    );
    return contentsArray?.join(", ");
  };

  return (
    <div>
      <Tooltip title={"Prompt info"}>
        <IconButton
          aria-label="Prompt info"
          size="small"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <InfoIcon htmlColor="rgb(107,149,185)" fontSize="small" />
        </IconButton>
      </Tooltip>
      <StyledPopper
        anchorEl={anchorEl}
        open={open}
        id={id}
        placement="bottom-start"
        sx={{ width: "330px" }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              width: "100%",
              padding: "0px 15px",
              "& dt": {
                display: "inline",
                fontWeight: "700",
              },
              "& dt::after": { content: "': '", fontWeight: "700" },
              "& dd": {
                display: "inline",
                margin: 0,
              },
            }}
          >
            <dl
              style={{ display: "flex", gap: "5px", flexDirection: "column" }}
            >
              <div>
                <dt>ID</dt>
                <dd>{prompt.promptId}</dd>
              </div>
              <div>
                <dt>Created</dt>
                <dd>{formatDate(new Date(prompt.createdAt))}</dd>
              </div>
              <div>
                <dt>Created by</dt>
                <dd>{formatName(prompt.createdUser)}</dd>
              </div>
              <div>
                <dt>Last modified</dt>
                <dd>{formatDate(new Date(prompt.updatedAt))}</dd>
              </div>
              <div>
                <dt>Last modified by</dt>
                <dd>{formatName(prompt.updatedUser)}</dd>
              </div>
              <div>
                <dt>Card types</dt>
                <dd>{getCardTypeString()}</dd>
              </div>
              <div>
                <dt>Contents</dt>
                <dd>{getContentString()}</dd>
              </div>
            </dl>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </div>
  );
}
