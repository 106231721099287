import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import IconButton from "@mui/material/IconButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Typography from "@mui/material/Typography";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import Image from "../../../../common/Image";
import Avatar from "@mui/material/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { addChildCounts } from "../../../../det/cards/OrgChartEditor";
import { isEmpty } from "lodash";
import { getColorFromString } from "../../../../common/Utils";

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    marginTop: "30px",
    // border: '2px solid blue',
  },
  childContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  dividerClass: {
    borderTop: "2px solid #888",
    borderLeft: "2px solid #888",
    borderRight: "2px solid #888",
    height: 40,
    width: "100%",
    borderRadius: "15px 15px 0 0",
    // boxShadow: 'inset 3px 3px 3px  rgba(0, 0, 0, 0.2)'
  },
  personClass: {
    position: "relative",
    border: "2px solid #888",
    borderRadius: "10px",
    padding: 10,
    paddingTop: 25,
    textAlign: "center",
    overflowX: "hidden",
    overflowY: "hidden",
    boxShadow: "0 3px 6px 0 rgb(32 33 36 / 28%)",
  },
  childPersonClass: {
    position: "relative",
    border: "1px solid #888",
    marginBottom: 25,
    borderRadius: "10px",
    padding: 10,
    paddingTop: 25,
    textAlign: "center",
    overflowX: "hidden",
    overflowY: "hidden",
    cursor: "pointer",
    boxShadow: "0 3px 6px 0 rgb(32 33 36 / 28%)",
  },
  profilePic: {
    position: "absolute",
    left: "50%",
    top: 0,
    transform: "translate(-50%, -50%)",
    width: 50,
    height: 50,
    borderRadius: "50%",
    border: "2px solid #888",
    backgroundColor: "white",
    zIndex: 10,
    padding: 2,
  },
  rootNodeBubblePosition: {
    position: "absolute",
    left: "50%",
    transform: "translate(-75%, -0%)",
    zIndex: 10,
    padding: 2,
    bottom: "-13px",
  },
  rootNodeChildCountBubble: {
    width: "calc(100% + 20px)",
    height: 24,
    fontSize: ".80rem",
    backgroundColor: "#1976d2",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
}));

export default function Orgchart(props) {
  const classes = useStyles();
  const previewData = props.previewData;
  // const previewData = true;

  const [orgData, setOrgData] = React.useState(null);
  const [orgParent, setOrgParent] = React.useState([]);
  const [orgCurrent, setOrgCurrent] = React.useState({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // eslint-disable-next-line
  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    if (previewData) {
      // Preview Data supplied by calling application
      setOrgData(previewData);
      setOrgCurrent(previewData.People[0]);
      setOrgParent([]);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId; // cardId hardcoded for now
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            let rawdata = response.data;
            if (rawdata.People) {
              if (!rawdata.People[0].childCounts) {
                addChildCounts(rawdata.People);
              }
              // Validate content
              setOrgData(rawdata);
              setOrgCurrent(rawdata.People[0]);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
  }, [firstTime, openSnackbar, snackbarMessage, previewData]);

  const handleClick = (event, person) => {
    orgParent.push(orgCurrent);
    setOrgParent(orgParent);
    setOrgCurrent(person);
  };

  const handleParentClick = (event) => {
    let person = orgParent.pop();
    setOrgCurrent(person);
    setOrgParent(orgParent);
  };

  if (!orgData || isEmpty(orgCurrent)) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={orgData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div className={classes.parentContainer}>
          <PersonAvatar person={orgCurrent}></PersonAvatar>
          <div
            className={classes.personClass}
            style={{ paddingRight: orgParent.length > 0 ? 20 : 10 }}
          >
            {orgParent.length > 0 && (
              <div style={{ position: "absolute", top: -7, right: -5 }}>
                <IconButton onClick={(e) => handleParentClick(e)}>
                  <FileUploadIcon />
                </IconButton>
              </div>
            )}
            <Typography variant="subtitle2" component="div">
              {orgCurrent.FullName}
            </Typography>
            <Typography variant="body2" component="div">
              {orgCurrent.Title}
            </Typography>
          </div>
          <div className={classes.rootNodeBubblePosition}>
            <Avatar
              className={classes.rootNodeChildCountBubble}
              variant="rounded"
            >
              {orgCurrent.childCount}
              <ArrowDropDownIcon sx={{ fontSize: 15 }} />
            </Avatar>
          </div>
        </div>
        {orgCurrent.People && orgCurrent.People.length > 0 && (
          <div>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                margin: 0,
                padding: 0,
                border: "",
              }}
            >
              <div
                style={{
                  display: "inline",
                  border: "1px solid #888",
                  width: 1,
                  height: 20,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              ></div>
              <div className={classes.dividerClass}></div>
            </div>
            <div className={classes.childContainer}>
              {orgCurrent.People.map((person, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      marginTop: 30,
                      marginBottom: 20,
                    }}
                  >
                    <PersonAvatar person={person}></PersonAvatar>
                    <div onClick={(e) => handleClick(e, person)}>
                      <div className={classes.childPersonClass}>
                        <Typography variant="subtitle2" component="div">
                          {person.FullName}
                        </Typography>
                        <Typography variant="caption" component="div">
                          {person.Title}
                        </Typography>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translate(-84%, -78%)",
                          zIndex: 10,
                          padding: 2,
                          bottom: "-13px",
                          cursor: "pointer",
                        }}
                      >
                        {person.childCount !== undefined &&
                          person.childCount !== 0 && (
                            <Avatar
                              className={classes.rootNodeChildCountBubble}
                              variant="rounded"
                            >
                              {person.childCount}
                              <ArrowDropDownIcon sx={{ fontSize: 15 }} />
                            </Avatar>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div style={{ height: "20px" }}></div>
      </Researchcard>
    );
}

function PersonAvatar(props) {
  const classes = useStyles();
  const { person } = props;

  if (person.Avatar) {
    return (
      <Image src={person.Avatar} alt="Profile" className={classes.profilePic} />
    );
  }

  return (
    <div className={classes.profilePic}>
      <Avatar
        sx={{
          width: 42,
          height: 42,
          backgroundColor: getColorFromString(person.FullName),
        }}
      >
        {person.FullName?.charAt(0)}
      </Avatar>
    </div>
  );
}
