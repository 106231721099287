import React, { useContext } from "react";

const FilterFormContext = React.createContext({
  form: {},
  handleFormChange: () => {},
});

export function useFilterForm() {
  return useContext(FilterFormContext);
}

export default function FilterFormProvider({ children }) {
  const [formFilters, setFormFilters] = React.useState();
  const filterFormValues = React.useMemo(
    () => ({ formFilters, setFormFilters }),
    [formFilters]
  );

  return (
    <FilterFormContext.Provider value={filterFormValues}>
      {children}
    </FilterFormContext.Provider>
  );
}
