import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import useSnack from "../../context/Snack";
import { PLAN_TYPES, useUser } from "../../context/UserContext";
import axios from "axios";
import { rule5properties } from "../../properties";
import CircleIcon from "@mui/icons-material/Circle";
import upgradeFeatureCheck from "../../res/upgrade_feature_check.svg";
import { isEmpty } from "lodash";

export const PLAN_LIMITS = {
  [PLAN_TYPES.standard]: {
    monitorAccountsLimit: 20,
    monitorPeopleLimit: 200,
  },
  [PLAN_TYPES.professional]: {
    monitorAccountsLimit: 50,
    monitorPeopleLimit: 500,
    publicResearchLimit: 20,
    privateResearchLimit: 200,
  },
  [PLAN_TYPES.professionalPlus]: {
    monitorAccountsLimit: 50,
    monitorPeopleLimit: 500,
    publicResearchLimit: 20,
    privateResearchLimit: 200,
  },
  [PLAN_TYPES.premium]: {
    monitorAccountsLimit: "Unlimited",
    monitorPeopleLimit: "Unlimited",
    publicResearchLimit: "Unlimited",
    privateResearchLimit: "Unlimited",
  },
};

export default function PricingTable(props) {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(120px, 290px))",
          gap: "20px",
          maxWidth: "1250px",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <PlanDetails
          planType={PLAN_TYPES.standard}
          name="Monitor"
          featuresText="Signal IQ"
          price={9}
          features={[
            {
              name: "Signal IQ",
              points: [
                `${PLAN_LIMITS[
                  PLAN_TYPES.standard
                ].monitorAccountsLimit.toLocaleString()} companies monitored`,
                `${PLAN_LIMITS[
                  PLAN_TYPES.standard
                ].monitorPeopleLimit.toLocaleString()} people monitored`,
              ],
            },
          ]}
        />
        <PlanDetails
          planType={PLAN_TYPES.professional}
          name="Research"
          featuresText="Signal IQ + Account IQ"
          price={39}
          features={[
            {
              name: "Signal IQ",
              points: [
                `${PLAN_LIMITS[
                  PLAN_TYPES.professional
                ].monitorAccountsLimit.toLocaleString()} companies monitored`,
                `${PLAN_LIMITS[
                  PLAN_TYPES.professional
                ].monitorPeopleLimit.toLocaleString()} people monitored`,
              ],
            },
            {
              name: "Account IQ",
              points: [
                `${PLAN_LIMITS[
                  PLAN_TYPES.professional
                ].publicResearchLimit.toLocaleString()} public companies research`,
                `${PLAN_LIMITS[
                  PLAN_TYPES.professional
                ].privateResearchLimit.toLocaleString()} private companies research`,
              ],
            },
          ]}
        />
        <PlanDetails
          planType={PLAN_TYPES.professionalPlus}
          name="Act"
          featuresText="Signal IQ + Account IQ + rule5GPT"
          price={99}
          features={[
            {
              name: "Signal IQ",
              points: [
                `${PLAN_LIMITS[
                  PLAN_TYPES.professionalPlus
                ].monitorAccountsLimit.toLocaleString()} companies monitored`,
                `${PLAN_LIMITS[
                  PLAN_TYPES.professionalPlus
                ].monitorPeopleLimit.toLocaleString()} people monitored`,
              ],
            },
            {
              name: "Account IQ",
              points: [
                `${PLAN_LIMITS[
                  PLAN_TYPES.professionalPlus
                ].publicResearchLimit.toLocaleString()} public companies research`,
                `${PLAN_LIMITS[
                  PLAN_TYPES.professionalPlus
                ].privateResearchLimit.toLocaleString()} private companies research`,
              ],
            },
            { name: "rule5GPT AI Assistant" },
            { name: "Extensions & Integrations" },
            { name: "Onboarding & Premium Support" },
          ]}
        />
        <PlanDetails
          planType={PLAN_TYPES.premium}
          name="Scale"
          featuresText="Signal IQ + Account IQ + rule5GPT"
          price={null}
          features={[
            {
              name: "Signal IQ",
              points: [
                `${PLAN_LIMITS[
                  PLAN_TYPES.premium
                ].monitorAccountsLimit.toLocaleString()} companies monitored`,
                `${PLAN_LIMITS[
                  PLAN_TYPES.premium
                ].monitorPeopleLimit.toLocaleString()} people monitored`,
              ],
            },
            {
              name: "Account IQ",
              points: [
                `${PLAN_LIMITS[
                  PLAN_TYPES.premium
                ].publicResearchLimit.toLocaleString()} public companies research`,
                `${PLAN_LIMITS[
                  PLAN_TYPES.premium
                ].privateResearchLimit.toLocaleString()} private companies research`,
              ],
            },
            { name: "rule5GPT AI Assistant" },
            { name: "Extensions, Integrations, & API" },
            { name: "Onboarding & Premium Support" },
          ]}
        />
      </Box>
    </>
  );
}

function PlanDetails(props) {
  //todo Loading spinner since there's request in here
  const { name, price, features, featuresText, planType } = props;

  const snackBar = useSnack();
  const user = useUser();

  const isUserPlanType = user.userSubscription?.planType === planType;

  function onUpgrade() {
    if (isUserPlanType) {
      return;
    }

    if (planType !== PLAN_TYPES.standard) {
      window.open("https://www.rule5.io/pricing#contact", "_blank");
      return;
    }

    axios
      .post(rule5properties.checkoutSession, {
        planType: planType,
        isUpgrade: true,
      })
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          window.location.href = data.url;
        } else {
          snackBar.createSnack(
            `Error updating plan: ${JSON.stringify(res.data)}`
          );
        }
      })
      .catch((err) => {
        snackBar.createSnack("Error updating user sub: " + err);
      });
  }

  let buttonText = "Upgrade";
  if (isUserPlanType) {
    buttonText = "Your plan";
  } else if (planType !== PLAN_TYPES.standard) {
    buttonText = "Contact us";
  }

  return (
    <Box
      sx={{
        borderRadius: "16px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        backgroundColor:
          planType === user.userSubscription?.planType ? "#e8f1fa" : "#f2f3f2",
      }}
    >
      <Typography variant="h5" sx={{ width: "100%", mb: 2, fontWeight: 500 }}>
        {name}
      </Typography>
      {price ? (
        <Typography
          variant="caption2"
          sx={{ width: "100%", fontWeight: 400, mb: 4 }}
        >
          <span style={{ fontSize: 28, fontWeight: 500 }}>${price} </span> per
          month
        </Typography>
      ) : (
        <Typography
          sx={{ mb: 4, fontSize: "20px", mt: "12px" }}
          variant="settingsHeader"
        >
          Let's talk
        </Typography>
      )}
      <Button
        variant={isUserPlanType ? "text" : "contained"}
        disableElevation
        disableRipple
        role={isUserPlanType ? "presentation" : "button"}
        disabled={
          user.userSubscription?.planType === PLAN_TYPES.premium &&
          !isUserPlanType
        } // todo this will need to be smarter once there are more plantypes
        sx={{
          textTransform: "none",
          width: "170px",
          mb: 3,
          alignSelf: "center",
          cursor: isUserPlanType ? "default" : "pointer",
          backgroundColor: isUserPlanType
            ? "rgba(25, 118, 210, 0.04)"
            : "#1976d2",
        }}
        onClick={onUpgrade}
      >
        {buttonText}
      </Button>
      <Typography
        variant="subtitle1"
        sx={{ width: "100%", fontWeight: 500, mb: 1.5, opacity: 0.85 }}
      >
        FEATURES
      </Typography>
      <Typography variant="settingsCaption" sx={{ mb: 2, fontSize: 14 }}>
        {featuresText}
      </Typography>
      <List sx={{ pt: 0, pl: 0 }}>
        {features.map((feature) => (
          <ListItem
            key={feature.name}
            sx={{ display: "flex", flexWrap: "wrap", p: 0, mb: 1 }}
          >
            <ListItemIcon sx={{ minWidth: "20px" }}>
              <CircleIcon
                sx={{ height: "8px", width: "8px" }}
                htmlColor="d2d2d2"
              />
            </ListItemIcon>
            <ListItemText
              sx={{ flexBasis: "80%" }}
              primaryTypographyProps={{
                fontWeight: 500,
                sx: { opacity: 0.75 },
              }}
            >
              {feature.name}
            </ListItemText>
            {!isEmpty(feature.points) && (
              <List sx={{ pt: 0, pl: 2 }}>
                {feature.points.map((point) => (
                  <ListItem key={point} sx={{ p: 0 }}>
                    <ListItemIcon sx={{ minWidth: "40px" }}>
                      <img src={upgradeFeatureCheck} alt="checkmark" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "caption2",
                        sx: { opacity: 0.7 },
                      }}
                    >
                      {point}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
