import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { rule5properties } from "../../properties";
import makeStyles from "@mui/styles/makeStyles";
import { cardTypes } from "../../workspaces/opportunity/research/OPXResearch";
import { cardEditorTypes } from "../DetCardEditor";
import { addCard, removeCard } from "../api/ResearchApi";

const useStyles = makeStyles(() => ({
  infoTable: {
    borderCollapse: "collapse",
    // border: "1px solid black",
    padding: "15px",
    "& td": {
      padding: "2px 6px",
      border: "1px solid black",
    },
  },
}));

export default function ResearchAddCard(props) {
  // const researchCardInfo = props.cardInfo.info;
  const classes = useStyles();
  const research = props.research;
  const onUpdate = props.onUpdate;
  const [newCardType, setNewCardType] = useState(null);
  const [availableCards, setAvailableCards] = useState(null);
  const [assignee, setAssignee] = useState(null);

  const onNewCardTypeChange = (event) => {
    setNewCardType(event);
    const queryString = new URLSearchParams({
      type: event,
      scope: research.scope,
      companyId: parseInt(research.companyId),
      ...(research.orgId ? { orgId: parseInt(research.orgId) } : {}),
      // functionalArea: research.functionalArea,
      ...(research.functionalArea
        ? { functionalArea: research.functionalArea }
        : { functionalArea: "NA" }),
    });
    axios
      .get(rule5properties.detCards + `?` + queryString)
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
      })
      .then((response) => {
        // alert(JSON.stringify(response));
        setAvailableCards(response.data.results);
      });
  };

  const createNewCard = () => {
    if (
      [
        "Executive Summary",
        "Risk Factors",
        "Industry Trends",
        "Post COVID Scenario",
      ].includes(newCardType)
    ) {
      alert(
        "Sorry this feature is under construction for cards with an RTE. For now, please use Manage Cards to create this card."
      );
      return;
    }
    const cardEditor = cardEditorTypes.find((cardEditorType) => {
      return cardEditorType.cardType === newCardType;
    });
    axios
      .post(rule5properties.detCards, {
        type: newCardType,
        scope: research.scope,
        ...(research.companyId
          ? { company_id: parseInt(research.companyId) }
          : {}),
        ...(research.orgId ? { org_id: parseInt(research.orgId) } : {}),
        ...(research.functionalArea && research.functionalArea !== "NA"
          ? { functional_area: research.functionalArea }
          : {}),
        card_info: cardEditor.newCardDefaultValues,
        action_info: {},
      })
      .catch(function (error) {
        alert("ERROR: " + JSON.stringify(error));
      })
      .then((response) => {
        // alert(JSON.stringify(response.data));
        addCard(response.data, research, onUpdate);
      });
  };

  const getExistingCardMatches = (availableCard) => {
    const isAlreadyAdded =
      research.cardInfo.info &&
      research.cardInfo.info.find((card) => {
        return card.cardId === parseInt(availableCard.id);
      });
    if (!isAlreadyAdded) {
      return (
        <Button
          onClick={() => {
            addCard(availableCard, research, onUpdate);
          }}
        >
          Add this version to research
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            removeCard(availableCard.id, research, onUpdate);
          }}
        >
          Remove from research
        </Button>
      );
    }
  };

  return (
    <div style={{ display: "flex", padding: "15px" }}>
      {!["Published", "Approved"].includes(research.status) && (
        <Stack spacing={1} style={{ padding: "10px 20px" }}>
          Add/remove Cards:
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Autocomplete
              id="cardType"
              sx={{ width: 300 }}
              options={cardTypes.map((row, index) => {
                return row.cardType;
              })}
              autoHighlight
              getOptionDisabled={(option) =>
                option === "Seller Competition" &&
                research.scope !== "Seller Company"
              }
              onChange={(e, data) => onNewCardTypeChange(data)}
              renderInput={(params) => (
                <TextField {...params} label="Select card type" />
              )}
            />
            {/* <AssigneeSelector
              onUpdate={setAssignee}
              value={assignee}
            ></AssigneeSelector> */}
          </div>
          {availableCards && availableCards.length > 0 && (
            <div>
              Available cards:
              <table className={classes.infoTable}>
                <tbody>
                  {availableCards.map((card, index) => {
                    <tr>
                      <td>Card type</td>
                      <td>Version</td>
                      <td>Status</td>
                      <td>Add to this research</td>
                    </tr>;
                    return (
                      <tr>
                        <td>{card.type}</td>
                        <td>{card.version}</td>
                        <td>{card.status}</td>
                        <td>{getExistingCardMatches(card)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {newCardType && (!availableCards || availableCards.length === 0) && (
            <h4>No cards created yet</h4>
          )}
          {newCardType && (
            <>
              <Button
                onClick={() => {
                  createNewCard();
                }}
                variant="contained"
                sx={{ marginBottom: "30px", height: "38px", width: "350px" }}
              >
                Create new draft and add
              </Button>
            </>
          )}
        </Stack>
      )}
    </div>
  );
}
