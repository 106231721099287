import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { convertFromRaw, EditorState } from "draft-js";
import Image from "../../../../common/Image";
import RichTextReader from "../../../../common/RichTextReader";
import { useUser } from "../../../../context/UserContext";
import { detRoles } from "../../../../det/DetMainPage";
import { OperationsContent } from "./Operations";
import { hasRteContent } from "../../../../common/Utils";
import { Stack } from "@mui/material";
import { OverviewContentForExecSummary } from "./Overview";
import { NewsContent } from "./News";

const useStyles = makeStyles(() => ({
  cardContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  flexContainer: {
    position: "relative",
    marginTop: "20px",
    border: "1px solid #EEE",
    borderRadius: "5px",
  },
  flexContent: {
    padding: "10px",
    paddingBottom: "0px",
    textAlign: "left",
  },
  flexCollapsed: {
    padding: "10px",
    paddingBottom: "0px",
    textAlign: "left",
    "&::after": {
      position: "absolute",
      bottom: 0,
      height: "30%",
      width: "100%",
      content: '""',
      background:
        "linear-gradient(to bottom, rgba(255,255,255, 0.4), rgba(255,255,255, 0.8), rgba(255,255,255, 0.9));",
      pointerEvents: "none" /* so the text is still selectable */,
    },
  },
  image: {
    maxWidth: "100%",
    marginBottom: "16px",
    border: "1px solid #EEE",
    borderRadius: "5px",
  },
  rteHeader: {
    textAlign: "left",
    margin: "0px",
    fontStyle: "italic",
    fontWeight: "400",
    paddingTop: "20px",
    fontSize: "18px",
  },
}));

export default function ExecutiveSummary(props) {
  const classes = useStyles();
  const user = useUser();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;

  const convertToEditorState = (editorContent) => {
    const content = convertFromRaw(editorContent);
    const editorState = EditorState.createWithContent(content);
    return editorState;
  };

  const [editorState, setEditorState] = React.useState(null);
  const [executiveData, setExecutiveData] = React.useState(null);
  const [cardData, setCardData] = React.useState({});

  useEffect(() => {
    // TODO remove isMounted.
    let isMounted = true; // note mutable flag
    if (previewData) {
      // Call Stub
      setExecutiveData(previewData);
      setEditorState(convertToEditorState(previewData.Content));
    } else {
      // Call API
      let parameter = "/" + props.cardId;
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          if (isMounted) {
            let rawdata = response.data;
            if (rawdata.Content) {
              // Validate content
              setExecutiveData(rawdata);
              setEditorState(convertToEditorState(rawdata.Content));
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          }
        })
        .catch((error) => {
          if (error.response && isMounted) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        });
    }
    return () => {
      isMounted = true;
    };
  }, [previewData]);

  useEffect(() => {
    if (!executiveData?.IsAutomated) {
      return;
    }
    const endpointUrl = detRoles.includes(user.role)
      ? rule5properties.detCardData
      : rule5properties.cardData;

    const cardDataParams = new URLSearchParams({
      cardType: "Executive Summary",
      ...(executiveData.functionalArea && executiveData.functionalArea !== "NA"
        ? { functionalArea: executiveData.functionalArea }
        : {}),
      companyId: executiveData.companyId,
      expandedOutput: false,
    });

    axios
      .get(endpointUrl + "?" + cardDataParams)
      .then((resp) => {
        setCardData(resp.data);
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("ERROR: " + err);
        setOpenSnackbar(true);
      });
  }, [executiveData]);

  if (!executiveData || !editorState) {
    return (
      <Researchcard
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else {
    let content;

    const hasStrategicPriorities =
      executiveData["Strategic Priorities"] &&
      hasRteContent(executiveData["Strategic Priorities"]);
    const hasBusinessChallenges =
      executiveData["Business Challenges"] &&
      hasRteContent(executiveData["Business Challenges"]);
    const highlightedNews = cardData?.News?.filter(
      (article) => article.IsHighlightedArticle
    );

    if (executiveData.IsAutomated) {
      content = (
        <>
          {cardData?.["Company Overview"] && (
            <OverviewContentForExecSummary
              overviewDetail={cardData["Company Overview"]}
              opportunityId={props.opportunity?.id}
            ></OverviewContentForExecSummary>
          )}
          {cardData?.Operations && (
            <>
              {cardData?.["Company Overview"] && (
                // empty div just for drawing center line in gap between overview and operations.
                <div
                  style={{
                    height: "15px",
                    width: "50%",
                    borderRight: "solid 1px rgba(0, 0, 0, 0.4)",
                  }}
                ></div>
              )}
              <OperationsContent
                operationsDetail={cardData.Operations}
                isExecSummary={true}
              ></OperationsContent>
            </>
          )}
          {(hasBusinessChallenges || hasStrategicPriorities) && (
            <>
              <h4
                variant="h6"
                style={{
                  textAlign: "left",
                  margin: "0px",
                  paddingTop: "30px",
                  fontSize: "18px",
                }}
              >
                What's on leadership's mind
              </h4>
              {executiveData["Strategic Priorities"] &&
                hasRteContent(executiveData["Strategic Priorities"]) && (
                  <div style={{}}>
                    <Stack>
                      <h4 className={classes.rteHeader}>
                        Strategic Priorities
                      </h4>
                      <RichTextReader
                        editorState={EditorState.createWithContent(
                          convertFromRaw(executiveData["Strategic Priorities"])
                        )}
                      />
                    </Stack>
                  </div>
                )}
              {executiveData["Business Challenges"] &&
                hasRteContent(executiveData["Business Challenges"]) && (
                  <div style={{}}>
                    <Stack>
                      <h4 className={classes.rteHeader}>Business Challenges</h4>
                      <RichTextReader
                        editorState={EditorState.createWithContent(
                          convertFromRaw(executiveData["Business Challenges"])
                        )}
                      />
                    </Stack>
                  </div>
                )}
            </>
          )}
          {highlightedNews && highlightedNews.length > 0 && (
            <>
              <h4
                style={{
                  textAlign: "left",
                  margin: "0px",
                  paddingTop: "30px",
                  paddingBottom: "15px",
                  fontSize: "18px",
                }}
              >
                Key Events/News
              </h4>
              <NewsContent articles={highlightedNews}></NewsContent>
            </>
          )}
        </>
      );
    } else if (executiveData.Image) {
      content = (
        <Image
          src={executiveData.Image}
          className={classes.image}
          alt={"Executive Summary"}
        />
      );
    } else {
      content = (
        <div
          style={{
            padding: "0px 40px",
            marginTop: "0px",
            marginBottom: "25px",
          }}
        >
          <RichTextReader editorState={editorState} />
        </div>
      );
    }
    return (
      <Researchcard
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={executiveData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        {content}
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }
}
