import React from "react";
import Card from "@mui/material/Card";
import Toolbar from "../common/Toolbar";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Tooltip, useTheme } from "@mui/material";
import CardFilterForm from "./util/DetailsFilterForm";
import axios from "axios";
import { useDialog } from "../context/DialogContext";
import PreviewPopup from "./modal/ResearchPopup";
import { rule5properties } from "../properties";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { formatDataGridColumnDate, formatDate } from "./ResearchDetail";
import { dataGridStyles } from "./common/utils";
import { useHistory } from "react-router-dom";
import { useImmer } from "use-immer";

export default function ManageResearch(props) {
  // Using immer so can conveniently reset page state to 0 when searchFilters are updated.
  const [searchCriteria, setSearchCriteria] = useImmer({
    page: 0,
    pageSize: 20,
    searchFilters: null,
  });
  const [loading, setLoading] = React.useState(false);
  const [researchResponse, setResearchResponse] = React.useState({
    total: 0,
    results: [],
  });

  const theme = useTheme();
  const history = useHistory();

  const onFindClick = (data) => {
    setSearchCriteria((draft) => {
      draft.page = 0;
      draft.searchFilters = data;
    });
  };

  const getResearch = ({ searchFilters, page, pageSize }) => {
    if (!searchFilters) {
      return;
    }
    setLoading(true);
    const queryString = new URLSearchParams({
      ...(searchFilters.scope ? { scope: searchFilters.scope } : {}),
      ...(searchFilters.buyerCompany
        ? { companyId: parseInt(searchFilters.buyerCompany.id) }
        : {}),
      ...(searchFilters.sellerCompany
        ? { orgId: parseInt(searchFilters.sellerCompany.id) }
        : {}),
      ...(searchFilters.functionalArea
        ? { functionalArea: searchFilters.functionalArea }
        : {}),
      ...(searchFilters.status ? { status: searchFilters.status } : {}),
      ...(searchFilters.assignee ? { assignee: searchFilters.assignee } : {}),
      ...(searchFilters.maxVersion
        ? { maxVersion: searchFilters.maxVersion }
        : {}),
      pageSize: pageSize,
      pageIndex: page,
    });
    axios
      .get(rule5properties.detResearch + `?` + queryString)
      .catch(function (error) {
        setLoading(false);
        alert("ERROR: " + JSON.stringify(error));
      })
      .then((response) => {
        setLoading(false);
        setResearchResponse(response.data);
      });
  };

  React.useEffect(() => getResearch(searchCriteria), [searchCriteria]);

  const onSelectionChange = (event) => {
    const row = researchResponse.results.find((row) => {
      return row.id === event[0];
    });
    return history.push(`/det/manageresearch/${row.id}`);
  };

  const classes = dataGridStyles();
  const openDialog = useDialog();

  const previewResearch = (event, cellValues) => {
    event.stopPropagation();
    const row = cellValues.row;
    const title =
      row.buyerName + (row.functionalArea ? " / " + row.functionalArea : "");
    if (row) {
      openDialog.openModal(title, PreviewPopup, row);
    }
  };

  const columns = [
    {
      field: "preview",
      type: "actions",
      headerName: "Preview",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <IconButton
            color="primary"
            aria-label="preview"
            component="span"
            onClick={(event) => {
              previewResearch(event, cellValues);
            }}
          >
            <RemoveRedEyeOutlinedIcon />
          </IconButton>
        );
      },
    },
    {
      field: "buyerName",
      headerName: "Buyer Company",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.row.buyerName || ""}>
            <span className={classes.tableCellTrucate}>
              {cellValues.row.buyerName}
            </span>
          </Tooltip>
        );
      },
    },
    { field: "sellerName", headerName: "Seller Company", width: 150 },
    { field: "scope", headerName: "Scope", width: 150 },
    { field: "functionalArea", headerName: "Functional Area", width: 150 },
    { field: "version", headerName: "Version", width: 100 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "assignee",
      headerName: "Assignee",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.assignee || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.assignee}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.approved_by || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.approved_by}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "approval_date",
      headerName: "Approved On",
      width: 200,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo &&
          cellValues.row.actionInfo.approval_date && (
            <span className={classes.tableCellTrucate}>
              {formatDate(cellValues.row.actionInfo.approval_date)}
            </span>
          )
        );
      },
    },
    {
      field: "published_by",
      headerName: "Published By",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo && (
            <Tooltip title={cellValues.row.actionInfo.published_by || ""}>
              <span className={classes.tableCellTrucate}>
                {cellValues.row.actionInfo.published_by}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "published_date",
      headerName: "Published On",
      width: 200,
      renderCell: (cellValues) => {
        return (
          cellValues.row.actionInfo &&
          cellValues.row.actionInfo.published_date && (
            <span className={classes.tableCellTrucate}>
              {formatDate(cellValues.row.actionInfo.published_date)}
            </span>
          )
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated On",
      width: 200,
      valueGetter: formatDataGridColumnDate,
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        paddingBottom: `${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>
        <div>Manage Research</div>
      </Toolbar>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          paddingTop: 1,
          borderRadius: "8px",
          height: "100%",
          flexDirection: "column",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        <div style={{ margin: "1%" }}>
          {/* <Typography variant="h6" marginBottom="1%">
            Find existing research
          </Typography> */}
          <CardFilterForm
            requiresAllFields={false}
            onSubmit={onFindClick}
            buttonLabel="Find"
            excludeType={true}
          />
        </div>

        {searchCriteria.searchFilters && (
          <div
            style={{
              height: "calc(100% - 150px)",
              marginLeft: "2%",
              marginRight: "2%",
            }}
          >
            <DataGridPro
              components={{
                Toolbar: GridToolbar,
              }}
              className={classes.root}
              pagination
              rows={researchResponse.results}
              rowCount={researchResponse.total}
              loading={loading}
              columns={columns}
              disableColumnSelector={true}
              pageSizeOptions={[10, 20, 50]}
              paginationMode="server"
              paginationModel={searchCriteria}
              onPaginationModelChange={(newModel) =>
                setSearchCriteria((draft) => {
                  draft.pageSize = newModel.pageSize;
                  draft.page = newModel.page;
                })
              }
              onRowSelectionModelChange={onSelectionChange}
            />
          </div>
        )}
      </Card>
    </div>
  );
}
