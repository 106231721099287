import {
  Autocomplete,
  Typography,
  Button,
  ToggleButtonGroup,
  Box,
  Popper,
  Fade,
  IconButton,
  styled,
} from "@mui/material";
import { rule5properties } from "../properties";
import React from "react";
import axios from "axios";
import { useDialog } from "../context/DialogContext";
import FormGroup from "@mui/material/FormGroup";
import {
  StyledNumericInput,
  StyledLabel,
  StyledTextfield,
} from "../common/StyledComponents";
import { Controller, useForm } from "react-hook-form";
import COUNTRIES from "../res/countries.json";
import INDUSTRIES from "../res/industries.json";
import { isBoolean, isEqual, pickBy } from "lodash";
import { makeStyles } from "@mui/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import { CURRENCIES, isValidAmount, numberAbbrev } from "../common/Utils";
import { useSearchForm } from "../context/SearchFormContext";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles(() => ({
  styledSelect: {
    backgroundColor: "#FCFCFC",
    "& fieldset": {
      border: "1px solid #DDD",
    },
  },
  toLabel: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  lowerUpperBoundsContainer: {
    display: "grid",
    gridTemplateColumns: "45% 10% 45%",
    marginBottom: "6px",
  },
  numericField: {
    borderRadius: "6px",
  },
  formGroupContainer: {
    marginBottom: "10px",
  },
  divider: {
    width: "1000px",
    height: "1px",
    borderBottom: "1px solid #d1d1d1",
    margin: "25px -15px 15px -15px",
  },
  errorStyle: {
    color: "red",
    flexBasis: "100%",
    fontSize: "12px",
    textAlign: "center",
    margin: 0,
  },
  numericValsContainer: {
    display: "grid",
    position: "relative",
    "&:hover": {
      "& $numericClearButton": {
        visibility: "visible",
      },
    },
  },
  moneyValLabel: {
    textAlign: "center",
    fontWeight: 300,
  },
  periodButton: {
    borderRadius: "6px",
    "& button": { opacity: "0.3" },
  },
  autocomplete: {
    height: "auto",
    marginBottom: "6px",
  },
  listbox: {
    backgroundColor: "",
    padding: 0,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "5px 0px 5px 0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
  textFieldRoot: {
    "& .MuiInputBase-root": {
      paddingRight: "100px !important",
      maxHeight: "150px",
      overflow: "scroll",
    },
    "& .MuiInputAdornment-positionEnd": {
      position: "absolute",
      right: "10px",
      order: 3,
    },
    // Clear icon
    "& .MuiAutocomplete-endAdornment": {
      position: "absolute",
      paddingTop: "3px",
      order: 2,
    },
  },
  option: {
    backgroundColor: "white",
    boxSizing: "border-box",
    height: 60,
    margin: "5px",
    padding: "10px",
    transition: ".2s",
    borderRadius: "5px",
    textTransform: "none",
  },
  optionText: {
    textTransform: "none",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  numericClearButton: {
    position: "absolute",
    right: "15px",
    top: "9px",
    visibility: "hidden",
  },
}));

const OTHER_CARD_TYPE_OPTIONS = [
  "Strategic Snapshot",
  "Quarterly Earnings Calls",
  "Annual Reports",
  "Strategic Priorities",
  "Strategic Initiatives",
  "Business Challenges",
  "Management Quotes",
  "News",
  "Discovery",
];

// TODO this is duplicate info from key periods card, but over there it's not in a nice object constant like this
const PERIODS = [
  { name: "Execute Projects", bgColor: "rgb(247,216,89)" },
  { name: "Key Decision Period", bgColor: "#8BC34A" },
  { name: "Finalize Budgets", bgColor: "#E64A19" },
];

const CardTypeToggleButton = styled(MuiToggleButton)(() => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    border: "2px solid #1976d2",
    opacity: "1",
  },
}));

const PeriodToggleButton = styled(MuiToggleButton)(() => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    opacity: "1",
  },
}));

export default function SearchOpportunities(props) {
  const dialog = useDialog();
  const classes = useStyles();
  const { searchForm, setSearchForm } = useSearchForm();

  const [functionalAreas, setFunctionalAreas] = React.useState([]);

  const DEFAULT_VALUES = {
    accountName: null,
    industry: null,
    functionalArea: null,
    currency: null,
    revenueLowerRange: null,
    revenueUpperRange: null,
    marketCapLowerRange: null,
    marketCapUpperRange: null,
    numEmployeesLowerRange: null,
    numEmployeesUpperRange: null,
    countryOfHeadquarters: null,
    keyWordsInTechLandscapeCard: [],
    keyWordsInOtherCardTypes: [],
    otherCardTypes: [],
    periodCloseMonth: null,
    periods: [],
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: { ...DEFAULT_VALUES, ...searchForm },
  });

  // Attempts to load functional area first from Org preferences, then by default list of values if not successful.
  //TODO this should probably be common async function. This was copied from Research Company modal and does the exactly the same thing there.
  const loadFunctionalAreas = () => {
    axios.get(rule5properties.orgFunctionalAreas).then((res) => {
      if (res.data) {
        setFunctionalAreas(res.data.sort());
      } else {
        axios
          .get(`${rule5properties.values}?name=FunctionalArea`)
          .then((res) => {
            if (res.data.values) {
              setFunctionalAreas(res.data.values.sort());
            } else {
              console.log("Error loading Functional Areas: " + res);
            }
          });
      }
    });
  };

  React.useEffect(() => {
    loadFunctionalAreas();
  }, []);

  const onSubmit = (data, e) => {
    const data1 = pickBy(
      data,
      (value) =>
        value?.length > 0 || isBoolean(value) || Number.isInteger(value)
    );
    setSearchForm(data1);
    props.onSubmit(data1);
    if (!e?.isReset) {
      dialog.closeModal();
    }
  };

  const CustomPopper = (props) => {
    return (
      <Popper {...props} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{ enter: 400, exit: 250 }}>
            {props.children}
          </Fade>
        )}
      </Popper>
    );
  };

  const minValueForAbbrevLabel = 1_000_000;
  const INVALID_RANGE_ERROR = "Lower bound should not exceed upper bound.";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          height: "calc(85vh - 160px)",
          overflow: "scroll",
        }}
      >
        <StyledLabel>Account Name</StyledLabel>
        <StyledTextfield
          {...register("accountName")}
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          placeholder={"Search by name"}
          endAdornment={
            <IconButton
              size="small"
              sx={{
                visibility: watch("accountName") ? "visible" : "hidden",
              }}
              onClick={() => setValue("accountName", "")}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          }
        />
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              autoComplete
              autoHighlight
              openOnFocus
              blurOnSelect
              includeInputInList
              onChange={(e, data) => field.onChange(data)}
              className={classes.autocomplete}
              noOptionsText={"No options found."}
              classes={{
                option: classes.option,
                listbox: classes.listbox,
              }}
              renderOption={(props, option) => {
                return (
                  <Box {...props}>
                    <Typography variant="body1" className={classes.optionText}>
                      {option}
                    </Typography>
                  </Box>
                );
              }}
              ListboxProps={{ style: { maxHeight: 350 } }}
              PopperComponent={CustomPopper}
              options={INDUSTRIES.sort().map((option) => option)}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <React.Fragment>
                    <StyledLabel>Industry</StyledLabel>
                    <StyledTextfield {...params.InputProps} {...rest} />
                  </React.Fragment>
                );
              }}
            />
          )}
          name="industry"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              autoComplete
              autoHighlight
              openOnFocus
              blurOnSelect
              includeInputInList
              onChange={(e, data) => field.onChange(data)}
              className={classes.autocomplete}
              noOptionsText={"No options found."}
              classes={{
                option: classes.option,
                listbox: classes.listbox,
              }}
              renderOption={(props, option) => {
                return (
                  <Box {...props}>
                    <Typography variant="body1" className={classes.optionText}>
                      {option}
                    </Typography>
                  </Box>
                );
              }}
              ListboxProps={{ style: { maxHeight: 350 } }}
              PopperComponent={CustomPopper}
              options={functionalAreas.sort().map((option) => option)}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <React.Fragment>
                    <StyledLabel>Functional Area</StyledLabel>
                    <StyledTextfield {...params.InputProps} {...rest} />
                  </React.Fragment>
                );
              }}
            />
          )}
          name="functionalArea"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              autoComplete
              autoHighlight
              openOnFocus
              blurOnSelect
              includeInputInList
              onChange={(e, data) => field.onChange(data?.value)}
              className={classes.autocomplete}
              noOptionsText={"No options found."}
              classes={{
                option: classes.option,
                listbox: classes.listbox,
              }}
              renderOption={(props, option) => {
                return (
                  <Box {...props}>
                    <Typography variant="body1" className={classes.optionText}>
                      {option.name || option}
                    </Typography>
                  </Box>
                );
              }}
              ListboxProps={{ style: { maxHeight: 350 } }}
              PopperComponent={CustomPopper}
              options={CURRENCIES.map((option) => option)}
              isOptionEqualToValue={(option, value) => {
                return option.value === value;
              }}
              getOptionLabel={(option) =>
                option.name
                  ? option.name
                  : CURRENCIES.find((currency) => currency.value === option)
                      ?.name || ""
              }
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <React.Fragment>
                    <StyledLabel>Currency</StyledLabel>
                    <StyledTextfield {...params.InputProps} {...rest} />
                  </React.Fragment>
                );
              }}
            />
          )}
          name="currency"
          control={control}
        />
        {/* TODO would be nice if these upper bound lower bound pairs were modularized.. */}
        <StyledLabel>Revenue</StyledLabel>
        <div className={classes.lowerUpperBoundsContainer}>
          <div className={classes.numericValsContainer}>
            <Controller
              render={({ field }) => (
                <div>
                  <StyledNumericInput
                    step={100000000}
                    allowDecimals={false}
                    onValueChange={(value) => {
                      let intVal = parseInt(value);
                      if (!isValidAmount(intVal)) {
                        return field.onChange("");
                      }
                      field.onChange(intVal);
                    }}
                    value={field.value}
                    intlConfig={{
                      locale: "en-US",
                      currency: watch("currency"),
                    }}
                    placeholder={"Lower bound"}
                  ></StyledNumericInput>
                  {watch("revenueLowerRange") > 0 && (
                    <IconButton
                      size="small"
                      className={classes.numericClearButton}
                      onClick={() => setValue("revenueLowerRange", "")}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </div>
              )}
              rules={{
                validate: {
                  invalidLowerBound: (value) =>
                    !value ||
                    !watch("revenueUpperRange") ||
                    value <= watch("revenueUpperRange") ||
                    INVALID_RANGE_ERROR,
                },
              }}
              name="revenueLowerRange"
              control={control}
            />
            {watch("revenueLowerRange") > minValueForAbbrevLabel && (
              <Typography variant="caption" className={classes.moneyValLabel}>
                {numberAbbrev(watch("currency"), watch("revenueLowerRange"))}
              </Typography>
            )}
            {errors?.revenueLowerRange && (
              <p className={classes.errorStyle}>
                {errors.revenueLowerRange.message}
              </p>
            )}
          </div>
          <StyledLabel className={classes.toLabel}>to</StyledLabel>
          <div className={classes.numericValsContainer}>
            <Controller
              render={({ field }) => (
                <div>
                  <StyledNumericInput
                    step={100000000}
                    allowDecimals={false}
                    onValueChange={(value) => {
                      let intVal = parseInt(value);
                      if (!isValidAmount(intVal)) {
                        return field.onChange("");
                      }
                      field.onChange(intVal);
                    }}
                    value={field.value}
                    intlConfig={{
                      locale: "en-US",
                      currency: watch("currency"),
                    }}
                    placeholder={"Upper bound"}
                  ></StyledNumericInput>
                  {watch("revenueUpperRange") > 0 && (
                    <IconButton
                      size="small"
                      className={classes.numericClearButton}
                      onClick={() => setValue("revenueUpperRange", "")}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </div>
              )}
              name="revenueUpperRange"
              control={control}
            />
            {watch("revenueUpperRange") > minValueForAbbrevLabel && (
              <Typography variant="caption" className={classes.moneyValLabel}>
                {numberAbbrev(watch("currency"), watch("revenueUpperRange"))}
              </Typography>
            )}
            {errors?.revenueLowerRange && (
              <p className={classes.errorStyle}>
                {errors.revenueLowerRange.message}
              </p>
            )}
          </div>
        </div>
        <StyledLabel>Market Cap</StyledLabel>
        <div className={classes.lowerUpperBoundsContainer}>
          <div className={classes.numericValsContainer}>
            <Controller
              render={({ field }) => (
                <div>
                  <StyledNumericInput
                    step={100000000}
                    allowDecimals={false}
                    onValueChange={(value) => {
                      let intVal = parseInt(value);
                      if (!isValidAmount(intVal)) {
                        return field.onChange("");
                      }
                      field.onChange(intVal);
                    }}
                    value={field.value}
                    intlConfig={{
                      locale: "en-US",
                      currency: watch("currency"),
                    }}
                    placeholder={"Lower bound"}
                  ></StyledNumericInput>
                  {watch("marketCapLowerRange") > 0 && (
                    <IconButton
                      size="small"
                      className={classes.numericClearButton}
                      onClick={() => setValue("marketCapLowerRange", "")}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </div>
              )}
              rules={{
                validate: {
                  invalidLowerBound: (value) =>
                    !value ||
                    !watch("marketCapUpperRange") ||
                    value <= watch("marketCapUpperRange") ||
                    INVALID_RANGE_ERROR,
                },
              }}
              name="marketCapLowerRange"
              control={control}
            />
            {watch("marketCapLowerRange") > minValueForAbbrevLabel && (
              <Typography variant="caption" className={classes.moneyValLabel}>
                {numberAbbrev(watch("currency"), watch("marketCapLowerRange"))}
              </Typography>
            )}
            {errors?.marketCapLowerRange && (
              <p className={classes.errorStyle}>
                {errors.marketCapLowerRange.message}
              </p>
            )}
          </div>
          <StyledLabel className={classes.toLabel}>to</StyledLabel>
          <div className={classes.numericValsContainer}>
            <Controller
              render={({ field }) => (
                <div>
                  <StyledNumericInput
                    step={100000000}
                    allowDecimals={false}
                    onValueChange={(value) => {
                      let intVal = parseInt(value);
                      if (!isValidAmount(intVal)) {
                        return field.onChange("");
                      }
                      field.onChange(intVal);
                    }}
                    value={field.value}
                    intlConfig={{
                      locale: "en-US",
                      currency: watch("currency"),
                    }}
                    placeholder={"Upper bound"}
                  ></StyledNumericInput>
                  {watch("marketCapUpperRange") > 0 && (
                    <IconButton
                      size="small"
                      className={classes.numericClearButton}
                      onClick={() => setValue("marketCapUpperRange", "")}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </div>
              )}
              name="marketCapUpperRange"
              control={control}
            />
            {watch("marketCapUpperRange") > minValueForAbbrevLabel && (
              <Typography variant="caption" className={classes.moneyValLabel}>
                {numberAbbrev(watch("currency"), watch("marketCapUpperRange"))}
              </Typography>
            )}
            {errors?.marketCapLowerRange && (
              <p className={classes.errorStyle}>
                {errors.marketCapLowerRange.message}
              </p>
            )}
          </div>
        </div>
        <StyledLabel>Number of Employees</StyledLabel>
        <div className={classes.lowerUpperBoundsContainer}>
          <Controller
            render={({ field }) => (
              <div className={classes.numericValsContainer}>
                <StyledNumericInput
                  step={10000}
                  allowDecimals={false}
                  onValueChange={(value) => {
                    let intVal = parseInt(value);
                    if (!isValidAmount(intVal)) {
                      return field.onChange("");
                    }
                    field.onChange(intVal);
                  }}
                  value={field.value}
                  intlConfig={{ locale: "en-US" }}
                  placeholder={"Lower bound"}
                ></StyledNumericInput>
                {watch("numEmployeesLowerRange") > 0 && (
                  <IconButton
                    size="small"
                    className={classes.numericClearButton}
                    onClick={() => setValue("numEmployeesLowerRange", "")}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                {errors?.numEmployeesLowerRange && (
                  <p className={classes.errorStyle}>
                    {errors.numEmployeesLowerRange.message}
                  </p>
                )}
              </div>
            )}
            rules={{
              validate: {
                invalidLowerBound: (value) =>
                  !value ||
                  !watch("numEmployeesUpperRange") ||
                  value <= watch("numEmployeesUpperRange") ||
                  INVALID_RANGE_ERROR,
              },
            }}
            name="numEmployeesLowerRange"
            control={control}
          />
          <StyledLabel className={classes.toLabel}>to</StyledLabel>
          <Controller
            render={({ field }) => (
              <div className={classes.numericValsContainer}>
                <StyledNumericInput
                  step={10000}
                  allowDecimals={false}
                  onValueChange={(value) => {
                    let intVal = parseInt(value);
                    if (!isValidAmount(intVal)) {
                      return field.onChange("");
                    }
                    field.onChange(intVal);
                  }}
                  value={field.value}
                  intlConfig={{ locale: "en-US" }}
                  placeholder={"Upper bound"}
                ></StyledNumericInput>
                {watch("numEmployeesUpperRange") > 0 && (
                  <IconButton
                    size="small"
                    className={classes.numericClearButton}
                    onClick={() => setValue("numEmployeesUpperRange", "")}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                {errors?.numEmployeesLowerRange && (
                  <p className={classes.errorStyle}>
                    {errors.numEmployeesLowerRange.message}
                  </p>
                )}
              </div>
            )}
            name="numEmployeesUpperRange"
            control={control}
          />
        </div>
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              autoComplete
              autoHighlight
              openOnFocus
              blurOnSelect
              includeInputInList
              onChange={(e, data) => field.onChange(data)}
              className={classes.autocomplete}
              noOptionsText={"No options found."}
              classes={{
                option: classes.option,
                listbox: classes.listbox,
              }}
              renderOption={(props, option) => {
                return (
                  <Box {...props}>
                    <Typography variant="body1" className={classes.optionText}>
                      {option}
                    </Typography>
                  </Box>
                );
              }}
              ListboxProps={{ style: { maxHeight: 350 } }}
              PopperComponent={CustomPopper}
              options={COUNTRIES.map((option) => option.name)}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <React.Fragment>
                    <StyledLabel>Country of Headquarters</StyledLabel>
                    <StyledTextfield {...params.InputProps} {...rest} />
                  </React.Fragment>
                );
              }}
            />
          )}
          name="countryOfHeadquarters"
          control={control}
        />
        <div className={classes.divider}></div>
        <StyledLabel>Keywords</StyledLabel>
        <div className={classes.formGroupContainer}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                options={[]}
                classes={{
                  option: classes.option,
                  listbox: classes.listbox,
                  root: classes.textFieldRoot,
                }}
                className={classes.autocomplete}
                onChange={(e, data) => field.onChange(data)}
                freeSolo
                autoSelect
                renderInput={(params) => {
                  const { InputLabelProps, ...rest } = params;
                  return (
                    <StyledTextfield
                      placeholder="Enter keywords and select content(s) to search…"
                      {...rest}
                      {...params.InputProps}
                    />
                  );
                }}
              />
            )}
            rules={{
              validate: {
                cardContentRequired: (value) =>
                  value == null ||
                  value.length === 0 ||
                  watch("otherCardTypes")?.length > 0 ||
                  "Select at least one card type for keyword search",
              },
            }}
            name="keyWordsInOtherCardTypes"
            type="select"
            control={control}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              paddingTop: "8px",
              gap: "8px",
            }}
          >
            <Controller
              name="otherCardTypes"
              control={control}
              render={({ field }) => (
                <>
                  {OTHER_CARD_TYPE_OPTIONS.map((cardType) => (
                    <ToggleButtonGroup
                      key={cardType}
                      label={cardType}
                      {...field}
                      color="primary"
                      style={{
                        borderRadius: "6px",
                        border: "1px solid #DDD",
                        width: "100%",
                      }}
                      onChange={(e, value) => {
                        field.onChange(value);
                      }}
                    >
                      <CardTypeToggleButton
                        value={cardType}
                        key={cardType}
                        style={{ textTransform: "none", width: "100%" }}
                      >
                        {cardType}
                      </CardTypeToggleButton>
                    </ToggleButtonGroup>
                  ))}
                </>
              )}
              rules={{
                validate: {
                  keywordsRequired: (value) => {
                    return (
                      value == null ||
                      value.length === 0 ||
                      watch("keyWordsInOtherCardTypes")?.length > 0 ||
                      "Keywords are required to search card content"
                    );
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 10px 0px 10px",
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <Button
              disableRipple
              variant="outlined"
              style={{ textTransform: "none" }}
              size="small"
              disabled={!(watch("otherCardTypes")?.length > 0)}
              className={classes.selectionButtons}
              component="label"
              onClick={() => setValue("otherCardTypes", [])}
            >
              Clear All
            </Button>
            <div />
            <Button
              disableRipple
              variant="outlined"
              style={{ textTransform: "none" }}
              disabled={isEqual(
                watch("otherCardTypes"),
                OTHER_CARD_TYPE_OPTIONS
              )}
              size="small"
              className={classes.selectionButtons}
              component="label"
              onClick={() =>
                setValue("otherCardTypes", OTHER_CARD_TYPE_OPTIONS)
              }
            >
              Select All
            </Button>
            {errors?.keyWordsInOtherCardTypes && (
              <p className={classes.errorStyle}>
                {errors.keyWordsInOtherCardTypes.message}
              </p>
            )}
            {errors?.otherCardTypes && (
              <p className={classes.errorStyle}>
                {errors.otherCardTypes.message}
              </p>
            )}
          </div>
        </div>
        <div className={classes.divider}></div>
        <StyledLabel>Technology Landscape Keywords</StyledLabel>
        <div className={classes.formGroupContainer}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                options={[]}
                classes={{
                  option: classes.option,
                  listbox: classes.listbox,
                  root: classes.textFieldRoot,
                }}
                className={classes.autocomplete}
                onChange={(e, data) => field.onChange(data)}
                freeSolo
                autoSelect
                renderInput={(params) => {
                  const { InputLabelProps, ...rest } = params;
                  return (
                    <StyledTextfield
                      placeholder="Enter technology landscape keywords…"
                      {...rest}
                      {...params.InputProps}
                    />
                  );
                }}
              />
            )}
            name="keyWordsInTechLandscapeCard"
            type="select"
            control={control}
          />
        </div>
        <div className={classes.divider}></div>
        <StyledLabel>Period</StyledLabel>
        <div className={classes.formGroupContainer}>
          <FormGroup
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "8px",
              marginBottom: "15px",
            }}
          >
            <Controller
              name="periods"
              control={control}
              render={({ field }) => (
                <>
                  {PERIODS.map((period) => (
                    <ToggleButtonGroup
                      key={period.name}
                      label={period.name}
                      {...field}
                      className={classes.periodButton}
                      style={{}}
                      onChange={(e, value) => {
                        field.onChange(value);
                      }}
                    >
                      <PeriodToggleButton
                        value={period.name}
                        key={period.name}
                        style={{
                          textTransform: "none",
                          width: "100%",
                          backgroundColor: period.bgColor,
                        }}
                      >
                        {period.name}
                      </PeriodToggleButton>
                    </ToggleButtonGroup>
                  ))}
                </>
              )}
            />
          </FormGroup>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          padding: "20px 40px 0px 40px",
          margin: "0px -48px",
          borderTop: "1px solid #d1d1d1",
          justifyContent: "space-between",
          borderRadius: "0px 0px 6px 6px",
          position: "relative",
        }}
      >
        <Button
          variant="outlined"
          disableElevation
          sx={{
            textTransform: "none",
            width: "100px",
            alignSelf: "center",
          }}
          onClick={() => {
            reset(DEFAULT_VALUES);
            handleSubmit(onSubmit)({ isReset: true });
          }}
        >
          Clear All
        </Button>
        <Button
          type="submit"
          variant="contained"
          disableElevation
          sx={{
            textTransform: "none",
            alignSelf: "center",
          }}
        >
          Search
        </Button>
      </div>
    </form>
  );
}
