import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {
  AddButton,
  appendFuncClickHandler,
  DeleteButton,
  MoveAutocomplete,
  TitleInput,
} from "../util/CardEditorButtons";

export default function SocialMediaEditor(props) {
  const { register, control, trigger, getValues } = useFormContext();
  const formStyle = props.formStyle;

  return (
    <div>
      <TitleInput register={register} />
      <Typography variant="h6">Social Media: </Typography>
      <SocialMedia {...{ trigger, control, register, getValues, formStyle }} />
      <br />
    </div>
  );
}

/** Form array */
function SocialMedia({ trigger, control, register, getValues, formStyle }) {
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `SocialMedia`,
  });
  const [lastDeleted, setLastDeleted] = useState(null);

  return (
    <div>
      {fields.map((item, socialMediaIndex) => {
        return (
          <div key={item.id}>
            <div className={formStyle}>
              Source
              <input {...register(`SocialMedia[${socialMediaIndex}].Source`)} />
              Author handle
              <input
                {...register(`SocialMedia[${socialMediaIndex}].author_handle`)}
              />
              Author name
              <input
                {...register(`SocialMedia[${socialMediaIndex}].author_name`)}
              />
              Content
              <TextareaAutosize
                {...register(`SocialMedia[${socialMediaIndex}].content`, {
                  // required: 'error message' // JS only: <p>error message</p> TS only support string
                })}
                aria-label=""
                minRows={3}
                style={{ width: "100%" }}
              />
              <Typography variant="subtitle2">Metrics:</Typography>
              <div />
              Likes
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                {...register(`SocialMedia[${socialMediaIndex}].metrics.likes`, {
                  valueAsNumber: true,
                })}
              />
              Comments
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                {...register(
                  `SocialMedia[${socialMediaIndex}].metrics.comments`,
                  {
                    valueAsNumber: true,
                  }
                )}
              />
              Shares
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                {...register(
                  `SocialMedia[${socialMediaIndex}].metrics.shares`,
                  {
                    valueAsNumber: true,
                  }
                )}
              />
              <MoveAutocomplete
                onChange={(moveToIndex) => {
                  move(socialMediaIndex, moveToIndex);
                  trigger();
                }}
                index={socialMediaIndex}
                options={[...Array(getValues("SocialMedia").length).keys()]}
              ></MoveAutocomplete>
            </div>
            <DeleteButton
              onClick={() => {
                setLastDeleted(item);
                remove(socialMediaIndex);
                trigger();
              }}
            >
              Delete Social Media
            </DeleteButton>
            <br />
            <Divider
              light
              style={{ width: "100%", marginBottom: "1%", marginTop: "1%" }}
            />
          </div>
        );
      })}

      <AddButton onClick={() => appendFuncClickHandler(append, lastDeleted)}>
        Add Social Media
      </AddButton>
    </div>
  );
}

// let testData = {
//     "Title": "Social Media",
//     "SocialMedia": [
//         {
//             "Source": "twitter",
//             "author_handle": "@CDCgov",
//             "author_name": "CDC",
//             "content": "CDC recommends that certain populations receive a booster shot of Pfizer-BioNTech’s #COVID19 Vaccine at least 6 months after completion of their 2-dose Pfizer-BioNTech vaccine series. See full details: https://bit.ly/3mrK4Z5.",
//             "metrics": {
//                 "likes": 1221,
//                 "comments": 3587,
//                 "shares": 456
//             }
//         },
//         {
//             "Source": "Instagram",
//             "author_handle": "@foxSocialMedia",
//             "author_name": "FOX SocialMedia",
//             "content": "Pfizer sets race based hiring goals in the name of fighting systemic racism",
//             "metrics": {
//                 "likes": 345,
//                 "comments": 23
//             }
//         }
//     ],
//     "NewUpdate": false
// }
