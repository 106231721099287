import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const useStyles = makeStyles(() => ({
  cardContent: {
    textAlign: "left",
    padding: "0px",
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  cardActions: {
    "&.MuiCardActions-root": {
      padding: "0px",
    },
    "&.MuiCardContent-root:last-child": {
      paddingBottom: "0px",
    },
  },
  monthLabel: {
    display: "inline-block",
    borderLeft: "1px solid #DDD",
    borderRight: "1px solid #DDD",
    borderBottom: "1px solid #DDD",
    textAlign: "center",
    width: "8.3333%",
  },
  graphContainer: {
    width: "100%",
    margin: "0 auto",
    textAlign: "center",
    position: "relative",
  },
  graphLine: {
    minHeight: 30,
    textAlign: "center",
  },
  openLine: {
    height: 30,
    width: "8.3333%",
    display: "inline-block",
  },
  executeLine: {
    height: 30,
    width: "8.3333%",
    display: "inline-block",
    // borderTop: "1px solid black",
    // borderBottom: "1px solid black",
    backgroundColor: "yellow",
  },
  arrowRight: {
    border: "solid black",
    borderWidth: "0 2px 2px 0",
    display: "inline-block",
    padding: "3px",
    transform: "rotate(-45deg)",
    WebkitTransform: "rotate(-45deg)",
  },
  arrowLeft: {
    border: "solid black",
    borderWidth: "0 2px 2px 0",
    display: "inline-block",
    padding: "3px",
    transform: "rotate(135deg)",
    WebkitTransform: "rotate(135deg)",
  },
  arrowUp: {
    border: "solid black",
    borderWidth: "0 2px 2px 0",
    display: "inline-block",
    padding: "3px",
    transform: "rotate(-135deg)",
    WebkitTransform: "rotate(-135deg)",
  },
  arrowDown: {
    border: "solid black",
    borderWidth: "0 2px 2px 0",
    display: "inline-block",
    padding: "3px",
    transform: "rotate(45deg)",
    WebkitTransform: "rotate(45deg)",
  },
}));

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function processLines(fyStart, period) {
  let months = new Array(12).fill(" ");
  let current = fyStart;
  for (let i = 0; i < months.length; i++) {
    if (period > 0) {
      if (i === 0) months[current - 1] = "S";
      else if (period === 1) months[current - 1] = "E";
      else months[current - 1] = "X";
      current = current + 1 > 12 ? 1 : current + 1;
      period--;
    }
  }
  return months;
}

function getQuarters(fyStart) {
  let quarters = [];
  while (fyStart - 3 > 0) {
    fyStart = fyStart - 3;
  }
  quarters.push(fyStart);
  quarters.push(fyStart + 3);
  quarters.push(fyStart + 6);
  quarters.push(fyStart + 9);

  return quarters;
}

function getQuartersLabel(fyStart) {
  let quarters = [];
  let quarter = 0;
  switch (fyStart) {
    case 1:
      quarter = 0;
      break;
    case 2:
      quarter = 0;
      break;
    case 3:
      quarter = 1;
      break;
    case 4:
      quarter = 1;
      break;
    case 5:
      quarter = 1;
      break;
    case 6:
      quarter = 2;
      break;
    case 7:
      quarter = 2;
      break;
    case 8:
      quarter = 2;
      break;
    case 9:
      quarter = 3;
      break;
    case 10:
      quarter = 3;
      break;
    case 11:
      quarter = 3;
      break;
    case 12:
      quarter = 0;
      break;
  }
  for (let i = 0; i < 4; i++) {
    quarters[quarter] = "Q" + (i + 1);
    quarter = quarter + 1 > 3 ? 0 : quarter + 1;
  }
  return quarters;
}

export default function KeyPeriods(props) {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;

  const [keyPeriodData, setKeyPeriodData] = React.useState(null);
  const [yearEnd, setYearEnd] = React.useState(null);
  const handleChange = (event) => {
    setYearEnd(event.target.value);
    processData(event.target.value, keyPeriodData);
  };

  const processData = (fyEnd, keyData) => {
    // console.log(`processData(${fyEnd}, ${JSON.stringify(keyData)}`);
    // console.log("key period data: " + JSON.stringify(keyPeriodData));
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthsAbbrev = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let fyStart = fyEnd === 12 ? 1 : fyEnd + 1;
    let closeDate = props.opportunity.CloseDate
      ? new Date(Date.parse(props.opportunity.CloseDate))
      : new Date();

    closeDate.setMonth(closeDate.getMonth() - (fyStart - 1));
    let dateObj = new Date();
    let currDate = new Date();
    // let dateObj = new Date(Date.parse('December 15, 2021'));
    let fyEndYear = dateObj.getFullYear();
    let currentQuarterEnd = "";
    let quarterIndex = 1;
    let current = false;

    let adjustedMonth = [];
    let adjustedMonthIdx = fyEnd === 12 ? 1 : fyEnd + 1;
    monthsAbbrev.map((row, index) => {
      adjustedMonth.push(monthsAbbrev[adjustedMonthIdx - 1]);
      if (dateObj.getUTCMonth() === adjustedMonthIdx - 1) current = true;
      if (current && quarterIndex % 3 === 0) {
        currentQuarterEnd = adjustedMonthIdx;
        current = false;
      }

      adjustedMonthIdx = adjustedMonthIdx === 12 ? 1 : adjustedMonthIdx + 1;
      quarterIndex++;
    });

    // Adjust current month to map to graph
    dateObj.setMonth(dateObj.getMonth() - (fyStart - 1));
    let currentQuarter =
      dateObj.getUTCMonth() < 3
        ? 1
        : dateObj.getUTCMonth() < 6
        ? 2
        : dateObj.getUTCMonth() < 9
        ? 3
        : 4;
    console.log(
      "current month: " +
        currDate.getMonth() +
        1 +
        ", currentQuarterEnd=" +
        currentQuarterEnd
    );
    if (currDate.getMonth() + 1 > 9 && currentQuarterEnd < 3) fyEndYear++;

    // D1: Hardcoding fyStart to align chart to beginning of periods.  The month labels will adapt
    fyStart = 1;

    setKeyPeriodData({
      Title: keyData.Title,
      FiscalYearStart: fyStart,
      FiscalYearEnd: months[fyEnd - 1] + " " + daysInMonth(fyEnd, fyEndYear),
      currentQuarter:
        "Q" +
        currentQuarter +
        " " +
        dateObj.getFullYear() +
        "/" +
        (dateObj.getFullYear() - 1999),
      currentQuarterEnd:
        monthsAbbrev[currentQuarterEnd - 1] +
        " " +
        daysInMonth(currentQuarterEnd, fyEndYear) +
        ", " +
        fyEndYear,
      ExecuteLine: processLines(fyStart, 6),
      DecisionLine: processLines(
        fyStart + 6 > 12 ? fyStart - 6 : fyStart + 6,
        3
      ),
      FinalizeLine: processLines(
        fyStart + 9 > 12 ? fyStart - 3 : fyStart + 9,
        3
      ),
      Quarters: getQuarters(fyStart),
      QuartersLabel: getQuartersLabel(fyStart),
      currentDay:
        dateObj.getUTCMonth() * 8.3333 + (dateObj.getUTCDate() / 30) * 8.3333,
      closeDay:
        closeDate.getUTCMonth() * 8.3333 +
        (closeDate.getUTCDate() / 30) * 8.3333,
      monthLabels: adjustedMonth,
    });
  };

  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    if (previewData) {
      // Call Stub
      setYearEnd(previewData.FiscalYearEnd);
      processData(previewData.FiscalYearEnd, previewData);
    } else {
      if (firstTime) {
        setFirstTime(false);
        // Call API
        let parameter = "/" + props.cardId; // cardId hardcoded for now
        axios
          .get(rule5properties.getCard + parameter)
          .then((response) => {
            // let columns=JSON.parse(response.data);
            let rawdata = response.data;
            if (rawdata.FiscalYearEnd) {
              // Validate content
              setYearEnd(rawdata.FiscalYearEnd);
              processData(rawdata.FiscalYearEnd, rawdata);
            } else {
              // API call failed
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              setSnackbarMessage(
                "There was a problem with the " + props.cardType + " Card"
              );
              setOpenSnackbar(true);
            }
          });
      }
    }
  }, [firstTime, previewData]);

  if (!keyPeriodData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  } else
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={keyPeriodData.Title}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <CardContent className={classes.cardContent}>
          <div
            className={classes.graphLine}
            style={{
              padding: "0px",
              paddingLeft: "20px",
              paddingRight: "20px",
              overflowX: "hidden",
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "33%", textAlign: "left" }}>
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Current Quarter:&nbsp;
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ whiteSpace: "nowrap" }}
                      fontWeight="700"
                    >
                      {keyPeriodData.currentQuarter}
                    </Typography>
                  </td>
                  <td style={{ width: "34%" }}>
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Current Quarter End:&nbsp;
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ whiteSpace: "nowrap" }}
                      fontWeight="700"
                    >
                      {keyPeriodData.currentQuarterEnd}
                    </Typography>
                  </td>
                  <td style={{ width: "33%", textAlign: "right" }}>
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      FY End:&nbsp;
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ whiteSpace: "nowrap" }}
                      fontWeight="700"
                    >
                      {keyPeriodData.FiscalYearEnd}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.graphContainer}>
            <div className={classes.graphLine}>
              <FormControl sx={{ width: "10em", display: "none" }}>
                <InputLabel id="demo-simple-select-label">
                  Fiscal Year End
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={yearEnd}
                  label="Fiscal Year End"
                  onChange={handleChange}
                >
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.graphContainer} style={{ minHeight: "20px" }}>
            {keyPeriodData.Quarters.map((row, index) => {
              if (keyPeriodData.FiscalYearStart % 3 === 1) {
                if (index === 3) {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          width: `calc(${8.3333 * 3 + "%"} - 12px)`,
                          left: `calc(${8.3333 * (row - 1) + "%"} + 7px)`,
                          height: 1,
                          borderTop: "1px solid black",
                        }}
                      />
                      <Typography
                        variant="caption"
                        component="div"
                        style={{
                          position: "absolute",
                          top: 2,
                          left: `calc(${
                            8.3333 * (row - 1) + 12.49995 + "%"
                          } - 9px)`,
                          padding: 0,
                          backgroundColor: "white",
                        }}
                      >
                        {keyPeriodData.QuartersLabel[index]}
                      </Typography>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          width: 1,
                          left: 8.3333 * (row - 1) + "%",
                          height: 25,
                          // borderLeft: "1px dashed #AAA",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          width: 1,
                          left: 8.3333 * (row + 2) + "%",
                          height: 25,
                          // borderLeft: "1px dashed #AAA",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          left: `calc(${8.3333 * (row - 1) + "%"} + 5px)`,
                        }}
                        className={classes.arrowLeft}
                      ></div>
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          left: `calc(${8.3333 * (row + 2) + "%"} - 12px)`,
                        }}
                        className={classes.arrowRight}
                      />
                    </div>
                  );
                } else
                  return (
                    <div key={index}>
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          width: `calc(${8.3333 * 3 + "%"} - 12px)`,
                          left: `calc(${8.3333 * (row - 1) + "%"} + 7px)`,
                          height: 1,
                          borderTop: "1px solid black",
                        }}
                      />
                      <Typography
                        variant="caption"
                        component="div"
                        style={{
                          position: "absolute",
                          top: 2,
                          left: `calc(${
                            8.3333 * (row - 1) + 12.49995 + "%"
                          } - 9px)`,
                          padding: 0,
                          backgroundColor: "white",
                        }}
                      >
                        {keyPeriodData.QuartersLabel[index]}
                      </Typography>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          width: 1,
                          left: 8.3333 * (row - 1) + "%",
                          height: 25,
                          // borderLeft: "1px dashed #AAA",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          left: `calc(${8.3333 * (row - 1) + "%"} + 5px)`,
                        }}
                        className={classes.arrowLeft}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          left: `calc(${8.3333 * (row + 2) + "%"} - 12px)`,
                        }}
                        className={classes.arrowRight}
                      />
                    </div>
                  );
              } else {
                if (index === 0) {
                  let rowPfx =
                    (keyPeriodData.FiscalYearStart - 1) % 3 === 2 ? 1 : 2.5;
                  return (
                    <div key={index}>
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          width: `calc(${
                            8.3333 * ((keyPeriodData.FiscalYearStart - 1) % 3) +
                            "%"
                          } - 5px)`,
                          left: 0,
                          height: 1,
                          borderTop: "1px solid black",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          width: `calc(${
                            8.3333 *
                              (3 - ((keyPeriodData.FiscalYearStart - 1) % 3)) +
                            "%"
                          } - 5px)`,
                          right: 0,
                          height: 1,
                          borderTop: "1px solid black",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          width: 1,
                          left: 8.3333 * (row - 1) + "%",
                          height: 25,
                          borderLeft: "1px dashed #AAA",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          left: `calc(${8.3333 * (row - 1) + "%"} + 5px)`,
                        }}
                        className={classes.arrowLeft}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          left: `calc(${8.3333 * (row - 1) + "%"} - 12px)`,
                        }}
                        className={classes.arrowRight}
                      />
                      <Typography
                        variant="caption"
                        component="div"
                        style={{
                          position: "absolute",
                          top: 2,
                          left: `calc(${8.3333 * rowPfx + "%"} - 9px)`,
                          padding: 0,
                          backgroundColor: "white",
                          zIndex: 10,
                        }}
                      >
                        {keyPeriodData.QuartersLabel[index]}
                      </Typography>
                    </div>
                  );
                } else {
                  let rowPfx = keyPeriodData.FiscalYearStart % 3 === 2 ? 1 : 4;
                  return (
                    <div key={index}>
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          width: `calc(${8.3333 * 3 + "%"} - 12px)`,
                          left: `calc(${8.3333 * (row - 4) + "%"} + 7px)`,
                          height: 1,
                          borderTop: "1px solid black",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          width: 1,
                          left: 8.3333 * (row - 1) + "%",
                          height: 25,
                          // borderLeft: "1px dashed #AAA",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          left: `calc(${8.3333 * (row - 1) + "%"} + 5px)`,
                        }}
                        className={classes.arrowLeft}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 6,
                          left: `calc(${8.3333 * (row - 1) + "%"} - 12px)`,
                        }}
                        className={classes.arrowRight}
                      />
                      <Typography
                        variant="caption"
                        component="div"
                        style={{
                          position: "absolute",
                          top: 2,
                          left: `calc(${
                            8.3333 * (row - rowPfx) + 12.49995 + "%"
                          } - 9px)`,
                          padding: 0,
                          backgroundColor: "white",
                          zIndex: 10,
                        }}
                      >
                        {keyPeriodData.QuartersLabel[index]}
                      </Typography>
                    </div>
                  );
                }
              }
            })}
          </div>
          <div className={classes.graphContainer}>
            <div className={classes.graphLine}>
              {keyPeriodData.monthLabels.map((row, index) => {
                return (
                  <Typography
                    key={index}
                    variant="caption"
                    className={classes.monthLabel}
                    component="div"
                  >
                    {row}
                  </Typography>
                );
              })}
            </div>
            {keyPeriodData.Quarters.map((row, index) => {
              if (keyPeriodData.FiscalYearStart % 3 === 1) {
                if (index === 3) {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          position: "absolute",
                          top: 25,
                          width: 1,
                          left: 8.3333 * (row - 1) + "%",
                          height: 180,
                          borderLeft: "1px dashed #AAA",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 25,
                          width: 1,
                          left: 8.3333 * (row + 2) + "%",
                          height: 180,
                          borderLeft: "1px dashed #AAA",
                        }}
                      />
                    </div>
                  );
                }
              }
              return (
                <div
                  key={index}
                  style={{
                    position: "absolute",
                    top: 25,
                    width: 1,
                    left: 8.3333 * (row - 1) + "%",
                    height: 180,
                    borderLeft: "1px dashed #AAA",
                  }}
                />
              );
            })}
            <div
              style={{
                position: "absolute",
                top: 25,
                width: 1,
                left: keyPeriodData.currentDay + "%",
                height: 215,
                borderLeft: "1px dashed #000",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 235,
                left: `calc(${keyPeriodData.currentDay + "%"} - 11px)`,
              }}
              // className={classes.arrowDown}
            >
              <ArrowDropUpIcon />
            </div>
            <Typography
              variant="caption"
              component="div"
              style={{
                position: "absolute",
                top: 250,
                left: `calc(${keyPeriodData.currentDay + "%"} - 15px)`,
              }}
            >
              Today
            </Typography>
            {props.opportunity.CloseDate && (
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: 25,
                    width: 1,
                    left: keyPeriodData.closeDay + "%",
                    height: 172,
                    borderLeft: "1px dashed #0288D1",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 198,
                    left: `calc(${keyPeriodData.closeDay + "%"} - 11px)`,
                    // borderColor: "blue",
                  }}
                  // className={classes.arrowDown}
                >
                  <CircleIcon style={{ color: "#0288D1" }} />
                </div>
                <Typography
                  variant="caption"
                  component="div"
                  style={{
                    position: "absolute",
                    top: 222,
                    left: `calc(${keyPeriodData.closeDay + "%"} - 30px)`,
                    // color: "blue",
                    color: "#0288D1",
                  }}
                >
                  Close Date
                </Typography>
              </div>
            )}
            <div className={classes.graphLine} />
            {/*
            <Typography variant="h6" component="div" style={{ position: 'absolute', color: '#888', left: `calc(50% - 4em)`, minHeight: '' }} >
              Execute Projects
            </Typography>
            */}
            <Typography
              variant="subtitle2"
              component="div"
              style={{
                position: "absolute",
                color: "#000",
                width: "22em",
                left: `calc(${8.3333 * 3 + "%"} - 11em)`,
                minHeight: "",
              }}
            >
              <div className={classes.graphLine} style={{ paddingTop: "5px" }}>
                Execute Projects
              </div>
              <Typography
                variant="caption"
                component="div"
                style={{ color: "#888", fontStyle: "italic" }}
              >
                Focus on implementing approved projects
              </Typography>
            </Typography>
            <div className={classes.graphLine} style={{ textAlign: "left" }}>
              {keyPeriodData.ExecuteLine.map((row, index) => {
                let bgColor = "#FDD835";
                if (row === "X")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{ backgroundColor: bgColor }}
                    />
                  );
                else if (row === "S")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{
                        backgroundColor: bgColor,
                        borderRadius: "5px 0px 0px 5px",
                        // borderLeft: "1px solid black",
                      }}
                    />
                  );
                else if (row === "E")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{
                        backgroundColor: bgColor,
                        borderRadius: "0px 5px 5px 0px",
                        // borderRight: "1px solid black",
                      }}
                    />
                  );
                else return <div key={index} className={classes.openLine} />;
              })}
            </div>
            <Typography
              variant="subtitle2"
              component="div"
              style={{
                position: "absolute",
                color: "#000",
                width: "16em",
                left: `calc(${8.3333 * 7.5 + "%"} - 8em)`,
                minHeight: "",
              }}
            >
              <div className={classes.graphLine} style={{ paddingTop: "5px" }}>
                Key Decision Period
              </div>
              <Typography
                variant="caption"
                component="div"
                style={{ color: "#888", fontStyle: "italic" }}
              >
                Budgeting and Planning
              </Typography>
            </Typography>
            <div className={classes.graphLine} style={{ textAlign: "left" }}>
              {keyPeriodData.DecisionLine.map((row, index) => {
                let bgColor = "#8BC34A";
                if (row === "X")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{ backgroundColor: bgColor }}
                    />
                  );
                else if (row === "S")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{
                        backgroundColor: bgColor,
                        borderRadius: "5px 0px 0px 5px",
                        // borderLeft: "1px solid black",
                      }}
                    />
                  );
                else if (row === "E")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{
                        backgroundColor: bgColor,
                        borderRadius: "0px 5px 5px 0px",
                        // borderRight: "1px solid black",
                      }}
                    />
                  );
                else return <div key={index} className={classes.openLine} />;
              })}
            </div>
            <Typography
              variant="subtitle2"
              component="div"
              style={{
                position: "absolute",
                color: "#000",
                width: "16em",
                left: `calc(${8.3333 * 10.5 + "%"} - 8em)`,
                minHeight: "",
              }}
            >
              <div className={classes.graphLine} style={{ paddingTop: "5px" }}>
                Finalize Budgets
              </div>
              <Typography
                variant="caption"
                component="div"
                style={{ color: "#888", fontStyle: "italic" }}
              >
                Lock budget for next fiscal year
              </Typography>
            </Typography>
            <div className={classes.graphLine} style={{ textAlign: "left" }}>
              {keyPeriodData.FinalizeLine.map((row, index) => {
                let bgColor = "#E64A19";
                if (row === "X")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{ backgroundColor: bgColor }}
                    />
                  );
                else if (row === "S")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{
                        backgroundColor: bgColor,
                        borderRadius: "5px 0px 0px 5px",
                        // borderLeft: "1px solid black",
                      }}
                    />
                  );
                else if (row === "E")
                  return (
                    <div
                      key={index}
                      className={classes.executeLine}
                      style={{
                        backgroundColor: bgColor,
                        borderRadius: "0px 5px 5px 0px",
                        // borderRight: "1px solid black",
                      }}
                    />
                  );
                else return <div key={index} className={classes.openLine} />;
              })}
            </div>
          </div>
          <div className={classes.graphLine} />
          <div className={classes.graphLine} />
          <div className={classes.graphLine} />
        </CardContent>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
}
