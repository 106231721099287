import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import RichTextEditor from "../util/RichTextEditor";
import {
  convertHtmlToRawContentState,
  formatUrl as formatStorageUrl,
} from "../common/utils";
import { TitleInput } from "../util/CardEditorButtons";
import { Button, Checkbox, IconButton, Snackbar } from "@mui/material";
import axios from "axios";
import { rule5properties } from "../../properties";
import CloseIcon from "@mui/icons-material/Close";
import { useDialog } from "../../context/DialogContext";
import RteArrayCard from "../../workspaces/opportunity/research/cards/IndustryTrends";

export default function ExecutiveSummaryEditor(props) {
  const {
    register,
    control,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext();
  const openDialog = useDialog();

  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [cardData, setCardData] = React.useState({});

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const cardDataParams = new URLSearchParams({
      cardType: "Executive Summary",
      functionalArea: props.functionalArea,
      companyId: props.companyId,
      expandedOutput: true,
    });

    axios
      .get(rule5properties.detCardData + "?" + cardDataParams)
      .then((resp) => {
        setCardData(resp.data);
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("ERROR: " + err);
        setSnackbarOpen(true);
      });
  }, []);

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function getBulletListContentState(textArray) {
    const markup = "<ul><li>" + textArray?.join("</li><li>") + "</li></ul>";
    return convertHtmlToRawContentState(markup);
  }

  return (
    <div>
      <input
        {...register(`companyId`)}
        hidden={true}
        defaultValue={props.companyId}
      />
      <input
        {...register(`functionalArea`)}
        hidden={true}
        defaultValue={props.functionalArea}
      />
      <TitleInput register={register} />
      <br />
      <div style={{ paddingBottom: "20px" }}>
        <Controller
          name="IsAutomated"
          control={control}
          render={({ field }) => (
            <Checkbox
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          )}
        />
        <label>Use Automated Exec Summary</label>
      </div>
      <Button
        style={{ textTransform: "none", marginBottom: "30px" }}
        color="primary"
        variant="outlined"
        onClick={() => {
          const strategicPriorities = cardData["Strategic Priorities"]?.map(
            (trend) => trend.Title
          );
          const strategicInitiatives = cardData["Strategic Initiatives"]?.map(
            (trend) => trend.Title
          );
          const businessChallenges = cardData["Business Challenges"]?.map(
            (trend) => trend.Title
          );
          setValue(
            "Strategic Priorities",
            getBulletListContentState([
              ...[...(strategicPriorities || [])],
              ...[...(strategicInitiatives || [])],
            ]),
            { shouldDirty: true }
          );
          setValue(
            "Business Challenges",
            getBulletListContentState([...[...(businessChallenges || [])]]),
            { shouldDirty: true }
          );
        }}
      >
        Autopopulate RTE's (this overwrites!)
      </Button>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "20px",
          paddingBottom: "30px",
        }}
      >
        Strategic Priorities (manually condensed for Exec Summary)
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Button
            variant="contained"
            disabled={!cardData["Strategic Priorities"]}
            style={{ marginRight: "20px" }}
            onClick={() => {
              openDialog.openModal("Strategic Priorities", RteArrayCard, {
                previewData: { Trend: cardData["Strategic Priorities"] },
                toggleExpanded: () => {},
                expanded: true,
              });
            }}
          >
            View Strategic Priorities
          </Button>
          <Button
            variant="contained"
            disabled={!cardData["Strategic Initiatives"]}
            onClick={() => {
              openDialog.openModal("Strategic Initiatives", RteArrayCard, {
                previewData: { Trend: cardData["Strategic Initiatives"] },
                toggleExpanded: () => {},
                expanded: true,
              });
            }}
          >
            View Strategic Initiatives
          </Button>
        </div>
        <Controller
          style={{ flexBasis: "100%" }}
          name="Strategic Priorities"
          control={control}
          render={({ field }) => {
            return (
              <RichTextEditor value={field.value} onChange={field.onChange} />
            );
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "20px",
          paddingBottom: "30px",
        }}
      >
        Business Challenges (manually condensed for Exec Summary)
        <Button
          variant="contained"
          disabled={!cardData["Business Challenges"]}
          onClick={() => {
            openDialog.openModal("Business Challenges", RteArrayCard, {
              previewData: { Trend: cardData["Business Challenges"] },
              toggleExpanded: () => {},
              expanded: true,
            });
          }}
        >
          View latest source card
        </Button>
        <Controller
          style={{ flexBasis: "100%" }}
          name="Business Challenges"
          control={control}
          render={({ field }) => {
            return (
              <RichTextEditor value={field.value} onChange={field.onChange} />
            );
          }}
        />
      </div>
      <h3>Legacy content below</h3>
      Image Content
      <br />
      <Controller
        control={control}
        defaultValue=""
        name={"Image"}
        render={({ field }) => {
          return (
            <input
              style={{ width: "100%" }}
              {...field}
              onChange={(e) => field.onChange(formatStorageUrl(e.target.value))}
            />
          );
        }}
      />
      <br />
      <br />
      {!getValues("Image") && (
        <>
          -OR-
          <br />
          <hr></hr>
          <h5>Content:</h5>
          <div>
            <Controller
              name="Content"
              control={control}
              rules={{
                required: { value: true, message: "RTE text is required" },
              }}
              render={({ field }) => {
                return (
                  <RichTextEditor
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            />
            {errors?.Content?.type === "required" && (
              <p style={{ color: "red" }}>{errors.Content.message}</p>
            )}
          </div>
          <br />
          <br />
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
        action={snackbarAction}
      />
    </div>
  );
}
