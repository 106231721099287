import React from "react";
import {
  Collapse,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { StyledTextfield, ErrLabel } from "../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../properties";
import { useDialog } from "../context/DialogContext";
import { useForm } from "react-hook-form";
import { onEnterPress } from "../common/Utils";
import NewPromptOptions from "../workspaces/prompts/NewPromptOptions";

export default function CreatePrompt(props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [promptParams, setPromptParams] = React.useState({});
  const { register, handleSubmit, getValues } = useForm();

  const { loadPromptBooks, promptBook, addExpandedId } = props;

  const dialog = useDialog();

  const onSubmit = (data, event) => {
    event.preventDefault();
    addPrompt(data.newPrompt);
  };

  const addPrompt = (newPrompt) => {
    const params = {
      promptBookId: promptBook.id,
      prompts: [
        {
          prompt: newPrompt,
          ...promptParams,
        },
      ],
    };
    axios
      .post(rule5properties.promptBookPrompts, params)
      .then((resp) => {
        if (resp.status === 200) {
          loadPromptBooks();
          addExpandedId(promptBook.id);
          dialog.closeModal();
          setLoading(false);
        } else {
          setError(resp?.data?.message || "Unexpected error creating prompt.");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <Box sx={{}}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          <Typography sx={{ width: "100%" }}>{promptBook?.name}</Typography>
          <StyledTextfield
            style={{
              width: "100%",
              backgroundColor: "inherit",
              fontSize: "0.9rem",
              minHeight: "200px",
              maxHeight: "calc(80vh - 200px)",
              display: "block",
              overflow: "scroll",
            }}
            inputRef={(input) => input && input.focus()}
            {...register("newPrompt")}
            autoFocus
            onKeyDown={onEnterPress(() => addPrompt(getValues("newPrompt")))}
            multiline
            placeholder="New prompt"
          ></StyledTextfield>
          <NewPromptOptions setPromptParams={setPromptParams} />
          <Collapse in={error !== ""}>
            <ErrLabel>{error}</ErrLabel>
          </Collapse>
          <Button
            sx={{
              textTransform: "none",
            }}
            color="primary"
            variant="outlined"
            onClick={dialog.closeModal}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            sx={{ width: "100px" }}
            variant="contained"
          >
            <Typography
              variant="button"
              sx={{ textTransform: "none", mt: "3px" }}
            >
              Create
            </Typography>
            {loading ? (
              <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
            ) : null}
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
}
