import React from "react";
import { Avatar, Link, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getColorFromString } from "./Utils";
import Image from "./Image";

const useStyles = makeStyles(() => ({
  connectionsProfilePic: {
    position: "relative",
    padding: 0,
    width: "20px",
    height: "20px",
    borderRadius: "70px",
    "&.MuiAvatar-root ": {
      width: "20px",
      height: "20px",
    },
  },
  connectionsText: {
    whiteSpace: "nowrap",
    fontSize: ".750rem",
    color: "rgba(0, 0, 0, 0.6) ",
  },
}));

export default function PeopleConnectionsList(props) {
  const classes = useStyles();
  const { connections, companyName, orgName, displayEcosystemOrgs, imageMap } =
    props;
  const numToDisplay = 3;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        marginTop: "5px",
      }}
    >
      <Typography className={classes.connectionsText}>Connected to</Typography>
      {/* TODO this should just be a loop, so can break out */}
      {connections.map((person, index) => {
        if (index === numToDisplay) {
          let endText =
            !displayEcosystemOrgs && companyName ? `at ${companyName}` : "";
          const remaining = connections.length - index;
          if (index < connections.length) {
            // 'others' if more than one extra, otherwise 'other'
            endText =
              `and ${remaining} other${remaining > 1 ? "s" : ""} ` + endText;
          }
          return (
            <Typography
              key={index}
              component="div"
              className={classes.connectionsText}
              sx={{
                marginLeft: "4px !important",
              }}
            >
              {endText}
            </Typography>
          );
        } else if (index < numToDisplay) {
          const ProfilePic = imageMap ? (
            <img
              src={imageMap.get(person.linkedinProfileId)}
              className={classes.connectionsProfilePic}
              alt={person.profileInfo?.FullName}
            />
          ) : (
            <Image
              src={person.personInfo?.avatar_url}
              className={classes.connectionsProfilePic}
            />
          );
          return (
            <React.Fragment key={index}>
              <Avatar
                className={classes.connectionsProfilePic}
                sx={{
                  width: "20px",
                  height: "20px",
                  fontSize: ".750rem",
                  bgcolor: getColorFromString(person.personInfo?.full_name),
                }}
              >
                {person.personInfo?.avatar_url != null
                  ? ProfilePic
                  : person.personInfo?.full_name?.toUpperCase().charAt(0)}
              </Avatar>
              <Typography className={classes.connectionsText}>
                <Link
                  color="inherit"
                  href={
                    "https://www.linkedin.com/in/" + person.linkedinProfileId
                  }
                  target="_blank"
                >
                  {person.personInfo?.full_name}
                  {orgName !== person.orgName &&
                    displayEcosystemOrgs &&
                    "@" + person.orgName}
                </Link>
              </Typography>
            </React.Fragment>
          );
        }
      })}
    </Stack>
  );
}
